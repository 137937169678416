import React, { useState } from 'react';
import {
  Box,
  Typography,
  Alert
} from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../shared/constants';


const ShortQuestions = ({
    handleClose,
    handleSave,
    data,
  }) => {
    const [ questions, setQuestions] = useState(data.questions || []);
    const dragItem = React.useRef(null);
    const dragOverItem = React.useRef(null);
 
    const handleSort = () => {
      let _questions = [...questions];

      const draggedItemContent = _questions.splice(dragItem.current, 1)[0];

      _questions.splice(dragOverItem.current, 0, draggedItemContent);

      dragItem.current = null;
      dragOverItem.current = null;

      setQuestions(_questions);
    };

    return (
      <div>
         <Dialog
          maxWidth="sm"
          fullWidth="sm"
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ mb: 2 }}>
            <Typography 
              variant="h5" 
              style={{ color: '#1a3072' }} 
              fontWeight="bold" 
            >
              Organizar preguntas<span style={{ color: '#00b0ef' }}> Evaluación</span>
            </Typography>
            <Typography 
              variant="body2" 
              style={{ color: '#666' }} 
              sx={{ mt: 2, mb: 1.5 }} // Ajuste del margen inferior para dar espacio antes del contenido
            >
               <Alert severity="info">Arrastra y suelta las preguntas para reorganizarlas según tu preferencia.</Alert>
              
            </Typography>
          </DialogTitle>
          <DialogContent>
            <div className="list-sort">
              {questions.map((item, index) => (
                <Box
                  sx={{ p: 2, border: '1px solid grey' }}
                  key={index}
                  className="list-item"
                  draggable
                  onDragStart={(e) => (dragItem.current = index)}
                  onDragEnter={(e) => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <i className="fa-solid fa-bars"></i>
                  <h3>{item.questionText}</h3>
                </Box>
              ))}
            </div>

          </DialogContent>
          <DialogActions sx={{ m: 3 }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                   data.questions = [...questions];
                   handleSave(data);
                }}
                endIcon={<SaveIcon />}
              >
                {ACTIONS.SAVE}
              </Button>
            </Box>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                endIcon={<CancelIcon />}
                variant="outlined"
                onClick={() => { handleClose(false) }} autoFocus>{ACTIONS.CANCEL}</Button>
            </Box>
          </DialogActions>
        </Dialog>
    </div>
    );
  }

export default ShortQuestions;