import { 
  getAllPositionHomologationInform,
  saveGroupHomologation, 
  editGroupHomologation, 
  deleteGroupHomologation, 
  validateGroupHomologation, 
  getGroupHomologation, 
  savePositions 
} from '../../../shared/data-services/positionHomologation-service';
import { 
  getEmployeePositions 
} from '../../../shared/data-services/employee-position-service';

export async function getPositionHomologation(){
  const data = await getAllPositionHomologationInform();
  return data || [ ];
};

export async function getAllEmployeePosition(){
  const data = await getEmployeePositions();
  return data || [ ];
};

export async function saveSelectedGroupHomologation (id, name){
  const groupHomologation = {
    id, 
    name
  }

  const data = await saveGroupHomologation(groupHomologation);
  return data;
};

export async function editSelectedGroupHomologation (id, name){
  const groupHomologation = {
    id, 
    name
  }
  await editGroupHomologation(groupHomologation);
  return;
};

export async function deleteSelectedGroupHomologation (id){
  const data = await deleteGroupHomologation(id);
  return data;
};

export async function validateSelectedGroupHomologation (id){
  const data = await validateGroupHomologation(id);
  return data;
};

export async function getSelectedGroupHomologation (id){
  const data = await getGroupHomologation(id);
  return data;
};

export async function saveAllPositions (positions, groupId){
  const data = await savePositions(positions, groupId);
  return data;
};