import React, { useState } from 'react';
import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  FormControl,
  MenuItem,
  Switch,
  Typography,
  Select,
  InputLabel,
  IconButton,
  Divider,
  Alert,
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { ACTIONS } from '../../../shared/constants';


const AssessmentDetails = ({
    handleClose,
    handleSave,
    data,
    questionTypes,
  }) => {
    const [ name, setName ] = useState(data.name);
    const [ starDate, setStarDate ] = useState(data.starDate || new Date());
    const [ endDate, setEndDate ] = useState(data.endDate || new Date());
    const [ randomOrder, setRandomOrder ] = useState(data.randomOrder);
    const [ numberOfAttempts, setNumberOfAttempts ] = useState(data.numberOfAttempts);
    const [ questionsToShow, setQuestionsToShow ] = useState(data.questionsToShow);
    const [ showFeedback, setShowFeedback ] = useState(data.showFeedback);
    const [ feedback, setFeedback ] = useState(data.feedback);
    const [ navigationBetweenQuestions , setNavigationBetweenQuestions ] = useState(data.navigationBetweenQuestions);
    const [ active, setActive ] = useState(data.active);
    const [ questions, setQuestions] = useState(data.questions || []);
    const [ minimunScoreToWin, setMinimunScoreToWin] = useState(data.minimunScoreToWin || 0);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = data;
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);


    const validateForm = () => {
      return !(name && numberOfAttempts && questionsToShow
              && ((new Date(starDate)).getTime() <= (new Date(endDate)).getTime())
              && minimunScoreToWin
              && evaluateQuestions()
        );
    }

    const evaluateQuestions = () => {
        if (!questions) return false;
        if (questions.length === 0)  return false;
        if (questions.find((question) => !question.questionText ||  question.questionText.length == 0 )) return false;
        if (questions.find((question) => !question.score )) return false;
        if (questions.find((question) => !question.questionTypeId )) return false;
        // responses
        if (questions.find((question) => question.optionByQuestions?.length == 0 )) return false;
        if (questions.find((question) => question.optionByQuestions?.some(o => !o.optionText) )) return false;
        return true; 
    }

    const handleQuestionChange = (position, property, value) => {
      let localquestions = [...questions];
      localquestions[position][property] = value;
      setQuestions(localquestions);
    }

    const handleOptionChange = (position, property, value,optionPositition) => {
      let localquestions = [...questions];
      let options =  [...localquestions[position].optionByQuestions];
      options[optionPositition][property] = value;
      localquestions[position].optionByQuestions = options;
      setQuestions(localquestions);
    }
    
    const handleNewOption = (position,e) => {
      e.stopPropagation();
      let localquestions = [...questions];
      let options =  [...localquestions[position].optionByQuestions];
      localquestions[position].optionByQuestions = [...options, {}];
      setQuestions(localquestions);
      enqueueSnackbar("Nueva respuesta creada!");
    }

    const handleNewQuestion = () => {
      let newQuestion = { id: 0, optionByQuestions: [] };
      setQuestions([...questions, newQuestion]);
      enqueueSnackbar("Nueva pregunta creada!");
    }

    const handleDeleteQuestion = (index) => {
      let data = [...questions];
      data.splice(index, 1)
      setQuestions(data);
      enqueueSnackbar("Pregunta borrada!");
    } 

    const handleDeleteOption = (position, optionPosition) => {
      let data = [...questions];
      let options =  [...data[position].optionByQuestions];
      options.splice(optionPosition, 1);
      data[position].optionByQuestions = [...options];
      setQuestions(data);
      enqueueSnackbar("Respuesta borrada!");
    } 

    return (
      <div   >
        <Dialog
          maxWidth="xl"
          fullWidth="xl"
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
              Detalle <span style={{ color: '#00b0ef' }}>Evaluación</span>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p />
            <span><i>Los campos marcados con (*) son obligatorios</i></span>
            {isMobile && <>
                            <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="Nombre (*)"
                                placeholder="Nombre (*)"
                                value={name || ''}
                                onChange={(e) => setName(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-ES'}>
                            <FormControl fullWidth={true} margin="dense">
                              <DesktopDatePicker
                                label="Inicio publicación (*)"
                                inputFormat="DD/MM/YYYY"
                                value={starDate}
                                fullWidth
                                onChange={(e) => setStarDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </FormControl>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-ES'}>
                            <FormControl fullWidth={true} margin="dense">
                              <DesktopDatePicker
                                label="Finaliza publicación (*)"
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                fullWidth
                                onChange={(e) => setEndDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </FormControl>
                          </LocalizationProvider>
                          <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="# Intentos Día (*)"
                                placeholder="Cantidad Intentos (*)"
                                value={numberOfAttempts || ''}
                                onChange={(e) => setNumberOfAttempts(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                          <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="Preguntas Mostrar (*)"
                                placeholder="Preguntas Mostrar (*)"
                                value={questionsToShow || ''}
                                onChange={(e) => setQuestionsToShow(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                          <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="Puntaje Mínimo (*)"
                                placeholder="Puntaje Mínimo (*)"
                                value={minimunScoreToWin || ''}
                                onChange={(e) => setMinimunScoreToWin(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                          {/* <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={navigationBetweenQuestions || ''}
                              onChange={(e) => setNavigationBetweenQuestions(e.target.checked)}
                                />} label="Navegación" />
                          </FormControl> */}
                      <p />
                          <FormControl fullWidth={true} margin="dense">
                                <TextField
                                  label="Retroalimentación"
                                  placeholder="Retroalimentación"
                                  value={feedback || ''}
                                  onChange={(e) => setFeedback(e.target.value)}
                                  require
                                  fullWidth
                                  multiLine={true}
                                  rows={2}
                                />
                              </FormControl>
                          <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={showFeedback || ''}
                              onChange={(e) => setShowFeedback(e.target.checked)}
                                />} label="Mostrar Retroalimentación" />
                          </FormControl>
                          <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={randomOrder || ''}
                              onChange={(e) => setRandomOrder(e.target.checked)}
                                />} label="Ordenar las preguntas" />
                          </FormControl>
                          <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={active || ''}
                              onChange={(e) => setActive(e.target.checked)}
                                />} label="Esta activa?" />
                          </FormControl>
                      <p />
                          <Grid container spacing={4}>
                            <Grid item xs={2}>
                              <IconButton
                                  color="primary"
                                  onClick={(e) => { handleNewQuestion(e) }}
                                >
                                  <AddCircleIcon />
                              </IconButton>
                            </Grid> 
                            <Grid item xs={10}>
                              <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" >
                                    {'Preguntas (*) - ' + questions.length}
                              </Typography>
                            </Grid>
                          </Grid> 
                      <p />
                            {questions.map((item,index) => {
                                  const tittleQuestion = "Pregunta (*) # "+ (index + 1);

                                  return (
                                    <>
                                      <Divider light />
                                      <p />
                                          <FormControl fullWidth={true} margin="dense">
                                            <TextField
                                              label={tittleQuestion}
                                              placeholder={tittleQuestion}
                                              value={item.questionText || ''}
                                              onChange={(e) => handleQuestionChange(index, 'questionText' , e.target.value)}
                                              require
                                              fullWidth
                                            />
                                          </FormControl>
                                          <FormControl fullWidth={true} margin="dense">
                                            <TextField
                                              label="Puntos (*)"
                                              placeholder="Puntuación (*)"
                                              value={item.score || ''}
                                              onChange={(e) => handleQuestionChange(index, 'score' , e.target.value)}
                                              require
                                              fullWidth
                                            />
                                          </FormControl>
                                          <FormControl fullWidth={true} margin="dense">
                                            <TextField
                                              label="Retroalimentación"
                                              placeholder="Retroalimentación"
                                              value={item.feedback || ''}
                                              onChange={(e) => handleQuestionChange(index, 'feedback' , e.target.value)}
                                              require
                                              fullWidth
                                            />
                                          </FormControl>
                                          <FormControl>
                                            <FormControlLabel control={<Switch 
                                              checked={item.showOptionsInRandomOrder || ''}
                                              onChange={(e) => handleQuestionChange(index,'showOptionsInRandomOrder',e.target.checked)}
                                                />} label="Ordenar Respuestas" />
                                          </FormControl>
                                          <FormControl>
                                            <FormControlLabel control={<Switch 
                                              checked={item.showFeedback || ''}
                                              onChange={(e) => handleQuestionChange(index,'showFeedback',e.target.checked)}
                                                />} label="Mostrar Retroalimentación" />
                                          </FormControl>
                                          <Grid container spacing={1}>
                                            <Grid item xs={10}>
                                              <FormControl fullWidth>
                                                  <InputLabel id="demo-simple-select-label">Tipo pregunta (*)</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Tipo pregunta (*)"
                                                    fullWidth
                                                    value={item.questionTypeId || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'questionTypeId' , e.target.value)}
                                                  >
                                                    {questionTypes.map(item => {
                                                      return (<MenuItem value={item.id}>{item.description}</MenuItem>);
                                                    })}
                                                  </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={1}>
                                              <IconButton
                                                    color="error"
                                                    onClick={(e) => { handleDeleteQuestion(index) }}
                                                  >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                          </Grid> 
                                      <p />    
                                      
                                              
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >

                                            <Grid container spacing={5}>
                                              <Grid item xs={1}>
                                                <IconButton
                                                    color="success"
                                                    onClick={(e) => { handleNewOption(index,e) }}
                                                  >
                                                    <AddCircleIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid item xs={10}>
                                                <Typography><b>Respuestas - {item.optionByQuestions.length}</b></Typography>
                                              </Grid>
                                            </Grid>

                                          
                                          
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {item.optionByQuestions.map((option,indexOption) => {
                                                  const tittle = "Respuesta "+ (indexOption + 1);
                                                  return (<>
                                                      <FormControl fullWidth={true} margin="dense">
                                                        <TextField
                                                          label={tittle}
                                                          placeholder={tittle}
                                                          value={option.optionText || ''}
                                                          onChange={(e) => handleOptionChange(index, 'optionText' , e.target.value, indexOption)}
                                                          require
                                                          fullWidth
                                                        />
                                                      </FormControl>
                                                      <FormControl>
                                                        <FormControlLabel control={<Switch 
                                                          checked={option.correctAnswer || ''}
                                                          onChange={(e) => handleOptionChange(index, 'correctAnswer' , e.target.checked, indexOption)}
                                                            />} label="Respuesta correcta" />
                                                      </FormControl>
                                                        <IconButton
                                                            color="error"
                                                            onClick={(e) => { handleDeleteOption(index,indexOption) }}
                                                          >
                                                            <DeleteIcon />
                                                        </IconButton>
                                              </>)
                                              }
                                            )}
                                        </AccordionDetails>
                                        
                                      </Accordion>
                                      
                                      <p />
                                    </>);
                                })}
            </>
            }
            {!isMobile && <>
                      <Grid container spacing={4}>
                          <Grid item xs={3}>
                            <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="Nombre (*)"
                                placeholder="Nombre (*)"
                                value={name || ''}
                                onChange={(e) => setName(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                        <Grid item xs={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-ES'}>
                            <FormControl fullWidth={true} margin="dense">
                              <DesktopDatePicker
                                label="Inicio publicación (*)"
                                inputFormat="DD/MM/YYYY"
                                value={starDate}
                                fullWidth
                                onChange={(e) => setStarDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </FormControl>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-ES'}>
                            <FormControl fullWidth={true} margin="dense">
                              <DesktopDatePicker
                                label="Finaliza publicación (*)"
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                fullWidth
                                onChange={(e) => setEndDate(e)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </FormControl>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={1.3}>
                          <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="# Intentos Día (*)"
                                placeholder="Cantidad Intentos (*)"
                                value={numberOfAttempts || ''}
                                onChange={(e) => setNumberOfAttempts(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="Preguntas Mostrar (*)"
                                placeholder="Preguntas Mostrar (*)"
                                value={questionsToShow || ''}
                                onChange={(e) => setQuestionsToShow(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1.3}>
                          <FormControl fullWidth={true} margin="dense">
                              <TextField
                                label="Puntaje Mínimo (*)"
                                placeholder="Puntaje Mínimo (*)"
                                value={minimunScoreToWin || ''}
                                onChange={(e) => setMinimunScoreToWin(e.target.value)}
                                require
                                fullWidth
                              />
                            </FormControl>
                        </Grid>
                      </Grid>
                      <p />
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl fullWidth={true} margin="dense">
                                <TextField
                                  label="Retroalimentación"
                                  placeholder="Retroalimentación"
                                  value={feedback || ''}
                                  onChange={(e) => setFeedback(e.target.value)}
                                  require
                                  fullWidth
                                  multiLine={true}
                                  rows={2}
                                />
                              </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                          <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={showFeedback || ''}
                              onChange={(e) => setShowFeedback(e.target.checked)}
                                />} label="Mostrar Retroalimentación" />
                          </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                          <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={randomOrder || ''}
                              onChange={(e) => setRandomOrder(e.target.checked)}
                                />} label="Orden Aleatorio" />
                          </FormControl>
                        </Grid>                
                        
                        <Grid item xs={2}>
                          <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={active || ''}
                              onChange={(e) => setActive(e.target.checked)}
                                />} label="Activo" />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={2}>
                          <FormControl>
                            <FormControlLabel control={<Switch 
                              checked={navigationBetweenQuestions || ''}
                              onChange={(e) => setNavigationBetweenQuestions(e.target.checked)}
                                />} label="Navegación" />
                          </FormControl>
                        </Grid> */}
                      </Grid>
                      <p />
                      <Grid container spacing={4}>
                          <Grid item xs={0.5}>
                            <IconButton
                                color="primary"
                                onClick={(e) => { handleNewQuestion(e) }}
                              >
                                <AddCircleIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="h6" style={{ color: '#1a3072' }} fontWeight="bold" >
                                  {'Preguntas (*) - ' + questions.length}
                            </Typography>
                          </Grid>
                      </Grid>
                      <p />
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {questions.map((item,index) => {
                                  const tittleQuestion = "Pregunta # "+ (index + 1);

                                  return (
                                    <>
                                      <Divider light />
                                      <p />
                                      <Grid container spacing={4}>
                                        <Grid item xs={3.8}>
                                          <FormControl fullWidth={true} margin="dense">
                                            <TextField
                                              label={tittleQuestion}
                                              placeholder={tittleQuestion}
                                              value={item.questionText || ''}
                                              onChange={(e) => handleQuestionChange(index, 'questionText' , e.target.value)}
                                              require
                                              fullWidth
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <FormControl fullWidth={true} margin="dense">
                                            <TextField
                                              label="Puntos (*)"
                                              placeholder="Puntuación (*)"
                                              value={item.score || ''}
                                              onChange={(e) => handleQuestionChange(index, 'score' , e.target.value)}
                                              require
                                              fullWidth
                                            />
                                          </FormControl>
                                        </Grid>

                                        <Grid item xs={2}>
                                          <FormControl fullWidth={true} margin="dense">
                                            <TextField
                                              label="Retroalimentación"
                                              placeholder="Retroalimentación"
                                              value={item.feedback || ''}
                                              onChange={(e) => handleQuestionChange(index, 'feedback' , e.target.value)}
                                              require
                                              fullWidth
                                            />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={1.6}>
                                          <FormControl>
                                            <FormControlLabel control={<Switch 
                                              checked={item.showFeedback || ''}
                                              onChange={(e) => handleQuestionChange(index,'showFeedback',e.target.checked)}
                                                />} label="Mostrar Retroalimentación" />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={1.6}>
                                          <FormControl>
                                            <FormControlLabel control={<Switch 
                                              checked={item.showOptionsInRandomOrder || ''}
                                              onChange={(e) => handleQuestionChange(index,'showOptionsInRandomOrder',e.target.checked)}
                                                />} label="Ordenar Respuestas" />
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                          <FormControl fullWidth>
                                              <InputLabel id="demo-simple-select-label">Tipo pregunta (*)</InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Tipo pregunta (*)"
                                                fullWidth
                                                value={item.questionTypeId || ''}
                                                onChange={(e) => handleQuestionChange(index, 'questionTypeId' , e.target.value)}
                                              >
                                                {questionTypes.map(item => {
                                                  return (<MenuItem value={item.id}>{item.description}</MenuItem>);
                                                })}
                                              </Select>
                                          </FormControl>
                                        </Grid>
                                      
                                        <Grid item xs={0.5}>
                                              <IconButton
                                                  color="error"
                                                  onClick={(e) => { handleDeleteQuestion(index) }}
                                                >
                                                  <DeleteIcon />
                                              </IconButton>
                                        </Grid>
                                      </Grid>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >

                                            <Grid container spacing={4}>
                                              <Grid item xs={0.5}>
                                                <IconButton
                                                    color="success"
                                                    onClick={(e) => { handleNewOption(index,e) }}
                                                  >
                                                    <AddCircleIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid item xs={11.5}>
                                                <Typography><b>Respuestas - {item.optionByQuestions.length}</b></Typography>
                                              </Grid>
                                            </Grid>

                                          
                                          
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {item.optionByQuestions.map((option,indexOption) => {
                                                  const tittle = "Respuesta (*) "+ (indexOption + 1);
                                                  return (<>
                                                  <Grid container spacing={4}>
                                                    <Grid item xs={9}>
                                                      <FormControl fullWidth={true} margin="dense">
                                                        <TextField
                                                          label={tittle}
                                                          placeholder={tittle}
                                                          value={option.optionText || ''}
                                                          onChange={(e) => handleOptionChange(index, 'optionText' , e.target.value, indexOption)}
                                                          require
                                                          fullWidth
                                                        />
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2.5}>
                                                      <FormControl>
                                                        <FormControlLabel control={<Switch 
                                                          checked={option.correctAnswer || ''}
                                                          onChange={(e) => handleOptionChange(index, 'correctAnswer' , e.target.checked, indexOption)}
                                                            />} label="Respuesta correcta" />
                                                      </FormControl>
                                                    </Grid>
                                                    <Grid item xs={0.5}>
                                                        <IconButton
                                                            color="error"
                                                            onClick={(e) => { handleDeleteOption(index,indexOption) }}
                                                          >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    
                                                  </Grid>
                                              </>)
                                              }
                                            )}
                                        </AccordionDetails>
                                        
                                      </Accordion>
                                      
                                      <p />
                                    </>);
                                })}
                        </Grid>
                        <Grid item xs={3}>
                          {/* options */}
                        </Grid>
                      </Grid>
            </>}
          </DialogContent>
          <DialogActions sx={{ m: 3 }}>
              <Grid container spacing={0}>
                  <Grid item xs={8.6}>
                    <Alert severity="warning">¡Recuerde que los cambios solo se aplicaran si presiona el boton guardar!</Alert>
                  </Grid>
                  <Grid item xs={0.2}>
                  </Grid>             
                  <Grid item xs={1}>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled = {validateForm() || isSaveButtonDisabled}
                          onClick={() => {
                            setIsSaveButtonDisabled(true);
                            handleSave(
                                {
                                  id,
                                  name,
                                  starDate,
                                  endDate,
                                  randomOrder,
                                  numberOfAttempts,
                                  questionsToShow,
                                  showFeedback,
                                  feedback,
                                  navigationBetweenQuestions,
                                  active,
                                  questions,
                                  minimunScoreToWin,
                                }

                              )
                          }}
                          endIcon={<SaveIcon />}
                        >
                          {isSaveButtonDisabled ? "Guardando..." : ACTIONS.SAVE}
                        </Button>

                   </Grid>
                   <Grid item xs={0.5}>
                  </Grid>             
             
                   <Grid item xs={1}>
                      <Button
                        endIcon={<CancelIcon />}
                        variant="outlined"
                        onClick={() => { handleClose(false) }} autoFocus>{ACTIONS.CANCEL}</Button>
                    </Grid>
              </Grid>
            </DialogActions>
        </Dialog>
      </div>
    );
  }

export default AssessmentDetails;