import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  CircularProgress,
  Snackbar
} from "@mui/material";

import { Save, Cancel } from '@mui/icons-material';
import { ACTIONS } from '../../../shared/constants';
import { GetCheckShiftCodeExistence } from '../logic';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewsShiftDetails = ({
  handleClose,
  handleSave,
  data
}) => {
  const [code, setCode] = useState('');
  const [checkingCode, setCheckingCode] = useState(false);
  const [existCode, setExistCode] = useState(false);
  const [descriptionShift, setDescriptionShift] = useState('');
  const [shiftType, setShiftType] = useState(0);
  const [shiftAssignment, setShiftAssignment] = useState([data.airports[0].code]);
  const [externalShiftCode, setExternalShiftCode] = useState(0);
  const { id } = data;
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const [severety, setSeverety] = useState('');

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    //console.log(event);

    setShiftAssignment(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function OpenMessage(message, severety) {
      setMessage(message);
      setSeverety(severety);
      setOpen(true);
  };

  const handleOnBlurCodeChange = async (event) => {
    setCheckingCode(true);
    const code = event.target.value;
    let exist = false;

    if (code !== ''){
        exist = await GetCheckShiftCodeExistence(code);
        if (exist){
          OpenMessage('Código turno ya existe', 'error');
        }
    }

    setExistCode(exist);
    setCheckingCode(false);
  };

  const handleCloseSnackBar = (event, reason) => {
      if (reason === 'clickaway') { return; }
      setOpen(false);
  };

  const validateForm = () => {
    return !(code && (externalShiftCode > 0) && (shiftAssignment.length > 0) && !existCode);
  }

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth="sm"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ color: '#1A3072', fontWeight: 'bold', fontSize: '24px' }}>
            Nuevo <span style={{ color: '#00B0EF' }}>Turno</span>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <FormControl fullWidth margin="dense">
                <InputLabel htmlFor="outlined-adornment-Código">Código</InputLabel>
                <OutlinedInput
                        label="Código"
                        value={code || ''}
                        onChange={(e) => setCode(e.target.value)}
                        onBlur={(e) => handleOnBlurCodeChange(e)}
                        type='text'
                        require
                        error={existCode}
                        disabled={checkingCode}
                        endAdornment={
                          <InputAdornment position="end">
                              {checkingCode && <CircularProgress size={20}/>}
                          </InputAdornment>
                        }
                      />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth margin="dense">
                <InputLabel htmlFor="outlined-adornment-extcode">Código Externo</InputLabel>
                <OutlinedInput
                        label="Código Externo"
                        value={externalShiftCode || ''}
                        onChange={(e) => setExternalShiftCode(e.target.value)}
                        type="number"
                      />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-shifttype">Tipo de Turno</InputLabel>
                <Select
                  label="Tipo de Turno"
                  placeholder="Descripción Turno"
                  labelId="select-shifttype"
                  value={shiftType || 0}
                  onChange={(e) => setShiftType(e.target.value)}
                >
                  {data.typeData.map((option) => (
                    <MenuItem value={option.id}>{option.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
              <TextField
                      label="Descripción Turno"
                      placeholder="Descripción Turno"
                      value={descriptionShift || ''}
                      onChange={(e) => setDescriptionShift(e.target.value)}
                      type='text'
                      require
                    />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                  <InputLabel id="select-shiftAssignment">Aeropuerto(s)</InputLabel>
                  <Select
                    labelId="select-shiftAssignment"
                    multiple
                    value={shiftAssignment || 0}
                    onChange={handleChange}
                    input={<OutlinedInput label="Aeropuerto(s)" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {data.airports.map((airport) => (
                      <MenuItem key={airport.id} value={airport.code}>
                        <Checkbox checked={shiftAssignment.indexOf(airport.code) > -1} />
                        <ListItemText primary={airport.code} />
                      </MenuItem>
                    ))}
                  </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ m: 1 }}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              disabled = {validateForm()}
              onClick={() => { handleSave(id, code, externalShiftCode, descriptionShift, shiftType, shiftAssignment) }}
              endIcon={<Save />}>
              {ACTIONS.SAVE}
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              onClick={() => { handleClose(false) }}
              endIcon={<Cancel />}
              autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={severety} sx={{ width: '100%' }}>
              {message}
          </Alert>
      </Snackbar>
    </div>
  );
}

export default NewsShiftDetails;