import { callAPI } from '../network';

export async function getNewsletters (EmployeeId){
  
   const data = await callAPI(
                       null, 
                        'Newsletter?EmployeeId='+EmployeeId, 'get');
   return data || [ ];
}

export async function getNewsletter (id){

    const data = await callAPI(
                         null, 
                         'Newsletter/GetById?id='+ id, 'get');
    return data;
 }

 export async function saveNewsletter (newsletter){
   const method = newsletter.id ? 'put' : 'post';
   const data = await callAPI(
                        newsletter, 
                        'Newsletter', method);
   return data;
}

export async function saveNewsletterTargets (targets,OnlyEnabledCustomerEmployees){
   const data = await callAPI(targets, `Newsletter/updateNewsletterTarget/${OnlyEnabledCustomerEmployees}`, 'post');
   return data;
}

export async function getReportState (id){

   const data = await callAPI(
                        null, 
                        'Newsletter/getReportState?id='+ id, 'get');
   return data;
}

export async function getUsignedNewsletters (parameter ,employeeid ){

   const data = await callAPI(
                        null, 
                        'Newsletter/getUsignedNewsletters?parameter='+ parameter +'&employeeId='+employeeid, 'get');
   return data;
}

export async function getUsignedNewsletterDetail (newsletterId, employeeid){

   const data = await callAPI(
                        null, 
                        'Newsletter/getUnsignedNewsletterDetail?newsletterId='+ newsletterId + '&employeeId='+employeeid, 'get');
   return data;
}

export async function sendSignatureToServer (signatureId, signature,employeeId){
   const parameters = {
      signatureId, 
      signature,
      employeeId
    }

   const data = await callAPI(
                        parameters, 
                        'Newsletter/postSignature', 'post');
   return data;
}

export async function getPendingNewslettersByEmployee (employeeId){
   
   const data = await callAPI(
                  null, 
                        'Newsletter/getPendingNewslettersByEmployeeId?employeeId='+ employeeId, 'get');
   return data;
}