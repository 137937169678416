import { getUser } from '../../../shared/auth-service';
import { getAirports } from '../../../shared/data-services/airport-service';
import { getUserData } from '../../../shared/info-device';
import { getActiveShifts,sendNewShift,sendDeleteShift,sendUpdateShift, getCheckShiftCodeExistence} from '../../../shared/data-services/shift-service';
import { getAllPositionHomologation } from '../../../shared/data-services/positionHomologation-service';

export async function getPositionHomologation (){
     const data = await getAllPositionHomologation();
    return data;
};

export async function getShiftType(){
    return [
        {id: 0, description: 'Completo'},
        {id: 1, description: 'Horas / Fracción'},
        {id: 2, description: 'Flexible'}
    ];
};

export async function GetAllGroupPosition(){
    const data = GetAllGroupPosition();
    return data;
};

export async function saveNewShift(code, externalShiftCode, descriptionShift, shiftType, shiftAssignment){
    const { employeeId: user } = getUser();
    const userAgent = navigator.userAgent;
    //const { userdata: { ip } } = await getUserData();
    const ip = '';
    const params = { code, externalShiftCode, descriptionShift, shiftType, shiftAssignment, userAgent, user, ip };

    const data = await sendNewShift(params);
    return data.data;
};

export async function deleteShift(shiftId){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;

    const params = { shiftId, userAgent, user, ip }
    const data = await sendDeleteShift(params);
    return data;
};

export async function saveUpdateShift(dataEdit){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;
    const params = {...dataEdit, userAgent, user, ip};

    const data = await sendUpdateShift(params);
    return data;
};

export async function GetCheckShiftCodeExistence (code){
    const data = await getCheckShiftCodeExistence(code);
    return data;
};