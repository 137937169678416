import {
  CssBaseline,
  ThemeProvider,
 } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { CookiesProvider } from 'react-cookie';
import MainComponent from './components/containers/main-container';
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './shared/error-boundary'; 

function App() {

  // define theme
  const theme = createTheme({
    palette: {
      primary: {
        light: "#63b8ff",
        main: "#1a3072",
        dark: "#1a3072",
        contrastText: "#fff",
      },
      secondary: {
        main: "#4db6ac",
        light: "#82e9de",
        dark: "#00867d",
        contrastText: "#fff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookiesProvider>
        <SnackbarProvider maxSnack={3}>
          <ErrorBoundary>
            <MainComponent />
          </ErrorBoundary>
        </SnackbarProvider>  
      </CookiesProvider>
    </ThemeProvider>
    );
}

export default App;
