import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import {
    Card,
    List,
    CardContent,
    TableCell,
    Divider,
    Input,
    IconButton,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import BrushIcon from '@mui/icons-material/Brush';
import CloseIcon from '@mui/icons-material/Close';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Delete from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAllUsignedNewslettersDetail, sendSignature } from './logic';
import { TITLES } from '../../shared/constants';
import { cardStyles } from './styles.js';
import { CreateGuid, FormatPercentage } from '../../shared/functions';
import { ACTIONS } from '../../shared/constants';
import SignatureCanvas from "react-signature-canvas";
import { useSnackbar } from 'notistack';
import { getUser } from '../../shared/auth-service';
import BackDrop from '../../shared/backdropDE';

const Signature = () => {
    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const { key } = useParams();
    const [showPad, setShowPad] = useState(false);
    const [signatureId, setSignatureId] = useState(null);
    const sigCanvas = useRef();
    const [dataBackup, setdataBackup] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function fetchData() {
            if (loading) {
                const data = await getAllUsignedNewslettersDetail(key, user.employeeId);
                setLoading(false);
                setdataBackup(data);
                setData(data);
            }
        }
        fetchData();
    });

    if (loading) {
        return (
            // <Box sx={{ display: 'flex' }}>
            //     <CircularProgress />
            // </Box>
            <BackDrop />
        );
    }
    if (data.length === 0) {
        return (
            <>
                <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                    Recolección <span style={{ color: '#00b0ef' }}>Circulares</span>
                </Typography>
                {/*<h2>{`${TITLES.SIGNATURE_COLECT}`}</h2>*/}
                <h3>Sin datos</h3>
            </>);
    }

    const handleSearch = () => {
        const parameter = document.getElementById('InputSearch').value;
        if (parameter.length === 0) {
            setData(dataBackup);
            return;
        }
        let filtered = dataBackup.filter(x => x.employeeId.toUpperCase().indexOf(parameter.toUpperCase()) >= 0);
        if (filtered.length > 0) {
            setData(filtered);
            return;
        }
        filtered = dataBackup.filter(x => x.employeeName.toUpperCase().indexOf(parameter.toUpperCase()) >= 0);
        if (filtered.length > 0) {
            setData(filtered);
            return;
        }
        enqueueSnackbar('No se encontraron datos');
    }

    const handleSignature = (id) => {
        setSignatureId(id);
        setShowPad(true);
    }

    const handleClose = () => {
        setSignatureId(null);
        setShowPad(false);
    }

    const handleSignatureDone = async () => {
        var dataImage = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        if (dataImage === 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC') {
            enqueueSnackbar('Debe firmar antes de guardar');
            return;
        }
        await sendSignature(signatureId, dataImage, user.employeeId);
        setLoading(true);
        setShowPad(false);
    }

    return (
        <>
            {showPad &&

                <Dialog
                    maxWidth="sm"
                    fullWidth="sm"
                    open={showPad}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={handleClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        <b>Firme en el recuadro</b>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ m: 2, mb: -2, border: 1 }}>
                            <SignatureCanvas
                                ref={sigCanvas}
                                canvasProps={{ width: 500, height: 200 }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ m: 3 }}>
                        <Box>
                            <Button
                                onClick={() => { handleSignatureDone() }}
                                variant="contained"
                                color="info"
                                endIcon={<BrushIcon />}>
                                {ACTIONS.SIGN}
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                onClick={() => { sigCanvas.current.clear() }}
                                variant="contained"
                                color="error"
                                endIcon={<Delete />}>
                                {ACTIONS.CLEAR}
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                variant="outlined"
                                onClick={() => { handleClose(false) }}
                                endIcon={<CancelIcon />}
                                autoFocus>
                                {ACTIONS.CANCEL}
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            }

            <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                Recolección <span style={{ color: '#00b0ef' }}>Circulares</span>
            </Typography>
            {/*<h2>{`${TITLES.SIGNATURE_COLECT}`}</h2>*/}
            {/*<h3>{`${data[0].name}`}</h3>*/}

            <Box sx={{ m: 4 }}>
                <Card styles={cardStyles} >
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            <b>{`${data[0].name}`}</b>
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow
                                        key={CreateGuid()}
                                    >
                                        <TableCell component="th" scope="row">
                                            <b>Estado:</b>
                                        </TableCell>
                                        <TableCell align="right">{data[0].state}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={CreateGuid()}
                                    >
                                        <TableCell component="th" scope="row">
                                            <b>Avance:</b>
                                        </TableCell>
                                        <TableCell align="right">{FormatPercentage(data[0].status)}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={CreateGuid()}
                                    >
                                        <TableCell component="th" scope="row">
                                            <b>Firmas:</b>
                                        </TableCell>
                                        <TableCell align="right">{data[0].signedCount}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={CreateGuid()}
                                    >
                                        <TableCell component="th" scope="row">
                                            <b>Pendientes:</b>
                                        </TableCell>
                                        <TableCell align="right">{data[0].signatureCount - data[0].signedCount}</TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={CreateGuid()}
                                    >
                                        <TableCell component="th" scope="row">
                                            <b>Total:</b>
                                        </TableCell>
                                        <TableCell align="right">{data[0].signatureCount}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                <Divider light />
            </Box>

            <Box sx={{ m: 4 }}>
                <Card styles={cardStyles} >
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            <b>Filtro de Búsqueda</b>
                        </Typography>
                        <Paper
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        >
                            <Input
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Digite la cédula o el nombre a buscar"
                                inputProps={{ 'aria-label': 'Digite la cédula o el nombre a buscar' }}
                                type="text"
                                id="InputSearch"
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                                onClick={() => {
                                    handleSearch();
                                }}>
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </CardContent>
                </Card>
                <Divider light />
            </Box>

            <p />

            <List>
                {data.map(({ employeeName, employeeId, employeePosition, employeeAirport, signatureId }) => {

                    return (
                        <Box sx={{ p: '2px 32px 32px' }}>
                            <Card styles={cardStyles} >
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        <b>{employeeName}</b>
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow
                                                    key={CreateGuid()}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <b>Cédula:</b>
                                                    </TableCell>
                                                    <TableCell align="right">{employeeId}</TableCell>
                                                </TableRow>
                                                <TableRow
                                                    key={CreateGuid()}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <b>Cargo:</b>
                                                    </TableCell>
                                                    <TableCell align="right">{employeePosition}</TableCell>
                                                </TableRow>
                                                <TableRow
                                                    key={CreateGuid()}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <b>Base:</b>
                                                    </TableCell>
                                                    <TableCell align="right">{employeeAirport}</TableCell>
                                                </TableRow>
                                                <TableRow
                                                    key={CreateGuid()}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Button
                                                            onClick={() => { handleSignature(signatureId) }}
                                                            variant="contained"
                                                            color="info"
                                                            endIcon={<BrushIcon />}>
                                                            {ACTIONS.SIGN}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                            <Divider light />
                        </Box>
                    );
                }
                )}
            </List>
        </>
    );
}

export default Signature;