import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../../shared/constants';
import DataTable from '../../../shared/data-table';
import {
  getPositionHomologation,
  saveSelectedGroupHomologation,
  editSelectedGroupHomologation,
  deleteSelectedGroupHomologation,
  validateSelectedGroupHomologation,
  getSelectedGroupHomologation,
  getAllEmployeePosition,
  saveAllPositions
} from './logic';
//import ProfileTypeDetails from '../../Profile-type/Profile-type-detail';
import PositionHomologationEngranaDetail from '../../position-homologation/position-homologation-engrana/position-homologation-engrana-source';
import PositionHomologationEngranaAdd from '../../position-homologation/position-homologation-engrana/position-homologation-engrana-add';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import ErrorSnackbar from '../../error-snackbar';
import BackDrop from '../../../shared/backdropDE';
import { getUser } from '../../../shared/auth-service';
import AlertDialogQuestion from '../../alert-dialog-question';

const PositionHomologationEngrana= () => {
  const [data, setData] = useState(null);
  const [listData, setListData] = useState(null);
  const [positions, setPositions] = useState(null);
  const [prevPositions, setPrevPositions] = useState(null);
  const [id, setId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [action, setAction] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          
          const data = await getPositionHomologation();

          setListData(data);
           // Calcular el total de positions para cada item
           let updatedData = data.map(item => ({
            ...item,
            total: item.positions ? item.positions.length : 0
          }));

          let allPositions = await getAllEmployeePosition();
          //Se descartan los cargos que ya tengan un grupo asociado
          allPositions = allPositions.filter(position => position.groupPositionEngranaId == null || position.groupPositionEngranaId === undefined);
          setLoading(false);
          setData(updatedData);
          setPositions(allPositions);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
  }, [loading]); 

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleActionButton = (e, cellValues, action) => {
    e.stopPropagation();
    setId(cellValues.row.id);
    setAction(action);
    setSelectedItem(cellValues.row);
    setPrevPositions(cellValues.row.positions);
    if (action === ACTIONS.DELETE) {
      setShowDeleteConfirm(true); 
    } else {
      setShowModal(true);
    }
  };

  const handleCloseDeleteConfirm = () => {
    setShowDeleteConfirm(false);
  };

  const handleSave = async (idGroup, name) => {
    if(idGroup == undefined)
      await saveSelectedGroupHomologation(idGroup, name);
    else
      await editSelectedGroupHomologation(id, name);
    
    setShowModal(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  };
  
  const handleSavePositions = async (positions) => {
    await saveAllPositions(positions.positions,positions.groupHomologationId);
    setShowModal(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleDelete = async () => {
    var data = await validateSelectedGroupHomologation(id);
    if (data)
      enqueueSnackbar('No se puede eliminar dado a que tiene cargos asociados', { variant: 'warning' });
    else  {
      await deleteSelectedGroupHomologation(id);
      setShowDeleteConfirm(false);
      setLoading(true);
      enqueueSnackbar(MESSAGES.DELETE);
    }
  };

  const handleNew = async () => {
    setSelectedItem({});
    setId(null);
    setShowModal(true);
    setAction(ACTIONS.NEW);
  };

  const columns = [
    { field: 'id', 
      headerName: 'Código Engrana', 
      miwidth: 100, 
      flex: 0.2,
      headerAlign: 'center',align: 'center',
    },
    {
      field: 'name',
      headerName: 'Nombre Grupo Cargo Engrana',
      minwidth: 350,
      flex: 1,
      headerAlign: 'center',align: 'center',
    },
    {
      field: 'total',
      headerName: 'Total Cargos Asociados',
      minwidth: 350,
      flex: 0.4,
      headerAlign: 'center',align: 'center',
    },
    {
      field: 'positions',
      headerName: 'Cargos',
      minwidth: 350,
      flex: 1,
      headerAlign: 'center',align: 'center',
      hide: true
    },
    {
      field: "Agregar Cargos",
      headerName: "Agregar Cargos",
      width: 150,
      sortable: false,
      headerAlign: 'center',align: 'center',
      renderCell: (cellValues) => {
        return <IconButton
          color="warning"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.ADD) }}
        >
          <AddCircle />
        </IconButton>;
      }
    },
    {
      field: "Editar",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.EDIT) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "Borrar",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          aria-label="delete"
          color="error"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.DELETE) }}
        >
          <DeleteIcon />
        </IconButton>;
      }
    },
  ]

  if (loading) {
    return (
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>
      {showModal && (action === ACTIONS.NEW || action === ACTIONS.EDIT) && (
        <PositionHomologationEngranaDetail
          data={selectedItem}
          idData={id}
          listData={listData}
          handleClose={handleCloseModal}
          action={action}
          handleSave={handleSave}
          handleDelete={handleDelete}
        />
      )}

      {showModal && action === ACTIONS.ADD && (
        <PositionHomologationEngranaAdd
          handleClose={handleCloseModal}
          handleSavePositions = {handleSavePositions}
          handleSave={handleSave}
          groupHomologationId={id}
          prevPositions = {prevPositions}
          name={selectedItem ? selectedItem.name : ''}
          positions={positions}
          action={action}
        />
      )}

      {showDeleteConfirm && (
        <Dialog
          open={showDeleteConfirm}
          onClose={handleCloseDeleteConfirm}
        >
          <DialogTitle>Confirmar Eliminación</DialogTitle>
          <DialogContent>
            <Typography>¿Estás seguro que deseas eliminar este elemento, grupo: <strong>{selectedItem.name}</strong>?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteConfirm} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDelete} color="error">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Homologación de Cargos <span style={{ color: '#00b0ef' }}>Engrana</span>
          </Typography>
          {/* <Typography variant="h5" gutterBottom>
                {`${TITLES.Profile_TYPE}`}
              </Typography> */}
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Button
              endIcon={<AddCircle />}
              variant="contained"
              onClick={() => { handleNew() }} autoFocus>{ACTIONS.NEW}</Button>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data} 
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
          }
        }}/>
    </>
  );
};

export default PositionHomologationEngrana;
