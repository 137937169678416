import { SERVICE_URLS } from "./constants";
import axios from "axios";
import { getToken, logout } from "./auth-service";
import moment from 'moment';

const evaluateError = (error)  => {
     throw new Error('Ocurrio un error en el servidor reintente o comunìquese con TI');
}


export async function callAPI(
    params, 
    method,
    action){

    const tokenExpiration = sessionStorage.getItem('tokenExpiration');
    var now = moment();
    var tokenExpirationParsed = moment(tokenExpiration);
    //console.log(now.diff(tokenExpirationParsed, 'hours')); // 1

    if (now.diff(tokenExpirationParsed, 'hours') >= 0) {
        logout();
        return;
    }
    else {
        const token =  getToken();

        if (action === 'get') {

                const getResponse = await axios.get(
                    SERVICE_URLS.ROOT + method,
                    { 
                        headers: {"Authorization" : `Bearer ${token}`}
                    }   , {validateStatus: () => true}             
                ).catch(function (error) {
                    
                    //console.log('Error', error);
                    evaluateError(error);              
                });
                return getResponse.data;
        }
        if (action === 'post') {  
            try {
                const postResponse = await axios.post(
                    SERVICE_URLS.ROOT + method ,
                    params
                        ,{
                            headers: { 
                                'Content-Type' : 'application/json',
                                'Accept' : 'application/json',
                                'Authorization':  'Bearer ' + token, 
                            }
                        },
                    );
                return postResponse;
            } catch (error) {            
                //console.log('Error', error);
                evaluateError(error);   
            }
        }
        if (action === 'put') {  
            try {
                const postResponse = await axios.put(
                    SERVICE_URLS.ROOT + method ,
                    params
                        ,{
                            headers: { 
                                'Content-Type' : 'application/json',
                                'Accept' : 'application/json',
                                'Authorization':  'Bearer ' + token, 
                            }
                        },
                    );
                return postResponse;
            } catch (error) {            
                //console.log('Error', error);
                evaluateError(error);  
            }
        }
        if (action === 'delete') {
            try {
                const deleteResponse = await axios.delete(
                    SERVICE_URLS.ROOT + method, {
                    headers: {
                        'Authorization':  'Bearer ' + token, 
                        'Content-Type': 'application/json',
                        'Accept' : 'application/json'
                    },
                    data: params
                });
                return deleteResponse;
            } catch (error) {            
                //console.log('->Error', error);
                evaluateError(error);  
            }
            /*
            const getResponse = await axios.delete(
                SERVICE_URLS.ROOT + method,
                { headers: {"Authorization" : `Bearer ${token}`} }
            ).catch(function (error) {
                console.log('Error', error);
                evaluateError(error);   
            });
            return getResponse;
            */
        }
    }     
} 
