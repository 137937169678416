import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';
import {
    Card,
    List,
    CardContent,
    Typography,
    Divider,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getEmployeeWorkShift } from './logic';
import { cardStyles } from './styles.js';
import { FormatDateToStringComplete, FormatDateTimeToStringComplete } from '../../shared/functions';
import { getUser } from '../../shared/auth-service';
import { CreateGuid } from '../../shared/functions';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const EmployeeWorkShif = () => {

    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (loading) {
                try {
                    const data = await getEmployeeWorkShift(user.employeeId);
                    setLoading(false);
                    setData(data);
                    // await RegisterUsabilityLog('Marcaciones', user.employeeId);
                } catch (error) {
                    setError(error.message);
                    setLoading(false);
                }
            }
        }
        fetchData();
    });

    if (loading) {
        return (
            // <Box sx={{ display: 'flex' }}>
            //     <CircularProgress />
            // </Box>
            <BackDrop />
        );
    }

    if (!data && error) {
        return (<ErrorSnackbar message={error} />);
    }

    const period = data.length > 0 ? data[0].shiftPeriod : null;

    let shiftArray = [];

    data.forEach(element => {
        if (shiftArray.indexOf(element.shiftDate) === -1) {
            shiftArray.push(element.shiftDate);
        }
    });

    return (
        <>
            <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                Mis <span style={{ color: '#00b0ef' }}>Marcaciones</span>
            </Typography>
            <Box sx={{ m: 1 }}>
                <h3>{`Periodo: ${period || ''}`}</h3>
            </Box>
            {/* <h2>{`${TITLES.WORKSHIFT}`}</h2><h3>{`Periodo ${period || ''}`}</h3> */}
            <>
                <List>
                    {shiftArray.map((item) => {

                        const getItems = (shiftDate) => {
                            const detailItems = data.filter(function (dataItem) {
                                return dataItem.shiftDate === shiftDate;
                            })
                            return detailItems;
                        }

                        const detailItems = getItems(item);
                        const shiftDescription = detailItems[0].shiftDescription;
                        const shiftDate = FormatDateToStringComplete(detailItems[0].shiftDate);

                        if (isMobile) {
                            return (
                                <Box>
                                    <Card styles={cardStyles} >
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                                {shiftDate}
                                            </Typography>
                                            <Typography variant="h6" gutterBottom>
                                                Turno {shiftDescription}
                                            </Typography>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow key={CreateGuid()}>
                                                            <TableCell align="left">Fecha entrada</TableCell>
                                                            <TableCell align="left">Fecha salida</TableCell>
                                                            <TableCell align="right">Tiempo descanso</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {detailItems.map(({ shiftPeriod, realTimeIn, realTimeOut, breakTime }) => (
                                                            <TableRow
                                                                key={CreateGuid()}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="left">{FormatDateTimeToStringComplete(realTimeIn)}</TableCell>
                                                                <TableCell align="left">{FormatDateTimeToStringComplete(realTimeOut)}</TableCell>
                                                                <TableCell align="right">{breakTime}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                    <Divider light />
                                </Box>
                            );
                        }
                        else {
                            return (
                                <>
                                    <Typography variant="h5" gutterBottom>
                                        {shiftDate}
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow key={CreateGuid()}>
                                                    <StyledTableCell>Turno</StyledTableCell>
                                                    <StyledTableCell align="left">Fecha entrada</StyledTableCell>
                                                    <StyledTableCell align="left">Fecha salida</StyledTableCell>
                                                    <StyledTableCell align="right">Tiempo descanso</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {detailItems.map(({ payrollConcept, realTimeIn, realTimeOut, breakTime }) => (
                                                    <StyledTableRow key={CreateGuid()}>
                                                        <StyledTableCell>{shiftDescription}</StyledTableCell>
                                                        <StyledTableCell>{FormatDateTimeToStringComplete(realTimeIn)}</StyledTableCell>
                                                        <StyledTableCell>{FormatDateTimeToStringComplete(realTimeOut)}</StyledTableCell>
                                                        <StyledTableCell align="right">{breakTime}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>);
                        }
                    }
                    )}
                </List>
            </>
        </>
    );
}

export default EmployeeWorkShif;