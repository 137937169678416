import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableCell,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FormatPercentage } from '../../../shared/functions';
import DataTable from '../../../shared/data-table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../shared/constants';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

ChartJS.register(ArcElement, Tooltip, Legend);

const NewsletterStateReport = ({
  handleClose,
  reportData,
  name,
}) => {

  const [selectedEmployeePositionId, setSelectedEmployeePositionId] = useState(null);
  const [selectedStatesId, setSelectedStatesId] = useState(null);
  const [selectedAirportId, setSelectedAirportId] = useState(null);
  const [dataReport, setDataReport] = useState(reportData.reportData || []);

  const employeePositions = reportData.profiles || [];
  const airports = reportData.airports || [];
  const states = reportData.signatureState || [];

  const [totalSigned, setTotalSigned] = useState(dataReport.filter(x => x.signatureState === 'Firmado').length);
  const [totalUnSigned, setTotalUnSigned] = useState(dataReport.filter(x => x.signatureState === 'Pendiente').length);
  const [totalArchived, setTotalArchived] = useState(dataReport.filter(x => x.signatureState === 'Archivado').length);

  const [totalSignedPercentage, setTotalSignedPercentage] = useState(0);
  const [totalUnSignedPercentage, setTotalUnSignedPercentage] = useState(0);
  const [totalArchivedPercentage, setTotalArchivedPercentage] = useState(0);

  const [data, setData] = useState(null);

  const green = 'rgba(0, 255, 0, 0.5)';
  const red = 'rgba(255, 0, 0, 0.5)';
  const blue = 'rgba(0, 0, 255, 0.5)';

  const handleChange = () => {

    /*const totalUnSigned = reportData.reportData.filter(x=> x.signatureState === 'Pendiente').length;
    const totalSigned = reportData.reportData.filter(x=> x.signatureState === 'Firmado').length; 
    const totalArchived = reportData.reportData.filter(x=> x.signatureState === 'Archivado').length;
    
    const total = totalSigned + totalUnSigned + totalArchived;
    const setTotalSignedPercentage = ((totalSigned * 100)/total);
    const setTotalUnSignedPercentage = ((totalUnSigned * 100)/total);    
    const setTotalArchivedPercentage = ((totalArchived * 100)/total);   
    setData({
      labels: ['% Pendientes', '% Archivadas' ,'% Realizadas' ],
      datasets: [
        {
          data: [setTotalUnSignedPercentage || 0, setTotalArchivedPercentage || 0, setTotalSignedPercentage || 0],
          backgroundColor: [
            red,
            blue,
            green,
          ],
          borderWidth: 1,
        },
      ]
    });*/
  };

  useEffect(() => {
    SetDataReportToTotals(reportData.reportData || []);
  }, []);

  const handleCleanFilter = () => {
    setSelectedAirportId(null);
    setSelectedEmployeePositionId(null);
    setSelectedStatesId(null);
    
    setDataReport(reportData.reportData || []);
    SetDataReportToTotals(reportData.reportData || []);

  }

  const handleActionButton = () => {
    const filteredData = reportData.reportData.filter(x =>
      (x.position === selectedEmployeePositionId || !selectedEmployeePositionId)
      &&
      (x.signatureState === selectedStatesId || !selectedStatesId)
      &&
      (x.airport === selectedAirportId || !selectedAirportId)
    );

    SetDataReportToTotals(filteredData);

    setDataReport(filteredData);
  };

  function SetDataReportToTotals(dataReportT){
    const totalUnSigned = dataReportT.filter(x => x.signatureState === 'Pendiente').length;
    const totalSigned = dataReportT.filter(x => x.signatureState === 'Firmado').length;
    const totalArchived = dataReportT.filter(x => x.signatureState === 'Archivado').length;

    const total = totalSigned + totalUnSigned + totalArchived;
    const totalSignedPercentage = ((totalSigned * 100) / total);
    const totalUnSignedPercentage = ((totalUnSigned * 100) / total);
    const totalArchivedPercentage = ((totalArchived * 100) / total);
    setData({
      labels: ['% Pendientes', '% Firmadas'],
      datasets: [
        {
          data: [totalUnSignedPercentage || 0, totalSignedPercentage || 0],
          backgroundColor: [
            red,
            green,
          ],
          borderWidth: 1,
        },
      ]
    });
    setTotalUnSignedPercentage(totalUnSignedPercentage);
    setTotalArchivedPercentage(totalArchivedPercentage);
    setTotalSignedPercentage(totalSignedPercentage);
    setTotalUnSigned(totalUnSigned);
    setTotalSigned(totalSigned);
  }

  const reportColumns = [
    // {
    //   field: "descarga",
    //   headerName: "Descargar",
    //   width: 90,
    //   sortable: false,
    //   renderCell: (cellValues) => {
    //     if (cellValues.row.blobUrl) {
    //       return (<Button href={cellValues.row.blobUrl}>
    //         <FileDownloadIcon />
    //       </Button>)
    //     }
    //   }
    // },
    {
      field: "newsletterName",
      headerName: "Nombre Circular",
      flex: 1.5
    },
    { field: 'employeeId', headerName: 'Cédula', minWidth: 110, flex: 1 },
    { field: 'name', headerName: 'Nombre', minWidth: 350, flex: 1 },
    { field: 'position', headerName: 'Cargo', minWidth: 350, flex: 1 },
    { field: 'airport', headerName: 'Base', minWidth: 100, flex: 0.3},
    { field: 'signatureState', headerName: 'Estado Firma', minWidth: 100, flex: 0.6 },
    { field: 'fechaNewsletter', headerName: 'Fecha-Hora', minWidth: 100, flex: 1 },
  ];


  return (
    <div>
      <Dialog
        maxWidth="xl"
        fullWidth="xl"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Histórico de la Circular: <b>{name}</b>
        </DialogTitle>
        <DialogContent>
          <p />
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={2}>
                <FormControl fullWidth={true} margin="dense">
                  <InputLabel id="customer-select-label">Base</InputLabel>
                  <Select
                    labelId="customer-select-label"
                    label="Base"
                    fullWidth
                    required
                    value={selectedAirportId}
                    onChange={(e) => setSelectedAirportId(e.target.value)}
                  >
                    {airports.map(item => {
                      return (<MenuItem value={item}>{item}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth={true} margin="dense">
                  <InputLabel id="state-select-label">Cargo</InputLabel>
                  <Select
                    labelId="state-select-label"
                    label="Cargo"
                    fullWidth
                    required
                    value={selectedEmployeePositionId}
                    onChange={(e) => setSelectedEmployeePositionId(e.target.value)}
                  >
                    {employeePositions.map(item => {
                      return (<MenuItem value={item}>{item}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth={true} margin="dense">
                  <InputLabel id="state-select-label">Estado</InputLabel>
                  <Select
                    labelId="state-select-label"
                    label="Cargo"
                    fullWidth
                    required
                    value={selectedStatesId}
                    onChange={(e) => setSelectedStatesId(e.target.value)}
                  >
                    {states.map(item => {
                      return (<MenuItem value={item}>{item}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2}>
                <IconButton aria-label="Restablecer" sx={{m: '13px'}} onClick={() => handleCleanFilter()}>
                  <FilterAltOffIcon />
                </IconButton>
              </Grid>
            </Grid>
            <p />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography><b>Totales y gráfico</b></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={4}>
                      <Grid item xs={12} lg={2.5}>
                        {data &&
                          <Pie style={{ height: 100 }}
                            plugins={[ChartDataLabels]}
                            data={data}
                            options={{
                              plugins: {
                                legend: {
                                  display: false
                                },
                                datalabels: {
                                  labels: {
                                    title: {
                                      color: 'black',
                                      backgroundColor: 'white',
                                      font: {
                                        weight: 'bold',
                                        size: 16
                                      }
                                    }
                                  },
                                  formatter: function (value, context) {
                                    return FormatPercentage(value);
                                  }
                                }
                              }
                            }}
                          />}
                      </Grid>
                      <Grid item xs={12} lg={4.75}>
                        <Box>
                          <Card >
                            <CardContent>
                              <Typography gutterBottom variant="h6" component="div">
                                <b>Porcentajes</b>
                              </Typography>
                              <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow key='1'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: red}}>Pendientes:</b>
                                      </TableCell>
                                      <TableCell align="right">{FormatPercentage(totalUnSignedPercentage || 0)}</TableCell>
                                    </TableRow>
                                    {/* <TableRow key='2'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: blue}}>Archivadas:</b>
                                      </TableCell>
                                      <TableCell align="right">{FormatPercentage(totalArchivedPercentage || 0)}</TableCell>
                                    </TableRow> */}
                                    <TableRow key='3'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: green}}>Firmadas:</b>
                                      </TableCell>
                                      <TableCell align="right">{FormatPercentage(totalSignedPercentage || 0)}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={4.75}>
                        <Box>
                          <Card >
                            <CardContent>
                              <Typography gutterBottom variant="h6" component="div">
                                <b>Totales</b>
                              </Typography>
                              <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow key='1'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: red}}>Pendientes:</b>
                                      </TableCell>
                                      <TableCell align="right">{totalUnSigned}</TableCell>
                                    </TableRow>
                                    {/* <TableRow key='2'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: blue}}>Archivadas:</b>
                                      </TableCell>
                                      <TableCell align="right">{totalArchived}</TableCell>
                                    </TableRow> */}
                                    <TableRow key='3'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: green}}>Firmadas:</b>
                                      </TableCell>
                                      <TableCell align="right">{totalSigned}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CardContent>
                          </Card>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>

            <p />
            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataTable columns={reportColumns} rows={dataReport} />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 3 }}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              endIcon={<FilterAltIcon />}
              onClick={() => { handleActionButton() }} >
              {ACTIONS.FILTER}
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={() => { handleClose(false) }} autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NewsletterStateReport;