import React, { useState, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import WebCam from 'react-webcam';
import {
    getemployeesByAirport,
    GetUserFaceFeature,
    SaveNewUser,
    UpdateUser
} from './logic';
// import Grid from '@mui/material/Unstable_Grid2';
import Grid from '@mui/material/Grid';
import {
    Typography,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    FilledInput,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    CircularProgress,
    Snackbar,
    FormHelperText,
    Autocomplete,
    TextField,
} from "@mui/material";
import BackDrop from '../../shared/backdropDE';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AlertDialogQuestion from '../alert-dialog-question';
import Chip from '@mui/material/Chip';
import MuiAlert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import { green } from '@mui/material/colors';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function generate() {
    const Items = [
        { "Primary": "Buena iluminación del entorno", "Secondary": "Asegúrese de tener una excelente iluminación del entorno" },
        { "Primary": "Buena visualización del rostro", "Secondary": "Asegúrese de que el rostro de la persona no se vea oscura, borrosa o en movimiento" },
        { "Primary": "Que ningún elemento interfiera en su rostro", "Secondary": "Asegúrese de que no haya ningún elemento que interfiera en la visualización del rostro como por ejemplo: Lentes oscuros, tapabocas" }
    ];

    const listItems = Items.map((item) =>
        <ListItem sx={{ p: 0 }}>
            <ListItemIcon sx={{ minWidth: '28px' }}>
                <CheckCircleOutlineIcon sx={{ color: "#8ebb37" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00b0ef', fontWeight: 'bold' }}
                primary={item.Primary}
                secondary={item.Secondary}
            />
        </ListItem>
    );

    return listItems;
}


const getCameraSelection = async () => {
    await navigator.mediaDevices.getUserMedia({video: true});

    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    // const options = videoDevices.map(videoDevice => {
    //   return `<option value="${videoDevice.deviceId}">${videoDevice.label}</option>`;
    // });
    return videoDevices;
};

const UserRegistrationTalmaID = ({
    data,
    handleClose,
    airport,
    airports,
    handleCloseModalSource
}) => {
    const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
    const [dialogContentText, setDialogContentText] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(false);
    const [dialogElement, setDialogElement] = useState(null);
    const [dense, setDense] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [cameraOptions, setCameraOptions] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(null);
    const webcamRef = useRef(null);
    const [img, setImg] = useState(null);
    const [selectedAirport, setSelectedAirport] = useState(airport || 0);
    const [employees, setEmployees] = useState([]);
    const [employeeSelected, setEmployeeSelected] = useState([]);
    const [user, setUser] = useState(data != null ? data : []);
    const [userId, setUserId] = useState(data != null ? data.id : 0);
    const [changes, setChanges] = useState({
        Base: false,
        Picture: false
    });
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState('');
    const [snackMessage, setSnackMessage] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [validIdentification, setValidIdentification] = useState(true);
    const [validImg, setValidImg] = useState(true);
    const [idAlertQuestion, setIdAlertQuestion] = useState(0);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    const [loadingSave, setLoadingSave] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const handleSelectedCamera = (e) => {
        e.stopPropagation();
        setSelectedCamera(e.target.value);
    };

    const handleCapture = (e) => {
        e.stopPropagation();
        const imageSrc = webcamRef.current.getScreenshot();
        setImg(imageSrc);

        if (userId !== 0 && selectedCamera != null) {
            changes.Picture = true;
        } else if (selectedCamera == null) {
            setSnackMessage('Debe seleccionar una cámara para tomar la foto');
            setSnackSeverity('info');
            setSnackOpen(true);
        }

    };

    const handleSelectedAirport = async (e) => {
        e.stopPropagation();

        setSelectedAirport(e.target.value);

        await loadEmployeesByAirport(e.target.value);

        if (userId === 0) {
            setEmployeeSelected([]);
            user.identification = '';
            user.airportId = 0;
            user.fullName = '';
            user.active = true;
            user.position = '';
            user.onboardingDate = '';
            user.birthDate = '';
            setChanges({ Base: false, Picture: false });
        } else {
            changes.Base = true;
            setChanges(changes);
        }
    }

    const handleSelectedEmployee = (e, value) => {
        e.stopPropagation();

        //console.log(value);


        if (value !== null) {
            const employee = employees.find(employee => employee.employeeId === value.id);

            setEmployeeSelected(employee);

            user.identification = employee.employeeId;
            user.airportId = selectedAirport;
            user.fullName = employee.name + ' ' + employee.lastName;
            user.active = true;
            user.position = employee.employeePosition.name;
            user.onboardingDate = employee.OnboardingDate;
            user.birthDate = employee.BirthDate;
        } else {
            setEmployeeSelected([]);
            user.identification = '';
            user.airportId = 0;
            user.fullName = '';
            user.active = true;
            user.position = '';
            user.onboardingDate = '';
            user.birthDate = '';
        }


    }

    const handleSaveChanges = async () => {
        //Verificar si es nuevo o edición
        if (!loadingSave) {
            setSuccess(false);
            setLoadingSave(true);

            if (userId !== 0) {
                //Update

                if (changes.Base || changes.Picture) {
                    setDialogTitle('¿Está seguro de guardar los siguientes cambios?');
                    setDialogContentText('Revise los siguientes cambios antes de guardar:');
                    const element = (
                        <Grid content spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item xs={12} display={changes.Picture ? '' : 'none'}>
                                <Typography variant="h7" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                                    Se actualizará la <span style={{ color: '#00b0ef' }}>foto de reconocimiento facial</span>
                                </Typography>
                                <div>
                                    <Tooltip title="Foto anterior">
                                        <img src={user.picture} alt="logo" style={{ display: 'inline', width: '150px', height: '150px', border: '1px solid', padding: '5px', borderRadius: '10px' }}></img>
                                    </Tooltip>
                                    <ArrowForwardOutlinedIcon sx={{ ml: 2, mr: 2 }} style={{ verticalAlign: '180%' }} />
                                    <Tooltip title="Foto nueva">
                                        <img src={img} alt="logo" style={{ display: 'inline', width: '150px', height: '150px', border: '1px solid', padding: '5px', borderRadius: '10px' }}></img>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={12} display={changes.Base ? '' : 'none'}>
                                <Typography variant="h7" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                                    Se actualizará la <span style={{ color: '#00b0ef' }}>base de operación</span>
                                </Typography>
                                <div>
                                    <Chip color="info" label={airports.find(x => x.id === user.airportId).code} />
                                    <ArrowForwardOutlinedIcon sx={{ ml: 2, mr: 2 }} style={{ verticalAlign: '-17%' }} />
                                    <Chip color="success" label={airports.find(x => x.id === selectedAirport).code} />
                                </div>
                            </Grid>
                        </Grid>);
                    setDialogElement(element);
                    setIdAlertQuestion(1);
                    setAlertQuestionOpen(true);
                } else {
                    setSnackMessage('No hay cambios pendientes para guardar');
                    setSnackSeverity('info');
                    setSnackOpen(true);
                    setLoadingSave(false);
                }
            } else {
                //New
                if (img != null) {
                    user.picture = img;
                }

                if (user.identification === '') {
                    setValidIdentification(false);
                } else {
                    setValidIdentification(true);
                }

                if (user.picture === '') {
                    setValidImg(false);
                } else {
                    setValidImg(true);
                }

                if (user.identification === '' || user.picture === '') {
                    setSnackMessage('Hay campos pendientes');
                    setSnackSeverity('warning');
                    setSnackOpen(true);
                    setLoadingSave(false);
                } else {
                    const picturebase64 = user.picture.replace('data:image/png;base64,', '');
                    const userSave = user;

                    userSave.picture = picturebase64;

                    await SaveNewUser(userSave);

                    setSnackMessage('Usuario registrado correctamente');
                    setSnackSeverity('success');
                    setSnackOpen(true);
                    setLoadingSave(false);
                    setSuccess(true);

                    user.identification = '';
                    user.airportId = 0;
                    user.fullName = '';
                    user.active = true;
                    user.position = '';
                    user.onboardingDate = '';
                    user.birthDate = '';
                    user.picture = '';
                    setImg(null);
                    handleClose(false);
                    handleCloseModalSource();
                    // await loadEmployeesByAirport(selectedAirport);

                }
            }
        }


    }

    const handleCloseWindow = () => {
        if (changes.Base || changes.Picture) {
            setDialogTitle('Hay cambios pendientes');
            setDialogContentText('¿Desea descartar los cambios y cerrar la ventana?');
            setDialogElement(null);
            setIdAlertQuestion(2);
            setAlertQuestionOpen(true);
        } else {
            handleClose(false);
            handleCloseModalSource();
        }
    };

    const handleCloseAlertQuestion = () => {
        setAlertQuestionOpen(false);
        setLoadingSave(false);
    }

    const handleAccept = async () => {

        switch (idAlertQuestion) {
            case 1:
                setAlertQuestionOpen(false);
                setChanges({ Base: false, Picture: false });
                user.airportId = selectedAirport;
                user.picture = img != null ? img : user.picture;


                const picturebase64 = user.picture.replace('data:image/png;base64,', '');
                const userSave = user;

                userSave.picture = picturebase64;

                await UpdateUser(userSave);

                handleClose(false);
                handleCloseModalSource();
                setLoading(true);
                enqueueSnackbar(MESSAGES.SAVED);
                setSuccess(true);
                setLoadingSave(false);
                break;
            case 2:
                handleClose(false);
                handleCloseModalSource();
                break;
            default:
                break;
        }


    };

    useEffect(() => {
        async function fetchData() {
            if (loading) {
                try {
                    const camerasData = await getCameraSelection();

                    if (userId === 0) {
                        await loadEmployeesByAirport(selectedAirport);
                    } else {
                        //Load user Picture
                        const picture = await GetUserFaceFeature(user.identification);
                        user.picture = picture !== '' ? 'data:image/png;base64,' + picture : '';
                    }

                    setCameraOptions(camerasData);
                    // await RegisterUsabilityLog('Registro de Usuario - Talma ID', user.employeeId);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            }
        }
        fetchData();
    });

    async function loadEmployeesByAirport(AirportId) {
        setLoadingEmployees(true);

        const employeesData = await getemployeesByAirport(AirportId);

        setEmployees(employeesData);

        setLoadingEmployees(false);
    }

    const constraints = {
        video: {
            width: {
                min: 1280,
                ideal: 1920,
                max: 2560,
            },
            height: {
                min: 720,
                ideal: 1080,
                max: 1440
            },
        },
        deviceId: {
            exact: selectedCamera
        }
    };

    if (loading) {
        return (
            // <Box sx={{ display: 'flex' }}>
            //     <CircularProgress />
            // </Box>
            <BackDrop />
        );
    }

    return (
        <div   >
            {alertQuestionOpen && <AlertDialogQuestion
                handlePositive={handleAccept}
                handleNegative={handleCloseAlertQuestion}
                textButtonPositive={ACTIONS.ACCEPT}
                textButtonNegative={ACTIONS.CANCEL}
                dialogContentText={dialogContentText}
                dialogTitle={dialogTitle}
                open={alertQuestionOpen}
                elemento={dialogElement}
            />}
            <Dialog
                maxWidth="xl"
                fullWidth="xl"
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                        Registro/Edición <span style={{ color: '#00b0ef' }}>Usuario</span>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={2} xl={2}>
                                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                        <img src={img != null ? img : user.picture === '' || user.length === 0 || user.picture == null ? "./Images/ic_face_talma.png" : user.picture} alt="logo" style={{ width: img == null ? '55%' : '100%', height: '90%', border: '1px solid', padding: '5px', borderRadius: '10px', borderColor: !validImg ? 'red' : 'black' }}></img>
                                        <FormHelperText sx={{ display: !validImg ? 'inblock' : 'none', textAlign: 'center', color: 'red' }}>Capture la foto del usuario</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{ textAlign: 'center', color: '#00b0ef' }} sx={{ flexGrow: 1, m: 1, }}>
                                            Foto actual
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} md={5} xl={4}>
                                            <FormControl sx={{ m: 0, mb: 2, width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" size='small' sx={{ fontWeight: 'bold', color: "#1a3072" }}>Base</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Base"
                                                    size='small'
                                                    defaultValue={selectedAirport}
                                                    onChange={(e) => { handleSelectedAirport(e) }}
                                                >
                                                    {airports.map(item => {
                                                        return (<MenuItem value={item.id}>{item.code}</MenuItem>);
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12} xl={8}>
                                            <FormControl error={!validIdentification} sx={{ m: 0, mb: 2, width: '100%', display: userId !== 0 ? 'none' : 'inblock' }}>
                                                <Autocomplete
                                                    noOptionsText={'Sin resultados'}
                                                    disablePortal
                                                    size='small'
                                                    id="combo-box-demo"
                                                    options={employees.map(item => {
                                                        return ({ label: item.employeeId + ' - ' + item.name + ' ' + item.lastName, id: item.employeeId })
                                                    })}
                                                    onChange={handleSelectedEmployee}
                                                    disabled={loadingEmployees ? 'disabled' : ''}
                                                    renderInput={(params) => <TextField {...params} label={loadingEmployees ? <CircularProgress color="inherit" size={20} /> : 'Empleado'} />}
                                                />
                                                <FormHelperText sx={{ display: !validIdentification ? 'inblock' : 'none' }}>Seleccione el empleado</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth sx={{ m: 0, mb: 2 }} >
                                                <InputLabel size='small' htmlFor="outlined-adornment-DNI" sx={{ fontWeight: 'bold', color: "#1a3072" }}>Documento Empleado</InputLabel>
                                                <FilledInput
                                                    id="outlined-adornment-DNI"
                                                    startAdornment={<BadgeIcon sx={{ color: "#1a3072", marginTop: '12px', pr: 2, width: '40px' }} />}
                                                    label="DNI"
                                                    size='small'
                                                    value={user.length === 0 ? '' : user.identification}
                                                    sx={{ textAlign: 'center', color: '#00b0ef' }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormControl fullWidth sx={{ m: 0, mb: 2 }}>
                                                <InputLabel size='small' htmlFor="outlined-adornment-Name" sx={{ fontWeight: 'bold', color: "#1a3072" }}>Nombre Empleado</InputLabel>
                                                <FilledInput
                                                    id="outlined-adornment-Name"
                                                    startAdornment={<PersonIcon sx={{ color: "#1a3072", marginTop: '12px', pr: 2, width: '40px' }} />}
                                                    label="Name"
                                                    sx={{ textAlign: 'center', color: '#00b0ef' }}
                                                    size='small'
                                                    value={user.length === 0 ? '' : user.fullName}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormControl fullWidth sx={{ m: 0, mb: 2 }}>
                                                <InputLabel size='small' htmlFor="outlined-adornment-Cargue" sx={{ fontWeight: 'bold', color: "#1a3072" }}>Cargo Empleado</InputLabel>
                                                <FilledInput
                                                    id="outlined-adornment-Cargue"
                                                    startAdornment={<WorkIcon sx={{ color: "#1a3072", marginTop: '12px', pr: 2, width: '40px' }} />}
                                                    label="Cargo"
                                                    sx={{ textAlign: 'center', color: '#00b0ef' }}
                                                    size='small'
                                                    value={user.length === 0 ? '' : user.position}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2} xl={2} sx={{ ml: 3 }}>
                                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                        <WebCam videoConstraints={constraints}
                                            ref={webcamRef}
                                            audio={false}
                                            screenshotFormat='image/png'
                                            mirrored={true}
                                            screenshotQuality={1}
                                            height={1000}
                                            width={1000}
                                            style={{ width: '100%', height: '100%', border: '1px solid', padding: '5px', borderRadius: '10px' }} />
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Button variant="contained"
                                            endIcon={<CameraEnhanceIcon />}
                                            sx={{ m: 1 }}
                                            onClick={(e) => { handleCapture(e) }}>
                                            Capturar
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3} xl={3} sx={{ ml: 3 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={10} >
                                            <FormControl sx={{ m: 1, width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" size='small' sx={{ fontWeight: 'bold', color: "#1a3072" }}>Cámara</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Camera"
                                                    size='small'
                                                    onChange={(e) => { handleSelectedCamera(e) }}
                                                >
                                                    {cameraOptions.map(item => {
                                                        return (<MenuItem value={item.deviceId}>{item.label === '' ? 'Cámara' : item.label ?? 'Cámara'}</MenuItem>);
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography variant="h6" style={{ color: '#1a3072', fontWeight: 'bold' }} >
                                                Antes de tomar la foto:
                                            </Typography>
                                            <List dense={dense} sx={{ p: 0 }}>
                                                {generate()}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ m: 3 }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loadingSave}
                            endIcon={<><Badge style={{ display: changes.Base || changes.Picture ? '' : 'none' }} color="error" variant="dot">
                                <SaveIcon />
                            </Badge>
                                <SaveIcon style={{ display: changes.Base || changes.Picture ? 'none' : '' }} />
                            </>
                            }
                            onClick={() => { handleSaveChanges() }}
                        >
                            {ACTIONS.SAVE}
                        </Button>
                        {loadingSave && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                    <Box>
                        <Button
                            endIcon={<CancelIcon />}
                            variant="outlined"
                            onClick={() => { handleCloseWindow() }} autoFocus>{ACTIONS.CANCEL}</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackOpen} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>
        </div>
    );

}

export default UserRegistrationTalmaID;