import React, { useState, useEffect } from 'react';
import {
    getAllAirports
    , UpdateState
} from './logic';
import BackDrop from '../../shared/backdropDE';
import { getUser } from '../../shared/auth-service';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import {
    Typography,
    Grid,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Button,
    CircularProgress,
    Box,
    Snackbar,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DataTable from '../../shared/data-table';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { getUserByAirport } from '../../shared/data-services/talmaid-service';
import MuiAlert from '@mui/material/Alert';
import UserRegistrationTalmaID from '../user-registration-talma-id';
import Fab from '@mui/material/Fab';
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserQueryTalmaID = () => {
    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [airports, setAirports] = useState([]);
    const [usersTalmaID, setUsersTalmaID] = useState([]);
    const [usersTalmaIDFilter, setUsersTalmaIDFilter] = useState([]);
    const [selectedAirport, setSelectedAirport] = useState(0);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [identificationFilter, setIdentificationFilter] = useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState('');
    const [severety, setSeverety] = useState('');
    const [showModalSource, setShowModalSource] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const positions = [47, 56, 95, 60];

    const columns = [
        // { field: 'picture', headerName: '', width: 100 },
        {
            field: "EditSources",
            headerName: "Editar",
            width: 70,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <Tooltip title="Editar información usuario">
                        <IconButton
                            color="info"
                            onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.VIEW_STATE) }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                );
            }
        },
        {
            field: "action",
            headerName: "Acción",
            width: 90,
            sortable: false,
            renderCell: ({ row }) => {

                if (row.active) {
                    return (
                        <Tooltip title="Desactivar usuario">
                            <IconButton
                                color="error"
                                onClick={(e) => { handleActionButton(e, row, ACTIONS.DEACTIVATE) }}
                            >
                                <PowerSettingsNewIcon />
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title="Activar usuario">
                            <IconButton
                                color="success"
                                onClick={(e) => { handleActionButton(e, row, ACTIONS.ACTIVATE) }}
                            >
                                <PowerSettingsNewIcon />
                            </IconButton>
                        </Tooltip>
                    );
                }


            }
        },
        { field: 'id', headerName: 'Id', width: 60 },
        { field: 'identification', headerName: 'Número Documento', width: 170 },
        { field: 'fullName', headerName: 'Nombre Completo', width: 300 },
        {
            field: 'active', headerName: 'Estado', width: 100,
            valueGetter: (params) => {
                if (params.value) {
                    return 'ACTIVO';
                }
                return 'INACTIVO';
            }
        },
        { field: 'position', headerName: 'Cargo', width: 300 },
        { field: 'onboardingDate', headerName: 'Fecha Ingreso', width: 130 },
        { field: 'birthDate', headerName: 'Cumpleaños', width: 130 }
    ];

    const [successReload, setSuccessReload] = React.useState(false);
    const timer = React.useRef();
    const buttonSx = {
        ...(successReload && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleReloadQuery = async () => {
        if (!loadingUsers) {


            if (selectedAirport !== 0) {
                setSuccessReload(false);
                setLoadingUsers(true);

                const usersTalmaIDData = await getUserByAirport(selectedAirport);

                setUsersTalmaID(usersTalmaIDData);
                setIdentificationFilter('');
                setUsersTalmaIDFilter([]);


                setSuccessReload(true);
                setLoadingUsers(false);

            }

            timer.current = window.setTimeout(() => {
                setSuccessReload(false);
            }, 2000);


        }
    };

    const handleSelectedAirport = async (e) => {
        e.stopPropagation();
        setSelectedAirport(e.target.value);
        setLoadingUsers(true);

        const usersTalmaIDData = await getUserByAirport(e.target.value);

        setUsersTalmaID(usersTalmaIDData);

        if (usersTalmaIDData.length === 0) {
            OpenMessage('No hay registros para esta base', 'warning');
        }

        setLoadingUsers(false);

    };

    const handleFilterIdentificationUsers = async (e) => {
        e.stopPropagation();

        if (identificationFilter === '') {
            setUsersTalmaIDFilter([]);
        } else {
            const filter = usersTalmaID.filter(user => user.identification.includes(identificationFilter.toUpperCase()) || user.fullName.includes(identificationFilter.toUpperCase()));
            setUsersTalmaIDFilter(filter);

            if (filter.length === 0) {
                OpenMessage('No hay resultados para ese número de documento', 'warning')
            }
        }

    };

    function OpenMessage(message, severety) {
        setMessage(message);
        setSeverety(severety);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseModalSource = () => {
        handleReloadQuery();
    }

    const handleNew = async () => {
        setSelectedItem({
            id: 0,
            identification: '',
            airportId: 0,
            fullName: '',
            active: true,
            position: '',
            onboardingDate: '',
            birthDate: '',
            picture: ''
        });
        // setId(null);
        if (selectedAirport === null || selectedAirport === undefined || selectedAirport === 0) {
            OpenMessage('Debe seleccionar primero una base', 'info')
        } else {
            setShowModalSource(true);
        }
    };

    const handleActionButton = async (e, row, action) => {
        e.stopPropagation();

        switch (action) {
            case ACTIONS.VIEW_STATE:
                //Edit info
                const item = usersTalmaID.find(x => x.id === row.id);

                setSelectedItem(item);

                setShowModalSource(true);
                break;
            case ACTIONS.DEACTIVATE:
            case ACTIONS.ACTIVATE:
                //Activated and Deactivate user

                setLoadingUsers(true);

                await UpdateState(row.id, action === ACTIONS.DEACTIVATE ? 0 : 1)

                const usersTalmaIDData = await getUserByAirport(selectedAirport);

                setUsersTalmaID(usersTalmaIDData);

                const messageAction = action === ACTIONS.DEACTIVATE ? MESSAGES.DEACTIVATED : MESSAGES.ACTIVATED;

                OpenMessage('El usuario de ' + row.fullName + ' ha sido ' + messageAction + ' correctamente', 'success');

                if (usersTalmaIDFilter.length > 0) usersTalmaIDFilter.find(x => x.id === row.id).active = action === ACTIONS.ACTIVATE;

                setSuccessReload(true);
                setLoadingUsers(false);

                timer.current = window.setTimeout(() => {
                    setSuccessReload(false);
                    clearTimeout(timer.current);
                }, 2000);

                break;

            default:
                break;
        }
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {

        async function fetchData() {
            if (loading) {
                try {

                    var airportsData = [...user.airports];

                    var position = user.employeePosition.id;

                    if (positions.includes(position)) {
                        airportsData = await getAllAirports();
                    }

                    setSelectedAirport(user.airport.id);
                    setAirports(airportsData);

                    const usersTalmaIDData = await getUserByAirport(user.airport.id);

                    setUsersTalmaID(usersTalmaIDData);

                    // await RegisterUsabilityLog('Gestión de Usuarios - Talma ID', user.employeeId);

                    setLoading(false);


                } catch (error) {
                    setLoading(false);
                }
            }
        }
        fetchData();

    });

    if (loading) {
        return (
            // <Box sx={{ display: 'flex' }}>
            //     <CircularProgress />
            // </Box>
            <BackDrop />
        );
    }


    return (
        <>

            {showModalSource && <UserRegistrationTalmaID
                data={selectedItem}
                handleClose={setShowModalSource}
                airport={selectedAirport}
                airports={airports}
                handleCloseModalSource={handleCloseModalSource}
            />}
            <Box sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={1} style={{ textAlign: 'center' }}>
                        <img src={"./Images/ic_talmaid_mobile5.png"} alt="logo" style={{ width: '40%', padding: '5px', borderRadius: '10px' }}></img>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} >
                        <Typography variant="h5" style={{ textAlign: 'center', color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                            Consulta <span style={{ color: '#00b0ef' }}>Usuario</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} >
                        <Tooltip title="Filtrar por número de documento o nombre completo">
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-searchByDocumento" size="small">Buscar</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-searchByDocumento"
                                    type={'text'}
                                    size="small"
                                    onChange={(e) => { setIdentificationFilter(e.target.value) }}
                                    onKeyUp={(e) => { handleFilterIdentificationUsers(e) }}
                                    endAdornment={

                                        <SearchIcon />
                                    }
                                    label="Documento"
                                />
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <Tooltip title="Filtrar por base/aeropuerto" >
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel id="demo-simple-select-label" size="small"
                                >Base</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Airport"
                                    size="small"
                                    defaultValue={selectedAirport}
                                    onChange={(e) => { handleSelectedAirport(e) }}
                                >
                                    {airports.map(item => {
                                        return (<MenuItem value={item.id}>{item.code}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Actualizar listado">
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <Fab
                                        size="small"
                                        aria-label="save"
                                        color="primary"
                                        sx={buttonSx}
                                        onClick={handleReloadQuery}
                                    >
                                        {successReload ? <CheckIcon /> : <CachedIcon />}
                                    </Fab>
                                    {loadingUsers && (
                                        <CircularProgress
                                            size={50}
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: -4.9,
                                                left: -5,
                                                zIndex: 1,
                                            }}
                                        />
                                    )}

                                </Box>
                            </Tooltip>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Tooltip title="Registrar nuevo usuario">
                                    <Button variant="contained"
                                        endIcon={<PersonAddAltIcon />}
                                        sx={{ m: 0, display: { xs: 'none', sm: 'none', md: 'inline' } }}
                                        onClick={() => { handleNew() }}>
                                        Registrar
                                    </Button>
                                </Tooltip>
                                <Fab
                                    size="small"
                                    aria-label="new"
                                    color="primary"
                                    sx={{ m: 0, display: { md: 'none', sm: 'inline', xs: 'inline' } }}
                                    onClick={() => { handleNew() }}
                                >
                                    <PersonAddAltIcon />
                                </Fab>
                            </Box>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={1} sx={{ display: loadingUsers ? 'flex' : 'none', textAlign: 'center' }}>
                <Box >
                    <CircularProgress />
                </Box>
            </Grid> */}
                </Grid>
            </Box>
            {(usersTalmaIDFilter.length > 0 || usersTalmaID.length > 0) && <DataTable columns={columns} rows={usersTalmaIDFilter.length === 0 ? usersTalmaID : usersTalmaIDFilter} />}


            {(usersTalmaID.length === 0) && <Box style={{ textAlign: 'center' }}>
                <Typography variant="h7" style={{ textAlign: 'center' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                    <span style={{ color: '#00b0ef' }}>No hay registros para esta base</span>
                </Typography>
            </Box>}

            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severety} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

        </>
    );

}

export default UserQueryTalmaID;