import React, { useState, useEffect } from 'react';
import BackDrop from '../../shared/backdropDE';
import ErrorSnackbar from '../error-snackbar';
import { ACTIONS } from '../../shared/constants';
import { getUser } from '../../shared/auth-service';
import DataTable from '../../shared/data-table';
import axios from "axios";
import AlertDialogQuestion from '../alert-dialog-question';
import moment from 'moment';

import {
    GetShiftHistoryResume,
    GetShiftHistoryYearMonthResume,
    SaveShiftFile,
    SaveShiftHistory,
    ActivateShifts
} from './logic';

import {
    Grid,
    Select,
    Tooltip,
    Typography,
    MenuItem,
    InputLabel,
    FormControl,
    Box,
    Button,
    Paper,
    Skeleton,
    Snackbar,
    Stack,
    CircularProgress,
    Chip
} from "@mui/material";

import UploadFileIcon from '@mui/icons-material/UploadFile';
import ShiftImportUpload from './shift-import-upload';
import Badge from '@mui/material/Badge';

import Groups2Icon from '@mui/icons-material/Groups2';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ManIcon from '@mui/icons-material/Man';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import MuiAlert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ShiftImport = () => {
    const user = getUser();
    const [ip, setIP] = useState("");
    const [userAgent, setUserAgent] = useState('');
    const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [yearMonth, setYearMonth] = React.useState([]);
    const [loadingResume, setLoadingResume] = useState(false);
    const [loadingActivation, setLoadingActivation] = useState(false);
    const [resume, setResume] = useState([]);
    const [yearMonthSelected, setYearMonthSelected] = useState(((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString());
    const [dataReturnImport, setDataReturnImport] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState('');
    const [severety, setSeverety] = useState('');
    const [messageImport, setMessageImport] = useState('');
    const [severetymessageImport, setSeveretyMessageImport] = useState('');
    const [selectedAirport, setSelectedAirport] = useState(user.airport.id);
    const [selectedAirportName, setSelectedAirportName] = useState(null);
    const [airports, setAirports] = useState([]);
    const [dataAirport, setDataAirport] = useState({
        id: user.airport.id,
        code: user.airport.code
    });
    const [currentYearMonth, setCurrentYearMonth] = useState(null);

    
    const columns = [
        { field: 'id', headerName: 'Id', width: 60 },
        { field: 'employeeId', headerName: 'Documento empleado', width: 200 },
        { field: 'date', headerName: 'Fecha', width: 150 },
        {
            field: "message",
            headerName: "Mensaje",
            width: 900,
            sortable: false,
            renderCell: params => {
                return (<><WarningIcon sx={{color: 'orange', mr:'10px'}}/> <b> {params.value} </b></>                     
                );
            }
        }
    ];

    useEffect(() => {
        var airportsData = [...user.airports];
        setSelectedAirport(user.airport.id);
        setSelectedAirportName(user.airport.code)
        setAirports(airportsData);
        
    }, []);

    useEffect(() => {
        if (currentYearMonth) {
            setYearMonthSelected(currentYearMonth);
            loadResume(currentYearMonth);
        }
    }, [currentYearMonth]);

    useEffect(() => {
        const fetchData = async () => {
            if (loading) {
                try {
                    const userAgent = window.navigator.userAgent;
                    setUserAgent(userAgent);
    
                    let YearMonth = await GetShiftHistoryYearMonthResume();
                    let YearMonthValue = currentYearMonth;
                    if(currentYearMonth === null){
                       YearMonthValue = YearMonth.length > 0 ? YearMonth[YearMonth.length-1].id : ((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()
                       setCurrentYearMonth(YearMonthValue);
                    }
                                           
                    setYearMonth(YearMonth);
                    setYearMonthSelected(YearMonthValue);
    
                    await loadResume(YearMonthValue);
    
                    //console.log('se ejecuto');
                } catch (error) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }
        };
    
        // Obtener datos iniciales
        fetchData();
    
        // El siguiente useEffect gestiona la carga del resumen basado en los cambios de selectedAirport y yearMonthSelected
        // Por lo tanto, no es necesario llamar a fetchData() nuevamente aquí.
        // fetchData(); <- Esto se elimina para evitar llamadas duplicadas
    
    }, []); // La dependencia vacía asegura que esto se ejecute solo al montar el componente
    
    useEffect(() => {
        // Este useEffect se mantiene para actualizar el resumen cuando cambian selectedAirport o yearMonthSelected
        const loadResume = async (YearMonth) => {
            setLoadingResume(true);
            let data = await GetShiftHistoryResume(YearMonth.replace('-', ''), selectedAirport);
            setResume(data);
            setLoadingResume(false);
        };
    
        if (selectedAirport && yearMonthSelected) {
            loadResume(yearMonthSelected);
        }
    }, [selectedAirport, yearMonthSelected]); 

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
    };

    async function fetchData() {
        if (loading) {
          try {
              const userAgent = window.navigator.userAgent;
              setUserAgent(userAgent);

              let YearMonth = await GetShiftHistoryYearMonthResume();

              let YearMonthValue = currentYearMonth;
              if(currentYearMonth === null){
                YearMonthValue = YearMonth.length > 0 ? YearMonth[YearMonth.length-1].id : ((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()
                setCurrentYearMonth(YearMonthValue);
                setYearMonthSelected(YearMonthValue); 
             }
                                    
             setYearMonth(YearMonth);

             await loadResume(currentYearMonth);
              
              //console.log('se ejecuto');
              setLoading(false);
          } catch (error) {
              setError(error.message);
              setLoading(false);
          }
        }
      }
    async function loadResume(YearMonth){
        setLoadingResume(true);

        let data = await GetShiftHistoryResume(YearMonth.replace('-', ''),selectedAirport);

        setResume(data);
        setLoadingResume(false);
    }

    /* HANDLES*/
    const handleImport = async () => {
        setShowModalUpload(true);
    };
    const handleSelectAirport = (e) => {
        const newSelectedAirport = e.target.value;
        const newSelectedAirportCode = airports.find(airport => airport.id === newSelectedAirport)?.code; // Asegúrate de usar el operador opcional para evitar errores
        setSelectedAirport(newSelectedAirport); 
        setSelectedAirportName(newSelectedAirportCode);
        //console.log(newSelectedAirport);   
        setDataAirport({
            id:newSelectedAirport,
            code:newSelectedAirportCode
        });
        setDataReturnImport('');
        setMessageImport('');
       // console.log(dataAirport);
        fetchData();
        // Puedes hacer más acciones si es necesario
    };
    const handleSelectYearMonth = async (e) => {
        e.stopPropagation();
        setYearMonthSelected(e.target.value);

        await loadResume(e.target.value);
    };

    const convertDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${year}${parseInt(month)}`; // se elimina los ceros a la izquierda del mes
      };
    

    const hanldeSaveImport = async (
        dataShifts,
        dataFile,
        date
        ) => {
            try {

                // console.log(date);

                var requestFile = {
                    "fileName": dataFile.FileName,
                    "shiftFile": dataFile.FileData,
                    "user": user.employeeId
                };

                var a = await SaveShiftFile(requestFile);

                if(a.status === 200){

                    //Armar estructura para enviar
                    const request = {
                        user: user.employeeId,
                        airport: [dataAirport],
                        dateImport: date,
                        fileId: a.data,
                        ip: ip,
                        userAgent: userAgent,
                        data: dataShifts
                    };    

                    // console.log(request);

                    var response = await SaveShiftHistory(request);

                    var returnImport = response.data;
                    var MessageResult = returnImport[0];
                    setMessageImport(MessageResult.message);
                    //console.log(returnImport);
                    if(returnImport.length === 1 && returnImport[0].employeeId === 'OK'){
                        returnImport = [];
                        setSeveretyMessageImport('success');
                        OpenMessage("Se cargaron todos los registros correctamente", "success");
                        //loadResume(yearMonthSelected);
                    }else{
                        setSeveretyMessageImport('warning');
                        OpenMessage("Hay registros con novedades, por favor revisar, corregir e intentarlo de nuevo.", "warning");
                        //loadResume(yearMonthSelected);
                    }

                    if(returnImport.length > 1) returnImport.splice(0, 1);

                    setDataReturnImport(returnImport);

                    const newYearMonthValue = convertDate(date);
                    setCurrentYearMonth(newYearMonthValue);
                    setYearMonthSelected(newYearMonthValue);
       
                }

                setShowModalUpload(false);
            } catch (error) {
                setError(error.message);
                setShowModalUpload(false);
            }

      };

      const handleActivateShifts = async () => {
        setLoadingActivation(true);
        setDialogTitle('¿Está seguro de activar ' + resume.cantShiftDisabled + ' turnos?');
        setAlertQuestionOpen(true);

        
      };

      const handleAcceptActivateShifts = async () => {
        setAlertQuestionOpen(false);
        var date = moment(new Date(yearMonthSelected.replace(yearMonthSelected.substring(0, 4), yearMonthSelected.substring(0, 4) + '-') + '-01')).format('YYYY-MM-DD') ;


        let request = {
            user: user.employeeId,
            airport: [dataAirport],
            yearMonth: date,
            ip: ip,
            userAgent: userAgent
        };

        var response = await ActivateShifts(request)
        if(response.data){
            OpenMessage("Se activaron " + resume.cantShiftDisabled + " turnos correctamente", "success");
            loadResume(yearMonthSelected);
        }else{
            OpenMessage("No se activaron los turnos correctamente", "error");
        }

        setLoadingActivation(false);
      }


    const handleCloseAlertQuestion = () => {
        setAlertQuestionOpen(false);
        setLoadingActivation(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    /* HANDLES*/

    function OpenMessage(message, severety) {
        setMessage(message);
        setSeverety(severety);
        setOpen(true);
    };

    if (loading) {
        return (<BackDrop />);
    }

    if (error) {
        return (<ErrorSnackbar message={error} />);
    }

    return (
        <>

            {showModalUpload && <ShiftImportUpload
                hanldeSaveImport={hanldeSaveImport}
                handleClose={() => setShowModalUpload(false)}
                selectedAirport={selectedAirportName}
            />}

            {alertQuestionOpen && <AlertDialogQuestion
                handlePositive={handleAcceptActivateShifts}
                handleNegative={handleCloseAlertQuestion}
                textButtonPositive={ACTIONS.ACCEPT}
                textButtonNegative={ACTIONS.CANCEL}
                dialogContentText={""}
                dialogTitle={dialogTitle}
                open={alertQuestionOpen}
                elemento={null}
            />}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ color: '#00b0ef' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                        Importación de <span style={{ color: '#1a3072' }}>Turnos</span>
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel id="demo-simple-select-label" size="small"
                                >Base</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Airport"
                                    size="small"
                                    defaultValue={selectedAirport}
                                    onChange={(e) => { handleSelectAirport(e) }}
                                >
                                    {airports.map(item => {
                                        return (<MenuItem value={item.id} data-code={item.code}>{item.code}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                    </Grid>
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel id="demo-simple-select-label" size="small"
                            >Año-Mes</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Año-Mes"
                                size="small"
                            value={yearMonthSelected}
                            onChange={(e) => { handleSelectYearMonth(e) }}
                        >
                            {yearMonth.map(item => {
                                return (<MenuItem key={item.id} value={item.id}>{item.yearMonth}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl  sx={{ m: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<><UploadFileIcon/></>
                            }
                            onClick={() => { handleImport() }}>
                            {ACTIONS.IMPORT}
                        </Button>
                    </FormControl>
                    <FormControl  sx={{ m: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loadingActivation || resume.cantShiftDisabled === 0}
                            endIcon={<><Badge badgeContent={resume.cantShiftDisabled} style={{ display: resume.cantShiftDisabled > 0 ? '' : 'none' }} color="error">
                                <ToggleOnOutlinedIcon/>
                            </Badge>
                                <ToggleOnOutlinedIcon style={{ display: resume.cantShiftDisabled > 0 ? 'none' : '' }} />
                            </>
                            }
                            onClick={() => { handleActivateShifts() }}>
                            {!loadingResume ? ( "Activar turnos") : (
                                        <Skeleton animation="wave" variant="text" width={90} height={25} sx={{ fontSize: '2rem'}} />
                            )}
                            {loadingActivation   && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#1a3072',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                {messageImport !== '' && <><Chip icon={severetymessageImport === 'success' ? <CheckCircleIcon /> : <WarningAmberIcon/>} label={messageImport} color={severetymessageImport} variant="outlined" /></>}
                </Grid>               
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        spacing={2}
                        >
                        <Box
                            sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            textAlign: 'center',
                            '& > :not(style)': {
                            m: 1,
                            width: 170,
                            height: 170,
                            },
                            }}
                        >
                            <Tooltip title="Cantidad de personas registradas">
                                <Paper elevation={3} sx={{textAlign: 'center', p:1}}>
                                    <Groups2Icon sx={{fontSize: '50px', color: '#00b0ef'}}/>
                                    <Typography variant="h4" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                                        {!loadingResume ? ( resume.cantUsers) : (
                                            <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem'}} />
                                            )}
                                    </Typography>
                                    <Typography variant="p" style={{ color: '#00b0ef' }} sx={{ flexGrow: 1, m: 1, }}>
                                        Cant. Personas
                                    </Typography>
                                </Paper>
                            </Tooltip>
                            </Box>
                            <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                m: 1,
                                width: 170,
                                height: 170,
                                },
                                }}
                            >
                            <Tooltip title="Cantidad de turnos registrados">
                                <Paper elevation={3} sx={{textAlign: 'center', p:1}}>
                                    <DateRangeIcon sx={{fontSize: '50px', color: '#00b0ef'}}/>
                                    <Typography variant="h4" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                                        {!loadingResume ? ( resume.cantShifts) : (
                                            <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem' }} />
                                        )}
                                    </Typography>
                                    <Typography variant="p" style={{ color: '#00b0ef' }} sx={{ flexGrow: 1, m: 1, }}>
                                        Cant. Turnos
                                    </Typography>
                                </Paper>
                            </Tooltip>
                            </Box>
                            <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                m: 1,
                                width: 170,
                                height: 170,
                                },
                                }}
                            >
                        <Tooltip title="Promedio de turnos por persona">
                                <Paper elevation={3} sx={{textAlign: 'center', p:1}}>
                                    <ManIcon sx={{fontSize: '50px', color: '#00b0ef'}}/>
                                    <Typography variant="h4" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                                        {!loadingResume ? ( resume.promShiftsXUsers) : (
                                            <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem' }} />
                                        )}
                                    </Typography>
                                    <Typography variant="p" style={{ color: '#00b0ef' }} sx={{ flexGrow: 1, m: 1, }}>
                                        Promedio Turnos x Persona
                                    </Typography>
                                </Paper>
                            </Tooltip>
                            </Box>
                            <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                m: 1,
                                width: 170,
                                height: 170,
                                },
                                }}
                            >
                        <Tooltip title="Porcentaje de turnos activos (Visualización para el usuario)">
                                <Paper elevation={3} sx={{textAlign: 'center', p:1}}>
                                    <EventAvailableIcon sx={{fontSize: '50px', color: '#00b0ef'}}/>
                                    <Typography variant="h4" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                                        {!loadingResume ? ( resume.porcEnabledShift) : (
                                            <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem' }} />
                                        )}
                                    </Typography>
                                    <Typography variant="p" style={{ color: '#00b0ef' }} sx={{ flexGrow: 1, m: 1, }}>
                                        % Turnos activos (Visualizar)
                                    </Typography>
                                </Paper>
                            </Tooltip>
                            </Box>
                    </Stack>                
                </Grid>
                <Grid item xs={12} >
                    {dataReturnImport.length > 0 && <><Typography variant="h6" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                        Errores de validación
                    </Typography>
                    <DataTable columns={columns} rows={dataReturnImport} />
                    </>}
                </Grid>
            </Grid>

            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severety} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );

};

export default ShiftImport;