import { getUser } from '../../shared/auth-service';
import { getUserData } from '../../shared/info-device';
import { 
    getShiftAbbreviations,
    getAbbreviationsTypes,
    sendNewAbbreviated,
    sendUpdateAbbreviation,
    sendDeleteAbbreviation,
    getCheckAbbreviationExistence
} from '../../shared/data-services/shift-service';


export async function getAbbreviation(){
    const data = await getShiftAbbreviations();
    return data;
};

export async function getAbbreviationType(){
    const data = await getAbbreviationsTypes();
    return data;
};

export async function saveNewAbbreviated(dataForm){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;

    const params = {...dataForm, userAgent, user, ip};
    const data = await sendNewAbbreviated(params);
    return data;
};

export async function saveUpdateAbbreviated(dataEdit){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;
    const params = {...dataEdit, userAgent, user, ip};

    const data = await sendUpdateAbbreviation(params);
    return data;
};

export async function deleteAbbreviation(shiftAbbreviationId){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;

    const params = { shiftAbbreviationId, userAgent, user, ip }
    const data = await sendDeleteAbbreviation(params);
    return data;
};

export async function GetCheckAbbreviationExistence(code){
    const data = await getCheckAbbreviationExistence(code);
    return data;
};