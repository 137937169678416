import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
  Tooltip,
  Chip
} from "@mui/material";

import {
  getAbbreviation,
  getAbbreviationType,
  saveNewAbbreviated,
  saveUpdateAbbreviated,
  deleteAbbreviation
} from './logic';

import {
  Edit,
  AddCircle,
  DeleteOutline
} from '@mui/icons-material';

import BackDrop from '../../shared/backdropDE';
import DataTable from '../../shared/data-table';
import ErrorSnackbar from '../error-snackbar';
import { useSnackbar } from 'notistack';
import ShiftAbbreviationDetail from './shift-abbreviation-detail';
import AlertDialogQuestion from '../alert-dialog-question';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';
import { getUser } from '../../shared/auth-service';

const ShiftAbbreviation = () => {
  const user = getUser();
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [action, setAction] = useState(null);


  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getAbbreviation();
          const types = await getAbbreviationType();

          setTypeData(types);
          setData(data);
          // await RegisterUsabilityLog('Abreviados - Turnos', user.employeeId);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
    return () => {};
  });

  const handleSave = async (dataNew) => {
      setShowModal(false);
      setLoading(true);
      if(!dataNew.startTime) dataNew.startTime = '00:00';
      if(!dataNew.endTime) dataNew.endTime = '00:00';
      await saveNewAbbreviated(dataNew);

      const dataLoad = await getAbbreviation();
      setData(dataLoad);
      enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleNew = async () => {
    setSelectedItem({
        shiftAbbreviationId: 0,
        abbreviation: '',
        description: '',
        shiftAbbreviationTypeId: 1,
        typeData: typeData,
        startTime: '',
        endTime: ''
    });
    setId(id);
    setAction(ACTIONS.NEW);
    setShowModal(true);
  };

  const handleDataEdit = async (cellValues) => {
    const extra = {
      typeData,
    };
    setSelectedItem({...cellValues.row, ...extra });
    setId(cellValues.row.id);
    setAction(ACTIONS.EDIT);
    setShowModal(true);
  };

  const handleEdit = async (dataEdit) => {
    if(!dataEdit.startTime) dataEdit.startTime = '00:00';
    if(!dataEdit.endTime) dataEdit.endTime = '00:00';
    setShowModal(false);
    setLoading(true);
    await saveUpdateAbbreviated(dataEdit);

    const dataLoad = await getAbbreviation();
    setData(dataLoad);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleDelete = async () => {
    setAlertQuestionOpen(false);
    setLoading(true);
    await deleteAbbreviation(idDelete);
    const dataLoad = await getAbbreviation();
    setData(dataLoad);
    enqueueSnackbar(MESSAGES.DELETE);
  };

  const handleDeleteData = async (cellValues) => {
    setAlertQuestionOpen(true);
    setIdDelete(cellValues.row.shiftAbbreviationId);
  };

  const handleCloseAlert = () => {
    setAlertQuestionOpen(false);
    setIdDelete(null);
  };

  const getRowId = (row) => row.shiftAbbreviationId;

  const columns = [
    {
      field: "Editar",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleDataEdit(cellValues) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "Borrar",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          aria-label="delete"
          color="error"
          onClick={(e) => { handleDeleteData(cellValues) }}
        >
          <DeleteOutline />
        </IconButton>;
      }
    },
    { field: 'abbreviation', headerName: 'Abreviado', minwidth: 100, maxwidht: 300, flex: 1,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value} arrow>{cellValues.value}</Tooltip>;
      }
    },
    { field: 'shiftAbbreviationType', headerName: 'Grupo', width: 150, align: 'center',
      renderCell: (cellValues) => {
        const { description, abbreviationTypeId } = cellValues.value;
        const colorChip = ['primary', 'info', 'error', 'warning', 'success'];
        return <Chip label={description} color={colorChip[abbreviationTypeId]} variant="outlined" />;
      }
    },
    { field: 'description', headerName: 'Descripción', minwidth: 400, flex: 1 },
    { field: 'startTime', headerName: 'Inicio', width: 100, align: 'center',
      valueGetter: (params) => params.value.substring(0,5)
    },
    { field: 'endTime', headerName: 'Final', width: 100, align: 'center',
      valueGetter: (params) => params.value.substring(0,5)
    },
    { field: 'creationDate', headerName: 'Fecha creación', width: 200, align: 'center',
       valueGetter: (params) => moment.tz(new Date(params.value), "America/Bogota").format('YYYY-MM-DD HH:mm:ss')
       
    },
  ];

  if (loading) {
    return ( <BackDrop /> );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>
      {showModal && <ShiftAbbreviationDetail
          data={selectedItem}
          handleClose={setShowModal}
          handleSave={handleSave}
          handleEdit={handleEdit}
          action={action}
      />}

      {alertQuestionOpen && <AlertDialogQuestion
          handlePositive={handleDelete}
          handleNegative={handleCloseAlert}
          textButtonPositive={ACTIONS.ACCEPT}
          textButtonNegative={ACTIONS.CANCEL}
          dialogContentText={MESSAGES.CONFIRM_DELETE}
          dialogTitle={""}
          open={alertQuestionOpen}
          elemento={""}
      />}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Administración <span style={{ color: '#00b0ef' }}>Abreviados</span>
          </Typography>
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Tooltip title="Crear Nuevo Abreviado">
                <Button
                endIcon={<AddCircle />}
                variant="contained"
                onClick={() => { handleNew() }} 
                autoFocus
                >{ACTIONS.NEW}
                </Button>
            </Tooltip>
          </Grid>
        </Box>
      </Grid>
      {<DataTable columns={columns} rows={data} getRowId={getRowId} />}
    </>
  );
};

export default ShiftAbbreviation;
