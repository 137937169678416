import { callAPI } from '../network';

export async function getAssessments (){
  
   const data = await callAPI(
                       null, 
                        'Assessment', 'get');
   return data || [ ];
}

export async function getAssessment (id){

    const data = await callAPI(
                         null, 
                         'Assessment/GetById?id='+ id, 'get');
    return data;
 }

 export async function saveAssessment (assessment){
   const method = 'post';
   const data = await callAPI(
                  assessment, 
                  'Assessment', method);
   return data;
}

export async function getAllQuestionTypes (){

   const data = await callAPI(
                        null, 
                        'QuestionType', 'get');
   return data;
}

export async function saveEmployeeAssessment (assessment){
   const method = 'post';
   const data = await callAPI(
                  assessment, 
                  'EmployeeAssessment', method);
   return data;
}

export async function getAssessmentReportByNewsletterId (id){

   const data = await callAPI(
                        null, 
                        'EmployeeAssessment/GetAssessmentReportByNewsletterId?newsletterId='+ id, 'get');
   return data;
}


export async function getAssessmentReportDetailByNewsletterId (id, hours){
  return await callAPI(null,'EmployeeAssessment/GetAssessmentReportDetailByNewsletterId?newsletterId='+ id+'&hours='+hours, 'get');
}


