import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import XLSXStyle from 'sheetjs-style';

import {
    Tooltip,
    Box,
    Fab,
    CircularProgress
} from "@mui/material";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';

const ExportExcel = ({excelData, fileName, excelHeaders}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const [loadingExcel, setLoadingExcel] = useState(false);
    const [successExport, setSuccessExport] = React.useState(false);
    const timer = React.useRef();
    const buttonSx = {
        ...(successExport && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleExportToExcel = async () => {
        if (!loadingExcel) {
            setSuccessExport(false);
            setLoadingExcel(true);

            const ws = XLSXStyle.utils.json_to_sheet(excelData);

            if(excelHeaders != null){
                XLSXStyle.utils.sheet_add_aoa(ws, [excelHeaders], { origin: "A1" });
            }
            const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
            const excelBuffer = XLSXStyle.write(wb, {bookType: 'xlsx', type: 'array'});
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);

            setSuccessExport(true);
            setLoadingExcel(false);

            timer.current = window.setTimeout(() => {
                setSuccessExport(false);
            }, 2000);
        }

    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Descargar plantilla en Excel">
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Fab
                            size="small"
                            aria-label="save"
                            color="primary"
                            sx={buttonSx}
                            onClick={handleExportToExcel}
                            >
                            {successExport ? <CheckIcon /> : <SimCardDownloadIcon />}
                            {loadingExcel && (
                                        <CircularProgress
                                            size={50}
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: -4.9,
                                                left: -5,
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                        </Fab>
                    </Box>
                </Tooltip>
            </Box>
        </>
    )

}

export default ExportExcel;