import { callAPI } from '../network';

export async function getNewsletterStates (){
  
   const data = await callAPI(
                        null, 
                        'NewsletterState', 'get');
   return data || [ ];
}

