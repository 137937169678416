import { getNewsletters, getNewsletter, saveNewsletter, saveNewsletterTargets, getReportState } from '../../shared/data-services/newsletter-service';
import { getNewsletterStates } from '../../shared/data-services/newsletter-state-service';
import { getCustomers } from '../../shared/data-services/consumer-service';
import { getNewsletterTypes } from '../../shared/data-services/newslettertype-service';
import { getEmployeePositions } from '../../shared/data-services/employee-position-service';
import { getAirports } from '../../shared/data-services/airport-service';
import { getAssessments, getAssessmentReportByNewsletterId , getAssessmentReportDetailByNewsletterId } from '../../shared/data-services/assessment-service';
import { getNewsLetterContentTypes } from '../../shared/data-services/newsletter-content-type-service';


export async function getAllNewsletters (EmployeeId){
  const data = await getNewsletters(EmployeeId);
  return data || [ ];
};

export async function getSelectedNewsletter (id){
  const data = await getNewsletter(id);
  return data;
};

export async function getResportStateData (id){
  const data = await getReportState(id);
  return data || [ ];
};

export async function saveSelectedNewsletter ( 
          id,
          code,
          name,
          customerId,
          newsletterStateId,
          date,
          newsletterTypeId,
          assessmentId,
  ){
  const parameters = {
        id,
        code,
        name,
        customerId,
        newsletterStateId,
        date,
        newsletterTypeId,
        assessmentId,
  }

  const data = await saveNewsletter(parameters);
  return data;
};

export async function getAllNewsletterStates (){
  
  const data = await getNewsletterStates();
  return data;
};

export async function getAllCustomers (){
  
  const data = await getCustomers();
  return data;
};

export async function getAllNewsletterTypes (){
  
  const data = await getNewsletterTypes();
  return data;
};

export async function getAllEmployeePositions (){
  
  const data = await getEmployeePositions();
  return data;
};

export async function getAllAirports (){
  
  const data = await getAirports();
  return data;
};


export async function saveAllNewsletterTargets (params,OnlyEnabledCustomerEmployees){
  const data = await saveNewsletterTargets(params, OnlyEnabledCustomerEmployees);
  return data;
};

export async function getListAssessment (){
  const data = await getAssessments();
  return data || [ ];
};



export async function getAllNewsLetterContentTypes (){
  
  const data = await getNewsLetterContentTypes();
  return data;
};

export async function getAssessmentReportByNewsletterIdData (id){
  const data = await getAssessmentReportByNewsletterId(id);
  return data || [ ];
};

export async function getAssessmentReportDetailByNewsletterIdData (id){
  const data = await getAssessmentReportDetailByNewsletterId(id);
  return data || [ ];
};
