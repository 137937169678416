import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  ListItemText,
  Typography,
  Checkbox,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton
} from "@mui/material";

import { Save, Cancel, Segment } from '@mui/icons-material';
import { ACTIONS } from '../../../shared/constants';

const ShiftEdition = ({
  handleClose,
  handleEdit,
  data,
  airports
}) => {
  const { shiftId } = data;
  const dataAssignment = data.shiftAssignments.map(airport => airport.code);
  const [code, setCode] = useState(data.code);
  const [externalShiftCode, setExternalShiftCode] = useState(data.externalShiftCode);
  const [descriptionShift, setDescriptionShift] = useState(data.descriptionShift);
  const [shiftType, setShiftType] = useState(data.shiftType);
  const [shiftAssignment, setShiftAssignment] = useState(dataAssignment);
  const [isActive, setIsActive] = useState(data.isActive);
  const [isEnabled, setIsEnabled] = useState(data.isEnabled);

  const navigate = useNavigate();

  //console.log(airports);

  const handleActiveChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleEnabledChange = (event) => {
    setIsEnabled(event.target.checked);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setShiftAssignment(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const validateForm = () => {
    return !(code && (shiftAssignment.length > 0) && (externalShiftCode > 0));
  }

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth="sm"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ color: '#1A3072', fontWeight: 'bold', fontSize: '24px' }}>
            Editar <span style={{ color: '#00B0EF' }}>Turno</span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <FormControl fullWidth margin="dense">
              <TextField
                      label="Código"
                      placeholder="Código"
                      value={code || ''}
                      onChange={(e) => setCode(e.target.value)}
                      type='text'
                    />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth margin="dense">
                <InputLabel htmlFor="outlined-adornment-extcode">Código Externo</InputLabel>
                <OutlinedInput
                        label="Código Externo"
                        value={externalShiftCode || ''}
                        onChange={(e) => setExternalShiftCode(e.target.value)}
                        type="number"
                      />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-shifttype">Tipo de Turno</InputLabel>
                <Select
                  label="Tipo de Turno"
                  placeholder="Descripción Turno"
                  labelId="select-shifttype"
                  value={shiftType || 0}
                  onChange={(e) => setShiftType(e.target.value)}
                >
                  {data.typeData.map((option) => (
                    <MenuItem value={option.id}>{option.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
              <TextField
                      label="Descripción Turno"
                      placeholder="Descripción Turno"
                      value={descriptionShift || ''}
                      onChange={(e) => setDescriptionShift(e.target.value)}
                      type='text'
                    />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                  <InputLabel id="select-shiftAssignment">Aeropuerto(s)</InputLabel>
                  <Select
                    labelId="select-shiftAssignment"
                    multiple
                    value={shiftAssignment || 0}
                    onChange={handleChange}
                    input={<OutlinedInput label="Aeropuerto(s)" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {airports.map((airport) => (
                      <MenuItem key={airport.id} value={airport.code}>
                        <Checkbox checked={shiftAssignment.indexOf(airport.code) > -1} />
                        <ListItemText primary={airport.code} />
                      </MenuItem>
                    ))}
                  </Select>
              </FormControl>
            </Grid>
            <Grid container justifyContent="flex-end" item xs={6}>
              <FormControl margin="dense">
                <FormControlLabel
                  control={
                    <Switch 
                    checked={isEnabled} 
                    onChange={handleEnabledChange}
                    />
                  }
                  label="Habilitar"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="dense">
                <FormControlLabel
                  control={
                    <Switch 
                    checked={isActive} 
                    onChange={handleActiveChange}
                    />
                  }
                  label="Activar"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ m: 1 }}>
          <Box sx={{ flex: '1 1 auto' }}>
            <Tooltip title="Ver Segmentos del Turno" placement="right">
              <IconButton
                color="success"
                aria-label="get segment shift"
                onClick={(e) => { navigate(`/ShiftSegments/${shiftId}`); }}>
                <Segment />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              disabled = {validateForm()}
              onClick={(e) => { handleEdit({code, externalShiftCode, descriptionShift, shiftType, shiftAssignment, isActive, isEnabled, shiftId}) }}
              endIcon={<Save />}>
              {ACTIONS.SAVE}
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              onClick={() => { handleClose(false) }}
              endIcon={<Cancel />}
              autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShiftEdition;