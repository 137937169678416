import { getShiftHistoryResume, getShiftHistoryYearMonthResume, saveShiftFile, shiftHistory, activateShifts} from '../../shared/data-services/shift-service';

export async function GetShiftHistoryResume (YearMonth,airportId){
    const data = await getShiftHistoryResume(YearMonth,airportId);
    return data;
};

export async function GetShiftHistoryYearMonthResume (){
    const data = await getShiftHistoryYearMonthResume();
    return data;
};

export async function SaveShiftFile (model){
    const data = await saveShiftFile(model);
    return data;
};

export async function SaveShiftHistory (model){
    const data = await shiftHistory(model);
    return data;
};

export async function ActivateShifts (model){
    const data = await activateShifts(model);
    return data;
};