import React from 'react';
import {
    Alert,
    Grid,
    Paper,
} from "@mui/material";
import { paperStyles, loginStyles, alertTextStyles}  from './styles.js';

const InlineMessage = (props) => { 
  
  const { message } = props;
  return (
      <Grid>
         <Paper elevation={10} style={paperStyles} >
            <Grid align="center">
                <img src="./logo.png" alt="logo" style={loginStyles}/>
                <Alert severity="info" style={alertTextStyles}>{message}</Alert>
                <p />
            </Grid>
        </Paper>
      </Grid>
    );
  };
  
  export default InlineMessage;
  