import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Grid,
  IconButton
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { TITLES, MESSAGES } from '../../../shared/constants';
import DataTable from '../../../shared/data-table';
import {
  updateAllOptionsByProfile,
  getProfilesByEmployeePosition,
  getAllProfiles,
  updateAllProfilesByPosition
} from './logic';
import ManagementPositionOptionDetail from '../management-position/management-position-options-detail';
import ManagementPositionDetail from '../management-position/management-position-detail';
import Edit from '@mui/icons-material/Edit';
import ErrorSnackbar from '../../error-snackbar';
import BackDrop from '../../../shared/backdropDE';
import { ZoomIn } from '@mui/icons-material';

const ManageProfileOptions = () => {
  const [loading, setLoading] = useState(true);
  const [employeePosition, setEmployeePosition] = useState(null);
  const [data, setData] = useState(null);
  const [employeePositionId, setEmployeePositionId] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [optionDetails, setOptionDetails] = useState(null);
  const [profileDetails, setProfileDetails] = useState(null);
  const [loadingOptionDetails, setLoadingOptionDetails] = useState(false);
  const [loadingProfileDetails, setLoadingProfileDetails] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [uniquePositions, setUniquePositions] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [showModal, setShowModal] = useState(null);

  const columns = [
    { field: 'employeePosition', headerName: 'Cargo', flex: 1 },
    {
      field: 'totalPerProfile',
      headerName: 'Perfiles Asignados',
      type: 'number',
      minWidth: 150,
      flex: 0.1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "Editar",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleEdit(e, cellValues) }}
        >
          <Edit />
        </IconButton>;
      }
    },
  ];

  const getUniquePositions = (data) => {
    const unique = {};
    data.forEach((item) => {
      if (!unique[item.employeePosition]) {
        unique[item.employeePosition] = item;
      }
    });
    //console.log(unique);
    return Object.values(unique);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (loading) {
        try {
          const rawData = await getProfilesByEmployeePosition();
          const profiles = await getAllProfiles();
  
          // Calcula el total por cada employeePositionId con descripción no vacía
          const totalsPerId = rawData.reduce((acc, item) => {
            // Considera solo los items con descripción no vacía
            if (item.description !== '') {
              const key = item.employeePositionId; // Usa employeePositionId como clave
              acc[key] = (acc[key] || 0) + 1; // Incrementa el contador para el id
            }
            return acc;
          }, {});
  
          // Agrega el total a cada elemento basado en su employeePositionId
          // Solo para los registros con descripción no vacía
          const dataWithTotals = rawData.map((item) => {
            return item.description !== '' ? {
              ...item,
              totalPerProfile: totalsPerId[item.employeePositionId], // Agrega el total basado en el id
            } : item;
          });
  
          setData(dataWithTotals);
          setProfiles(profiles);
        } catch (error) {
          console.error("Error loading data:", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  });

  useEffect(() => {
    if (data) {
      setUniquePositions(getUniquePositions(data));
    }
  }, [data]);

  const fetchOptionDetails = async (employeePositionId) => {
    //console.log("fetchOptionDetails called with id:", employeePositionId);
    setLoadingOptionDetails(true);
    try {
      const details = await getProfilesByEmployeePosition(employeePositionId);
      //console.log("Option details:", details);
      setOptionDetails(details);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoadingOptionDetails(false);
      setModalType('optionDetail'); 
    }
  };

  const fetchProfileDetails = async (employeePositionId) => {
    //console.log("fetchProfileDetails called with id:", employeePositionId);
    setLoadingProfileDetails(true);
    try {
      const details = await getProfilesByEmployeePosition(employeePositionId);
      //console.log("Profile details:", details);
      setProfileDetails(details);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoadingProfileDetails(false);
      setModalType('profileDetail');
    }
  };

  const handleEdit = (e, cellValues) => {
    e.stopPropagation(); 
    setEmployeePosition(cellValues.row.employeePosition)
    setEmployeePositionId(cellValues.row.employeePositionId);
    fetchOptionDetails(cellValues.row.employeePositionId); 
    setShowModal(true);
  }

  const handleView = (e, cellValues) => {
    e.stopPropagation(); 
    setEmployeePosition(cellValues.row.employeePosition)
    fetchProfileDetails(cellValues.row.employeePositionId); 
  }

  const handleSave = async (profiles) => {
    //console.log('FUNCIONO-handleSave');
    //console.log(profiles);
    await updateAllProfilesByPosition(profiles, employeePositionId);
    fetchOptionDetails(employeePositionId);
    setShowModal(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  }

  if (loading) {
    return (
      // <Box sx={{ display: 'flex' }}>
      //     <CircularProgress />
      // </Box>
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }  

  return (
    <>
     {modalType === 'optionDetail' && showModal &&(
      < ManagementPositionOptionDetail
        data={optionDetails}
        employeePositionId={employeePositionId}
        employeePosition={employeePosition}
        profiles={profiles}
        handleClose={() => setModalType(null)}
        handleSave={handleSave}
      />
    )}

    {modalType === 'profileDetail' && (
      <ManagementPositionDetail
        data={optionDetails}
        employeePositionId={employeePositionId}
        employeePosition={employeePosition}
        profiles={profiles}
        handleClose={() => setModalType(null)}
      />
    )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1 }}>
            Perfiles por <span style={{ color: '#00b0ef' }}>Cargo</span>
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ p: '32px 0px' }}>
        <DataTable columns={columns} rows={uniquePositions} 
         sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
          },
        }} />
      </Box>
    </>
  );

};

export default ManageProfileOptions;