import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch
} from "@mui/material";

import {
  Send,
  Check,
  Cancel,
} from '@mui/icons-material';

import BackDrop from '../../../shared/backdropDE';
import { ACTIONS, MESSAGES } from '../../../shared/constants';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AlertDialogQuestion from '../../alert-dialog-question';

const AbsencePermissionsDetail = ({
  handleClose,
  handleSave,
  handleEdit,
  data
}) => {
  const [loading, setLoading] = useState(false);

  // fields
  const [employeeAbsencePermissionsId, setEmployeeAbsencePermissionsId] = useState(data.employeeAbsencePermissionsId);
  const [requirementType, setRequirementType] = useState(data.requirementType);
  const [employeeId, setEmployeeId] = useState(data.employeeId);
  const [airportId, setAirportId] = useState(data.airportId);
  const [absenceType, setAbsenceType] = useState(data.absenceType);
  const [absenceDescription, setAbsenceDescription] = useState(data.absenceDescription);
  const [isDisabled, setIsDisabled] = useState(true);
  const [employeeArea, setEmployeeArea] = useState(data.employeeArea);
  const [startDate, setStartDate] = useState(data.startDate);
  const [endDate, setEndDate] = useState(data.endDate);
  const [justify, setJustify] = useState(data.justify);
  const [isResponse, setIsResponse] = useState((data.requirementType > 0 && data.isActive) ? true : false);
  const [response, setResponse] = useState(data.response);
  const [isVerified, setIsVerified] = useState(data.isVerified);
  const [isActive, setIsActive] = useState(data.isActive);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isActiveCheck, setIsActiveCheck] = useState((data.requirementType > 0 && data.isActive) ? true : false);
  const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isNew, setIsNew] = useState(data.isNew);
  const [isRead, setIsRead] = useState(data.isRead);

  // variables
  const absence = [
    {type: 1, description: "Incapacidad"},
    {type: 2, description: "Licencia"},
    {type: 3, description: "Calamidad"},
    {type: 4, description: "Otro"},
  ];

  // handles
  const validateForm = () => {
    return !(employeeArea && (requirementType > 0) && startDate && endDate && justify);
  }

  const handleMarkChange = (event) => {
    setIsDeleted(event.target.checked);
    setIsActive(!event.target.checked);
  };

  const handleAbsenceType = (e) => {
    setAbsenceType(e);
    setIsDisabled((e == 4) ? false : true);
    setAbsenceDescription((e == 4) ? absenceDescription : "");
  };

  const handleRequerimentMessage = (e) => {
    setRequirementType(e);
    setAlertQuestionOpen(true);

    const message = (e == 1) 
    ? "Recuerde que hasta el momento solo esta reportando su ausencia. No olvide radicar un soporte válido de su ausencia en la Oficina de Nómina a la que usted pertenece."
    : `1.Realizar la solicitud dentro de los 10 primeros días del mes anterior a la solicitud, las solicitudes recibidas fuera de este periodo no se tendrán en cuenta.
    2.La fecha de inicio y finalización de la petición y/o permiso deben estar claras en la solicitud.
    3.Tener en cuenta que la aprobación de las solicitudes se realizará teniendo en cuenta la prioridad, la operación y demás parámetros.
    4.No están autorizados los permisos No remunerados.
    5.En los casos que aplique se solicitarán documentos soporte.
    6.Se emitirá respuesta a la solicitud a través de los canales de comunicación de compañía antes de la publicación de turnos.`;

    setMessage(message);
  };

  const handlePositive = () => {
    //console.log("Acepta Condiciones");
    setAlertQuestionOpen(false);
  };
  
  const handleNegative = () => {
    handleClose(false);
    setAlertQuestionOpen(false);
  };

  return (
    <>
      {alertQuestionOpen && <AlertDialogQuestion
          handlePositive={handlePositive}
          handleNegative={handleNegative}
          textButtonPositive={ACTIONS.ACCEPT}
          textButtonNegative={ACTIONS.CANCEL}
          dialogContentText={message}
          dialogTitle={""}
          open={alertQuestionOpen}
          elemento={""}
      />}

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={true}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ color: '#1A3072', fontWeight: 'bold', fontSize: '24px' }}>
            { (isNew) ? ("Nuevo"):("Detalle") } <span style={{ color: '#00B0EF' }}>Registro</span>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ p: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="select-regtype">Tipo de Registro</InputLabel>
                <Select
                  label="Tipo de Registro"
                  labelId="select-regtype"
                  value={requirementType}
                  onChange={(e) => handleRequerimentMessage(e.target.value)}
                  disabled={isRead}
                >
                    <MenuItem value="0" disabled>Seleccionar una opción</MenuItem>
                    <MenuItem key="1" value="1">Ausencia</MenuItem>
                    <MenuItem key="2" value="2">Permiso</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sx={{ p: 1 }}>
            { isActiveCheck && (
              <FormControl margin="dense">
                <FormControlLabel
                  control={
                    <Switch
                    checked={isDeleted} 
                    onChange={handleMarkChange}
                    />
                  }
                  label="¿Eliminar registro?"
                  labelPlacement="start"
                />
              </FormControl>
            )}
            </Grid>

            { requirementType == 1 && (
              <>
              <Grid item xs={12} md={6}  sx={{ p: 1 }}>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="select-ausmot">Motivo de Ausencia</InputLabel>
                  <Select
                    label="motivo de ausencia"
                    labelId="select-ausmot"
                    value={absenceType}
                    onChange={(e) => handleAbsenceType(e.target.value)}
                    disabled={isRead}
                  >
                      <MenuItem value="0" disabled>Seleccionar una opción</MenuItem>
                    {absence.map((option) => (
                      <MenuItem key={option.type} value={option.type}>{option.description}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}  sx={{ p: 1 }}>
                <FormControl fullWidth={true} margin="dense">
                  <TextField
                    label="Otro motivo"
                    placeholder="¿Cúal?"
                    value={absenceDescription}
                    onChange={(e) => setAbsenceDescription(e.target.value)}
                    type='text'
                    disabled={isDisabled}
                  />
                </FormControl>
              </Grid>
              </>
            )}

            <Grid item xs={12} md={6} sx={{ p: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                <DatePicker
                  disabled={isRead}
                  label="Fecha Desde"
                  inputFormat="YYYY-MM-DD"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField sx={{ mb: 1}}{...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6} sx={{ p: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={isRead}
                  label="Fecha Hasta"
                  inputFormat="YYYY-MM-DD"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => (
                    <TextField sx={{ mb: 1}}{...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={12} sx={{ px: 1 }}>
              <FormControl fullWidth={true}>
                  <TextField
                    label="Área"
                    value={employeeArea}
                    onChange={(e) => setEmployeeArea(e.target.value)}
                    type='text'
                    InputProps={{ disabled: isRead }}
                  />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12} sx={{ px: 1, pt: 2 }}>
              <FormControl fullWidth={true}>
                  <TextField
                    label="Justificación"
                    value={justify}
                    onChange={(e) => setJustify(e.target.value)}
                    type='text'
                    InputProps={{ disabled: isRead }}
                  />
                </FormControl>
            </Grid>

          { isResponse && (
            <>
            <Grid item xs={12} md={6}  sx={{ p: 1 }}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-ver">Verificación</InputLabel>
                <Select
                  label="verificado"
                  labelId="select-ver"
                  value={isVerified}
                  onChange={(e) => setIsVerified(e.target.value)}
                >
                    <MenuItem key={false} value={false}>Sin Verificar</MenuItem>
                    <MenuItem key={true} value={true}>Verificado</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}  sx={{ p: 1 }}>
              <FormControl fullWidth={true} margin="dense">
                <TextField
                  label="Respuesta"
                  value={response}
                  onChange={(e) => setResponse(e.target.value)}
                  type='text'
                />
              </FormControl>
            </Grid>
            </>
          )}

          </Grid>
        </DialogContent>

        <DialogActions sx={{ m: 1 }}>
          <Box>
            {isNew ? (
              <Button
                color="primary"
                variant="contained"
                disabled = {validateForm()}
                onClick={() => { handleSave({requirementType, employeeId, airportId, absenceType, absenceDescription, employeeArea, startDate, endDate, justify, response, isVerified, isActive}) }}
                endIcon={<Send />}>
                {ACTIONS.SEND_ASSESMENT}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disabled = {validateForm()}
                onClick={() => { handleEdit({employeeAbsencePermissionsId, response, isVerified, isActive}) }}
                endIcon={<Check />}>
                {ACTIONS.SAVE}
              </Button>
            )}
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              onClick={() => { handleClose(false) }}
              endIcon={<Cancel />}
              autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>

        {loading && (<BackDrop />)}
      </Dialog>
    </>
  );
}

export default AbsencePermissionsDetail;
