import { getProfile, saveProfile, deleteProfile, getProfileById, savePermissions, getPermissions, getAllPermissionsByProfile } from '../../../shared/data-services/management-profile-service';
import { updateAirportsByEmployee, getEmployeesWithAirports, getAllAirportsByEmployee, GetAllEmployeesWithAirportsByAirport, deleteAirportsByEmployee, GetAllEmployeesByAirport } from '../../../shared/data-services/management-airport-service';
import { getAirports } from '../../../shared/data-services/airport-service';
import { getEmployeesByAirport, getAllEmployeesByAirport, getUserFaceFeature, saveNewUser, updateUser } from '../../../shared/data-services/talmaid-service';

export async function getAllProfile (){
  const data = await getProfile();
  return data || [ ];
};

export async function getemployeesByAirport(AirportId){
  const data = await getEmployeesByAirport(AirportId);
  return data;
}

export async function getallemployeesWithAirports(AirportId){
  const data = await GetAllEmployeesWithAirportsByAirport(AirportId);
  return data;
}

export async function getallemployeesByAirportForEdit(AirportId){
    const data = await GetAllEmployeesByAirport(AirportId);
    return data;
  }

export async function getAllAirports (){
  const data = await getAirports();
  return data;
};

// export async function getEmployees (){
//   const data = await getEmployeesWithProfiles();
//   return data || [ ];
// };

export async function getEmployees (){
    const data = await getEmployeesWithAirports();
    return data || [ ];
  };

// export async function updateAllProfilesByEmployee (profiles){
//   await updateProfilesByEmployee(profiles);
//   return;
// };

export async function updateAllAirportsByEmployee (airports){
    await updateAirportsByEmployee(airports);
    return;
};

// export async function getProfilesByEmployee (employeeId){
//   const data = await getAllProfilesByEmployee(employeeId);
//   return data || [ ];
// };

export async function getAirportsByEmployee (employeeId){
    const data = await getAllAirportsByEmployee(employeeId);
    return data || [ ];
  };

export async function getSelectedProfile (id){
  const data = await getProfileById(id);
  return data;
};

export async function saveSelectedProfile (id, description){
  const Profile = {
    id, 
    description
  }

  const data = await saveProfile(Profile);
  return data;
};


export async function getPermissionByProfile (profileId){
  const data = await getAllPermissionsByProfile(profileId);
  return data || [ ];
};

export async function getAllPermissions(){
  const data = await getPermissions();
  return data || [];
};


export async function saveSelectedPermissions (permissions, profileId, profileName, action){
  // const Permission = {
  //   code, 
  //   description: description
  // }
  if(action === 'CREAR'){
    profileId = null
  }
  const data = await savePermissions(permissions, profileId, profileName);
  return data;
};

export async function deleteAirports (id){
  const data = await deleteAirportsByEmployee(id);
  return data;
};