import { callAPI } from '../network';

export async function getAllPositionHomologationInform (){  
   const data = await callAPI(
                        null, 
                        'PositionHomologation/GetGroupPositionInformAll', 'get');
   return data || [ ];
}

export async function saveGroupHomologation (dataGroup){
    const data = await callAPI(
                          dataGroup, 
                         'PositionHomologation/AddGroupPositionInform', 'post');
    return data;
}

export async function editGroupHomologation (groupHomologation){  
   const data = await callAPI(
                         groupHomologation, 
                        'PositionHomologation/UpdateGroupPositionInform', 'post');
   return data;
}

export async function deleteGroupHomologation (id){
   const data = await callAPI(
                        null, 
                        'PositionHomologation/DeleteGroupPositionInform?id='+ id, 'get');
   return data;
}


export async function validateGroupHomologation (id){
   const data = await callAPI(
                        null, 
                        'PositionHomologation/ValidateRelatedPositionInform?id='+ id, 'get');
   return data;
}

export async function getGroupHomologation (id){
   const data = await callAPI(
                        null, 
                        'PositionHomologation/GetGroupPositionInform?id='+ id, 'get');
   return data;
}

export async function getAllPositionHomologation (){  
   const data = await callAPI(
                        null, 
                        'PositionHomologation/GetAllGroupPosition', 'get');
   return data || [ ];
}

export async function savePositions (positions,groupId){
   const params = {
      EmployeesPositionId: positions.map(position => position.id),
       GroupId: groupId
    };

   const data = await callAPI(
      params, 
            'EmployeePosition/UpdateGroupPositionInform', 'post');
   return data || [ ];
}

//Engrana

export async function getAllPositionHomologationEngrana (){  
   const data = await callAPI(
                        null, 
                        'PositionHomologation/GetGroupPositionEngranaAll', 'get');
   return data || [ ];
}

export async function saveGroupHomologationEngrana (dataGroup){
    const data = await callAPI(
                          dataGroup, 
                         'PositionHomologation/AddGroupPositionEngrana', 'post');
    return data;
}

export async function editGroupHomologationEngrana (groupHomologation){  
   const data = await callAPI(
                         groupHomologation, 
                        'PositionHomologation/UpdateGroupPositionEngrana', 'post');
   return data;
}

export async function deleteGroupHomologationEngrana (id){
   const data = await callAPI(
                        null, 
                        'PositionHomologation/DeleteGroupPositionEngrana?id='+ id, 'get');
   return data;
}

export async function validateGroupHomologationEngrana (id){
   const data = await callAPI(
                        null, 
                        'PositionHomologation/ValidateRelatedPositionEngrana?id='+ id, 'get');
   return data;
}

export async function getGroupHomologationEngrana (id){
   const data = await callAPI(
                        null, 
                        'PositionHomologation/GetGroupPositionEngrana?id='+ id, 'get');
   return data;
}

export async function savePositionsEngrana (positions,groupId){
   const params = {
      EmployeesPositionId: positions.map(position => position.id),
       GroupId: groupId
    };

   const data = await callAPI(
      params, 
            'EmployeePosition/UpdateGroupPositionEngrana', 'post');
   return data || [ ];
}