import { SERVICE_URLS } from '../../shared/constants';
import axios from 'axios';
import { processErrorMessage } from '../../shared/functions';

export async function changePasswordUser(password, setMessage, setOpen, key) {

  const parameters = {
    Password: password,
    Key: key,
  }

  try {
    const response = await axios.post(
      SERVICE_URLS.ROOT + 'Security/updatePassword',
      JSON.stringify(parameters), {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
   
    if (response.status === 200) {
      setMessage('Clave cambiada correctamente');
      setOpen(true);
      setTimeout(
        function () {
          window.location = "/";
        },
        5000);
    }
    else {
      setMessage('Ocurrio un problema con su cambio de clave. Valide con el área de tecnología.');
      setOpen(true);
    }
  } catch (error) {
    setMessage(processErrorMessage(error.response.data) || '');
    setOpen(true);
  }

  return null;
};
