import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const  AlertDialogQuestion = (props) => {

    // const { handleClose, dialogContentText, dialogTitle, open, elemento, handleAccept } = props;

    const { handlePositive, handleNegative, textButtonPositive, textButtonNegative, dialogContentText, dialogTitle, open, elemento } = props;

  return (
    <div>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleNegative}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle sx={{textAlign: 'center'}}>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" sx={{textAlign: 'center'}}>
                    {dialogContentText}
                </DialogContentText>
                {elemento}
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePositive}>{textButtonPositive}</Button>
                <Button onClick={handleNegative}>{textButtonNegative}</Button>
            </DialogActions>
        </Dialog>
    </div>
  );
  }
  
  export default AlertDialogQuestion;
