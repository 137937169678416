import { 
    getShiftAbbreviation,
    getShiftsSegments,
    getDataEmployeeRequired,
    saveShiftChange,
    getDataChangeRequired,
    sendShiftChange
} from '../../../shared/data-services/shift-service';
//import { getUserData } from '../../../shared/info-device';

export async function getSegments(sId){
    const data = await getShiftsSegments(sId);
    return data;
};

export async function getAbbreviation(abbreviation) {
    const data = await getShiftAbbreviation(abbreviation);
    return data;
};

// Changes
export async function getEmployeeRequired(search) {
    var queryString = Object.keys(search).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(search[key]);
    }).join('&');

    const data = await getDataEmployeeRequired(queryString);
    const check = (data.employeeRequiredId != null) ? true : false;

    return {...data, "status": check};
};

export async function getChangeRequired(fromId, toId) {
    const data = await getDataChangeRequired(fromId, toId);
    return data;
};

export async function saveShiftRequest(dataChange){
    const data = await saveShiftChange(dataChange);
    return data;
};

export async function approveShiftRequest(dataChange){
    const data = await sendShiftChange(dataChange);
    return data;
};
