import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    IconButton,
    ImageList,
    ImageListItem,
    TableContainer,
    ImageListItemBar,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Card,
    CardContent,
    Divider,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getEmployeePolyvalencePayment } from './logic';
import { TITLES } from '../../shared/constants';
import { CreateGuid } from '../../shared/functions';
import { getUser } from '../../shared/auth-service';
import { isMobile } from 'react-device-detect';
import { FormatCurrency } from '../../shared/functions';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';


const PolyvalencePayment = () => {
    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const showIcon = (value) => {
        if (value) {
            return (<CheckCircleOutlineIcon color="success" />);
        }
        return (<HighlightOffIcon color="error" />);
    };

    useEffect(() => {
        async function fetchData() {
            if (loading) {
                try {
                    const data = await getEmployeePolyvalencePayment(user.employeeId);
                    setLoading(false);
                    setData(data);
                    // await RegisterUsabilityLog('Polivalencia', user.employeeId);
                } catch (error) {
                    setError(error.message);
                    setLoading(false);
                }
            }
        }
        fetchData();
    });

    if (loading) {
        return (
            <BackDrop />
        );
    }

    if (!data && error) {
        return (<ErrorSnackbar message={error} />);
    }

    const employee = data[0];

    if (!isMobile) {
        return (
            <>
                <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                    Mis <span style={{ color: '#00b0ef' }}>Cumplimientos</span>
                </Typography>
                {/* <h2>{`${TITLES.POLYVALENCE}`}</h2> */}

                <Box sx={{ m: 4 }}>
                    <Card>
                        <CardContent>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                            key={CreateGuid()}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">¿Cumples Ausencias?</TableCell>
                                            <TableCell align="right">{showIcon(employee.complyAbsents)}</TableCell>
                                            <TableCell align="left">¿Cumples Disciplinarios?</TableCell>
                                            <TableCell align="right">{showIcon(employee.complyDisciplinaries)}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={CreateGuid()}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">¿Cumples Polivalencias?</TableCell>
                                            <TableCell align="right">{showIcon(employee.complyPolyvalenceFlights)}</TableCell>
                                            <TableCell align="left">Comisión por Polivalencia</TableCell>
                                            <TableCell align="right"> {FormatCurrency(employee.resultValue)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                    <Divider light />
                </Box>

                <Box sx={{ m: 4 }}>
                    <h3>{`${TITLES.POLYVALENCE_POLYVALENCE}`}</h3>

                    <ImageList cols={3} sx={{
                        columnCount: {
                            xs: '1 !important',
                            sm: '2 !important',
                            md: '3 !important',
                            lg: '4 !important',
                            xl: '5 !important',
                        },
                    }}>
                        {employee.polyvalenceDetail?.map(({ logoUrl, airlineName, complyPolyvalence }) => {

                            return (

                                <Box component="span" sx={{ p: 2, border: '1px solid grey' }}>
                                    <ImageListItem key={CreateGuid()} rowHeight={164} >
                                        <img
                                            src={logoUrl}
                                            alt={airlineName}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            position="below"
                                            actionIcon={
                                                <IconButton
                                                >
                                                    {showIcon(complyPolyvalence)}
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                </Box>
                            );
                        }
                        )}
                    </ImageList>
                </Box>
            </>
        );



    }

    return (
        <>
            <h2>{`${TITLES.POLYVALENCE}`}</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow
                            key={CreateGuid()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">¿Cumples Ausencias?</TableCell>
                            <TableCell align="right">{showIcon(employee.complyAbsents)}</TableCell>
                        </TableRow>
                        <TableRow
                            key={CreateGuid()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">¿Cumples Disciplinarios?</TableCell>
                            <TableCell align="right">{showIcon(employee.complyDisciplinaries)}</TableCell>
                        </TableRow>
                        <TableRow
                            key={CreateGuid()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">¿Cumples Polivalencias?</TableCell>
                            <TableCell align="right">{showIcon(employee.complyPolyvalenceFlights)}</TableCell>
                        </TableRow>
                        <TableRow
                            key={CreateGuid()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">Comisión por Polivalencia</TableCell>
                            <TableCell align="right">{FormatCurrency(employee.resultValue)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <h3>{`${TITLES.POLYVALENCE_POLYVALENCE}`}</h3>

            <ImageList cols={1} sx={{
                columnCount: {
                    xs: '1 !important',
                    sm: '2 !important',
                    md: '3 !important',
                    lg: '4 !important',
                    xl: '5 !important',
                },
            }}>
                {employee.polyvalenceDetail?.map(({ logoUrl, airlineName, complyPolyvalence }) => {

                    return (

                        <Box component="span" sx={{ p: 2, border: '1px solid grey' }}>
                            <ImageListItem key={CreateGuid()} rowHeight={164} >
                                <img
                                    src={logoUrl}
                                    alt={airlineName}
                                    loading="lazy"

                                />
                                <ImageListItemBar
                                    position="below"
                                    actionIcon={
                                        <IconButton
                                        >
                                            {showIcon(complyPolyvalence)}
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Box>
                    );
                }
                )}
            </ImageList>
        </>
    );
}

export default PolyvalencePayment;