import { callAPI } from '../network';

export async function RegisterUsabilityLog (ModuleName,EmployeeId){

    const parameters = {
        employeeId: EmployeeId,
        moduleName: ModuleName,
    }

    //console.log(parameters);

    const data = await callAPI(
                         parameters, 
                         'ModuleUsabilityLog/RegisterUsabilityLog', 'post');
    return null;
 }

 