import { getAssessmentReportDetailByNewsletterId } from '../../../shared/data-services/assessment-service';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export async function getDataAssessmentReportDetailByNewsletterId (id){
    debugger;
    const utcHours = new Date().getUTCHours();
    const localTimeHours = new Date().getHours();

    const hours = localTimeHours - utcHours;

    const response = await getAssessmentReportDetailByNewsletterId(id,hours);
    const fileName = 'ReporteDetalleEvaluacion_'+Date.now() ;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(response);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
};


