import React from 'react';
import { useSnackbar } from 'notistack';

import {
    List,
    Grid,
    Divider,
    Typography, 
    Button,
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CheckIcon from '@mui/icons-material/Check';
import { getUser } from '../../shared/auth-service';
import { ACTIONS, TITLES } from '../../shared/constants';
import ContentViewer from '../content-viewer';
import { sendSignature }  from './logic';
import { useState } from 'react';
import UserAssessment from '../user-assessment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import UserAssessmentFeedBack from '../user-assessment-feedback'

const PendingNewsletters = (props) => { 
    const [showQuestions, setShowQuestions] = useState(null);
    const [showFeedback, setShowFeedback] = useState(null);
    const { data, refresh } = props;
    const { enqueueSnackbar } = useSnackbar();
    const user = getUser();
    const [assessment, setAssessment] = useState(null);
    const [signatureId, setSignatureId] = useState(null);
    const [newsletterId, setNewsletterId] = useState(null);
    const [message, setMessage] = useState(null);
    
    const handleAcept = async (signatureId,resp) => {
        if (signatureId) {
            var accepted = await sendSignature(signatureId, '', user.employeeId);
            if(accepted){
                enqueueSnackbar('Circular aceptada', { variant: 'success' });
            }
            refresh();
        }else {
            setShowFeedback(true);
            setMessage(resp.data); 
        }
    }

    const handleShowQuestions = (assessment,signatureId,id) => {
        setSignatureId(signatureId);
        setAssessment(assessment);
        setNewsletterId(id);
        setShowQuestions(true);
    }


    if (showFeedback) {
        return (<UserAssessmentFeedBack 
                    name={assessment.name}
                    assessment = {assessment}
                    message = {message}
                    handleClose = {showFeedback}
                    questions={assessment.questions}
                    refresh = {refresh}
                    
        />);
    }

    if(showQuestions){

        return (<UserAssessment 
                questions={assessment.questions}
                assessmentId={assessment.id}
                name={assessment.name}
                handleAcept = {handleAcept}
                signatureId = {signatureId}
                assessment = {assessment}
                newsletterId = {newsletterId}
        />);
    }
    
    return (
            <>
                <Typography variant="h5" style={{color: '#1a3072'}} fontWeight="bold" sx={{ flexGrow: 1, m: 1,  }}>
                    Mis <span style={{color: '#00b0ef'}}>Circulares pendientes </span>
                </Typography>
                <Alert severity="info">Debes completar las circulares pendientes para lograr visualizar el calendario de turnos.</Alert>
                <List>
                        
                    {data.map(({name,newsletterContent,assessment,signatures, id,retries}) => {
                            const signatureId = signatures[0].id;
                            return (
                                <>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography gutterBottom variant="h6" component="div">
                                                        <b>{name}</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {newsletterContent.map(item => {
                                                        return (<ContentViewer {...item}/>);
                                                    })}
                                                    {(assessment && assessment.active) &&
                                                    <Grid container spacing={3}
                                                        rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                        > 
                                                        <Grid item xs={12}>
                                                            <Typography gutterBottom variant="h8" >
                                                                {TITLES.START_ASSESMENT}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                        {retries<assessment.numberOfAttempts && <Button 
                                                            onClick={()=> {handleShowQuestions(assessment,signatureId,id)}} 
                                                            variant="contained"
                                                            color="info"
                                                            endIcon={<CheckIcon  />}>
                                                                {ACTIONS.START_ASSESMENT}
                                                            </Button>
                                                        }
                                                        {retries >= assessment.numberOfAttempts && <Typography gutterBottom variant="h8" >
                                                                <span style={{color: '#00b0ef'}}> {TITLES.RETRY_TOMORROW} {retries} intentos</span>
                                                            </Typography>
                                                        }
                                                            </Grid>
                                                    </Grid>}
                                                    {(!assessment || !assessment?.active) &&
                                                        <Grid container spacing={12}
                                                            rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                            > 
                                                            <Grid item xs={12}>
                                                                <Typography gutterBottom variant="h9">
                                                                    {TITLES.MARK_NEWSLETTER_AS_COMPLETE}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button 
                                                                onClick={()=> {handleAcept(signatureId)}} 
                                                                variant="contained"
                                                                color="info"
                                                                endIcon={<CheckIcon  />}>
                                                                    {ACTIONS.MARK_NEWSLETTER_AS_COMPLETE}
                                                                </Button>
                                                            </Grid> 
                                                        </Grid>    
                                                    
                                                    }
                                                </AccordionDetails>
                                            </Accordion>    
                                            
                                    <Divider light />
                                </>
                            );
                        }
                    )}
                </List>
            </>
    );
}

export default PendingNewsletters;