import { callAPI } from '../network';

export async function getEmployeePositions (){
  
   const data = await callAPI(
                        null, 
                        'EmployeePosition', 'get');
   return data || [ ];
}

