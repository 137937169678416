import { getAllOptionsByProfile, updateOptionsByProfile } from '../../../shared/data-services/manage-profile-service';
import { getProfile, updateProfilesByPosition, getAllProfilesByEmployeePosition, getAllPermissionsByProfile } from '../../../shared/data-services/management-profile-service';

export async function getProfilesByEmployeePosition (employeePositionId){
    const data = await getAllProfilesByEmployeePosition(employeePositionId);
    return data || [ ];
};

export async function updateAllOptionsByProfile (options, employeePositionId){
  await updateOptionsByProfile(options, employeePositionId);
  return;
};

export async function getAllProfiles(){
  const data = await getProfile();
  return data || [];
};

export async function updateAllProfilesByPosition (profiles, employeePositionId){
  await updateProfilesByPosition(profiles, employeePositionId);
  return;
};

  
