import { callAPI } from '../network';

export async function getNewsletterTypes (){
  
   const data = await callAPI(
                        null, 
                        'NewsletterType', 'get');
   return data || [ ];
}

export async function getNewsletterType (id){

    const data = await callAPI(
                         null, 
                         'NewsletterType/GetById?id='+ id, 'get');
    return data;
 }

 export async function saveNewsletterType (customer){
   const method = customer.id ? 'put' : 'post';
   const data = await callAPI(
                        customer, 
                        'NewsletterType', method);
   return data;
}

export async function deleteNewsletterType (id){
   const data = await callAPI(
                        null, 
                        'NewsletterType?id='+ id, 'delete');
   return data;
}

export async function findNewsletterByTypeId (typeId){
   const data = await callAPI(
                        null, 
                        'NewsletterType/GetNewsletterByTypeId?typeId='+ typeId, 'get');
   return data;
}