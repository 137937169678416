import { 
    getShiftsSegments,
    getSegmentTypes
} from '../../../../shared/data-services/shift-service';

export async function getSegments(sId){
    const data = await getShiftsSegments(sId);
    return data;
};

export async function getSegmentType(){
    const data = await getSegmentTypes();
    return data;
};