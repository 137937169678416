export const Spanish = {
    // Root
    noRowsLabel: 'Sin datos',
    // noResultsOverlayLabel: 'No results found.',
    errorOverlayDefaultLabel: 'Ha ocurrido un error.',
  
    // Density selector toolbar button text
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacta',
    toolbarDensityStandard: 'Estandar',
    toolbarDensityComfortable: 'Comoda',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',
  
    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) =>
      count > 1 ? `${count} filtros activos` : `${count} filtro activo`,
  
    // Quick filter toolbar field
    // toolbarQuickFilterPlaceholder: 'Search…',
    // toolbarQuickFilterLabel: 'Search',
    // toolbarQuickFilterDeleteIconLabel: 'Clear',
  
    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'CSV',
     toolbarExportPrint: 'Imprimir',
    //  toolbarExportExcel: 'Download as Excel',
  
    // Columns panel text
    columnsPanelTextFieldLabel: 'Columna de búsqueda',
    columnsPanelTextFieldPlaceholder: 'Título de columna',
    columnsPanelDragIconLabel: 'Reorder columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',
  
    // Filter panel text
    filterPanelAddFilter: 'Agregar filtro',
    filterPanelDeleteIconLabel: 'Borrar',
    // filterPanelLinkOperator: 'Logic operator',
    filterPanelOperators: 'Operadores',
  
    // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor de filtro',
  
    // Filter operators text
    filterOperatorContains: 'Contiene',
    filterOperatorEquals: 'Es igual',
    filterOperatorStartsWith: 'Comienza con',
    filterOperatorEndsWith: 'Termina con',
    filterOperatorIs: 'Es',
    filterOperatorNot: 'No es',
    filterOperatorAfter: 'Es posterior',
    filterOperatorOnOrAfter: 'Es en o posterior',
    filterOperatorBefore: 'Es anterior',
    filterOperatorOnOrBefore: 'Es en o anterior',
    filterOperatorIsEmpty: 'Está vacío',
    filterOperatorIsNotEmpty: 'No esta vacío',
    filterOperatorIsAnyOf: 'Es cualquiera de',
  
    // Filter values text
    // filterValueAny: 'any',
    // filterValueTrue: 'true',
    // filterValueFalse: 'false',
  
    // Column menu text
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'Desordenar',
    columnMenuSortAsc: 'Ordenar ASC',
    columnMenuSortDesc: 'Ordenar DESC',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count > 1 ? `${count} filtros activos` : `${count} filtro activo`,
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Ordenar',
  
    // Rows selected footer text
    footerRowSelected: (count) =>
      count > 1
        ? `${count.toLocaleString()} filas seleccionadas`
        : `${count.toLocaleString()} fila seleccionada`,
  
    // Total row amount footer text
    footerTotalRows: 'Filas Totales:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    // checkboxSelectionHeaderName: 'Checkbox selection',
    // checkboxSelectionSelectAllRows: 'Select all rows',
    // checkboxSelectionUnselectAllRows: 'Unselect all rows',
    // checkboxSelectionSelectRow: 'Select row',
    // checkboxSelectionUnselectRow: 'Unselect row',
  
    // Boolean cell text
    // booleanCellTrueLabel: 'yes',
    // booleanCellFalseLabel: 'no',
  
    // Actions cell more text
    actionsCellMore: 'más',
  
    // Column pinning text
    // pinToLeft: 'Pin to left',
    // pinToRight: 'Pin to right',
    // unpin: 'Unpin',
  
    // Tree Data
    // treeDataGroupingHeaderName: 'Group',
    // treeDataExpand: 'see children',
    // treeDataCollapse: 'hide children',
  
    // Grouping columns
    // groupingColumnHeaderName: 'Group',
    // groupColumn: name => `Group by ${name}`,
    // unGroupColumn: name => `Stop grouping by ${name}`,
  
    // Master/detail
    // detailPanelToggle: 'Detail panel toggle',
    // expandDetailPanel: 'Expand',
    // collapseDetailPanel: 'Collapse',
  
    // Row reordering text
    // rowReorderingHeaderName: 'Row reordering',
  
    // Aggregation
    // aggregationMenuItemHeader: 'Aggregation',
    // aggregationFunctionLabelSum: 'sum',
    // aggregationFunctionLabelAvg: 'avg',
    // aggregationFunctionLabelMin: 'min',
    // aggregationFunctionLabelMax: 'max',
    // aggregationFunctionLabelSize: 'size',
  };