import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import DataTable from '../../shared/data-table';
import {
  saveAllNewsletterTargets,
  getAllNewsletters,
  getSelectedNewsletter,
  getAllNewsletterStates,
  getAllCustomers,
  getAllNewsletterTypes,
  getAllEmployeePositions,
  getAllAirports,
  getResportStateData,
  getListAssessment,
  getAllNewsLetterContentTypes,
  getAssessmentReportByNewsletterIdData,
} from './logic';
import NewsletterSources from './newsletter-sources';
import NewsletterStateReport from './newsletter-state-report';
import NewsletterAssessmentReport from './newsletter-assessment-report';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { FormatDateToStringComplete } from '../../shared/functions';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';
import { getUser } from '../../shared/auth-service';

const Newsletter = () => {
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [id, setId] = useState(null);
  const [newsletterId, setNewsletterId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModalSource, setShowModalSource] = useState(null);
  const [showModalState, setshowModalState] = useState(null);
  const [showModalAssessment, setShowModalAssessment] = useState(null);
  const [action, setAction] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [newsletterStates, setNewsletterStates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [newsletterTypes, setNewsletterTypes] = useState([]);
  const [employeePositions, setEmployeePositions] = useState([]);
  const [airports, setAirports] = useState([]);
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);
  const [assessments, setAssessments ] = useState([]);
  const [newsLetterContentTypes, setNewsLetterContentTypes ] = useState([]);
  const [onlyEnabledCustomerEmployees, setOnlyEnabledCustomerEmployees ] = useState(false);
  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getAllNewsletters(user.employeeId);
          const newsletterStatesData = await getAllNewsletterStates();
          const customersData = await getAllCustomers();
          const newsletterTypesData = await getAllNewsletterTypes();
          const employeePositionsData = await getAllEmployeePositions();
          const airportsData = await getAllAirports();
          const assessmentData  = await getListAssessment();
          const newsLetterContentTypesData = await getAllNewsLetterContentTypes();
          setNewsLetterContentTypes(newsLetterContentTypesData); 
          setAssessments(assessmentData); 
          setEmployeePositions(employeePositionsData);
          setNewsletterStates(newsletterStatesData);
          setCustomers(customersData);
          setNewsletterTypes(newsletterTypesData);
          setAirports(airportsData);
          setData(data);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      }
    }
    fetchData();
  });

  useEffect(() => {
    async function fetchRow() {
      if (id) {
        if (action === ACTIONS.VIEW_STATE) {
          const data = await getResportStateData(id);
          setReportData(data);
          setId(null);
          setshowModalState(true);
          return;
        }
        
        if (action === ACTIONS.VIEW_ASSESSMENT_STATE) {
          const data = await getAssessmentReportByNewsletterIdData(id);
          setReportData(data);
          setId(null);
          setShowModalAssessment(true);
          return;
        }

        if (action == ACTIONS.VIEW_STATE || action === ACTIONS.EDIT_SOURCES) {
          const item = await getSelectedNewsletter(id);
          //console.log('esta es la data')
          //console.log(item);
          setOnlyEnabledCustomerEmployees(item.onlyEnabledCustomerEmployees);
          setSelectedItem(item);
          setId(null);
          if (action === ACTIONS.EDIT_SOURCES) {
            setShowModalSource(true);
          }
          return;
        }
        if (action == ACTIONS.VIEW_ASSESSMENT_STATE) {
          const data = await getResportStateData(id);
          setReportData(data);
          setId(null);
          setShowModalAssessment(true);
          return;
        }
      }
    }
    fetchRow();
  });


  const handleSaveSources = async (
    id,
    code,
    date,
    newsletterStateId,
    newsletterTypeId,
    name,
    customerId,
    selectedEmployeePositions,
    selectedAirports,
    assessmentId,
    newsletterContents,
    OnlyEnabledCustomerEmployees,) => {

    await saveAllNewsletterTargets(
      {
        id,
        code,
        date,
        newsletterStateId,
        newsletterTypeId,
        name,
        customerId,
        employeePositions: selectedEmployeePositions,
        airports: selectedAirports,
        assessmentId,
        newsletterContents,
      },
      OnlyEnabledCustomerEmployees
    );
    setShowModalSource(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleNew = async () => {
    setSelectedItem({});
    setId(null);
    setShowModalSource(true);
  };

  const handleActionButton = (e, cellValues, action) => {
    e.stopPropagation();
    setId(cellValues.row.id);
    setNewsletterId(cellValues.row.id);
    setName(cellValues.row.name);
    setAction(action);
  };

  const columns = [
    {
      field: "EditSources",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.EDIT_SOURCES) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "ViewState",
      headerName: "Ver",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="success"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.VIEW_STATE) }}
        >
          <QueryStatsIcon />
        </IconButton>;
      }
    },
    {
      field: "ViewAssessmentState",
      headerName: "Eva.",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        if (!cellValues.row.assessmentId)  {
          return null;
        }
        return <IconButton
          color="error"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.VIEW_ASSESSMENT_STATE) }}
        >
          <QuestionAnswerIcon />
        </IconButton>;
      }
    },
    { field: 'id', headerName: 'Id', width: 50 },
    { field: 'code', headerName: 'Cód. Externo', width: 130 },
    {
      field: 'newsletterTypeName', headerName: 'Tipo',
      renderCell: (cellValues) => {
        return cellValues.value ? cellValues.value.name : null;
      }, width: 100
    },
    { field: 'name', headerName: 'Nombre', width: 600 },
    {
      field: 'date', headerName: 'Fecha',
      renderCell: (cellValues) => {
        return cellValues.value ? FormatDateToStringComplete(cellValues.value) : null;
      }, width: 100
    },
    {
      field: 'customerName', headerName: 'Emisor/Cliente',
      renderCell: (cellValues) => {
        return cellValues.value ? cellValues.value.name : null;
      }, width: 250
    },
    {
      field: 'stateName', headerName: 'Estado',
      renderCell: (cellValues) => {
        return cellValues.value ? cellValues.value.name : null;
      }, width: 120
    },
    
  ];

  if (loading) {
    return (
      // <Box sx={{ display: 'flex' }}>
      //     <CircularProgress />
      // </Box>
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>

      {showModalAssessment && <NewsletterAssessmentReport
         name={`Estado evaluación circular ${name}`}
         reportData={reportData}
         handleClose={setShowModalAssessment}
         id={newsletterId}
      />}

      {showModalSource && <NewsletterSources
        data={selectedItem}
        handleClose={setShowModalSource}
        handleSaveSources={handleSaveSources}
        airports={airports}
        customers={customers}
        employeePositions={employeePositions}
        newsletterTypes={newsletterTypes}
        newsletterStates={newsletterStates}
        assessments={assessments}
        newsLetterContentTypes={newsLetterContentTypes}
        onlyEnabledCustomerEmployees={onlyEnabledCustomerEmployees}
      />}

      {showModalState && <NewsletterStateReport
        name={name}
        reportData={reportData}
        handleClose={setshowModalState}
        airports={airports}
        employeePositions={employeePositions}
      />}
      {error && <ErrorSnackbar message={error} />

      }
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Circulares
          </Typography>
          {/* <Typography variant="h5" gutterBottom>
                {`${TITLES.NEWSLETTER}`}
              </Typography> */}
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Button
              endIcon={<AddCircle />}
              variant="contained"
              onClick={() => { handleNew() }} autoFocus>{ACTIONS.NEW}</Button>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data || []} />
    </>
  );
};

export default Newsletter;
