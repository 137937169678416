import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Alert,
  Tooltip,
  FormControlLabel,
  Switch,
  Autocomplete
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { Spanish } from '../../../shared/grid-translations';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Delete from '@mui/icons-material/Delete';
import { ACTIONS } from '../../../shared/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import NewsletterContentDetail from '../newsletter-content-detail';
import AddNewsletterContentDetail from '../add-newsletter-content';
import 'dayjs/locale/es';
import { useSnackbar } from 'notistack';

const NewsletterSources = ({
  handleClose,
  handleSaveSources,
  data,
  action,
  airports,
  employeePositions,
  customers,
  newsletterStates,
  newsletterTypes,
  assessments,
  newsLetterContentTypes,
  onlyEnabledCustomerEmployees={onlyEnabledCustomerEmployees}
}) => {

  const [name, setName] = useState(data.name);
  const [code, setCode] = useState(data.code);
  const [date, setDate] = useState(data.date || new Date());
  const [customerId, setCustomerId] = useState(data.customerId);
  const [newsletterTypeId, setNewsletterTypeId] = useState(data.newsletterTypeId);
  const [newsletterStateId, setNewsletterStateId] = useState(data.newsletterStateId);
  const { id } = data;
  const [selectedEmployeePositions, setSelectedEmployeePositions] = useState(data.employeePositions || []);
  const [selectedEmployeePositionId, setSelectedEmployeePosition] = useState(null);
  const [selectedAirports, setSelectedAirports] = useState(data.airports || []);
  const [newsletterContents, setNewsletterContents] = useState(data.newsletterContent || []);
  const [selectedAirportId, setSelectedAirport] = useState(null);
  const [assessmentId, setAssessmentId] = useState(data.assessmentId || null);
  const [value, setValue] = useState('1');
  const [selectedNewsLetterContentType,  setSelectedNewsLetterContentType] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [filtered, setFiltered] = useState(false);
  const [assessmentsData, setAssessmentsData] = useState(assessments);
  const [OnlyEnabledCustomerEmployees, setOnlyEnabledCustomerEmployees] = useState(true);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const allAirportsOption = { id: -1, code: "Todas", name: "Todas" };
  const airportsWithOptions = [allAirportsOption, ...airports];
  const allEmployeePositionOption = { id: -1, code: "TODOS", name: "TODOS" };
  const employeePositionWithOptions = [allEmployeePositionOption, ...employeePositions];
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleDeleteContent = (index) => {
    let data = [...newsletterContents];
    data.splice(index, 1)
    setNewsletterContents(data);
    enqueueSnackbar("Contenido borrado!");
  } 


  const handleActionButton = (e, cellValues, source) => {
    e.stopPropagation();
    if (source === 'airport') {
      setSelectedAirports(selectedAirports.filter((el) => { return el.id !== cellValues.row.id; }));
    }
    if (source === 'employeePositions') {
      setSelectedEmployeePositions(selectedEmployeePositions.filter((el) => { return el.id !== cellValues.row.id; }));
    }
  };

  const handleSafeNewsLetterContent = (data) => {
    setNewsletterContents([...newsletterContents, data]);
  }


  const handleAddEmployeePositions = (e) => {
    e.stopPropagation();
    if (selectedEmployeePositions.some(el => { return el.id === selectedEmployeePositionId; })) {
      return;
    }
    setSelectedEmployeePositions([...selectedEmployeePositions, employeePositionWithOptions.find(z => z.id === selectedEmployeePositionId)]);
  }

  const handleAddAirports = (e) => {
    e.stopPropagation();
    if (selectedAirports.some(el => { return el.id === selectedAirportId; })) {
      return;
    }
    setSelectedAirports([...selectedAirports, airportsWithOptions.find(z => z.id === selectedAirportId)]);
  }

  useEffect(() => {
    setOnlyEnabledCustomerEmployees(onlyEnabledCustomerEmployees);
  }, [onlyEnabledCustomerEmployees]);

  useEffect(() => {
    // Si ya tienes datos (estás en modo de edición, por ejemplo) y el cliente es una aerolínea.
    if (Object.keys(data).length > 0 && data.customer.isAirline) {
      setIsSwitchDisabled(false);
    } else {
      setIsSwitchDisabled(true);
    }
    // Además, ajusta el estado de OnlyEnabledCustomerEmployees si es necesario.
  }, [data]);

  const validateForm = () => {
    let errors = {};
    if (!name || !name.trim()) errors.name = "El nombre es requerido";
    if (!date) errors.date = "La fecha es requerida";
    if (!customerId) errors.customerId = "El cliente es requerido";
    if (!newsletterTypeId) errors.newsletterTypeId = "El tipo de circular es requerido";
    if (!newsletterStateId) errors.newsletterStateId = "El estado de la circular es requerido";
    if (!selectedAirports.length) errors.newsletterStateId = "Agregue almenos un aeropuerto";
    if (!selectedEmployeePositions.length) errors.newsletterStateId = "Agregue almenos un cargo";

    setValidationErrors(errors);
  
    // Retorna true si no hay errores
    return Object.keys(errors).length === 0;
  };

  const airportColumns = [
    { 
      field: 'code', 
      headerName: 'Base', 
      minWidth: 70, 
      flex: 1,
      renderCell: (cellValues) => {
       // console.log(cellValues);
        return cellValues.id === -1 ? "Todas" : cellValues.code;
      }
    },
    {
      field: "EditSources",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton
            color="error"
            onClick={(e) => handleActionButton(e, cellValues, 'airport')}
          >
            <Delete />
          </IconButton>
        );
      }
    }
  ];

  const employeePositionsColumns = [    
    { 
      field: 'name', 
      headerName: 'Cargo', 
      minWidth: 350, 
      flex: 1,
      renderCell: (cellValues) => {
        //console.log(cellValues);
        return cellValues.id === -1 ? "TODOS" : cellValues.value; 
      }
    },
    {
      field: "EditSources",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton
            color="error"
            onClick={(e) => handleActionButton(e, cellValues, 'employeePositions')}
          >
            <Delete />
          </IconButton>
        );
      }
    }
  ];

  return (
    <div   >
      <Dialog
        maxWidth="xl"
        fullWidth="xl"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Detalle Circular ${code || ''} - ${name || ''}`}
        </DialogTitle>
        <DialogContent>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Datos de la circular" value="1" />
                    <Tab label="Contenido de la circular" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                      <>
                        <span><i>Los campos marcados con * son obligatorios</i></span>
                          <Grid container spacing={4} sx={{marginTop: '1px'}}>
                            <Grid item xs={2}>
                              <FormControl fullWidth={true} margin="dense">
                                <TextField
                                  label="Código externo"
                                  onChange={(e) => setCode(e.target.value)}
                                  value={code}
                                  fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-ES'}>
                                <FormControl fullWidth={true} margin="dense">
                                  <DesktopDatePicker
                                    label="Fecha circular *"
                                    inputFormat="DD/MM/YYYY"
                                    value={date}
                                    fullWidth
                                    onChange={(e) => setDate(e)}
                                    renderInput={(params) => <TextField {...params}  />}
                                  />
                                </FormControl>
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={1.5}>
                              <FormControl fullWidth={true} margin="dense">
                                <InputLabel id="state-select-label">Estado *</InputLabel>
                                <Select
                                  labelId="state-select-label"
                                  label="Estado *"
                                  fullWidth
                                  required
                                  value={newsletterStateId}
                                  onChange={(e) => setNewsletterStateId(e.target.value)}
                                >
                                  {newsletterStates.map(item => {
                                    return (<MenuItem value={item.id}>{item.name}</MenuItem>);
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              <FormControl fullWidth={true} margin="dense">
                                <InputLabel id="type-select-label">Tipo circular *</InputLabel>
                                <Select
                                  labelId="type-select-label"
                                  label="Tipo circular *"
                                  fullWidth
                                  required
                                  value={newsletterTypeId}
                                  onChange={(e) => setNewsletterTypeId(e.target.value)}
                                >
                                  {newsletterTypes.map(item => {
                                    return (<MenuItem value={item.id}>{item.name}</MenuItem>);
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={3.5}>
                              <FormControl fullWidth={true} margin="dense">
                              <Autocomplete
                                id="combo-box-assessments"
                                options={assessmentsData}
                                getOptionLabel={(option) => option.name}
                                fullWidth
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={assessmentsData.find(a => a.id === assessmentId) || null}
                                onChange={(event, newValue) => {
                                  setAssessmentId(newValue ? newValue.id : '');
                                }}
                                renderInput={(params) => (
                                  <TextField 
                                    {...params} 
                                    label="Evaluación" 
                                  />
                                )}
                              />
                              </FormControl>
                            </Grid>
                            <Grid item xs={0.5}>
                              <IconButton
                                  color="error"
                                  onClick={(e) => {
                                    setAssessmentId(null); 
                                    }}
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                              <Grid item xs={0.5}>
                                <Tooltip title="Filtra las evaluaciones por su estado (Activas / Inactivas)">
                                   <IconButton
                                        color="success"
                                        onClick={(e) => {
                                          setAssessmentId(null);
                                          if (!filtered) {
                                            setAssessmentsData(assessments.filter((assessment) => assessment.active ));
                                          } else {
                                            setAssessmentsData(assessments);
                                          }
                                          setFiltered(!filtered); 
                                          }}
                                      >
                                      {filtered && <FilterAltOffIcon />}
                                      {!filtered && <FilterAltIcon />}
                                      </IconButton>
                                </Tooltip>
                              </Grid>
                          </Grid>
                          <p />
                          <Grid container spacing={4}>
                            <Grid item xs={6}>
                              <FormControl fullWidth={true} margin="dense">
                                <TextField
                                  label="Nombre *"
                                  placeholder="Nombre"
                                  value={name || ''}
                                  onChange={(e) => setName(e.target.value)}
                                  require
                                  fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth={true} margin="dense">
                              <Autocomplete
                                id="combo-box-customers"
                                options={customers}
                                getOptionLabel={(option) => option.name}
                                fullWidth
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={customers.find(c => c.id === customerId) || null}
                                onChange={(event, newValue) => {
                                  //console.log(newValue);
                                  setCustomerId(newValue.id);
                                  if (newValue && newValue.isAirline) {
                                    // Si IsAirline es true, habilita el Switch y lo pone en true
                                    setIsSwitchDisabled(false);
                                    setOnlyEnabledCustomerEmployees(true);
                                  } else {
                                    // Si IsAirline es false o no hay selección, deshabilita el Switch y lo pone en false
                                    setIsSwitchDisabled(true);
                                    setOnlyEnabledCustomerEmployees(false);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField 
                                    {...params} 
                                    label="Emisor/Cliente" 
                                    required
                                  />
                                )}
                              />
                              </FormControl>
                            </Grid>
                          </Grid>

                          <p />
                          <Typography variant="h6" gutterBottom>
                            Target / Público objetivo *
                          </Typography>
                          <Grid container spacing={4}>
                            <Grid item xs={3}>
                              <FormControl fullWidth={true} margin="dense">
                                <Grid container spacing={1}>
                                  <Grid item xs={11}>
                                    <Autocomplete
                                        id="combo-box-airports"
                                        options={airportsWithOptions}
                                        getOptionLabel={(option) => option.code}
                                        fullWidth
                                        onChange={(event, newValue) => {
                                          //console.log(newValue);
                                          setSelectedAirport(newValue ? newValue.id : null);
                                        }}
                                        renderInput={(params) => (
                                          <TextField 
                                            {...params} 
                                            label="Base" 
                                          />
                                        )}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      disabled={!selectedAirportId}
                                      color="primary"
                                      onClick={(e) => { handleAddAirports(e) }}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth={true} margin="dense">
                                <Grid container spacing={1}>
                                  <Grid item xs={11}>
                                    <Autocomplete
                                      disablePortal
                                      id="employee-position-autocomplete"
                                      options={employeePositionWithOptions}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event, newValue) => {
                                        setSelectedEmployeePosition(newValue ? newValue.id : null);
                                      }}
                                      renderInput={(params) => 
                                        <TextField 
                                          {...params} 
                                          label="Cargo" 
                                        />}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      color="primary"
                                      disabled={!selectedEmployeePositionId}
                                      onClick={(e) => { handleAddEmployeePositions(e) }}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                              <FormControl margin="normal" fullWidth>
                                <FormControlLabel 
                                 control={
                                  <Tooltip title="Permite definir si la circular será compartida únicamente con personal habilitado para la aerolínea seleccionada">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      <div>¿Solo Personal Habilitado Cliente?</div>
                                      <div style={{ color: OnlyEnabledCustomerEmployees ? 'green' : 'red' }}>
                                        <Switch
                                          checked={OnlyEnabledCustomerEmployees}
                                          onChange={(e) => setOnlyEnabledCustomerEmployees(e.target.checked)}
                                          disabled={isSwitchDisabled}
                                        />
                                        {OnlyEnabledCustomerEmployees ? 'Sí' : 'No'}
                                      </div>
                                  </div>
                                  </Tooltip>
                                }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <p />
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              <DataGrid style={{ height: 430 }} columns={airportColumns} rows={selectedAirports} localeText={{ ...Spanish }} componentsProps={{
                                pagination: {
                                  labelRowsPerPage: ('Filas por página')
                                }
                              }} />
                            </Grid>
                            <Grid item xs={9}>
                              <DataGrid style={{ height: 430 }} columns={employeePositionsColumns} rows={selectedEmployeePositions} localeText={{ ...Spanish }} componentsProps={{
                                pagination: {
                                  labelRowsPerPage: ('Filas por página')
                                }
                              }} />
                            </Grid>
                          </Grid>
                    </>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={2}>
                      <Grid item xs={8}>
                      </Grid> 
                      <Grid item xs={9}>
                              <FormControl fullWidth={true} margin="dense">
                                <InputLabel id="contenttype-select-label">Tipo contenido</InputLabel>
                                <Grid container spacing={1}>
                                  <Grid item xs={11}>
                                    <Select
                                      labelId="contenttype-select-label"
                                      label="Tipo contenido"
                                      fullWidth
                                      required
                                      onChange={(e) => setSelectedNewsLetterContentType(e.target.value)}
                                    >
                                      {newsLetterContentTypes.map(item => {
                                        return (<MenuItem value={item.id}>{item.name}</MenuItem>);
                                      })}
                                    </Select>
                                  </Grid>
                                  <Grid item xs={1}>
                                    {selectedNewsLetterContentType && <IconButton
                                      color="primary"
                                      onClick={(e) => { setShowAddModal(true) }}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                    }
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                  </Grid>
                  <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                      Contenido encontrado:   <span style={{ color: '#00b0ef' }}>{newsletterContents.length}</span>
                  </Typography>
                  {newsletterContents.map((item, index) => {
                        return (<NewsletterContentDetail 
                              title= {item.title}
                              content= {item.content}
                              newsLetterContentTypeId= {item.newsLetterContentTypeId}
                              index = {index}
                              handleDeleteContent = {handleDeleteContent}
                          />);
                  })}
                </TabPanel>
              </TabContext>
            </Box>
        </DialogContent>
        <DialogActions sx={{ m: 3 }}>
          <Grid container spacing={0}>
              <Grid item xs={8.6}>
                <Alert severity="warning">¡Recuerde que los cambios solo se aplicaran si presiona el boton guardar!</Alert>  
              </Grid>
              <Grid item xs={0.2}>
              </Grid>  
              <Grid item xs={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        if (validateForm()) {
                          // Lógica para guardar los datos
                          setIsSaveButtonDisabled(true);
                          handleSaveSources(
                            id,
                            code,
                            date,
                            newsletterStateId,
                            newsletterTypeId,
                            name,
                            customerId,
                            selectedEmployeePositions,
                            selectedAirports,
                            assessmentId,
                            newsletterContents,
                            OnlyEnabledCustomerEmployees
                          );
                          // Resto de tu lógica de guardado
                        } else {
                          enqueueSnackbar("Por favor, completa todos los campos requeridos.", { variant: "error" });
                        }
                        //setIsSaveButtonDisabled(false);
                      }}
                      endIcon={<SaveIcon />}
                      disabled={isSaveButtonDisabled}
                    >
                      {isSaveButtonDisabled ? "Guardando..." : ACTIONS.SAVE}
                    </Button>
              </Grid>
              <Grid item xs={0.5}>
              </Grid>
              <Grid item xs={1}>
                    <Button
                      endIcon={<CancelIcon />}
                      variant="outlined"
                      onClick={() => { handleClose(false) }} autoFocus>{ACTIONS.CANCEL}</Button>
              </Grid>
          </Grid>
          
        </DialogActions>
      </Dialog>

      {showAddModal && <AddNewsletterContentDetail
          newsletterId = {id}
          newsLetterContentTypeId = {selectedNewsLetterContentType}
          saveAddedItem = {handleSafeNewsLetterContent}
          handleClose = {()=> { setShowAddModal(false) }}
          isOpen = {showAddModal}
        />
      }
    </div>
  );
}

export default NewsletterSources;