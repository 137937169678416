import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Card,
    List,
    CardContent,
    TableCell,
    Divider,
    Typography, 
    Button,
    Box,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import BrushIcon from '@mui/icons-material/Brush';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import { getAllUsignedNewsletters } from './logic';
import { TITLES } from '../../shared/constants';
import { cardStyles }  from './styles.js';
import { FormatDateToStringComplete, CreateGuid, FormatPercentage } from '../../shared/functions';
import { ACTIONS } from '../../shared/constants';
import { getUser } from '../../shared/auth-service';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';

const PendingSignatures = () => { 
    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [parameter] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    

    useEffect(() => {
        async function fetchData() {
            if (loading){ 
                try {
                    const data = await getAllUsignedNewsletters(parameter, user.employeeId);
                    setLoading(false);
                    setData(data);
                } catch (error) {
                    setError(error.message);
                    setLoading(false);
                }
            }
          }
          fetchData();       
      });
    
    if (loading) {
        return (
        // <Box sx={{ display: 'flex' }}>
        //     <CircularProgress />
        // </Box>
        <BackDrop/>
        );
    }

    if (!data && error) {
        return(<ErrorSnackbar message={error}  />);
    }
    
    const handleSignature = (newsletterId) => {
        navigate('/Signature/'+newsletterId);
    }

    return (
            <>
                <Typography variant="h5" style={{color: '#1a3072'}} fontWeight="bold" sx={{ flexGrow: 1, m: 1,  }}>
                    Mis <span style={{color: '#00b0ef'}}>Circulares</span>
                </Typography>
                {/* <h2>{`${TITLES.SIGNATURE}`}</h2> */}

                <List>
                    {data.map(({name,date,state,status,newsletterId}) => {

                            return (
                                <Box sx={{ m: 4 }}>
                                    <Card styles={cardStyles} >
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                <b>{name}</b>
                                            </Typography>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                     <TableBody>
                                                        <TableRow
                                                            key={CreateGuid()}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <b>Fecha:</b>
                                                            </TableCell>
                                                            <TableCell align="right">{FormatDateToStringComplete(date)}</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={CreateGuid()}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <b>Estado:</b>
                                                            </TableCell>
                                                            <TableCell align="right">{state}</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={CreateGuid()}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <b>Avance:</b>
                                                            </TableCell>
                                                            <TableCell align="right">{FormatPercentage(status)}</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={CreateGuid()}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                            <Button 
                                                                onClick={()=> {handleSignature(newsletterId)}} 
                                                                variant="contained"
                                                                color="info"
                                                                endIcon={<BrushIcon  />}>
                                                                    {ACTIONS.SIGN}
                                                                </Button>
                                                            </TableCell>
                                                           
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                    <Divider light />
                                </Box>
                            );
                        }
                    )}
                </List>
            </>
    );
}

export default PendingSignatures;