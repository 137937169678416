import { callAPI } from '../../shared/network';

export async function getEmployeeOverTime (employeeId,handleError){
  
   const data = await callAPI(
                        null, 
                        'SelfManagement/getEmployeeOverTime?employeeId='+ employeeId, 'get',handleError);
   return data || [];
};


export async function getPendingNewslettersByEmployee (employeeId,handleError){
  
   const data = await callAPI(
      null, 
            'Newsletter/getPendingNewslettersByEmployeeId?employeeId='+ employeeId, 'get',handleError);
   
   return data || [];
};