import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Spanish } from './grid-translations';
export default function DataTable(props) {
  const {columns, rows} = props;
  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        localeText={{
          ...Spanish
        }}
        rows={rows}
        columns={columns} {...props}
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: ('Filas por página')            
          }
        }}
      />
    </div>
  );
}
