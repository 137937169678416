import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../../shared/constants';
import DataTable from '../../../shared/data-table';
import {
  getAllProfile,
  getSelectedProfile,
  saveSelectedProfile,
  deleteSelectedProfile,
  getAllPermissions,
  saveSelectedPermissions
} from './logic';
//import ProfileTypeDetails from '../../Profile-type/Profile-type-detail';
import ManagementProfileDetail from '../../gestion/management-profile/management-permissionsByProfile-Source';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import ErrorSnackbar from '../../error-snackbar';
import BackDrop from '../../../shared/backdropDE';
import { getAllPermissionsByProfile } from '../../../shared/data-services/management-profile-service';
import AlertDialogQuestion from '../../alert-dialog-question';

const ManagementProfile = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [action, setAction] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [profileId, setProfileId] = useState(null);
  const [profile, setProfile] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [optionDetails, setOptionDetails] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [uniquePositions, setUniquePositions] = useState([]);
  const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const profiles = await getAllProfile();
          const permissions = await getAllPermissions();
          // Suponiendo que getAllPermissionsByProfile pueda ser usado para obtener el conteo
          // Esto es ineficiente si tienes muchos perfiles; idealmente, querrías un endpoint que devuelva los perfiles con sus conteos de permissions
          const profilesWithPermissionsCount = await Promise.all(profiles.map(async (profile) => {
            const permissions = await getAllPermissionsByProfile(profile.id);
            return { ...profile, permissionsCount: permissions.length };
          }));
          setLoading(false);
          setData(profilesWithPermissionsCount);
          setPermissions(permissions);

        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
  }, [loading]);

  // useEffect(() => {
  //   async function fetchRow() {
  //     if (id) {
  //       const item = await getSelectedProfile(id);
  //       setSelectedItem(item);
  //       setId(null);
  //       setShowModal(true);
  //     }
  //   }
  //   fetchRow();
  // });
  useEffect(() => {
    //console.log(data);
    if (data) {
      setUniquePositions(getUniquePositions(data));
    }
  }, [data]);

  const getUniquePositions = (data) => {
    const unique = {};
    data.forEach((item) => {
      if (!unique[item.profile]) {
        unique[item.profile] = item;
      }
    });
    //console.log(unique);
    return Object.values(unique);
  };

  const handleDeleteData = async (cellValues) => {
    setAlertQuestionOpen(true);
    setIdDelete(cellValues.row.id);
  };

  const handleCloseAlert = () => {
    setAlertQuestionOpen(false);
    setIdDelete(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = async (permissions) => {
    //console.log(permissions)
    await saveSelectedPermissions(permissions, permissions.profileId, permissions.profileName, permissions.action);
    setShowModal(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleDelete = async () => {
    //console.log(id);
    await deleteSelectedProfile(idDelete);
    setShowModal(false);
    setAlertQuestionOpen(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.DELETE);
  };

  const handleNew = async () => {
    setSelectedItem({});
    setId(null);
    setShowModal(true);
    setAction(ACTIONS.NEW);
  };

  const handleActionButton = (e, cellValues, action) => {
    e.stopPropagation();
    setId(cellValues.row.id);
    setAction(action);
  };

  const handleEdit = (e, cellValues) => {
    e.stopPropagation(); 
    setProfile(cellValues.row.description)
    setProfileId(cellValues.row.id);
    fetchOptionDetails(cellValues.row.id); 
  }

  const fetchOptionDetails = async (profileId) => {
    //console.log("fetchOptionDetails called with id:", profileId);
    //setLoadingOptionDetails(true);
    try {
      const details = await getAllPermissionsByProfile(profileId);
      //console.log("Option details:");
      //console.log(details);
      setOptionDetails(details);
      setShowModal(true);
      setAction(ACTIONS.Edit);
    } catch (error) {
      setError(error.message);
    } finally {
    }
  };

  const columns = [
    { field: 'id', headerName: 'Código', miwidth: 100, flex: 0.2, hide: true },
    { field: 'description', headerName: 'Descripción', minwidth: 350, flex: 1 },
    { field: 'permissionsCount', headerName: 'Permisos Asignados', minwidth: 150, flex: 0.1, align: 'center', headerAlign: 'center' },
    {
      field: "Editar",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        //console.log(cellValues);
        return <IconButton
          color="info"
          onClick={(e) => { handleEdit(e, cellValues) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "Borrar",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          aria-label="delete"
          color="error"
          onClick={() => { handleDeleteData(cellValues) }}
        >
          <DeleteIcon />
        </IconButton>;
      }
    },
  ];

  if (loading) {
    return (
      // <Box sx={{ display: 'flex' }}>
      //     <CircularProgress />
      // </Box>
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>
      {showModal && <ManagementProfileDetail
          data={optionDetails}
          profileId={profileId}
          profile={profile}
          permissions={permissions}
          handleClose={handleCloseModal}
          handleSave={handleSave}
          action={action}
      />}

      {alertQuestionOpen && <AlertDialogQuestion
        handlePositive={handleDelete}
        handleNegative={handleCloseAlert}
        textButtonPositive={ACTIONS.ACCEPT}
        textButtonNegative={ACTIONS.CANCEL}
        dialogContentText={MESSAGES.CONFIRM_DELETE}
        dialogTitle={""}
        open={alertQuestionOpen}
        elemento={""}
      />}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Maestro <span style={{ color: '#00b0ef' }}>Perfiles</span>
          </Typography>
          {/* <Typography variant="h5" gutterBottom>
                {`${TITLES.Profile_TYPE}`}
              </Typography> */}
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Button
              endIcon={<AddCircle />}
              variant="contained"
              onClick={() => { handleNew() }} autoFocus>{ACTIONS.NEW}</Button>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data} 
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
          }
        }}/>
    </>
  );
};

export default ManagementProfile;
