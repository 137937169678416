import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  getProfile,
} from '../logic';
import { DataGrid } from '@mui/x-data-grid';
import { Spanish } from '../../../../shared/grid-translations';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../../shared/constants';

const ManagementPositionOptionDetail = ({
  data,
  handleClose,
  handleSave,
  employeePositionId,
  employeePosition,
  profiles
}) => {
  const [selectedEmployeePositionId, setSelectedEmployeePosition] = useState(employeePositionId);
  const [selectedCodes, setSelectedCodes] = useState(data || []);
  const [selectedCodesSend, setSelectedCodesSend] = useState({
    profiles: [], 
    employeePositionId: selectedEmployeePositionId, 
  });
  const [selectedCode, setSelectedCode] = useState(null);
  const [perfilId, setPerfilId] = useState(data.perfilId);

const handleAddEmployeePositions = (e) => {
    e.stopPropagation();
  if (!selectedCode || selectedCodes.some(el => el.description === selectedCode.description)) {
    return;
  }

  const newProfile = {
    description: selectedCode.description,
    id: selectedCode.id,
    employeePositionId: selectedEmployeePositionId,
  };

  const newSelectedCodes = [...selectedCodes, newProfile];

  setSelectedCodes(newSelectedCodes);
  setSelectedCodesSend({
    ...selectedCodesSend,
    profiles: newSelectedCodes.map(({ description, id }) => ({
      Description: description,
      Id: id,
    })),
  });
};

  const handleDelete = (e, cellValues) => {
    e.stopPropagation();
    //console.log('habia esto' + selectedCodes);
    const updatedSelectedCodes = selectedCodes.filter(el => el.id !== cellValues.row.id);
    setSelectedCodes(updatedSelectedCodes);

    setSelectedCodesSend({
      ...selectedCodesSend,
      profiles: updatedSelectedCodes.map(({ description, id }) => ({
        Description: description,
        Id: id,
      })),
    });

    //console.log('y quedo esto' + selectedCodesSend);
  };

  const codeColumns = [
    { field: 'id', headerName: 'Id', flex: 1, hide: true},
    { field: 'description', headerName: 'Descripción', minWidth: 100, flex: 1 },
    {
      field: "EditSources",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="error"
          onClick={(e) => { handleDelete(e, cellValues) }}
        >
          <Delete />
        </IconButton>;
      }
    },
  ];

  return (
    <div   >
      <Dialog
        maxWidth="md"
        fullWidth="md"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalle Cargo
        </DialogTitle>
        <DialogContent>
          <p />
          <>
            <TextField
              label="Cargo"
              fullWidth
              defaultValue={employeePosition}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <p />

            <Typography variant="h6" gutterBottom>
              Perfiles
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth={true} margin="dense">
                  <InputLabel id="state-select-label">Perfil</InputLabel>
                  <Grid container spacing={1}>
                    <Grid item xs={11}>
                      <Select
                        labelId="state-select-label"
                        label="Perfil"
                        fullWidth
                        required
                        value={perfilId}
                        onChange={(e) => {
                          const selectedProfile = profiles.find(profile => profile.id === e.target.value);
                          setPerfilId(e.target.value); // Actualiza perfilId con el ID del perfil seleccionado
                          setSelectedCode(selectedProfile || null); // Guarda el objeto de perfil completo
                        }}
                      >
                        {profiles.map(item => {
                          return (<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>);
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        disabled={!selectedCode}
                        onClick={(e) => { handleAddEmployeePositions(e) }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <p />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataGrid style={{ height: 300, width: '100%' }} columns={codeColumns} rows={selectedCodes} localeText={{ ...Spanish }} componentsProps={{
                  pagination: {
                    labelRowsPerPage: ('Filas por página')
                  }
                }} 
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
                  },
                }} />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => { handleSave(selectedCodesSend) }}
              endIcon={<SaveIcon />}
            >
              {ACTIONS.SAVE}
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              endIcon={<CancelIcon />}
              variant="outlined"
              onClick={() => { handleClose(false) }} autoFocus>{ACTIONS.CANCEL}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagementPositionOptionDetail;
