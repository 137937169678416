import { callAPI } from '../network';

/* AbsencePermissions */
export async function getRequestAbsencePermissions(params){
    const data = await callAPI(
        null, 
        `EmployeeAbsencePermissions?${params}`, 'get');
    return data || [];
}

export async function sendNewAbsencePermissions(params){
    const data = await callAPI(
                        params, 
                        'EmployeeAbsencePermissions', 'post');
    return data || [];
}

export async function sendUpdateAbsencePermissions(params){
    const data = await callAPI(
                        params, 
                        'EmployeeAbsencePermissions', 'put');
    return data || [];
}
