import { getAirports } from '../../shared/data-services/airport-service';
import { getShiftHistory } from '../../shared/data-services/shift-service';
import { headersExportShiftsHistory } from '../../shared/constants-shifts';
import Tooltip from '@mui/material/Tooltip';

export async function getAllAirports (){
    const data = await getAirports();
    return data;
};


export async function getMonthsView(strfechaInicio, strfechaFin){
    var fechaInicio = new Date(strfechaInicio);
    var fechaFin    = new Date(strfechaFin);
    var data = [];

    while (fechaFin >= fechaInicio) {

        var Month = new Intl.DateTimeFormat('es-CO', {month: "long"}).format(new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), 1));

        var item = {
            Year: fechaInicio.getFullYear(),
            Month: Month.charAt(0).toUpperCase() + Month.slice(1)
        }
        
        data.push(item);

        fechaInicio = new Date(fechaInicio.getFullYear(), fechaInicio.getMonth() + 1, 1, 0, 0, 0);
    }

    return data;
};


export async function getData(params){
    const data = await getShiftHistory(params);
    let dataReal = [];

    for (const element of data) {
        const employeeId = element.employee.employeeId;
        const datePart = element.date.split("-");

        const dataShift = {
            "id": element.shiftHistoryId,
            "dateDay": datePart[2],
            "shift": element.shift.code,
            "abbreviate": (element.shiftAbbreviation.abbreviation == null) ? "": element.shiftAbbreviation.abbreviation, 
            "color": element.color
        };

        const validate = dataReal.find(objeto => objeto.employeeId === employeeId);
        if (validate) {
            const nuevoElemento = { [`0${datePart[2]}`]: dataShift };            
            const nuevootro = { ...validate, ...nuevoElemento };

            dataReal = dataReal.filter((objeto) => objeto.employeeId !== employeeId);
            dataReal.push(nuevootro);

        } else {
            let dataDay = {
                "employeeId": employeeId,
                "dateFull": element.date,
                "name": `${element.employee.name} ${element.employee.lastName}`,
                "area": element.area,
                "airport": element.airport.code,
            };

            dataReal.push({ ...dataDay, [`0${datePart[2]}`]: dataShift });
        }
    }

    return dataReal;
};

const Definitions = [
    {
        color: '',
        definition: '',
    },
    {
        color: '#1A30721A',
        definition: 'Turno programado',
    },
    {
        color: '#BBF4FF',
        definition: 'Cambio por Administrador',
    },
    {
        color: '#FFF5C5',
        definition: 'Cambio por Usuario',
    },
    {
        color: '#EEC2F1',
        definition: 'Solicitud Cambio',
    },
    {
        color: '#FDBEBE',
        definition: 'Capacitación Programada',
    },
    {
        color: '#AFFFDB',
        definition: 'Talento Humano',
    }
]

export async function getColumns(dataShift) {
    const ColumnsTmp = [
        { field: 'employeeId', headerName: 'Número documento', width: 150 },
        { field: 'name', headerName: 'Nombre completo', width: 350 },
        { field: 'airport', headerName: 'Aeropuerto', width: 100 },
        { field: 'area', headerName: 'Area', width: 200 }
    ];

    dataShift.sort((a, b) => Object.keys(b).length - Object.keys(a).length);

    for (const clave in dataShift) {

        if (typeof dataShift[clave] === 'object') {

            for (const propiedad in dataShift[clave]) {

                if (propiedad.length < 4) {
                    const columna = {
                        field: `${propiedad}`,
                        headerName: propiedad.slice(1, 3),
                        align: 'center',
                        sortable: false,
                        renderCell: (cellValues) => {

                            const resultado = Definitions.find((def) => def.color === (cellValues.row[propiedad] !== undefined ? cellValues.row[propiedad].color : ''));

                            const cellStyle = {
                                width: '100%',
                                height: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: `${cellValues.row[propiedad] !== undefined ? cellValues.row[propiedad].color : ''}`
                            };

                            let dataDay = (cellValues.row[propiedad] !== undefined) ? (cellValues.row[propiedad].shift === "") 
                            ? cellValues.row[propiedad].abbreviate
                            : cellValues.row[propiedad].shift
                            : '';

                            if(cellValues.row[propiedad] !== undefined){
                                if(cellValues.row[propiedad].shift !== "" && cellValues.row[propiedad].abbreviate !== ""){
                                    dataDay = `${cellValues.row[propiedad].shift}_${cellValues.row[propiedad].abbreviate}`;
                                }
                            }

                            return <>
                             <Tooltip title={resultado === undefined || resultado === null ? '' : resultado.definition}>
                                <div style={cellStyle}>
                                    { dataDay }
                                </div>
                             </Tooltip>
                            </>;
                        }
                    };

                    ColumnsTmp.push(columna);
                }
            }
        }
        break;
    }

    return ColumnsTmp;
};


export async function getDataExport_V2(params){
    // quitar archivo de headers

    const data = await getData(params);
    const header = [
        "Documento",
        "Nombre Completo",
        "Base/Aeropuerto",
        "Area"
    ];

    const rows = [];

    let h = 1;
    for (const item in data) {

        const field = [
            data[item].employeeId,
            data[item].name,
            data[item].airport,
            data[item].area,            
        ];

        if (typeof data[item] === 'object') {

            for (const propiedad in data[item]) {
                if (propiedad.length < 4) {

                    if(h === 1) {
                        header.push(propiedad.slice(1, 3));
                    }

                    let dataDay = (data[item][propiedad].shift === "") 
                    ? data[item][propiedad].abbreviate
                    : data[item][propiedad].shift;

                    if(data[item][propiedad].shift !== "" && data[item][propiedad].abbreviate !== ""){
                        dataDay = `${data[item][propiedad].shift}_${data[item][propiedad].abbreviate}`;
                    }

                    field.push(dataDay);
                }
            }
            if(h === 1) {
                rows.push(header);
            }
            h++;
        }
        rows.push(field);
    }

    return rows;
}


export async function getDataExport(params){

    var data = await getData_ORI(params);

    var dataReal = [];

    //Creation headers
    var header = headersExportShiftsHistory;

    dataReal.push(header);

    data.forEach(element => {
        var itemReal = [
            {v: element.EmployeeId, t: "s"},
            {v: element.FullName, t: "s"},
            {v: element.AirportCode, t: "s"},
            {v: element.Area, t: "s"},
            {//D1
                v: element.D1,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD1 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD1 }
                    }
                }
            },
            {//D2
                v: element.D2,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD2 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD2 }
                    }
                }
            },
            {//D3
                v: element.D3,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD3 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD3 }
                    }
                }
            },
            {//D4
                v: element.D4,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD4 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD4 }
                    }
                }
            },
            {//D5
                v: element.D5,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD5 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD5 }
                    }
                }
            },
            {//D6
                v: element.D6,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD6 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD6 }
                    }
                }
            },
            {//D7
                v: element.D7,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD7 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD7 }
                    }
                }
            },
            {//D8
                v: element.D8,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD8 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD8 }
                    }
                }
            },
            {//D9
                v: element.D9,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD9 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD9 }
                    }
                }
            },
            {//D10
                v: element.D10,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD10 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD10 }
                    }
                }
            },
            {//D11
                v: element.D11,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD11 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD11 }
                    }
                }
            },
            {//D12
                v: element.D12,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD12 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD12 }
                    }
                }
            },
            {//D13
                v: element.D13,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD13 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD13 }
                    }
                }
            },
            {//D14
                v: element.D14,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD14 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD14 }
                    }
                }
            },
            {//D15
                v: element.D15,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD15 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD15 }
                    }
                }
            },
            {//D16
                v: element.D16,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD16 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD16 }
                    }
                }
            },
            {//D17
                v: element.D17,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD17 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD17 }
                    }
                }
            },
            {//D18
                v: element.D18,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD18 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD18 }
                    }
                }
            },
            {//D19
                v: element.D19,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD19 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD19 }
                    }
                }
            },
            {//D20
                v: element.D20,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD20 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD20 }
                    }
                }
            },
            {//D21
                v: element.D21,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD21 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD21 }
                    }
                }
            },
            {//D22
                v: element.D22,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD22 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD22 }
                    }
                }
            },
            {//D23
                v: element.D23,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD23 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD23 }
                    }
                }
            },
            {//D24
                v: element.D24,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD24 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD24 }
                    }
                }
            },
            {//D25
                v: element.D25,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD25 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD25 }
                    }
                }
            },
            {//D26
                v: element.D26,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD26 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD26 }
                    }
                }
            },
            {//D27
                v: element.D27,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD27 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD27 }
                    }
                }
            },
            {//D28
                v: element.D28,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD28 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD28 }
                    }
                }
            },
            {//D29
                v: element.D29,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD29 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD29 }
                    }
                }
            },
            {//D30
                v: element.D30,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD30 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD30 }
                    }
                }
            },
            {//D31
                v: element.D31,
                t: "s",
                s: {
                    fill: {
                        patternType: element.CD31 === '' ? "none" : "solid",
                        // fgColor: { rgb: element.CD31 },
                        fgColor: { rgb: element.CD31 }
                    }
                }
            }

            ];

        dataReal.push(itemReal);

    });

    return dataReal;
};


export async function getData_ORI(params){

    var data = await getShiftHistory(params);

    var dataReal = [];

    data.forEach(element => {
        var itemReal = {};

        if(dataReal.length === 0){
            itemReal = {
                id: element.employee.employeeId,
                EmployeeId: element.employee.employeeId,
                FullName: element.employee.name + ' ' + element.employee.lastName,
                AirportCode: element.airport.code,
                Area: element.area,
                D1: new Date(element.date + ' 00:00:00').getDate() === 1 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D2: new Date(element.date + ' 00:00:00').getDate() === 2 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D3: new Date(element.date + ' 00:00:00').getDate() === 3 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D4: new Date(element.date + ' 00:00:00').getDate() === 4 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D5: new Date(element.date + ' 00:00:00').getDate() === 5 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D6: new Date(element.date + ' 00:00:00').getDate() === 6 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D7: new Date(element.date + ' 00:00:00').getDate() === 7 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D8: new Date(element.date + ' 00:00:00').getDate() === 8 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D9: new Date(element.date + ' 00:00:00').getDate() === 9 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D10: new Date(element.date + ' 00:00:00').getDate() === 10 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D11: new Date(element.date + ' 00:00:00').getDate() === 11 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D12: new Date(element.date + ' 00:00:00').getDate() === 12 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D13: new Date(element.date + ' 00:00:00').getDate() === 13 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D14: new Date(element.date + ' 00:00:00').getDate() === 14 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D15: new Date(element.date + ' 00:00:00').getDate() === 15 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D16: new Date(element.date + ' 00:00:00').getDate() === 16 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D17: new Date(element.date + ' 00:00:00').getDate() === 17 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D18: new Date(element.date + ' 00:00:00').getDate() === 18 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D19: new Date(element.date + ' 00:00:00').getDate() === 19 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D20: new Date(element.date + ' 00:00:00').getDate() === 20 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D21: new Date(element.date + ' 00:00:00').getDate() === 21 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D22: new Date(element.date + ' 00:00:00').getDate() === 22 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D23: new Date(element.date + ' 00:00:00').getDate() === 23 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D24: new Date(element.date + ' 00:00:00').getDate() === 24 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D25: new Date(element.date + ' 00:00:00').getDate() === 25 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D26: new Date(element.date + ' 00:00:00').getDate() === 26 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D27: new Date(element.date + ' 00:00:00').getDate() === 27 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D28: new Date(element.date + ' 00:00:00').getDate() === 28 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D29: new Date(element.date + ' 00:00:00').getDate() === 29 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D30: new Date(element.date + ' 00:00:00').getDate() === 30 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                D31: new Date(element.date + ' 00:00:00').getDate() === 31 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                CD1: new Date(element.date + ' 00:00:00').getDate() === 1 ? element.color.replace('#', '').replace('#', '') : '',
                CD2: new Date(element.date + ' 00:00:00').getDate() === 2 ? element.color.replace('#', '') : '',
                CD3: new Date(element.date + ' 00:00:00').getDate() === 3 ? element.color.replace('#', '') : '',
                CD4: new Date(element.date + ' 00:00:00').getDate() === 4 ? element.color.replace('#', '') : '',
                CD5: new Date(element.date + ' 00:00:00').getDate() === 5 ? element.color.replace('#', '') : '',
                CD6: new Date(element.date + ' 00:00:00').getDate() === 6 ? element.color.replace('#', '') : '',
                CD7: new Date(element.date + ' 00:00:00').getDate() === 7 ? element.color.replace('#', '') : '',
                CD8: new Date(element.date + ' 00:00:00').getDate() === 8 ? element.color.replace('#', '') : '',
                CD9: new Date(element.date + ' 00:00:00').getDate() === 9 ? element.color.replace('#', '') : '',
                CD10: new Date(element.date + ' 00:00:00').getDate() === 10 ? element.color.replace('#', '') : '',
                CD11: new Date(element.date + ' 00:00:00').getDate() === 11 ? element.color.replace('#', '') : '',
                CD12: new Date(element.date + ' 00:00:00').getDate() === 12 ? element.color.replace('#', '') : '',
                CD13: new Date(element.date + ' 00:00:00').getDate() === 13 ? element.color.replace('#', '') : '',
                CD14: new Date(element.date + ' 00:00:00').getDate() === 14 ? element.color.replace('#', '') : '',
                CD15: new Date(element.date + ' 00:00:00').getDate() === 15 ? element.color.replace('#', '') : '',
                CD16: new Date(element.date + ' 00:00:00').getDate() === 16 ? element.color.replace('#', '') : '',
                CD17: new Date(element.date + ' 00:00:00').getDate() === 17 ? element.color.replace('#', '') : '',
                CD18: new Date(element.date + ' 00:00:00').getDate() === 18 ? element.color.replace('#', '') : '',
                CD19: new Date(element.date + ' 00:00:00').getDate() === 19 ? element.color.replace('#', '') : '',
                CD20: new Date(element.date + ' 00:00:00').getDate() === 20 ? element.color.replace('#', '') : '',
                CD21: new Date(element.date + ' 00:00:00').getDate() === 21 ? element.color.replace('#', '') : '',
                CD22: new Date(element.date + ' 00:00:00').getDate() === 22 ? element.color.replace('#', '') : '',
                CD23: new Date(element.date + ' 00:00:00').getDate() === 23 ? element.color.replace('#', '') : '',
                CD24: new Date(element.date + ' 00:00:00').getDate() === 24 ? element.color.replace('#', '') : '',
                CD25: new Date(element.date + ' 00:00:00').getDate() === 25 ? element.color.replace('#', '') : '',
                CD26: new Date(element.date + ' 00:00:00').getDate() === 26 ? element.color.replace('#', '') : '',
                CD27: new Date(element.date + ' 00:00:00').getDate() === 27 ? element.color.replace('#', '') : '',
                CD28: new Date(element.date + ' 00:00:00').getDate() === 28 ? element.color.replace('#', '') : '',
                CD29: new Date(element.date + ' 00:00:00').getDate() === 29 ? element.color.replace('#', '') : '',
                CD30: new Date(element.date + ' 00:00:00').getDate() === 30 ? element.color.replace('#', '') : '',
                CD31: new Date(element.date + ' 00:00:00').getDate() === 31 ? element.color.replace('#', '') : '',
            };

            dataReal.push(itemReal);
        }else
        {
            var index = dataReal.findIndex( row => row.EmployeeId === element.employee.employeeId );

            if(index !== -1){
                dataReal[index].D1 = new Date(element.date + ' 00:00:00').getDate() === 1 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D1;
                dataReal[index].D2 = new Date(element.date + ' 00:00:00').getDate() === 2 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D2;
                dataReal[index].D3 = new Date(element.date + ' 00:00:00').getDate() === 3 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D3;
                dataReal[index].D4 = new Date(element.date + ' 00:00:00').getDate() === 4 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D4;
                dataReal[index].D5 = new Date(element.date + ' 00:00:00').getDate() === 5 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D5;
                dataReal[index].D6 = new Date(element.date + ' 00:00:00').getDate() === 6 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D6;
                dataReal[index].D7 = new Date(element.date + ' 00:00:00').getDate() === 7 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D7;
                dataReal[index].D8 = new Date(element.date + ' 00:00:00').getDate() === 8 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D8;
                dataReal[index].D9 = new Date(element.date + ' 00:00:00').getDate() === 9 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D9;
                dataReal[index].D10 = new Date(element.date + ' 00:00:00').getDate() === 10 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D10;
                dataReal[index].D11 = new Date(element.date + ' 00:00:00').getDate() === 11 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D11;
                dataReal[index].D12 = new Date(element.date + ' 00:00:00').getDate() === 12 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D12;
                dataReal[index].D13 = new Date(element.date + ' 00:00:00').getDate() === 13 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D13;
                dataReal[index].D14 = new Date(element.date + ' 00:00:00').getDate() === 14 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D14;
                dataReal[index].D15 = new Date(element.date + ' 00:00:00').getDate() === 15 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D15;
                dataReal[index].D16 = new Date(element.date + ' 00:00:00').getDate() === 16 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D16;
                dataReal[index].D17 = new Date(element.date + ' 00:00:00').getDate() === 17 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D17;
                dataReal[index].D18 = new Date(element.date + ' 00:00:00').getDate() === 18 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D18;
                dataReal[index].D19 = new Date(element.date + ' 00:00:00').getDate() === 19 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D19;
                dataReal[index].D20 = new Date(element.date + ' 00:00:00').getDate() === 20 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D20;
                dataReal[index].D21 = new Date(element.date + ' 00:00:00').getDate() === 21 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D21;
                dataReal[index].D22 = new Date(element.date + ' 00:00:00').getDate() === 22 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D22;
                dataReal[index].D23 = new Date(element.date + ' 00:00:00').getDate() === 23 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D23;
                dataReal[index].D24 = new Date(element.date + ' 00:00:00').getDate() === 24 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D24;
                dataReal[index].D25 = new Date(element.date + ' 00:00:00').getDate() === 25 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D25;
                dataReal[index].D26 = new Date(element.date + ' 00:00:00').getDate() === 26 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D26;
                dataReal[index].D27 = new Date(element.date + ' 00:00:00').getDate() === 27 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D27;
                dataReal[index].D28 = new Date(element.date + ' 00:00:00').getDate() === 28 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D28;
                dataReal[index].D29 = new Date(element.date + ' 00:00:00').getDate() === 29 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D29;
                dataReal[index].D30 = new Date(element.date + ' 00:00:00').getDate() === 30 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D30;
                dataReal[index].D31 = new Date(element.date + ' 00:00:00').getDate() === 31 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : dataReal[index].D31;
                dataReal[index].CD1 = new Date(element.date + ' 00:00:00').getDate() === 1 ? element.color.replace('#', '') : dataReal[index].CD1;
                dataReal[index].CD2 = new Date(element.date + ' 00:00:00').getDate() === 2 ? element.color.replace('#', '') : dataReal[index].CD2;
                dataReal[index].CD3 = new Date(element.date + ' 00:00:00').getDate() === 3 ? element.color.replace('#', '') : dataReal[index].CD3;
                dataReal[index].CD4 = new Date(element.date + ' 00:00:00').getDate() === 4 ? element.color.replace('#', '') : dataReal[index].CD4;
                dataReal[index].CD5 = new Date(element.date + ' 00:00:00').getDate() === 5 ? element.color.replace('#', '') : dataReal[index].CD5;
                dataReal[index].CD6 = new Date(element.date + ' 00:00:00').getDate() === 6 ? element.color.replace('#', '') : dataReal[index].CD6;
                dataReal[index].CD7 = new Date(element.date + ' 00:00:00').getDate() === 7 ? element.color.replace('#', '') : dataReal[index].CD7;
                dataReal[index].CD8 = new Date(element.date + ' 00:00:00').getDate() === 8 ? element.color.replace('#', '') : dataReal[index].CD8;
                dataReal[index].CD9 = new Date(element.date + ' 00:00:00').getDate() === 9 ? element.color.replace('#', '') : dataReal[index].CD9;
                dataReal[index].CD10 =  new Date(element.date + ' 00:00:00').getDate() === 10 ? element.color.replace('#', '') : dataReal[index].CD10;
                dataReal[index].CD11 = new Date(element.date + ' 00:00:00').getDate() === 11 ? element.color.replace('#', '') : dataReal[index].CD11;
                dataReal[index].CD12 = new Date(element.date + ' 00:00:00').getDate() === 12 ? element.color.replace('#', '') : dataReal[index].CD12;
                dataReal[index].CD13 = new Date(element.date + ' 00:00:00').getDate() === 13 ? element.color.replace('#', '') : dataReal[index].CD13;
                dataReal[index].CD14 = new Date(element.date + ' 00:00:00').getDate() === 14 ? element.color.replace('#', '') : dataReal[index].CD14;
                dataReal[index].CD15 = new Date(element.date + ' 00:00:00').getDate() === 15 ? element.color.replace('#', '') : dataReal[index].CD15;
                dataReal[index].CD16 = new Date(element.date + ' 00:00:00').getDate() === 16 ? element.color.replace('#', '') : dataReal[index].CD16;
                dataReal[index].CD17 = new Date(element.date + ' 00:00:00').getDate() === 17 ? element.color.replace('#', '') : dataReal[index].CD17;
                dataReal[index].CD18 = new Date(element.date + ' 00:00:00').getDate() === 18 ? element.color.replace('#', '') : dataReal[index].CD18;
                dataReal[index].CD19 = new Date(element.date + ' 00:00:00').getDate() === 19 ? element.color.replace('#', '') : dataReal[index].CD19;
                dataReal[index].CD20 = new Date(element.date + ' 00:00:00').getDate() === 20 ? element.color.replace('#', '') : dataReal[index].CD20;
                dataReal[index].CD21 = new Date(element.date + ' 00:00:00').getDate() === 21 ? element.color.replace('#', '') : dataReal[index].CD21;
                dataReal[index].CD22 = new Date(element.date + ' 00:00:00').getDate() === 22 ? element.color.replace('#', '') : dataReal[index].CD22;
                dataReal[index].CD23 = new Date(element.date + ' 00:00:00').getDate() === 23 ? element.color.replace('#', '') : dataReal[index].CD23;
                dataReal[index].CD24 = new Date(element.date + ' 00:00:00').getDate() === 24 ? element.color.replace('#', '') : dataReal[index].CD24;
                dataReal[index].CD25 = new Date(element.date + ' 00:00:00').getDate() === 25 ? element.color.replace('#', '') : dataReal[index].CD25;
                dataReal[index].CD26 = new Date(element.date + ' 00:00:00').getDate() === 26 ? element.color.replace('#', '') : dataReal[index].CD26;
                dataReal[index].CD27 = new Date(element.date + ' 00:00:00').getDate() === 27 ? element.color.replace('#', '') : dataReal[index].CD27;
                dataReal[index].CD28 = new Date(element.date + ' 00:00:00').getDate() === 28 ? element.color.replace('#', '') : dataReal[index].CD28;
                dataReal[index].CD29 = new Date(element.date + ' 00:00:00').getDate() === 29 ? element.color.replace('#', '') : dataReal[index].CD29;
                dataReal[index].CD30 = new Date(element.date + ' 00:00:00').getDate() === 30 ? element.color.replace('#', '') : dataReal[index].CD30;
                dataReal[index].CD31 = new Date(element.date + ' 00:00:00').getDate() === 31 ? element.color.replace('#', '') : dataReal[index].CD31;
            }else{
                itemReal = {
                    id: element.employee.employeeId,
                    EmployeeId: element.employee.employeeId,
                    FullName: element.employee.name + ' ' + element.employee.lastName,
                    AirportCode: element.airport.code,
                    Area: element.area,
                    D1: new Date(element.date + ' 00:00:00').getDate() === 1 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D2: new Date(element.date + ' 00:00:00').getDate() === 2 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D3: new Date(element.date + ' 00:00:00').getDate() === 3 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D4: new Date(element.date + ' 00:00:00').getDate() === 4 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D5: new Date(element.date + ' 00:00:00').getDate() === 5 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D6: new Date(element.date + ' 00:00:00').getDate() === 6 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D7: new Date(element.date + ' 00:00:00').getDate() === 7 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D8: new Date(element.date + ' 00:00:00').getDate() === 8 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D9: new Date(element.date + ' 00:00:00').getDate() === 9 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D10: new Date(element.date + ' 00:00:00').getDate() === 10 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D11: new Date(element.date + ' 00:00:00').getDate() === 11 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D12: new Date(element.date + ' 00:00:00').getDate() === 12 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D13: new Date(element.date + ' 00:00:00').getDate() === 13 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D14: new Date(element.date + ' 00:00:00').getDate() === 14 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D15: new Date(element.date + ' 00:00:00').getDate() === 15 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D16: new Date(element.date + ' 00:00:00').getDate() === 16 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D17: new Date(element.date + ' 00:00:00').getDate() === 17 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D18: new Date(element.date + ' 00:00:00').getDate() === 18 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D19: new Date(element.date + ' 00:00:00').getDate() === 19 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D20: new Date(element.date + ' 00:00:00').getDate() === 20 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D21: new Date(element.date + ' 00:00:00').getDate() === 21 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D22: new Date(element.date + ' 00:00:00').getDate() === 22 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D23: new Date(element.date + ' 00:00:00').getDate() === 23 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D24: new Date(element.date + ' 00:00:00').getDate() === 24 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D25: new Date(element.date + ' 00:00:00').getDate() === 25 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D26: new Date(element.date + ' 00:00:00').getDate() === 26 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D27: new Date(element.date + ' 00:00:00').getDate() === 27 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D28: new Date(element.date + ' 00:00:00').getDate() === 28 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D29: new Date(element.date + ' 00:00:00').getDate() === 29 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D30: new Date(element.date + ' 00:00:00').getDate() === 30 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    D31: new Date(element.date + ' 00:00:00').getDate() === 31 ? element.shift.code + '' + (element.shiftAbbreviation.abbreviation != null ? '_' + element.shiftAbbreviation.abbreviation : '') : '',
                    CD1: new Date(element.date + ' 00:00:00').getDate() === 1 ? element.color.replace('#', '') : '',
                    CD2: new Date(element.date + ' 00:00:00').getDate() === 2 ? element.color.replace('#', '') : '',
                    CD3: new Date(element.date + ' 00:00:00').getDate() === 3 ? element.color.replace('#', '') : '',
                    CD4: new Date(element.date + ' 00:00:00').getDate() === 4 ? element.color.replace('#', '') : '',
                    CD5: new Date(element.date + ' 00:00:00').getDate() === 5 ? element.color.replace('#', '') : '',
                    CD6: new Date(element.date + ' 00:00:00').getDate() === 6 ? element.color.replace('#', '') : '',
                    CD7: new Date(element.date + ' 00:00:00').getDate() === 7 ? element.color.replace('#', '') : '',
                    CD8: new Date(element.date + ' 00:00:00').getDate() === 8 ? element.color.replace('#', '') : '',
                    CD9: new Date(element.date + ' 00:00:00').getDate() === 9 ? element.color.replace('#', '') : '',
                    CD10: new Date(element.date + ' 00:00:00').getDate() === 10 ? element.color.replace('#', '') : '',
                    CD11: new Date(element.date + ' 00:00:00').getDate() === 11 ? element.color.replace('#', '') : '',
                    CD12: new Date(element.date + ' 00:00:00').getDate() === 12 ? element.color.replace('#', '') : '',
                    CD13: new Date(element.date + ' 00:00:00').getDate() === 13 ? element.color.replace('#', '') : '',
                    CD14: new Date(element.date + ' 00:00:00').getDate() === 14 ? element.color.replace('#', '') : '',
                    CD15: new Date(element.date + ' 00:00:00').getDate() === 15 ? element.color.replace('#', '') : '',
                    CD16: new Date(element.date + ' 00:00:00').getDate() === 16 ? element.color.replace('#', '') : '',
                    CD17: new Date(element.date + ' 00:00:00').getDate() === 17 ? element.color.replace('#', '') : '',
                    CD18: new Date(element.date + ' 00:00:00').getDate() === 18 ? element.color.replace('#', '') : '',
                    CD19: new Date(element.date + ' 00:00:00').getDate() === 19 ? element.color.replace('#', '') : '',
                    CD20: new Date(element.date + ' 00:00:00').getDate() === 20 ? element.color.replace('#', '') : '',
                    CD21: new Date(element.date + ' 00:00:00').getDate() === 21 ? element.color.replace('#', '') : '',
                    CD22: new Date(element.date + ' 00:00:00').getDate() === 22 ? element.color.replace('#', '') : '',
                    CD23: new Date(element.date + ' 00:00:00').getDate() === 23 ? element.color.replace('#', '') : '',
                    CD24: new Date(element.date + ' 00:00:00').getDate() === 24 ? element.color.replace('#', '') : '',
                    CD25: new Date(element.date + ' 00:00:00').getDate() === 25 ? element.color.replace('#', '') : '',
                    CD26: new Date(element.date + ' 00:00:00').getDate() === 26 ? element.color.replace('#', '') : '',
                    CD27: new Date(element.date + ' 00:00:00').getDate() === 27 ? element.color.replace('#', '') : '',
                    CD28: new Date(element.date + ' 00:00:00').getDate() === 28 ? element.color.replace('#', '') : '',
                    CD29: new Date(element.date + ' 00:00:00').getDate() === 29 ? element.color.replace('#', '') : '',
                    CD30: new Date(element.date + ' 00:00:00').getDate() === 30 ? element.color.replace('#', '') : '',
                    CD31: new Date(element.date + ' 00:00:00').getDate() === 31 ? element.color.replace('#', '') : '',
                };
    
                dataReal.push(itemReal);
            }
        }
       
    });

    return dataReal;
};


export async function getColumns_ORI(StartDate, EndDate) {
    var _startDate = new Date(StartDate);
    var _endDate    = new Date(EndDate);
    
    var lastDayStart = new Date(_startDate.getFullYear(), _startDate.getMonth() + 1, 0);
    var lastDayEnd = new Date(_endDate.getFullYear(), _endDate.getMonth() + 1, 0);
    var lastDay = lastDayStart >= lastDayEnd ? lastDayStart : lastDayEnd;

    var ColumnsTmp = [
        { field: 'id', headerName: 'Id', width: 60, hide: true},
        { field: 'EmployeeId', headerName: 'Número documento', width: 150 },
        { field: 'Name', headerName: 'Nombre completo', width: 400 },
        { field: 'Airport', headerName: 'Aeropuerto', width: 100 },
        { field: 'Area', headerName: 'Area', width: 170, cellStyle: {color: 'blue'}}
    ];

    for (var i = 1; i <= lastDay.getDate();){
        ColumnsTmp.push({ field: 'D' + i, headerName: i, width: 150, align:'center', headerAlign: 'center'});
        i++;
    }

    return ColumnsTmp;
};
