import { getUsignedNewsletterDetail,sendSignatureToServer } from '../../shared/data-services/newsletter-service';


export async function getAllUsignedNewslettersDetail (newsletterId, employeeId){
  
   const data = await getUsignedNewsletterDetail(
                        newsletterId,
                        employeeId);
   return data || [];
};


export async function sendSignature (signatureId, signature,employeeId){

   const data = await sendSignatureToServer(
                        signatureId,signature,employeeId);
   return data || [];
};