import React, { useState, useEffect } from 'react';
import BackDrop from '../../../shared/backdropDE';
import ErrorSnackbar from '../../error-snackbar';
import ExportExcel from '../../../shared/exportExcel';
import { ACTIONS } from '../../../shared/constants';
import * as XLSX from 'xlsx';
import {
    Grid,
    Select,
    Typography,
    MenuItem,
    InputLabel,
    FormControl,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Chip
} from "@mui/material";
import { MuiFileInput } from 'mui-file-input';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import InfoIcon from '@mui/icons-material/Info';

const ShiftImportUpload = ({
    hanldeSaveImport,
    handleClose,
    selectedAirport
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [yearMonth, setYearMonth] = useState([]);
    const [yearMonthSelected, setYearMonthSelected] = useState(null);
    const [showProgressLoading, setShowProgressLoading] = useState(false);
    const [errorsValidation, setErrorsValidation] = useState(0);
    const [successValidation, setSuccessValidation] = useState(0);
    const [data, setData] = useState([]);
    const [date, setDate] = useState((((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()).substring(0, 4) + '-' + (((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()).substring(4, (((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()).length) + '-01');
    const [showConfirm, setShowConfirm] = useState(false);
    const [dataFile, setDataFile] = useState([]);
    const PlantillaExcel = [
        {
            "CEDULA": "",
            "AREA": "",
            "VER": "",
            "CAMBIO": "",
            "1": "",
            "2": "",
            "3": "",
            "4": "",
            "5": "",
            "6": "",
            "7": "",
            "8": "",
            "9": "",
            "10": "",
            "11": "",
            "12": "",
            "13": "",
            "14": "",
            "15": "",
            "16": "",
            "17": "",
            "18": "",
            "19": "",
            "20": "",
            "21": "",
            "22": "",
            "23": "",
            "24": "",
            "25": "",
            "26": "",
            "27": "",
            "28": "",
            "29": "",
            "30": "",
            "31": ""
        }
    ];
    const HeadersPlantilla = [
        "CEDULA",
        "AREA",
        "VER",
        "CAMBIO",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31"
    ];

    useEffect(() => {
        async function fetchData() {
            if (loading) {
                try {
                    let YearMonth = await getCurrentYearMonth();
                    setYearMonth(YearMonth);

                    PlantillaExcel.sort((a, b) => 0);
                    setLoading(false);
                } catch (error) {
                    setError(error.message);
                    setLoading(false);
                }
            }
        }
        fetchData();
    }, [loading]);

    const handleChange = async (e) => {
        if (e) {
            setFile(e);
            setShowProgressLoading(true);
            let rows = [];
            const isNumeric = n => !isNaN(n);
    
            const reader = new FileReader();
            const FileName = e.name;
    
            reader.onload = async (e) => {
                const d = e.target.result;
    
                var binary = '';
                var bytes = new Uint8Array(d);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
    
                var FileBase64 = window.btoa(binary);
    
                const dataFile = {
                    FileName: FileName,
                    FileData: FileBase64
                };
    
                setDataFile(dataFile);
    
                const workbook = XLSX.read(d, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                rows.push(json);
    
                let data = [];
                var errors = 0;
                var success = 0;
                var _YearMonth = yearMonthSelected.substring(0, 4) + '-' + yearMonthSelected.substring(4, yearMonthSelected.length);
    
                // Validar el número de días en el mes
                const daysInMonth = new Date(yearMonthSelected.substring(0, 4), yearMonthSelected.substring(4, yearMonthSelected.length), 0).getDate();
    
                rows[0].forEach(element => {
                    if (element.CEDULA === '' || element.CEDULA === undefined || element.AREA === '' || element.AREA === undefined || element.VER === '' || element.VER === undefined) {
                        errors++;
                        return;
                    } else {
                        success++;
                    }
    
                    let Shifts = [];
                    let dates = [];
    
                    for (var key in element) {
                        if (key !== 'CEDULA' && key !== 'AREA' && key !== 'VER' && key !== 'CAMBIO') {
                            if (key.trim() !== '__EMPTY' && key.trim() !== '' && isNumeric(key.trim())) {
                                var day = parseInt(key.trim(), 10);
                                //if (day <= daysInMonth) {
                                    var dateShift = _YearMonth + '-' + (day < 10 ? '0' + day : day);
                                    dates.push({ "dateShift": dateShift, "key": key });
                                //}
                            }
                        }
                    }
    
                    dates.forEach(dateShift => {
                        let Area = element.AREA || element.area || element.Area;
                        let Ver = element.VER || element.ver || element.Ver;
    
                        if (element[dateShift.key].toString().trim() !== '__EMPTY' && element[dateShift.key].toString().trim() !== '') {
                            let shiftText = element[dateShift.key].toString().trim();
                            shiftText = isNaN(shiftText) ? shiftText : (shiftText % 1 === 0) ? shiftText : getTextFromTimeXLS(shiftText);
    
                            let shift = {
                                "area": Area.trim(),
                                "isEnabled": Ver.trim().toUpperCase() === 'SI' ? true : false,
                                "date": dateShift.dateShift,
                                "shift": shiftText
                            };
                            Shifts.push(shift);
                        }
                    });
    
                    let Cedula = element.CEDULA || element.cedula || element.Cedula;
                    let Cambio = element.CAMBIO || element.cambio || element.Cambio;
                    Cambio = Cambio === undefined || Cambio === '' ? 'TRACKING' : Cambio;
    
                    var EmployeeShift = {
                        employeeId: Cedula.toString().trim(),
                        changeType: Cambio.toString().trim() === 'TRABAJADOR' ? 2 : 1,
                        shifts: Shifts
                    };
    
                    data.push(EmployeeShift);
                });
    
                setData(data);
                setErrorsValidation(errors);
                setSuccessValidation(success);
                setShowProgressLoading(false);
            };
            reader.readAsArrayBuffer(e);
        } else {
            setFile(null);
            setData([]);
            setErrorsValidation(0);
            setSuccessValidation(0);
            setShowProgressLoading(false);
        }
    };
    

    function getTextFromTimeXLS(data) {
        var HoraInt = data;
        var Result = (HoraInt * 24).toFixed(2);
        var P1 = Result.toString().split(".");
        var ResultHora = P1[0];
        var ResultMinutos = Math.round((parseInt(P1[1]) * 60) / 100);

        if (ResultHora < 10) {
            ResultHora = "0" + ResultHora;
        }

        if (ResultMinutos < 10) {
            ResultMinutos = "0" + ResultMinutos;
        }

        return (ResultHora + ":" + ResultMinutos);
    }

    const handleSelectYearMonth = (e) => {
        e.stopPropagation();
        setYearMonthSelected(e.target.value);

        var _yearMonth = e.target.value.toString().trim();
        var datee = _yearMonth.substring(0, 4) + '-' + (_yearMonth.substring(4, _yearMonth.length).length === 1 ? '0' + _yearMonth.substring(4, _yearMonth.length) : _yearMonth.substring(4, _yearMonth.length)) + '-01';

        setDate(datee);
        setFile(null);
        setData([]);
        setErrorsValidation(0);
        setSuccessValidation(0);
    };

    const handleCloseConfirm = () => {
        setShowConfirm(false);
    };

    const handleConfirmSaveData = () => {
        setShowConfirm(true);
    };

    const handleSaveImportConfirmed = () => {
        setShowProgressLoading(true);
        hanldeSaveImport(data, dataFile, date);
        handleCloseConfirm();
    };

    async function getCurrentYearMonth() {
        var colombiaTimeZoneOffset = -5;
        var currentDate = new Date(new Date().getTime() + colombiaTimeZoneOffset * 3600 * 1000);
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1;
        var data = [{ Id: currentYear.toString() + currentMonth.toString(), YearMonth: currentYear.toString() + '-' + currentMonth.toString() }];
        var MonthEnd = 1;

        for (var i = -2; i <= MonthEnd; i++) {
            currentDate = new Date(new Date().getTime() + colombiaTimeZoneOffset * 3600 * 1000);
            if (i !== 0) {
                currentDate.setDate(15);
                currentDate.setMonth(currentDate.getMonth() + i);
                currentYear = currentDate.getFullYear();
                currentMonth = currentDate.getMonth() + 1;

                var item = {
                    Id: currentYear.toString() + (currentMonth.toString().length === 1 ? '0' + currentMonth.toString() : currentMonth.toString()),
                    YearMonth: currentYear.toString() + '-' + currentMonth.toString()
                };

                data.push(item);
            } else {
                currentDate.setMonth(currentDate.getMonth() + 1);
            }
        }

        data.sort((a, b) => parseInt(a.Id) - parseInt(b.Id));
        return data;
    }

    if (loading) {
        return (<BackDrop />);
    }

    if (error) {
        return (<ErrorSnackbar message={error} />);
    }

    return (
        <>
            <div>
            {showConfirm && (
                <Dialog
                    open={showConfirm}
                    onClose={handleCloseConfirm}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        <Typography variant="h6" component="div" color="primary">
                            Confirmar Cargue
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            ¿Estás seguro que deseas cargar turnos para la base: <strong>{selectedAirport}</strong>,
                            <br />
                            en el periodo: <strong>{date.slice(0, -3)}</strong>?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirm} color="primary" variant="outlined">
                            Cancelar
                        </Button>
                        <Button onClick={handleSaveImportConfirmed} color="primary" variant="contained">
                            Cargar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {/* Título del diálogo */}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="body2" style={{ color: '#1a3072' }} sx={{ flexGrow: 1 }}>
                                        <InfoIcon sx={{ fontSize: 'medium' }} /> Seleccione el mes y el archivo de Excel a cargar, luego de clic en el botón de Continuar para guardar los registros.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <InputLabel id="demo-simple-select-label" size="small">Año-Mes</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Año-Mes"
                                        size="small"
                                        value={yearMonthSelected}
                                        onChange={handleSelectYearMonth}
                                    >
                                        {yearMonth.map(item => (
                                            <MenuItem key={item.Id} value={item.Id}>{item.YearMonth}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <MuiFileInput
                                        value={file}
                                        size="small"
                                        disabled={yearMonthSelected === null}
                                        inputProps={{ accept: '.xlsx' }}
                                        onChange={handleChange}
                                        placeholder='Selecciona el archivo de Excel'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <ExportExcel excelData={PlantillaExcel} excelHeaders={HeadersPlantilla} fileName={"Plantilla de importación de turnos"} />
                            </Grid>
                            {errorsValidation > 0 && (
                                <Grid item xs={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Chip icon={<FmdBadIcon />} label={`Hay ${errorsValidation} ${errorsValidation === 1 ? "error" : "errores"} en la información. Por favor corrija y vuelva a intentarlo`} color="error" variant="outlined" />
                                    </Box>
                                </Grid>
                            )}
                            {errorsValidation === 0 && successValidation > 0 && (
                                <Grid item xs={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Chip icon={<WhereToVoteIcon />} label={`Se detectaron ${successValidation} registros correctamente.`} color="success" variant="outlined" />
                                    </Box>
                                </Grid>
                            )}
                            {showProgressLoading && (
                                <Grid item xs={12}>
                                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                                        <LinearProgress />
                                        Estamos procesando la información del archivo, esto puede tardar unos minutos.
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ m: 3 }}>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={yearMonthSelected === null || showProgressLoading || errorsValidation > 0 || successValidation === 0}
                                endIcon={<CheckIcon />}
                                onClick={handleConfirmSaveData}
                            >
                                {ACTIONS.CONTINUE}
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                endIcon={<CancelIcon />}
                                variant="outlined"
                                disabled={showProgressLoading}
                                onClick={handleClose}
                                autoFocus>
                                {ACTIONS.CANCEL}
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default ShiftImportUpload;