import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Autocomplete,
  CircularProgress
} from "@mui/material";
import {
    getemployeesByAirport,
    getallemployeesByAirport,
    getallemployeesByAirportForEdit
} from '../logic';
import { DataGrid } from '@mui/x-data-grid';
import { Spanish } from '../../../../shared/grid-translations';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../../shared/constants';
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils';
import { useSnackbar } from 'notistack';

const ManagementEmployeeDetail = ({
  data,
  handleClose,
  handleSave,
  employeeId,
  employee,
  airports,
  airportId,
  profiles,
  action
}) => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(employeeId);
  const [selectedCodes, setSelectedCodes] = useState(data?.profiles || []);
  const [selectedCodesSend, setSelectedCodesSend] = useState({
    profiles: [], 
    employeeId: selectedEmployeeId, 
  });
  const [selectedCode, setSelectedCode] = useState(null);
  const [perfilId, setPerfilId] = useState(data?.employeeId.Id || '');
  const [selectedAirport, setSelectedAirport] = useState(airportId);
  const isDataReadOnly = action !== ACTIONS.NEW;
  const [profileValue, setProfileValue] = useState(action === ACTIONS.NEW ? '' : '');
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [user, setUser] = useState(data != null ? data : []);
  const [userId, setUserId] = useState(data != null ? data.id : 0);
  const [changes, setChanges] = useState({
    Base: false,
    Picture: false
  });
  const [employees, setEmployees] = useState([]);
  const [employeeValue, setEmployeeValue] = useState(action === ACTIONS.NEW ? '' : employeeId);

useEffect(() => {
    const fetchData = async () => {
      setLoadingEmployees(true);
      const employeesData = isDataReadOnly ? await getallemployeesByAirportForEdit(selectedAirport) : await getallemployeesByAirport(selectedAirport);
      setEmployees(employeesData);
      setLoadingEmployees(false);
  
      // Si la acción es EDITAR, configura los valores predeterminados
      if (isDataReadOnly) {
        //console.log('selectedEmployeeId');
       // console.log(selectedEmployeeId)
        const employeeToSelect = employeesData.find(e => e.employeeId === selectedEmployeeId);
        //console.log(employeeToSelect);
        setEmployeeSelected(employeeToSelect);
      }
      else{
        setSelectedCodes([]);
      }
    };
  
    fetchData();

  }, [action, employeeId, selectedAirport]);

const handleAddprofiles = (e) => {
    e.stopPropagation();
  if (!selectedCode || selectedCodes.some(el => el.id === selectedCode.id)) {
    return;
  }

  const newProfile = {
    id: selectedCode.id,
    description: selectedCode.description,
  };

  const newSelectedCodes = [...selectedCodes, newProfile];
  setSelectedCodes(newSelectedCodes);
  setSelectedCodesSend({
    ...selectedCodesSend,
    profiles: newSelectedCodes.map(({ description, id }) => ({
      Description: description,
      Id: id
    })),
    employeeId: selectedEmployeeId, 
    action: action === ACTIONS.NEW ? 'CREAR' : 'MODIFICAR'
  });
};

const handleSelectedAirport = async (e) => {
    e.stopPropagation();

    setSelectedAirport(e.target.value);

    await loadEmployeesByAirport(e.target.value);

    if (userId === 0) {
        setEmployeeSelected(null);
        user.identification = '';
        user.airportId = 0;
        user.fullName = '';
        user.active = true;
        user.position = '';
        user.onboardingDate = '';
        user.birthDate = '';
        setChanges({ Base: false, Picture: false });
    } else {
        changes.Base = true;
        setChanges(changes);
    }
}

async function loadEmployeesByAirport(AirportId) {
    setLoadingEmployees(true);

    const employeesData = await getallemployeesByAirport(AirportId);

    setEmployees(employeesData);

    setLoadingEmployees(false);
}

const onSave = () => {
  let errors = {};
  //console.log(employeeSelected);
  if (selectedAirport === '' || selectedAirport === null || selectedAirport === undefined || employeeSelected === '' || 
       employeeSelected === null || employeeSelected === undefined || employeeSelected.length === 0 || selectedCodes.length == 0)  {
    errors.name = "Debe ingresar todos los datos"; // Esto es solo un ejemplo, idealmente usarías algo como Material-UI Snackbars o un estado para mostrar el error.
    setValidationErrors(errors);
    enqueueSnackbar(errors.name, { variant: "error" });
    return Object.keys(errors).length === 0;
  }


  // Estructurar el objeto selectedCodesSend para el guardado
  const newSelectedCodesSend = {
    profiles: selectedCodes.map(({ description, id }) => ({
      Description: description,
      Id: id
    })),
    employeeId: employeeSelected.employeeId,
    action: isDataReadOnly ? 'MODIFICAR' : 'CREAR' // Si no hay códigos seleccionados, asumir acción 'CREAR'
  };

  // Llamar a handleSave con el nuevo objeto selectedCodesSend
  handleSave(newSelectedCodesSend);
  
};

  const handleDelete = (e, cellValues) => {
    e.stopPropagation();
    const updatedSelectedCodes = selectedCodes.filter(el => el.id !== cellValues.row.id);
    // Aquí debes actualizar el estado con una función callback para asegurarte de que estás utilizando el estado más reciente.
    setSelectedCodes(updatedSelectedCodes);
  };

  const codeColumns = [
    { field: 'code', headerName: 'Code', flex: 1, hide: true},
    { field: 'description', headerName: 'Descripción', minWidth: 100, flex: 1 },
    {
      field: "EditSources",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="error"
          onClick={(e) => { handleDelete(e, cellValues) }}
        >
          <Delete />
        </IconButton>;
      }
    },
  ];

  return (
    <div   >
      <Dialog
        maxWidth="md"
        fullWidth="md"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalle Empleado
        </DialogTitle>
        <DialogContent>
          <p />
          <>
          <Grid container spacing={2}>
                <Grid item xs={6}>
                <FormControl fullWidth={true} margin="dense">
                    <InputLabel id="demo-simple-select-label">Base</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Base"
                            size='small'
                            value={selectedAirport}
                            onChange={(e) => { handleSelectedAirport(e) }}
                            disabled={isDataReadOnly} // Deshabilita el Select si la acción es EDITAR
                        >
                            {airports.map(item => (
                            <MenuItem key={item.id} value={item.id}>{item.code}</MenuItem>
                            ))}
                        </Select>
                </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth={true} margin="dense">
                    <Autocomplete
                        noOptionsText={'Sin resultados'}
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={employees}
                        getOptionLabel={(option) => option ? `${option.employeeId} - ${option.name} ${option.lastName}` : ''}
                        value={employeeSelected}
                        onChange={(event, newValue) => {
                            setEmployeeSelected(newValue);
                        }}
                        disabled={isDataReadOnly}
                        renderInput={(params) => <TextField {...params} label="Empleado" />}
                    />
                  </FormControl>
                </Grid>
            </Grid>
            <p />

            <Typography variant="h6" gutterBottom>
              Perfiles
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth={true} margin="dense">
                  <InputLabel id="state-select-label">Perfil</InputLabel>
                  <Grid container spacing={1}>
                    <Grid item xs={11}>
                      <Select
                        labelId="state-select-label"
                        label="Perfil"
                        fullWidth
                        required
                        value={perfilId}
                        onChange={(e) => {
                          const selectedProfile = profiles.find(profile => profile.id === e.target.value);
                          setPerfilId(e.target.value); // Actualiza perfilId con el ID del perfil seleccionado
                          setSelectedCode(selectedProfile || null); // Guarda el objeto de perfil completo
                        }}
                      >
                        {profiles.map(item => {
                          return (<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>);
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        disabled={!selectedCode}
                        onClick={(e) => { handleAddprofiles(e) }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <p />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataGrid style={{ height: 300, width: '100%' }} columns={codeColumns} rows={selectedCodes} localeText={{ ...Spanish }} componentsProps={{
                  pagination: {
                    labelRowsPerPage: ('Filas por página')
                  }
                }} 
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
                  },
                }} />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={onSave}
              endIcon={<SaveIcon />}
            >
              {ACTIONS.SAVE}
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              endIcon={<CancelIcon />}
              variant="outlined"
              onClick={handleClose}>{ACTIONS.CANCEL}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagementEmployeeDetail;
