import moment from 'moment';
import { getUser } from '../../shared/auth-service';
import { getUserData } from '../../shared/info-device';
import { 
    getShiftsSegments,
    getSegmentTypes,
    sendNewSegment,
    sendDeleteSegment
} from '../../shared/data-services/shift-service';

const diffTime = async function(ini ,fin ,val = false){
    var duracion;
    var a, b;
  
    if(val === true){
        var i_base = '24:00';
        var f_base = '00:00';
        var a_base = '';
        var b_base = '';
  
        a = moment([ini], "HH:mm");
        var as = moment([i_base], "HH:mm");
  
        b = moment([fin], "HH:mm");
        var bs = moment([f_base], "HH:mm");
  
        a_base = as.diff(a, 'hours',true);
        b_base = bs.diff(b, 'hours',true);
        duracion  = (Math.abs(parseFloat(a_base)) + Math.abs(parseFloat(b_base)));
  
    } else {
        b = moment([ini], "HH:mm");
        a = moment([fin], "HH:mm");
        duracion = b.diff(a, 'hours',true)
        duracion = Math.abs(duracion);
    }
    return duracion.toFixed(1);
};

export async function getSegments(sId){
    const data = await getShiftsSegments(sId);
    return data;
};

export async function getSegmentType(){
    const data = await getSegmentTypes();
    return data;
};

export async function saveNewSegment(dataForm){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;

    const addons = {
        duration: await diffTime(dataForm.startTime, dataForm.endTime, dataForm.dayMore),
        user,
        userAgent,
        ip
    };
    const params = {...dataForm, ...addons};
    const data = await sendNewSegment(params);
    return data;
};

export async function deleteSegment(segmentId){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;

    const params = { segmentId, userAgent, user, ip }
    const data = await sendDeleteSegment(params);
    return data;
};
