import { callAPI } from '../network';

export async function getProfile (){
  
   const data = await callAPI(
                        null, 
                        'ManagementProfile', 'get');
   return data || [ ];
}

export async function getPermissions (){
  
   const data = await callAPI(
                        null, 
                        'ManagementEmployee/GetAllPermissions', 'get');
   return data || [ ];
}

export async function getProfileById (id){

    const data = await callAPI(
                         null, 
                         'ManagementProfile/GetById?id='+ id, 'get');
    return data;
 }

 export async function saveProfile (customer){
   const method = customer.id ? 'put' : 'post';
   //console.log(customer);
   const data = await callAPI(
                        customer, 
                        'ManagementProfile', method);
   return data;
}

export async function savePermissions (permissions,profileId,profileName){
   const params = {
         permission: permissions.permissions.map(permission => ({
         Code: permission.Code, // Asegúrate de usar la capitalización correcta, como aparece en el objeto.
         Description: permission.Description, // Igualmente aquí.
         Id: permission.Id
       })),
       profileId: profileId,
       profileName: profileName
    };
    //console.log('entro');
   //console.log(params);
   const data = await callAPI(
      params, 
            'Security/updatePermissionsByProfile', 'post');
return data || [ ];
}

export async function deleteProfile (id){
   const data = await callAPI(
                        null, 
                        'ManagementProfile?id='+ id, 'delete');
   return data;
}

export async function getAllProfilesByEmployeePosition(employeePositionId){
   let data;
   if (employeePositionId) {
      data = await callAPI(
         null, 
         'Security/getProfilesByEmployeePosition?employeePositionId='+ employeePositionId, 'get');

      
   } else {
      data = await callAPI(
         null, 
         'Security/getAllProfilesByEmployeePosition', 'get');

   }
  
   return data || [ ];
}

export async function getAllPermissionsByProfile(profileId){
   let data;
   if (profileId) {
      data = await callAPI(
         null, 
         'Security/getPermissionsByProfile?profileId='+ profileId, 'get');

      
   } else {
      data = await callAPI(
         null, 
         'Security/getAllPermissionsByProfile', 'get');

   }
  
   return data || [ ];
}


export async function updateProfilesByPosition (profiles,employeePositionId ){

   //console.log(profiles);
   const params = {
      profile: profiles.profiles.map(profile => ({
         Id: profile.Id, // Asegúrate de usar la capitalización correcta, como aparece en el objeto.
         Description: profile.Description // Igualmente aquí.
       })),
       employeePositionId: profiles.employeePositionId,
    };

   
   const data = await callAPI(
                  params, 
                        'Security/updateProfilesByPosition', 'post');
   return data || [ ];
}