import { callAPI } from '../network';

/* Shifts */
export async function getActiveShifts(params){
   const data = await callAPI(
                        null,
                        `Shift?IsActive=${params.isActive}&AirportId=${params.airportId}`,'get');
   return data || [];
}

export async function sendNewShift(params){
    const data = await callAPI(
                        params, 
                        'Shift', 'post');
    return data;
}

export async function sendDeleteShift(params){
    const data = await callAPI(
                        params, 
                        'Shift', 'delete');
    return data;
}

export async function sendUpdateShift(params){
    //console.log(params);
    const data = await callAPI(
                        params, 
                        'Shift', 'put');
    return data;
}

export async function getCheckShiftCodeExistence(code){
    const data = await callAPI(
                        null, 
                        `Shift/GetCheckShiftCodeExistence?Code=${code}`,'get');
    return data || false
}

/* Segments */
export async function getShiftsSegments(sId){
    const data = await callAPI(
                        null,
                        `ShiftSegment?ShiftId=${sId}`,'get');
    return data || [];
}

export async function getSegmentTypes(){
    const data = await callAPI(
                        null, 
                        'ShiftSegment/GetShiftSegmentsType', 'get');
    return data || [];
}

export async function sendNewSegment(params){
    const data = await callAPI(
                        params, 
                        'ShiftSegment', 'post');
    return data;
}

export async function sendDeleteSegment(params){
    const data = await callAPI(
                        params, 
                        `ShiftSegment?IdSegment=${params.segmentId}`, 'delete');
    return data;
}

/* Abbreviations */
export async function getShiftAbbreviations(){
    const data = await callAPI(
                        null,
                        'ShiftAbbreviation','get');
    return data || [];
}

export async function getAbbreviationsTypes(){
    const data = await callAPI(
                        null, 
                        'ShiftAbbreviation/GetAbbreviationTypes', 'get');
    return data || [];
}

export async function getShiftAbbreviation(params){
    const data = await callAPI(
                        null,
                        `ShiftAbbreviation/GetByAbbreviation?abbreviation=${params}`, 'get');
    return data || [];
}

export async function sendNewAbbreviated(params){
    const data = await callAPI(
                        params, 
                        'ShiftAbbreviation', 'post');
    return data;
}

export async function sendUpdateAbbreviation(params){
    const data = await callAPI(
                        params, 
                        'ShiftAbbreviation', 'put');
    return data;
}

export async function sendDeleteAbbreviation(params){
    const data = await callAPI(
                        params, 
                        'ShiftAbbreviation', 'delete');
    return data;
}

export async function getCheckAbbreviationExistence(params){
    const data = await callAPI(
                        null,
                        `ShiftAbbreviation/GetCheckAbbreviationExistence?abbreviation=${params}`, 'get');
    return data || false;
}

/* Shift History */
export async function getShiftHistory(params){
    const data = await callAPI(
                        null, 
                        `ShiftHistory${params}`, 'get');
    return data || [];
}

export async function getShiftHistoryResume(YearMonth,airportId){
    //console.log(airportId)
    const data = await callAPI(
                        null, 
                        `ShiftHistory/GetShiftHistoryResume?YearMonth=${YearMonth}&airportId=${airportId}`, 'get');
    return data || [];
}

export async function getShiftHistoryYearMonthResume(){
    const data = await callAPI(
                        null, 
                        `ShiftHistory/GetYearMonthResume`, 'get');
    return data || [];
}

export async function saveShiftFile(params){
    const data = await callAPI(
                        params, 
                        `ShiftHistory/saveShiftFile`, 'post');
    return data || [];
}

export async function shiftHistory(params){
    
    const data = await callAPI(
                        params, 
                        `ShiftHistory`, 'post');
    return data || [];
}

export async function activateShifts(params){
    const data = await callAPI(
                        params, 
                        `ShiftHistory`, 'put');
    return data || [];
}

/* Shift Change Request */
export async function getDataEmployeeRequired(params){
    const data = await callAPI(
        params, 
        `ShiftChangeRequest/GetShiftChangeRequestEmployee?${params}`, 'get');
    return data || [];
}

export async function saveShiftChange(params){
    const data = await callAPI(
                        params, 
                        'ShiftChangeRequest/ShiftChangeRequest', 'post');
    return data.data || [];
}

export async function getDataChangeRequired(fromId, toId) {
    const data = await callAPI(
                        null, 
                        `ShiftChangeRequest/GetShiftChangeRequest?shiftHistoryId=${fromId}&shiftHistoryChangeId=${toId}`, 'get');
    return data || [];
}

export async function sendShiftChange(params){
    const data = await callAPI(
                        params, 
                        'ShiftChangeRequest/ShiftChangeRequestAction', 'post');
    return data || [];
}
