import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TableCell,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FormatPercentage } from '../../../shared/functions';
import DataTable from '../../../shared/data-table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../shared/constants';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getDataAssessmentReportDetailByNewsletterId } from './logic';
import { FormatDateTimeToStringComplete } from '../../../shared/functions';

ChartJS.register(ArcElement, Tooltip, Legend);

const NewsletterAssessmentStateReport = ({
  handleClose,
  reportData,
  name,
  id,
}) => {
  const [totalGained, settotalGained] = useState(reportData.filter(x => x.state === 'Firmado').length);
  const [totalPending, settotalPending] = useState(reportData.filter(x => x.state === 'Pendiente').length);
  const [totalLosses, settotalLosses] = useState(reportData.filter(x => x.state === 'Archivado').length);

  const [totalGainedPercentage, settotalGainedPercentage] = useState(0);
  const [totalPendingPercentage, settotalPendingPercentage] = useState(0);
  const [totalLossesPercentage, settotalLossesPercentage] = useState(0);

  const [data, setData] = useState(null);

  const green = 'rgba(0, 255, 0, 0.5)';
  const red = 'rgba(255, 0, 0, 0.5)';
  const blue = 'rgba(0, 0, 255, 0.5)';

  useEffect(() => {
    const totalPending = reportData.filter(x => x.state === 'Pendiente').length;
    const totalGained = reportData.filter(x => x.state === 'Ganada').length;
    const totalLosses = reportData.filter(x => x.state === 'Perdida').length;

    settotalGained(totalGained);
    settotalPending(totalPending);
    settotalLosses(totalLosses);

    const total = totalGained + totalPending + totalLosses;
    const totalGainedPercentage = ((totalGained * 100) / total);
    const totalPendingPercentage = ((totalPending * 100) / total);
    const totalLossesPercentage = ((totalLosses * 100) / total);
    setData({
      labels: ['% Pendientes', '% Perdidas', '% Ganadas'],
      datasets: [
        {
          data: [totalPendingPercentage || 0, totalLossesPercentage || 0, totalGainedPercentage || 0],
          backgroundColor: [
            blue,
            red,
            green,
          ],
          borderWidth: 1,
        },
      ]
    });
    settotalPendingPercentage(totalPendingPercentage);
    settotalLossesPercentage(totalLossesPercentage);
    settotalGainedPercentage(totalGainedPercentage);
  }, []);

  const handleActionButton = () => {  
    getDataAssessmentReportDetailByNewsletterId(id);
  };

  const reportColumns = [
    { field: 'employeeId', headerName: 'Cédula', minWidth: 90, flex: 1 },
    { field: 'name', headerName: 'Nombres', minWidth: 200, flex: 1 },
    { field: 'lastName', headerName: 'Apellidos', minWidth: 200, flex: 1 }, 
    { field: 'employeePosition', headerName: 'Cargo', minWidth: 300, flex: 1 },
    { field: 'airport', headerName: 'Base', minWidth: 100, flex: 1},
    { field: 'state', headerName: 'Estado Evaluación', minWidth: 100, flex: 1 },
    { field: 'gainedScore', headerName: 'Puntaje', minWidth: 100, flex: 1 },
    { field: 'scoreToWin', headerName: 'Puntaje para ganar', minWidth: 100, flex: 1 },
    {
      field: 'creationDate', headerName: 'Fecha',
      renderCell: (cellValues) => {
        return cellValues.value ? FormatDateTimeToStringComplete(cellValues.value, true) : null;
      }, width: 160
    },
  ];


  return (
    <div>
      <Dialog
        maxWidth="xl"
        fullWidth="xl"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>{name}</b>
        </DialogTitle>
        <DialogContent>
          <p />
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography><b>Totales y gráfico</b></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={4}>
                      <Grid item xs={12} lg={2.5}>
                        {data &&
                          <Pie style={{ height: 100 }}
                            plugins={[ChartDataLabels]}
                            data={data}
                            options={{
                              plugins: {
                                legend: {
                                  display: false
                                },
                                datalabels: {
                                  labels: {
                                    title: {
                                      color: 'black',
                                      backgroundColor: 'white',
                                      font: {
                                        weight: 'bold',
                                        size: 16
                                      }
                                    }
                                  },
                                  formatter: function (value, context) {
                                    return FormatPercentage(value);
                                  }
                                }
                              }
                            }}
                          />}
                      </Grid>
                      <Grid item xs={12} lg={4.75}>
                        <Box>
                          <Card >
                            <CardContent>
                              <Typography gutterBottom variant="h6" component="div">
                                <b>Porcentajes</b>
                              </Typography>
                              <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow key='1'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: blue}}>Pendientes:</b>
                                      </TableCell>
                                      <TableCell align="right">{FormatPercentage(totalPendingPercentage || 0)}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: red}}>Perdidas:</b>
                                      </TableCell>
                                      <TableCell align="right">{FormatPercentage(totalLossesPercentage || 0)}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: green}}>Ganadas:</b>
                                      </TableCell>
                                      <TableCell align="right">{FormatPercentage(totalGainedPercentage || 0)}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={4.75}>
                        <Box>
                          <Card >
                            <CardContent>
                              <Typography gutterBottom variant="h6" component="div">
                                <b>Totales</b>
                              </Typography>
                              <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                  <TableBody>
                                    <TableRow key='1'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: blue}}>Pendientes:</b>
                                      </TableCell>
                                      <TableCell align="right">{totalPending}</TableCell>
                                    </TableRow>
                                    <TableRow key='2'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: red}}>Perdidas:</b>
                                      </TableCell>
                                      <TableCell align="right">{totalLosses}</TableCell>
                                    </TableRow>
                                    <TableRow key='3'>
                                      <TableCell component="th" scope="row">
                                        <b style={{backgroundColor: green}}>Ganadas:</b>
                                      </TableCell>
                                      <TableCell align="right">{totalGained}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CardContent>
                          </Card>
                        </Box>

                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>

            <p />
            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataTable columns={reportColumns} rows={reportData} />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 3 }}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              endIcon={<FileDownloadIcon />}
              onClick={() => { handleActionButton() }} >
              {ACTIONS.DOWNLOAD_DETAIL}
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={() => { handleClose(false) }} autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NewsletterAssessmentStateReport;