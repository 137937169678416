export const paperStyles = {
    padding: 20 ,
    width: 300,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
} 

export const loginStyles = {
    width: 250,
    margin: '20px auto',
} 

