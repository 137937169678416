import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
  Switch,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import DataTable from '../../shared/data-table';
import {
  getListAssessment,
  getAssessmentsById,
  upsertAssessment, 
  getQuestionTypes,
} from './logic';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { FormatDateToStringComplete } from '../../shared/functions';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';
import AssessmentDetails from './assessment-detail';
import SortIcon from '@mui/icons-material/Sort';
import ShortQuestions from './short-questions';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';
import { getUser } from '../../shared/auth-service';

const Assessment = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModalSource, setShowModalSource] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [ error, setError] = useState(null);
  const [ questionTypes, setQuestionTypes] = useState(null);
  const [action, setAction] = useState(null);
  const [showModalSortQuestions, setShowModalSortQuestions] = useState(null);
  const user = getUser();

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getListAssessment();
          const types = await  getQuestionTypes()
          setQuestionTypes(types);
          setData(data);
          // await RegisterUsabilityLog('Evaluaciones - Circulares', user.employeeId);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      }
    }
    fetchData();
  });

  useEffect(() => {
    async function fetchRow() {
      if (id) {
          setLoading(true);
          const item = await getAssessmentsById(id);
          setLoading(false);
          setSelectedItem(item);
          setId(null);
          if (action === ACTIONS.VIEW_SORT_QUESTIONS) {
            setShowModalSortQuestions(true);
          } else if (action === ACTIONS.EDIT_ASSESSMENT) {
            setShowModalSource(true);
          } 
      }
    }
    fetchRow();
  });

  const handleSave = async (assessment) => {
    await upsertAssessment(assessment);
    setShowModalSource(false);
    enqueueSnackbar(MESSAGES.SAVED);
    setLoading(true);
  };

  const handleNew = async () => {
    setSelectedItem({});
    setId(null);
    setShowModalSource(true);
  };

  const handleActionButton = (e, cellValues) => {
    e.stopPropagation();
    setAction(ACTIONS.EDIT_ASSESSMENT)
    setId(cellValues.row.id);
  };

  const handleOpenSortAction = (e, cellValues) => {
    e.stopPropagation();
    setId(cellValues.row.id);
    setAction(ACTIONS.VIEW_SORT_QUESTIONS);
  };

  const columns = [
    {
      field: "EditSources",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleActionButton(e, cellValues) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "Sort",
      headerName: "Ordenar",
      width: 70,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleOpenSortAction(e, cellValues) }}
        >
          <SortIcon />
        </IconButton>;
      }
    },
    { field: 'id', headerName: 'Id' },
    { field: 'name', headerName: 'Nombre', minWidth: 300, flex: 1 },
    {
      field: 'starDate', headerName: 'Fecha inicio',
      renderCell: (cellValues) => {
        return cellValues.value ? FormatDateToStringComplete(cellValues.value) : null;
      }, width: 100
    },
    {
      field: 'endDate', headerName: 'Fecha final',
      renderCell: (cellValues) => {
        return cellValues.value ? FormatDateToStringComplete(cellValues.value) : null;
      }, width: 100
    },
    {
      field: 'randomOrder', headerName: 'Orden Aleatorio', 
      renderCell: (cellValues) => {
        return (cellValues.value ? <DoneIcon color="success" /> : <HighlightOffIcon color="error" />)
      }, width: 120
    },
    {
      field: 'showFeedback', headerName: 'Mostrar feedback', 
      renderCell: (cellValues) => {
        return (cellValues.value ? <DoneIcon color="success" /> : <HighlightOffIcon color="error" />)
      }, width: 130
    },
    {
      field: 'navigationBetweenQuestions', headerName: 'Navegar entre preguntas', 
      renderCell: (cellValues) => {
        return (cellValues.value ? <DoneIcon color="success" /> : <HighlightOffIcon color="error" />)
      }, width: 190
    },
    {
      field: 'active', headerName: 'Activo', 
      renderCell: (cellValues) => {
        return (cellValues.value ? <DoneIcon color="success" /> : <HighlightOffIcon color="error" />)
      }, width: 100
    },
    { field: 'questionsToShow', headerName: '# Preguntas a mostrar', width: 160},
    { field: 'numberOfAttempts', headerName: '# Intentos', width: 100},
    
  ];

  if (loading) {
    return (
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  

  return (
    <>
      
      {error && <ErrorSnackbar message={error} />

      }

      {showModalSource && <AssessmentDetails
          data={selectedItem}
          handleClose={setShowModalSource}
          handleSave={handleSave}
          questionTypes={questionTypes}
      />}

      {showModalSortQuestions && <ShortQuestions 
          data={selectedItem}
          handleClose={setShowModalSortQuestions}
          handleSave={handleSave}
      />}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
             Administración <span style={{ color: '#00b0ef' }}>Evaluaciones</span>
          </Typography>
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Button
              endIcon={<AddCircle />}
              variant="contained"
              onClick={() => { handleNew() }} autoFocus>{ACTIONS.NEW}</Button>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data || []} />
    </>
  );
};

export default Assessment;
