import React from 'react';
import {
  Grid,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Delete from '@mui/icons-material/Delete';

const NewsletterContentDetail = (props) => { 
    const { 
        id,
        title,
        content,
        newsLetterContentTypeId,
        handleDeleteContent,
        index,
    } = props;
      
      const deleteItem = (index) => {
        handleDeleteContent(index);
      };


      const getUIForContentType = () => {
        switch (newsLetterContentTypeId) {
          case 1: // editor
            return <div dangerouslySetInnerHTML={{ __html: content }} />;
        case 2:
            return (
              <>
                {content ?
                        <>
                          {
                            <img src={content} alt="preview" width="100%" />
                          }
                        </> : null}
              </>  );
        case 3: // Imagen y Documento
            return (
            <>
              {content ?
                      <>
                        {
                          <iframe src={content}  
                              width="100%"
                              height="450">
                          </iframe>
                        }
                      </> : null}
            </>  );
        case 4:
        case 5: // Video y Pagina externa
            return (
              <>
                {content ?
                        <>
                          {
                            <iframe src={content}  
                                width="100%"
                                height="450">
                            </iframe>
                          }
                        </> : null}
              </> 
            );
        default:
              return null;
        }
    }

      const getDescription = () => {
        switch (newsLetterContentTypeId) {
            case 1: // editor
              return 'Editor';
          case 2: // Imagen
              return 'Imagen';
          case 3: // Documento
              return 'Documento';
          case 4: // Video
              return 'Video';
          case 5: // Pagina externa
              return 'Enlace';
          default:
                return null
          }
      };
      
       

            return (
              <>
                <p />
                <Divider light />
                <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography variant="h6" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                                {title} <span style={{ color: '#00b0ef' }}>{getDescription()}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            color="error"
                            onClick={(e) => { deleteItem(index) }}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                </Grid>    
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={styles.preview}>
                              {getUIForContentType()}  
                            </div>
                        </Grid>
                </Grid>
                
              </>
            );
      ;
    };
    
    export default NewsletterContentDetail;

        // Just some styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 500 },
  delete: {
    cursor: "pointer",
    padding: 15,
    background: "red",
    color: "white",
    border: "none",
  },
};
    