import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
    Grid,
    TextField,
    FormControl,
  } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';


const imageMimeType = /image\/(png|jpg|jpeg)/i;
  
const AddNewsletterContentDetail = (props) => { 
    const editorRef = useRef(null);
    const [ title, setTitle ] = useState(null);
    const [ fileName, setFileName ] = useState(null);
    const [ file, setFile ] = useState(null);
    const [ fileDataURL, setFileDataURL ] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { 
        newsletterId,
        newsLetterContentTypeId,
        saveAddedItem,
        handleClose,
        isOpen,
    } = props;


    const changeHandler = (e) => {
      const file = e.target.files[0];
      if (!file.type.match(imageMimeType) && newsLetterContentTypeId === 2) {
        alert("Image mime type is not valid");
        return;
      }
      setFile(file);
    }

    const validateForm = () => {
        if (!title) return true;
        
        if (newsLetterContentTypeId === 1 && editorRef.current.getContent().length === 0) {
          return true;
        }  
        return false; // !title && !getContentToSend();
    } 

    useEffect(() => {
      let fileReader, isCancel = false;
      if (file) {
        setFileName(file.name)
        fileReader = new FileReader();
        fileReader.onload = (e) => {
        
          const { result } = e.target;
          if (result && !isCancel) {
            setFileDataURL(result)
          }
        }
        fileReader.readAsDataURL(file);
      }
      return () => {
        isCancel = true;
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort();
        }
      }
  
    }, [file]);


      const AddItem = () => {
        var content = getContentToSend()
        if (!title || !content) {
          enqueueSnackbar("Faltan datos favor verifique!");
          return;
        }
        saveAddedItem({
            title,
            content,
            newsLetterContentTypeId,
            newsletterId,
            fileName,
        });
        handleClose();
      };


      const getUITitle = () => {
        switch (newsLetterContentTypeId) {
            case 1: // editor
              return 'Nuevo editor';
          case 2: // Imagen
              return 'Nueva imagen';
          case 3: // Documento
              return 'Nuevo documento';
          case 4: // Video
              return 'Nuevo video';
          case 5: // Pagina externa
              return 'Nuevo enlace';
          default:
                return null
          }
      };

      const getContentToSend = () => {
        switch (newsLetterContentTypeId) {
            case 1: // editor
              return editorRef.current.getContent();
          case 2: // Imagen
              return fileDataURL;
          case 3: // Documento
              return fileDataURL;
          case 4: // Video
              return fileDataURL;
          case 5: // Pagina externa
              return fileDataURL;
          default:
                return null
          }
      };

      const getUIForContentType = () => {
          switch (newsLetterContentTypeId) {
            case 1: // editor
              return (
                <>
                  <Editor
                   apiKey='jb28fk9vszb757k0wc3bzob762tor90ibhkagvo5ou3f0rm0'
                    onInit={(evt, editor) => editorRef.current = editor}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </>
                );
          case 2:
          case 3: // Imagen y Documento
              return (
              <>
                <input
                  type="file"
                  id='image'
                  accept={newsLetterContentTypeId == 2 ? '.png, .jpg, .jpeg': '.pdf, .txt'}
                  onChange={changeHandler}
                />

                {fileDataURL && newsLetterContentTypeId == 2 ?
                        <p >
                          {
                            <img src={fileDataURL} alt="preview" width="200" height="200" />
                          }
                        </p> : null}
              </>  );
          case 4:
          case 5: // Video y Pagina externa
              return (
              <FormControl fullWidth={true} margin="dense">
                <TextField
                  label="Ingrese la URL"
                  value={fileDataURL}
                  onChange={(e) => setFileDataURL(e.target.value)}
                  fullWidth
                />
              </FormControl>);
          default:
                return null;
          }
      }

      const getDescription = () => {
        switch (newsLetterContentTypeId) {
            case 1: // editor
              return 'Editor';
          case 2: // Imagen
              return 'Imagen';
          case 3: // Documento
              return 'Documento';
          case 4: // Video
              return 'Video';
          case 5: // Pagina externa
              return 'Enlace';
          default:
                return null
          }
      };
       
      return (
        <div>
          <Dialog
              maxWidth = 'md'
              open={isOpen}
              aria-labelledby="responsive-dialog-title"
              >
              <DialogTitle id="responsive-dialog-title">
                  {getUITitle(newsLetterContentTypeId)}
              </DialogTitle>
              <DialogContent>
                      <Grid container spacing={2}>
                          <Grid item xs={8}>
                                <FormControl fullWidth={true} margin="dense">
                                  <TextField
                                    label="Titulo"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    fullWidth
                                  />
                                </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                                <FormControl fullWidth={true} margin="dense">
                                  <TextField
                                    label="Tipo"
                                    value={getDescription()}
                                    disabled
                                    fullWidth
                                  />
                                </FormControl>
                          </Grid>
                      </Grid>
                      <Grid container spacing={2}> 
                        <Grid item xs={12}>
                            <div style={styles.preview}>
                              {getUIForContentType()}  
                            </div>
                        </Grid>
                      </Grid>  
              </DialogContent>
            <DialogActions>
                <Button onClick={AddItem} autoFocus>
                Aceptar
                </Button>
                <Button onClick={handleClose} >
                Cancelar
                </Button>
            </DialogActions>
          </Dialog>
        </div>
      );

      
    };
    
    export default AddNewsletterContentDetail;


    // Just some styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 420 },
  delete: {
    cursor: "pointer",
    padding: 15,
    background: "red",
    color: "white",
    border: "none",
  },
};


    
    