import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Chip,
  Button,
  Tooltip,
  Typography,
  IconButton
} from "@mui/material";

import {
  getAbsencePermissions,
  saveNewAbsencePermissions,
  editAbsencePermissions
} from './logic';

import {
  Check,
  Close,
  Remove,
  AddCircle,
} from '@mui/icons-material';

import BackDrop from '../../shared/backdropDE';
import ErrorSnackbar from '../error-snackbar';
import DataTable from '../../shared/data-table';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import { getUser } from '../../shared/auth-service';
import AbsencePermissionsDetail from './employee-absence-permissions-detail';

const EmployeeAbsencePermissions = () => {
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [dataItem, setDataItem] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          //const filters = [{employeeId: user.employeeId}, {airportId: user.airport.id}];
          //for(const item in user.airport){ airIds.push({airportId: user.airport[item].id}); }

          const filters = [{employeeId: user.employeeId}];
          const data = await getAbsencePermissions(filters);

          setData(data);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
    return () => {};
  });

  // handles
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  const handleNew = () => {
    setDataItem({
      employeeAbsencePermissionsId: 0,
      requirementType: 0,
      employeeId: user.employeeId,
      airportId: user.airport.id,
      absenceType: 0,
      absenceDescription: "",
      employeeArea: "",
      startDate: null,
      endDate: null,
      justify: "",
      response: "",
      isVerified: 0,
      isActive: 1,
      isNew: true,
      isRead: false
    });
    setShowModal(true);
  };

  const handleSave = async (send) => {
    send.startDate = formatDate(send.startDate);
    send.endDate = formatDate(send.endDate);
    setShowModal(false);
    const save = await saveNewAbsencePermissions(send);
    setLoading(save);
  };

  const handleEdit = async (edit) => {
      setShowModal(false);
      edit.isVerify = edit.isVerified;
      delete edit.isVerified;
      const edited = await editAbsencePermissions(edit);
      setLoading(true);
  };

  const handleDataEdit = async (cell) => {
    cell.employeeId = cell.employee.employeeId;
    cell.airportId = cell.airport.airportId;
    cell.isNew = false;
    cell.isRead = true;
    setDataItem(cell);
    setShowModal(true);
  };

  const getRowId = (row) => row.employeeAbsencePermissionsId;

  // variables
  const typeRequest = [{type: 'Ausencia', color: 'info'}, {type: 'Permiso', color: 'warning'}];

  const columns = [
    { field: "Ver", headerName: "Ver", width: 60, align: 'center', sortable: false,
      renderCell: (cellValues) => {
        return <Tooltip title="Ver detalle de solicitud">
          <IconButton
            color="success"
            onClick={(e) => { handleDataEdit(cellValues.row) }}
            >
            <AddCircle />
          </IconButton>
        </Tooltip>;
      }
    },
    { field: 'requirementType', headerName: 'Solicitud', width: 120,
      renderCell: (cellValues) => {
        const { requirementType } = cellValues.row;
        return <Chip label={typeRequest[(requirementType - 1)].type} color={typeRequest[(requirementType - 1)].color} variant="outlined" />;
      }
    },
    { field: 'employee.employeeId', headerName: 'Identificación', width: 110,
      valueGetter: (params) => params.row.employee.employeeId,
    },
    { field: 'employee.name', headerName: 'Empleado', width: 280,
      //valueGetter: (params) => (params.row.employee.name.length) > 30 ? `${params.row.employee.name.slice(0, 30)}...` : params.row.employee.name,
      valueGetter: (params) => params.row.employee.name,
    },
    { field: 'employeeArea', headerName: 'Área', minwidth: 100, flex: 1 },
    { field: 'startDate', headerName: 'Inicio', width: 100, align: 'center',
      valueGetter: (params) => params.value.substring(0,10)
    },
    { field: 'endDate', headerName: 'Final', width: 100, align: 'center',
    valueGetter: (params) => params.value.substring(0,10)
    },
    { field: 'justify', headerName: 'Justificación', width: 200, flex: 1,
      valueGetter: (params) => (params.row.justify.length) > 30 ? `${params.row.justify.slice(0, 30)}...` : params.row.justify,
    },
    { field: 'createdDate', headerName: 'Creado', width: 150, align: 'center',
      valueGetter: (params) => params.value.substring(0,16)
    },
    { field: "isVerified", headerName: "Validado", width: 60, align: 'center', sortable: false,
      renderCell: (cellValues) => {
        return (cellValues.row.isVerified) ? <Check color='success' /> : <Remove color='warning' />;
      }
    },
  ];

  if (loading) { return (<BackDrop />); }
  if (error) { return (<ErrorSnackbar message={error} />); }

  return (
    <>
      {showModal && <AbsencePermissionsDetail
          data={dataItem}
          handleClose={setShowModal}
          handleSave={handleSave}
          handleEdit={handleEdit}
      />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography style={{ color: '#00B0EF', fontWeight: 'bold', fontSize: '24px' }}>
            Ausencias <span style={{ color: '#1A3072' }}>y Permisos</span>
          </Typography>
        </Grid>
        <Box sx={{ my: 2, ml: 2 }}>
          <Button
            endIcon={<AddCircle />}
            variant="contained"
            onClick={() => { handleNew() }} 
            autoFocus
            >{ACTIONS.NEW}
          </Button>
        </Box>
        <DataTable columns={columns} rows={data} getRowId={getRowId} />
      </Grid>
    </>
  );
};

export default EmployeeAbsencePermissions;
