import { callAPI } from '../network';

export async function getUserByAirport (id){

    const data = await callAPI(
                         null, 
                         'TalmaID/GetUsersByAirport?AirportID=' + id, 'get');
    return data;
 }

 export async function getEmployeesByAirport(id){

    const data = await callAPI(
                         null, 
                         'TalmaID/GetEmployeesByAirport?AirportID=' + id, 'get');
    return data;
 }

 export async function getAllEmployeesByAirport(id){
   //console.log('funcino airports');
   const data = await callAPI(
                        null, 
                        'TalmaID/GetEmployeesByAirport?AirportID=' + id, 'get');
   return data;
}

 export async function getUserFaceFeature(IdentificationNumber){

    const data = await callAPI(
                         null, 
                         'TalmaID/GetUserFaceFeature?IdentificationNumber=' + IdentificationNumber, 'get');
    return data;
 }

 export async function saveNewUser (model){
    const data = await callAPI(
                         model, 
                         'TalmaID/SaveNewUser', 'post');
    return data;
 }

 export async function updateUser (model){
    const data = await callAPI(
                         model, 
                         'TalmaID/UpdateUser', 'post');
    return data;
 }

 export async function updateState (UserId, Active){
    const data = await callAPI(
                         null, 
                         'TalmaID/UpdateState?UserId=' + UserId + '&Active=' + Active, 'get');
    return data;
 }