import React from 'react';

import {
  Typography,
} from "@mui/material";


const ContentViewer = ({
    content,
    newsLetterContentTypeId,
    title,
}) => { 

    const getUIForContentType = () => {
        
        switch (newsLetterContentTypeId) {
          case 1: // editor
            return <div dangerouslySetInnerHTML={{ __html: content }} />;
        case 2:   // Imagen
            return (
                <>
                  {content ?
                          <p >
                            {
                              <img src={content} alt="preview" width="100%" />
                            }
                          </p> : null}
                </>  );
        case 3: // Imagen y Documento
            return (
                <>
                {content ?
                        <>
                            {
                                <iframe src={content}  
                                                width="100%"
                                                height="450"
                                                >
                                        </iframe>
                            }
                        </> : null}
                </>  );
        case 4:
        case 5: // Video y Pagina externa
            return (
                <>
                {content ?
                        <>
                            {
                                <iframe src={content}  
                                            width="100%"
                                            height="450"
                                            >
                                </iframe>
                            }
                        </> : null}
                </>  );
        default:
              return null;
        }
    }



    return (
        <>
        <Typography gutterBottom component="div">
            <b>{title}</b>
        </Typography>
        {getUIForContentType()}
        </>
    );
}

export default ContentViewer;