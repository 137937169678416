import { callAPI } from '../network';

export async function updateAirportsByEmployee (airports ){

    //console.log(airports);
    const params = {
       airports: airports.airports.map(airport => ({
          Id: airport.Id, // Asegúrate de usar la capitalización correcta, como aparece en el objeto.
          Code: airport.Code // Igualmente aquí.
        })),
        employeeId: airports.employeeId,
     };
 
    
    const data = await callAPI(
                   params, 
                         'ManagementEmployee/updateAirportsByEmployee', 'post');
    return data || [ ];
 }


 export async function getEmployeesWithAirports (){
  
    const data = await callAPI(
                         null, 
                         'ManagementEmployee/getEmployeesWithAirports', 'get');
    return data || [ ];
 }

 export async function getAllAirportsByEmployee(employeeId){
    let data;
       data = await callAPI(
          null, 
          'ManagementEmployee/getAirportsByEmployee?employeeId='+ employeeId, 'get');
    
   
    return data || [ ];
 }

 export async function GetAllEmployeesWithAirportsByAirport(id){

   const data = await callAPI(
                        null, 
                        'ManagementEmployee/GetAllEmployeesWithAirportsByAirport?AirportID=' + id, 'get');
   return data;
}

export async function GetAllEmployeesByAirport(id){

   const data = await callAPI(
                        null, 
                        'ManagementEmployee/GetAllEmployeesForEdit?AirportID=' + id, 'get');
   return data;
}

export async function deleteAirportsByEmployee (id){
   const data = await callAPI(
                        null, 
                        'ManagementEmployee/deleteAirportsByEmployee?employeeId='+ id, 'get');
   return data;
}