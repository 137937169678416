import { getUserData } from '../../shared/info-device';
import { 
    getRequestAbsencePermissions,
    sendNewAbsencePermissions,
    sendUpdateAbsencePermissions
} from '../../shared/data-services/employee-services';
import { ResetTvRounded } from '@mui/icons-material';

export async function getAbsencePermissions(filters = []){
    var queryString = filters.map(function (filter) {
        return Object.keys(filter).map(function (key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key]);
        }).join('&');
    }).join('&');

    const data = await getRequestAbsencePermissions(queryString);
    return data;
};

export async function saveNewAbsencePermissions(dataForm){
    const data = await sendNewAbsencePermissions(dataForm);
    return data.data;
};

export async function editAbsencePermissions(dataForm){
    //console.log('dataForm :>> ', dataForm);
    const data = await sendUpdateAbsencePermissions(dataForm);
    //console.log('data :>> ', data);
    return data;
};

/*
export async function getSegmentType(){
    const data = await getSegmentTypes();
    return data;
};

export async function saveNewSegment(dataForm){
    //const { employeeId: user } = getUser();
    //const { userdata: { ip } } = await getUserData();
    //const userAgent = navigator.userAgent;

    //const params = {...dataForm, ...addons};
    const data = await sendNewSegment(dataForm);
    return data;
};

export async function deleteSegment(segmentId){
    const { employeeId: user } = getUser();
    const { userdata: { ip } } = await getUserData();
    const userAgent = navigator.userAgent;

    const params = { segmentId, userAgent, user, ip }
    const data = await sendDeleteSegment(params);
    return data;
};
*/