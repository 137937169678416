import React, { useState, useEffect } from 'react';

import {
    Card,
    List,
    CardContent,
    Grid,
    Divider,
    Typography, 
    Checkbox,
    RadioGroup ,
    FormControl,
    FormControlLabel,
    Radio,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import { ACTIONS, TITLES } from '../../shared/constants';
import CheckIcon from '@mui/icons-material/Check';
import { sendEmployeeAssessment } from './logic';
import { getUser } from '../../shared/auth-service';

const UserAssessment = (props) => {
    const user = getUser();
    const [ questions, setQuestions] = useState([...props.questions]);   
    const [ isNotValidForm, setIsNotValidForm] = useState(true);   

    const { assessmentId,
            name,
            handleAcept,
            signatureId,
            assessment,
            newsletterId,
        } = props;

    useEffect(() => {
        validateForm();
    });

    const { feedback } =  assessment;
    const handleChange = (value,indexQuestion,indexOption) => {

            var val = value.split('§');

            indexOption = val[1] === undefined ? 0 : val[1];
            value = val[0] === undefined ? value : val[0];


            const newQuestions = [...questions];
            const options = [...newQuestions[indexQuestion].optionByQuestions];

            //clean up options
            options.forEach(option => {
                option.response = null;
            });

            options[indexOption].response = value;
            newQuestions[indexQuestion].optionByQuestions =  [...options];
            setQuestions(newQuestions);
    }

    const handleSend = async () => {
        const assessmentToSend  = {...assessment};
        const employeeId = user.employeeId;
        assessment.questions = questions;

        const resp = await sendEmployeeAssessment({...assessmentToSend,assessmentId,employeeId,newsletterId,signatureId});

        handleAcept(null, resp);
    } 
    
    const validateForm = () => {
        const newQuestions = [...questions];
        var result = false;
        for(var j= 0 ; j < newQuestions.length; j++ ) {
            const pos = newQuestions[j].optionByQuestions.find(x=> x.response);
            if (!pos) {
                result = true; 
                j = newQuestions.length;
            }
        };
        setIsNotValidForm(result);
      }

    const getUIForQuestionType = (question,indexQuestion) => {
        const { questionTypeId } = question;
        switch (questionTypeId) {
        case 1:   // Verdadero / Falso
        case 2:   // Verdadero / Falso
            return (
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) => handleChange(e.target.value,indexQuestion,0)}
                    >
                        {question.optionByQuestions.map((optionByQuestion, indexOption) => {
                            const {optionText} = optionByQuestion;
                            return (<FormControlLabel value={optionText + "§" + indexOption} control={<Radio />} label={optionText} />);
                        })}
                    </RadioGroup>
                </FormControl>

            );
        case 3: // Lista Desplegable
                        
            return (
                <FormControl fullWidth={true} margin="dense">
                    <InputLabel id={indexQuestion+"-select-label"}>Seleccione su respuesta</InputLabel>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                        <Select
                            labelId={indexQuestion+"-select-label"}
                            label="Seleccione su respuesta"
                            fullWidth
                            required
                            onChange={(e) => handleChange(e.target.value,indexQuestion,0)}
                        >
                            {question.optionByQuestions.map((item, index) => {
                                return (<MenuItem value={item.optionText + "§" + index}>{item.optionText}</MenuItem>);
                            })}
                        </Select>
                        </Grid>
                    </Grid>
                </FormControl>
            );
        case 4: //Respuesta Corta
                        
            return (
                    <FormControl fullWidth={true} margin="dense">
                        <TextField
                        label="Su respuesta"
                        onChange={(e) => handleChange(e.target.value,indexQuestion,0)}
                        fullWidth
                        />
                    </FormControl>
            );
        default:
              return null;
        }
    }

    
    return (
        <>
            <Typography variant="h5" style={{color: '#1a3072'}} fontWeight="bold" sx={{ flexGrow: 1, m: 1,  }}>
                Circular <span style={{color: '#00b0ef'}}>{name} </span>
            </Typography>
            <List>
                    
                {questions.map((question, indexQuestion) => {
                        const {questionText, feedback} = question;
                        return (
                            <>
                                <Card  >
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            <b>¿{questionText}?</b>
                                        </Typography>
                                        <Grid container spacing={3}
                                            rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            > 
                                            <Grid item xs={12}>
                                                {getUIForQuestionType(question,indexQuestion)}
                                            </Grid>
                                        </Grid>        
                                    </CardContent>
                                </Card>
                                <Divider light />
                            </>
                        );
                    }
                )}
            </List>
            <Grid container spacing={12}
                    rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    > 
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h8" >
                            {TITLES.SEND_ASSESMENT}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                        onClick={()=> {handleSend()}} 
                        disabled = {isNotValidForm}
                        variant="contained"
                        color="info"
                        endIcon={<CheckIcon  />}>
                            {ACTIONS.SEND_ASSESMENT}
                        </Button>
                    </Grid> 
                </Grid>    
        </>
);
} 


export default UserAssessment;