import { callAPI } from '../network';

export async function getCustomers (){
  
   const data = await callAPI(
                        null, 
                        'Customer', 'get');
   return data || [ ];
}

export async function getCustomer (id){

    const data = await callAPI(
                         null, 
                         'Customer/GetById?id='+ id, 'get');
    return data;
 }

 export async function saveCustomer (customer){
   const method = customer.id ? 'put' : 'post';
   const data = await callAPI(
                        customer, 
                        'Customer', method);
   return data;
}

export async function deleteCustomer (id){
   const data = await callAPI(
                        null, 
                        'Customer?id='+ id, 'delete');
   return data;
}