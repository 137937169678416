import { getProfile, saveProfile, deleteProfile, getProfileById, savePermissions, getPermissions, getAllPermissionsByProfile } from '../../../shared/data-services/management-profile-service';

export async function getAllProfile (){
  const data = await getProfile();
  return data || [ ];
};

export async function getSelectedProfile (id){
  const data = await getProfileById(id);
  return data;
};

export async function saveSelectedProfile (id, description){
  const Profile = {
    id, 
    description
  }

  const data = await saveProfile(Profile);
  return data;
};


export async function getPermissionByProfile (profileId){
  const data = await getAllPermissionsByProfile(profileId);
  return data || [ ];
};

export async function getAllPermissions(){
  const data = await getPermissions();
  return data || [];
};


export async function saveSelectedPermissions (permissions, profileId, profileName, action){
  // const Permission = {
  //   code, 
  //   description: description
  // }
  if(action === 'CREAR'){
    profileId = null
  }
  const data = await savePermissions(permissions, profileId, profileName);
  return data;
};

export async function deleteSelectedProfile (id){
  const data = await deleteProfile(id);
  return data;
};