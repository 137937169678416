import React, { useState, useEffect } from 'react';
import { getUser } from '../../shared/auth-service';
import ShiftDetail from './shift-details';
import { isMobile } from 'react-device-detect';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import BackDrop from '../../shared/backdropDE';
import ErrorSnackbar from '../error-snackbar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import IconButton from '@mui/material/IconButton';
import  PendingNewsletters  from '../pending-newsletter';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';

import {Card, CardContent, CardActionArea } from '@mui/material';

import {
    getDataEventFormat,
    getPendingNewslettersByEmployee
  } from './logic';

const ShiftCalendar = () => {
    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [events, setEvents] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [news, setNews] = useState(null);
    
    const refresh = () => {
        setLoading(true);
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        },
    }));

    const businessHours = {
        dow: [1, 2, 3, 4, 5]
    };

    const colorChip = ['primary', 'info', 'error', 'warning', 'success'];

    function QueryFilter(){
        var paramsQuery = '';

        paramsQuery = '?Employee=' + user.employeeId;

        paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'StartDate=' + formatDate(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));

        paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'EndDate=' + formatDate(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 2, 0));

        paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'IsEnabled=1';

        paramsQuery = paramsQuery + '&' + 'employeeId=' + user.employeeId;

        return paramsQuery;
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    useEffect(() => {
        async function fetchData() {
          if (loading) {
            try {
                var data = await getDataEventFormat(QueryFilter());
                const news = await getPendingNewslettersByEmployee(user.employeeId);
                setNews(news || []);   
                
                setEvents(data);

                // await RegisterUsabilityLog('Calendario de Turnos', user.employeeId);

                setLoading(false);

            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
          }
        }
        fetchData();
        return () => {};
    });
    
    function ClickEvent(Element){
        setSelectedItem(Element);
        setShowModal(true);
    };

    function renderEventContent(eventInfo) {
        return (
        <>
            <Card variant="outlined" style={{backgroundColor: eventInfo.event.extendedProps.color === '' ? '#1a30721a' : eventInfo.event.extendedProps.color, textAlign: 'center', width: '100%', margin: '2px'}}>
                <CardActionArea onClick={() => ClickEvent(eventInfo.event.extendedProps.data) }>
                    <CardContent style={{padding: isMobile ? '0' : ''}}>
                        <Stack>
                            {eventInfo.event.title !== '' ?
                            (
                                <>
                                    <span style={{color: '#1a3072', fontWeight: 'bold', fontSize: isMobile ? '15px' : '20px'}}>{eventInfo.event.title}</span>

                                </>
                            ):
                            (    
                                !isMobile && <div style={{height: '24px'}}></div>
                            )    
                            }

                            {
                                isMobile ?
                                (
                                    //mobile
                                    <>
                                        <span style={{color: 'black', fontWeight: 'bold', fontSize: '10px', marginTop: '5px', marginBottom: '5px'}}>{eventInfo.event.extendedProps.abbreviation}</span>
                                        {(eventInfo.event.title === '' && eventInfo.event.extendedProps.abbreviation !== '')  && <div style={{height: '17px'}}></div>}
                                        {(eventInfo.event.title !== '' && eventInfo.event.extendedProps.abbreviation === null) && <div style={{height: '12px'}}></div>}
                                    </>
                                    
                                ) :
                                (
                                    //Other
                                    <>
                                        {eventInfo.event.extendedProps.abbreviation !== null ? <Chip size="small" label={eventInfo.event.extendedProps.abbreviation} color={colorChip[eventInfo.event.extendedProps.abbreviationTypeId]} style={{fontSize: isMobile ? '5px' : ''}} /> : <div style={{height: '24px'}}></div>}
                                    </>
                                )
                            }
{/* 
                            {eventInfo.event.extendedProps.abbreviation !== null ? 
                            (
                                !isMobile && <Chip size="small" label={eventInfo.event.extendedProps.abbreviation} color={colorChip[eventInfo.event.extendedProps.abbreviationTypeId]} style={{fontSize: isMobile ? '5px' : ''}} />
                                (isMobile || eventInfo.event.title === '') && <span style={{color: 'black', fontWeight: 'bold', fontSize: '10px'}}>{eventInfo.event.extendedProps.abbreviation}</span>
                            ) : 
                            (
                                !isMobile && <div style={{height: '24px'}}></div>
                            )} */}
                        </Stack>                        
                    </CardContent>
                </CardActionArea>
            </Card>     
        </>
        )
    }

    if (loading) {
        return (<BackDrop />);
    }

    //Visualización de circulares en caso de tener pendientes
    if (news && news.length > 0 ) {
        return  (<PendingNewsletters data={news} refresh={refresh} />);
    }

    if (error) {
        return (<ErrorSnackbar message={error} />);
    }

    return (
        <>  
          {showModal && <ShiftDetail
            data={selectedItem}
            action="OPEN"
            handleClose={setShowModal}
            />}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row">
                            <span style={{fontSize: '30px', color: '#1a3072', fontWeight: 'bold'}}>Calendario de <span style={{ color: '#00b0ef' }}> turnos</span></span>
                            {isMobile && <HtmlTooltip 
                            open={openTooltip}
                            title={
                            <>
                                <Typography color="inherit">Definiciones</Typography>
                                <Stack spacing={1}>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#1a30721a'}}/>
                                        <span>Turno programado</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#bbf4ff'}}/>
                                        <span>Cambio por Administrador</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#fff5c5'}}/>
                                        <span>Cambio por Usuario</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#eec2f1'}}/>
                                        <span>Solicitud Cambio</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#fdbebe'}}/>
                                        <span>Capacitación Programada</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#afffdb'}}/>
                                        <span>Talento Humano</span>
                                    </Stack>
                                </Stack>
                            </>
                            }
                        >
                            <IconButton onFocus={() => setOpenTooltip(!openTooltip)} onBlur={() => setOpenTooltip(false)}>
                                <NotListedLocationIcon style={{fontSize: '30px', color: '#1a3072'}} />
                            </IconButton>
                        </HtmlTooltip>
                        }
                        {!isMobile && <HtmlTooltip 
                            title={
                            <>
                                <Typography color="inherit">Definiciones</Typography>
                                <Stack spacing={1}>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#1a30721a'}}/>
                                        <span>Turno programado</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#bbf4ff'}}/>
                                        <span>Cambio por Administrador</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#fff5c5'}}/>
                                        <span>Cambio por Usuario</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#eec2f1'}}/>
                                        <span>Solicitud Cambio</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#fdbebe'}}/>
                                        <span>Capacitación Programada</span>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Brightness1Icon style={{color: '#afffdb'}}/>
                                        <span>Talento Humano</span>
                                    </Stack>
                                </Stack>
                            </>
                            }
                        >
                            <NotListedLocationIcon style={{fontSize: '30px', color: '#1a3072'}} sx={{ml: 1, mt: 1}}/>
                        </HtmlTooltip>
                        }
                    </Stack>
                    
                </Grid>

                <Divider variant="middle" />
                <Grid item xs={12}>
                    <FullCalendar
                        initialView='dayGridMonth'
                        plugins={[dayGridPlugin]}
                        businessHours={businessHours}
                        events={events}
                        height= {800}
                        locale={esLocale}
                        eventContent={renderEventContent}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: ''
                        }}
                        style={{
                            flexWrap: "nowrap",
                            justifyContent: "",
                            //backgroundColor: 'none',
                            width: "90vw",
                            margin: "auto",
                            marginTop: "-4rem",
                            backgroundColor: "#FFF",
                            paddingTop: "4rem",
                            borderRadius: ".5rem",
                            color: "#8898aa"
                        }}
                        validRange={{
                            start: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1),
                            end: new Date((new Date()).getFullYear(), (new Date()).getMonth() + 2, 0, 23, 59, 59)
                          }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default ShiftCalendar;