import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Select,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  TextField,
  Switch,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon
} from "@mui/material";

import {
  getSegments,
  getSegmentType,
  saveNewSegment,
  deleteSegment
} from './logic';

import {
  Check,
  Close,
  TaskAlt,
  MoreTime,
  AddCircle,
  ExpandMore,
  DragIndicator,
  DeleteOutline,
  RadioButtonUnchecked
} from '@mui/icons-material';

import BackDrop from '../../shared/backdropDE';
import ErrorSnackbar from '../error-snackbar';
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import { getUser } from '../../shared/auth-service';

const ShiftSegments = () => {
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [typeSegment, setSegmentType] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [showBackdrop, setShowBackdrop] = useState(false);

  // campos form
  const [descriptionSegment, setDescriptionSegment] = useState(null);
  const [segmentTypeId, setSegmentTypeId] = useState(1);
  const [startTime, setStartTime] = useState('');
  const [startMark, setStartMark] = useState(false);
  const [endTime, setEndTime] = useState('');
  const [endMark, setEndMark] = useState(false);
  const [dayMore, setDayMore] = useState(false);
  const [observation, setObservation] = useState(null);
  const [order, setOrder] = useState(1);
  
  const { sId } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getSegments(sId);
          const typeSegment = await getSegmentType();

          setData(data);
          setSegmentType(typeSegment);
          setOrder(data.length);
          // await RegisterUsabilityLog('Segmentos - Turnos', user.employeeId);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
    return () => {};
  });

  const handleStartTimeChange = (event) => {
    const { value } = event.target;
    setStartTime(value);
  };

  const handleEndTimeChange = (event) => {
    const { value } = event.target;
    setEndTime(value);
  };

  const handleOpen = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStartMarkChange = (event) => {
    setStartMark(event.target.checked);
  };

  const handleEndMarkChange = (event) => {
    setEndMark(event.target.checked);
  };

  const handleDayChange = (event) => {
    setDayMore(event.target.checked);
  };

  const validateForm = () => {
    return !(descriptionSegment && startTime && endTime);
  }

  const handleSave = async (descriptionSegment, segmentTypeId, startTime, startMark, endTime, endMark, dayMore, observation, order) => {
      setLoading(true);
      const shiftId = parseInt(sId);
      const norder = order + 1;
      await saveNewSegment({shiftId, order: norder, descriptionSegment, segmentTypeId, startTime, startMark, endTime, endMark, dayMore, observation});

      const dataReload = await getSegments(sId);
      setData(dataReload);

      setDescriptionSegment('');
      setStartTime('');
      setEndTime('');
      setObservation('');
      setExpanded(false);
      setLoading(false);
      enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleDelete = async (id) => {
    setShowBackdrop(true);
    await deleteSegment(id);

    const dataReload = await getSegments(sId);
    setData(dataReload);
    
    setShowBackdrop(false);
    enqueueSnackbar(MESSAGES.DELETE);
  };

  if (loading) {
    return (<BackDrop />);
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>
      {showBackdrop && <BackDrop />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ color: '#00b0ef' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Segmentos <span style={{ color: '#1a3072' }}>Turnos</span>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Accordion expanded={expanded === 'panel'} onChange={handleOpen('panel')} key="1">
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panelbh-content"
              id="panelbh-header"
            >
              <MoreTime />
              <Typography sx={{ width: '33%', flexShrink: 0, ml:1 }}>
                Agregar Segmento
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Completa los campos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} >
                  <FormControl fullWidth={true} margin="dense">
                  <TextField
                          label="Descripción"
                          placeholder="Descripción"
                          size="small"
                          value={descriptionSegment || ''}
                          onChange={(e) => setDescriptionSegment(e.target.value)}
                          type='text'
                          fullWidth
                        />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} >
                  <FormControl fullWidth={true} margin="dense">
                    <InputLabel size='small' id="select-segmenttype">Tipo de Segmento</InputLabel>
                    <Select
                      label="Tipo de Segmento"
                      placeholder="Tipo de Segmento"
                      labelId="select-segmenttype"
                      size="small"
                      value={segmentTypeId || 0}
                      onChange={(e) => setSegmentTypeId(e.target.value)}
                    >
                      {typeSegment.map((option) => (
                        <MenuItem value={option.segmentTypeId} key={option.segmentTypeId}>{option.description}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} >
                  <FormControl fullWidth={true} margin="dense">
                  <TextField
                          label="Hora Inicio"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={startTime || false}
                          onChange={handleStartTimeChange}
                          type='time'
                          inputformat="HH:mm"
                        />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} >
                  <FormControl margin="dense">
                    <FormControlLabel
                      control={
                        <Switch 
                        checked={startMark} 
                        onChange={handleStartMarkChange}
                        />
                      }
                      label="Marcación"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth={true} margin="dense">
                    <TextField
                      label="Observaciones"
                      placeholder="Observaciones"
                      size="small"
                      value={observation || ''}
                      onChange={(e) => setObservation(e.target.value)}
                      type='text'
                      fullWidth
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl margin="dense">
                    <FormControlLabel
                      control={
                        <Switch 
                        checked={dayMore} 
                        onChange={handleDayChange}
                        />
                      }
                      label="Día Adicional"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth={true} margin="dense">
                  <TextField
                          label="Hora Final"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={endTime || false}
                          onChange={handleEndTimeChange}
                          type='time'
                        />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} >
                  <FormControl margin="dense">
                    <FormControlLabel
                      control={
                        <Switch 
                        checked={endMark} 
                        onChange={handleEndMarkChange}
                        />
                      }
                      label="Marcación"
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    endIcon={<AddCircle />}
                    variant="contained"
                    disabled = {validateForm()}
                    onClick={() => { handleSave(descriptionSegment, segmentTypeId, startTime, startMark, endTime, endMark, dayMore, observation, order) }}
                    autoFocus>{ACTIONS.SAVE}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: '#f7f7f7', minHeight: '60vh' }} >
        {data.map((item) => (
          <Card xs={12} sm={12} md={3} sx={{ m:2 }} key={item.shiftSegmentId}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: '#1a3072' }} aria-label={item.shiftSegmentId}>{item.order}</Avatar>
              }
              action={
                <ButtonGroup variant="text" aria-label="text button group">
                  <IconButton 
                    aria-label="delete"
                    color="error"
                    onClick={(e) => { handleDelete(item.shiftSegmentId) }}
                    >
                    <DeleteOutline />
                  </IconButton>
                  <IconButton aria-label="drag">
                    < DragIndicator />
                  </IconButton>
                </ButtonGroup>
              }
              title={item.descriptionSegment}
              subheader={item.segmentType.description}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>
                    Entrada
                  </Typography>
                    <Table sx={{}} aria-label="table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Hora</TableCell>
                          <TableCell align="center">{item.startTime}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                        <TableCell>Marcación</TableCell>
                        <TableCell align="center">
                          {item.startMark ? (
                            <Icon style={{ color: 'lightgreen' }}>
                              <TaskAlt />
                            </Icon>
                          ) : (
                            <Icon style={{ color: 'red' }}>
                              <RadioButtonUnchecked />
                            </Icon>
                          )}
                        </TableCell>
                      </TableRow>
                      </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    Salida
                  </Typography>
                    <Table sx={{}} aria-label="table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Hora</TableCell>
                          <TableCell align="center">{item.endTime}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                        <TableCell>Marcación</TableCell>
                        <TableCell align="center">
                          {item.endMark ? (
                            <Icon style={{ color: 'lightgreen' }}>
                              <TaskAlt />
                            </Icon>
                          ) : (
                            <Icon style={{ color: 'red' }}>
                              <RadioButtonUnchecked />
                            </Icon>
                          )}
                        </TableCell>
                      </TableRow>
                      </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4}>
                  <Typography>&nbsp;</Typography>
                    <Table aria-label="table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Duración</TableCell>
                          <TableCell align="center">{item.duration}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                        <TableCell>Día Adicional</TableCell>
                        <TableCell align="center">
                          {item.dayMore ? (
                            <Icon style={{ color: 'green' }}>
                              <Check />
                            </Icon>
                          ) : (
                            <Icon style={{ color: 'red' }}>
                              <Close />
                            </Icon>
                          )}
                        </TableCell>
                      </TableRow>
                      </TableBody>
                    </Table>
                </Grid>
              </Grid>
              <Typography variant="caption">
                {item.observation}
              </Typography>
            </CardContent>
          </Card>
        ))}
        </Grid>
      </Grid>
    </>
  );
};

export default ShiftSegments;
