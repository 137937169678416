import Dashboard from './dashboard.js';
import { TITLES } from '../../shared/constants';
import InlineMessage from '../inline-message';
import { getUser } from '../../shared/auth-service';

const MainComponent = () => {
    const user = getUser();

    if (user && !user.passwordChanged) {

        return (
            <InlineMessage message={TITLES.MESSAGE_CHANGE_PASSWORD_LINK} />
        );
    }

    if (user && user.isRecoverPassword) {

        return (
            <InlineMessage message={TITLES.MESSAGE_RECOVER_PASSWORD_LINK} />
        );
    }

    return (
        <Dashboard user={user} />
    );
}

export default MainComponent;
