import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
    Avatar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    ListItemButton,
    Badge,
    BadgeMark
} from "@mui/material";
import Divider from '@mui/material/Divider';
import { styled,useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import StickyNote2 from '@mui/icons-material/StickyNote2';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Newspaper from '@mui/icons-material/Newspaper'; 
import SaveAsIcon from '@mui/icons-material/SaveAs';
import UpdatePassword from '../update-password';
import Login from '../../components/login';
import NewsletterType from '../newsletter-type';
import Newsletter from '../newsletter'; 
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import TopicIcon from '@mui/icons-material/Topic';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Tooltip from '@mui/material/Tooltip';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ShortTextIcon from '@mui/icons-material/ShortText';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


import { TITLES, MESSAGES } from '../../shared/constants';
import AcceptDialog from '../accept-modal';
import Home from '../home';

import Overtime from '../overtime';
import EmployeeWorkShif from '../employee-work-shif';
import PolyvalencePayment from '../employee-polyvalence';
import PendingSignatures from '../pending-signature';
import Signature from '../signature';
import { logout } from '../../shared/auth-service';
import ManageProfileOptions from '../gestion/management-position';
import ManagementProfile from '../gestion/management-profile';
import ManagementEmployee from '../gestion/management-employee';
import ManagementAirport from '../gestion/management-airport';
import Compensation from '../compensation'
import PositionHomologationInform from '../position-homologation/position-homologation-inform'
import PositionHomologationEngrana from '../position-homologation/position-homologation-engrana'
import GroupHomologationReadOnly from '../position-homologation/position-homologation-source'
import UserQueryTalmaID from '../user-query-talma-id';
import ShiftManagement from '../shift-management';
import ShiftSegments from '../shift-segments';
import ShiftCalendar from '../shift-calendar';
import ShiftAbbreviation from '../shift-abbreviation';
import ShiftHistory from '../shift-history';
import ShiftImport from '../shift-import';
import EmployeeAbsencePermissions from '../employee-absence-permissions';
import ShiftReadOnly from '../shift-management/shift-management-readonly';
import ShiftAbbreviationReadOnly from '../shift-abbreviation/shift-abbreviation-readonly';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Collapse from '@mui/material/Collapse';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import PreviewIcon from '@mui/icons-material/Preview';
import Assessment from '../assessment';
import { isMobile } from 'react-device-detect';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';
import { BadgeRounded, EmojiPeople, Flight, GroupAdd, ManageAccountsRounded, ManageSearch, PlaylistAdd, PointOfSale, SettingsAccessibility, SocialDistance } from '@mui/icons-material';

const drawerWidth = 240;

const logoStyles = {
    width: 150,
    margin: '5px auto',
} 

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Dashboard = props => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedIndexMenu, setSelectedIndexMenu] = React.useState([0, 0]);

  const [openMenu, setOpenMenu] = useState(false);

  const handleClickItemOpen = (index, subIndex, navigateUrl, text) => {    

    if(selectedIndexMenu === index){
      setOpenMenu(false);
      setSelectedIndexMenu([0, 0]);
    }else{
      setSelectedIndexMenu([index, subIndex]);
      setOpenMenu(true);
    }

    if(navigateUrl !== ''){
      if(isMobile) setOpen(false);
      RegisterUsabilityLog(text, user.employeeId);
      navigate(navigateUrl);
      // setOpen(false);
    }

  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const { user } = props;
  const navigate = useNavigate();
  const [itemList] = useState([
    {
      code: '0',
      text: 'Inicio',
      icon: <HomeIcon/>,
      sort: 1,
      onClick: () => handleClickItemOpen(0, 0, '/', 'Inicio'),
      subItems: []
    }
    ,
    {
      code: 'DEOPP0001',
      text: 'Autogestión',
      icon: <EngineeringIcon />,
      sort: 2,
      onClick: () => handleClickItemOpen(1, -1, '', 'Autogestión'),
      subItems: [
        {
          subCode: 'DEOP0001',
          subText: 'Horas Extras',
          subIcon: <MoreTimeIcon />,
          subSort: 1,
          onClick: () => handleClickItemOpen(1, 0, '/Overtime', 'Horas Extras - Autogestión')
        },   
        {
          subCode: 'DEOP0002',
          subText: 'Marcaciones',
          subIcon: <AlarmOnIcon />,
          subSort: 2,
          onClick: () => handleClickItemOpen(1, 1, '/EmployeeWorkShif', 'Marcaciones - Autogestión')
        },
        {
          subCode: 'DEOP0003',
          subText: 'Polivalencia',
          subIcon: <ConnectingAirportsIcon />,
          subSort: 3,
          onClick: () => handleClickItemOpen(1, 2, '/PolyvalencePayment', 'Polivalencia -Autogestión')
        },
        {
          subCode: 'DEOP0014',
          subText: 'Calendario de Turnos',
          subIcon: <CalendarMonthIcon />,
          subSort: 4,
          onClick: () => handleClickItemOpen(1, 3, '/ShiftCalendar', 'Calendario de Turnos - Autogestión')
        },
        {
          subCode: 'DEOP0015',
          subText: 'Ausencias y Permisos',
          subIcon: <CallMissedOutgoingIcon />,
          subSort: 4,
          onClick: () => handleClickItemOpen(1, 4, '/EmployeeAbsencePermissions', 'Ausencias y Permisos - Autogestión')
        }
      ]
    }, 
    {
      code: 'DEOPP0002',
      text: 'Circulares',
      icon: <TopicIcon />,
      sort: 3,
      onClick: () => handleClickItemOpen(2, -1, ''),
      subItems: [
        {
          subCode: 'DEOP0004',
          subText: 'Tipo Circulares',
          subIcon: <StickyNote2 />,
          subSort: 1,
          onClick: () => handleClickItemOpen(2, 0, '/NewsletterType', 'Tipo Circulares - Circulares')
        },
        {
          subCode: 'DEOP0005',
          subText: 'Circulares',
          subIcon: <Newspaper />,
          subSort: 2,
          onClick: () => handleClickItemOpen(2, 1, '/Newsletter', 'Circulares - Circulares'), 
        },
        // {
        //   subCode: 'DEOP0006',
        //   subText: 'Mis Circulares',
        //   subIcon: <SaveAsIcon />,
        //   subSort: 3,
        //   onClick: () => handleClickItemOpen(2, 2, '/PendingSignatures', 'Mis Circulares - Circulares')
        // },
        {
          subCode: 'DEOP0012',
          subText: 'Evaluaciones',
          subIcon: <QuestionAnswerIcon />,
          subSort: 4,
          onClick: () => handleClickItemOpen(2, 3, '/Assessment', 'Evaluaciones - Circulares')
        }
      ]
    },
    {
      code: 'DEOPP0003',
      text: 'Talma ID',
      icon: <SensorOccupiedIcon />,
      sort: 4,
      onClick: () => handleClickItemOpen(3, -1, '', 'Talma ID'),
      subItems: [
        {
          subCode: 'DEOP0008',
          subText: 'Gestión Usuarios',
          subIcon: <PersonAddIcon />,
          subSort: 1,
          onClick: () => handleClickItemOpen(3, 0, '/UserQueryTalmaID', 'Gestión Usuarios - Talma ID')
        }
      ]
    },
    {
      code: 'DEOPP0005',
      text: 'Gestión Accesos',
      icon: <ManageAccountsRounded/>,
      sort: 5,
      onClick: () => handleClickItemOpen(4, -1, '', 'Gestión'),
      subItems: [
        {
          subCode: 'DEOP0018',
          subText: 'Maestro Perfiles',
          subIcon: <GroupAdd  />,
          subSort: 1,
          onClick: () => handleClickItemOpen(4, 0, '/manageposition', 'Maestro Perfiles')
        },
        {
          subCode: 'DEOP0007',
          subText: 'Perfiles Por Cargo',
          subIcon: <SettingsAccessibility />,
          subSort: 2,
          onClick: () => handleClickItemOpen(4, 1, '/manageprofileoptions', 'Perfiles Por Cargo')
        },
        {
          subCode: 'DEOP0019',
          subText: 'Perfiles Adi. Por Empleado',
          subIcon: <BadgeRounded  />,
          subSort: 3,
          onClick: () => handleClickItemOpen(4, 2, '/manageemployee', 'Perfiles Adi. Por Empleado')
        },
        {
          subCode: 'DEOP0020',
          subText: 'Aeropuertos Adi. Por Empleado',
          subIcon: <Flight  />,
          subSort: 4,
          onClick: () => handleClickItemOpen(4, 3, '/manageairport', 'Aeropuertos Adi. Por Empleado')
        },
      ]
    },
    {
      code: 'DEOPP0006',
      text: 'Gestión Compensación',
      icon: <PointOfSale/>,
      sort: 7,
      onClick: () => handleClickItemOpen(6, -1, '/', 'Gestión Compensación'),
      subItems: [
        {
          subCode: 'DEOP0021',
          subText: 'Requeridos',
          subIcon: <EmojiPeople />,
          subSort: 1,
          onClick: () => handleClickItemOpen(6, 0, '/Compensation', 'Requeridos')
        }
      ]
    },
    {
      code: 'DEOPP0007',
      text: 'Homologación Cargos',
      icon: <SettingsAccessibility/>,
      sort: 8,
      onClick: () => handleClickItemOpen(7, -1, '/', 'Homologación Cargos'),
      subItems: [
        {
          subCode: 'DEOP0022',
          subText: 'Inform',
          subIcon: <SocialDistance />,
          subSort: 1,
          onClick: () => handleClickItemOpen(7, 0, '/positionhomologationinform', 'Inform')
        },
        {
          subCode: 'DEOP0023',
          subText: 'Engrana',
          subIcon: <SocialDistance />,
          subSort: 1,
          onClick: () => handleClickItemOpen(7, 1, '/positionhomologationengrana', 'Engrana')
        },
        {
          subCode: 'DEOP0024',
          subText: 'Consultar Grupos Homologación',
          subIcon: <ManageSearch />,
          subSort: 1,
          onClick: () => handleClickItemOpen(7, 2, '/positionHomologationReadOnly', 'Consultar Grupos Homologación')
        }
      ]
    },
    {
      code: 'DEOPP0004',
      text: 'Admin. Turnos',
      icon: <EventNoteIcon />,
      sort: 6,
      onClick: () => handleClickItemOpen(5, -1, '', 'Admin. Turnos'),
      subItems: [
        {
          subCode: 'DEOP0016',
          subText: 'Consultar Turnos',
          subIcon: <AccessTimeIcon />,
          subSort: 1,
          onClick: () => handleClickItemOpen(5, 0, '/ShiftReadOnly', 'Consultar readOnly - Turnos')
        },
        {
          subCode: 'DEOP0025',
          subText: 'Consultar Abreviados',
          subIcon: <ShortTextIcon />,
          subSort: 1,
          onClick: () => handleClickItemOpen(5, 1, '/ShiftAbbreviationReadOnly', 'Consultar readOnly - Abreviados')
        },
        {
          subCode: 'DEOP0009',
          subText: 'Maestro Turnos',
          subIcon: <MoreTimeIcon />,
          subSort: 2,
          onClick: () => handleClickItemOpen(5, 2, '/ShiftManagement', 'Turnos - Turnos')
        },
        {
          subCode: 'DEOP0010',
          subText: 'Maestro Abreviados',
          subIcon: <PlaylistAdd />,
          subSort: 3,
          onClick: () => handleClickItemOpen(5, 3, '/ShiftAbbreviation', 'Abreviados - Turnos')
        },
        {
          subCode: 'DEOP0011',
          subText: 'Consultar Programación',
          subIcon: <CalendarMonthIcon />,
          subSort: 4,
          onClick: () => handleClickItemOpen(5, 4, '/ShiftHistory', 'Consultar - Turnos')
        },
        {
          subCode: 'DEOP0013',
          subText: 'Importar Programación',
          subIcon: <CloudUploadIcon />,
          subSort: 5,
          onClick: () => handleClickItemOpen(5, 5, '/ShiftImport', 'Importar - Turnos')
        }
      ]
    },
  ]);

  itemList.sort((x, z) => x.sort - z.sort);

  const verifyOption = (option) => {
    if(!user) {
      return false;
    }
    return user?.employeePosition.employeePositionOptions.some(x=> x.code === option || option === "0");
  };

  return (
    <>
      {message && <AcceptDialog 
        handleClose= {() => {setMessage(null);}}
        dialogContentText ={message}
        dialogTitle={MESSAGES.ALERT}
        handleAccept ={() => logout()}
        open={message}
      />}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
        {user && <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {TITLES.DRAWER}
            </Typography>
            {/* <Button color="inherit" onClick={() => {
                  setMessage(MESSAGES.CONFIRM_EXIT);
                }}
               >Salir</Button> */}
            <Tooltip title={user.name + ' ' + user.lastName}>
              <Avatar style={{backgroundColor: 'rgb(0, 176, 239)'}}>{user ? `${ user.name[0]}${user.lastName[0]}` : ''}</Avatar>
            </Tooltip>
            <Tooltip title="Salir">
              <IconButton aria-label="Example" onClick={() => {
                  setMessage(MESSAGES.CONFIRM_EXIT);
                }}>
                <ExitToAppIcon style={{color: 'white'}}/>
              </IconButton>
            </Tooltip>
          </Toolbar>}
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <img src="./logo.png" alt="logo" style={logoStyles} />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            
            {
              itemList.map((item, index) => {
                
                const { code, text, icon, onClick, subItems} = item;

                // Verificar si es único menú o tiene submenú

                if(subItems.length === 0){
                  return (
                    verifyOption(code) && 
                    <>
                      <ListItemButton selected={selectedIndexMenu[0] === index} key={index} onClick={onClick}>
                        <ListItemIcon>
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItemButton>
                    </>
                  );
                }else{

                  return (
                    verifyOption(code) &&
                    <>
                      <ListItemButton selected={selectedIndexMenu[0] === index && selectedIndexMenu === -1} onClick={onClick}>
                        <ListItemIcon>
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {openMenu && selectedIndexMenu[0] === index ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openMenu && selectedIndexMenu[0] === index} timeout="auto" unmountOnExit>
                        {
                          subItems.map((subItem, subIndex) => {

                            const { subCode, subText, subIcon, onClick} = subItem;
                            
                            return (
                              verifyOption(subCode) &&
                              <>
                                <List component="div" disablePadding>
                                  <ListItemButton selected={selectedIndexMenu[0] === index && selectedIndexMenu[1] === subIndex} 
                                  sx={{ pl: 4 }} 
                                  onClick={onClick}>
                                    <ListItemIcon>
                                      {subIcon}
                                    </ListItemIcon>
                                    <ListItemText primary={subText} />
                                  </ListItemButton>
                                </List>
                              </>
                            );
        
                          })
                        }
                       </Collapse>
                    </>
                  );
                }
              })
            }
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
              <Routes>
                  <Route path="/changePassword/:key" 
                          element={<UpdatePassword />}/>
                  <Route path="/" 
                    element={!user ? <Login /> :<Home />}/>
                  <Route path="/Overtime" 
                      element={!user ? <Login /> :<Overtime />}/>
                  <Route path="/EmployeeWorkShif" 
                      element={!user ? <Login /> : <EmployeeWorkShif /> }
                      />
                  <Route path="/PolyvalencePayment" 
                      element={!user ? <Login /> : <PolyvalencePayment /> }
                      />
                  <Route path="/NewsletterType" 
                      element={!user ? <Login /> : verifyOption('DEOP0004') && <NewsletterType /> }
                      />
                  <Route path="/Newsletter" 
                      element={!user ? <Login /> : verifyOption('DEOP0005') && <Newsletter /> }
                      />
                  <Route path="/PendingSignatures" 
                      element={!user ? <Login /> : verifyOption('DEOP0006') && <PendingSignatures /> }
                      />
                  <Route path="/Signature/:key" 
                      element={!user ? <Login /> : verifyOption('DEOP0006') && <Signature /> }
                      />
                  <Route path="/manageprofileoptions" 
                      element={!user ? <Login /> : verifyOption('DEOP0007') && <ManageProfileOptions /> }
                      />
                  <Route path="/manageposition" 
                      element={!user ? <Login /> : verifyOption('DEOP0018') && <ManagementProfile /> }
                      />
                  <Route path="/manageemployee" 
                      element={!user ? <Login /> : verifyOption('DEOP0019') && <ManagementEmployee /> }
                      />
                  <Route path="/manageairport" 
                      element={!user ? <Login /> : verifyOption('DEOP0020') && <ManagementAirport /> }
                      />  
                  <Route path="/compensation" 
                      element={!user ? <Login /> : verifyOption('DEOP0021') && <Compensation /> }
                      />
                  <Route path="/positionhomologationinform" 
                    element={!user ? <Login /> : verifyOption('DEOP0022') && <PositionHomologationInform /> }
                    />
                  <Route path="/positionhomologationengrana" 
                    element={!user ? <Login /> : verifyOption('DEOP0023') && <PositionHomologationEngrana /> }
                    />
                  <Route path="/positionHomologationReadOnly" 
                    element={!user ? <Login /> : verifyOption('DEOP0024') && <GroupHomologationReadOnly /> }
                    />
                  <Route path="/UserQueryTalmaID" 
                      element={!user ? <Login /> : verifyOption('DEOP0008') && <UserQueryTalmaID /> }
                      />
                  <Route path="/ShiftManagement" 
                      element={!user ? <Login /> : verifyOption('DEOP0009') && <ShiftManagement /> }
                      />
                  <Route path="/ShiftSegments/:sId" 
                      element={!user ? <Login /> : verifyOption('DEOP0009') && <ShiftSegments /> }
                      />
                  <Route path="/ShiftAbbreviation" 
                      element={!user ? <Login /> : verifyOption('DEOP0010') && <ShiftAbbreviation /> }
                      />
                  <Route path="/ShiftHistory" 
                      element={!user ? <Login /> : verifyOption('DEOP0011') && <ShiftHistory /> }
                      />
                   <Route path="/Assessment" 
                      element={!user ? <Login /> : verifyOption('DEOP0012') && <Assessment /> }
                      />
                  <Route path="/ShiftImport" 
                      element={!user ? <Login /> : verifyOption('DEOP0013') && <ShiftImport /> }
                      />
                  <Route path="/ShiftCalendar" 
                      element={!user ? <Login /> : <ShiftCalendar /> }
                      />
                  <Route path="/EmployeeAbsencePermissions" 
                      element={!user ? <Login /> : verifyOption('DEOP0015') && <EmployeeAbsencePermissions /> }
                      />
                  <Route path="/ShiftReadOnly" 
                      element={!user ? <Login /> : verifyOption('DEOP0016') && <ShiftReadOnly /> }
                      />
                  <Route path="/ShiftAbbreviationReadOnly" 
                      element={!user ? <Login /> : verifyOption('DEOP0025') && <ShiftAbbreviationReadOnly /> }
                      />
              </Routes>
        </Main>
      </Box>
    </>
  );
}

  
  export default Dashboard;