import axios from 'axios';
import { SERVICE_URLS } from './constants';
import { processErrorMessage } from './functions';

export const getToken = () => {
    return sessionStorage.getItem('token');
}

export const login = async (user, password, isRecoverPassword) => {

    const parameters = {
        Password: password,
        Login: user,
        IsRecoverPassword: isRecoverPassword
    }

    try {
        const response = await axios.post(
            SERVICE_URLS.ROOT + 'Security/GetToken',
            JSON.stringify(parameters), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        
        if (response.data.employeeId) {
            //console.log(response.data);
            var date = new Date();
            date.setDate(date.getDate() + 1);

            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('user', JSON.stringify(response.data));
            sessionStorage.setItem('tokenExpiration', date);

            if (response.data.token === null){
                return ({
                    message: response.data.errorMessage,
                });
            }else{
                return ({
                    message: 'Ok',
                });
            }

            
        }
        else {
            return ({
                message: processErrorMessage(response.data.errorMessage || ''),
            });
        }
    } catch (error) {

        return ({
            message: error,
        });
    }
}

export const logout = () => {
    sessionStorage.clear();
    window.location = "/";
}

export const getUser = () => {
    if (sessionStorage.getItem('user')) {
        return JSON.parse(sessionStorage.getItem('user'));
    }

    return null;
}