import React, { useState, useEffect } from 'react';
import SegmentsReadOnly from './shift-management-segments-readonly';

import {
    IconButton,
    Typography,
    Grid,
    Tooltip,
    Link
  } from "@mui/material";
  import {
    Segment
  } from '@mui/icons-material';
  
import {
    getShifts,
    getAllAirports,
  } from '../logic';
  import BackDrop from '../../../shared/backdropDE';
import DataTable from '../../../shared/data-table';
import ErrorSnackbar from '../../error-snackbar';

const ShiftReadOnly = () => {
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showModalSegments, setShowModalSegments] = useState(false);
    const [reloadEffect, setReloadEffect] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [shiftDescription, setShiftDescription] = useState('');

    useEffect(() => {
        async function fetchData() {
          if (loading) {
            try {
              const data = await getShifts();

              setData(data);
              setLoading(false);
            } catch (error) {
              setError(error.message);
              setLoading(false);
              setData([]);
            }
          }
        }
        fetchData();
        return () => {};
      }, [reloadEffect]);

      const handleActionButton = async (e, shiftId, description) => {
        e.stopPropagation();

        setId(shiftId);
        setShiftDescription(description);

        setShowModalSegments(true);
      }

      const columns = [
        {
          field: "Segments",
          headerName: "Segm",
          width: 60,
          sortable: false,
          renderCell: (cellValues) => {
            return <Link
              component="span"
              onClick={(e) => { handleActionButton(e, cellValues.row.shiftId, cellValues.row.code) }}
            >
              <Tooltip title="Ver Segmentos del Turno">
                <IconButton
                  color="success"
                >
                  <Segment />
                </IconButton>
              </Tooltip>
            </Link>
          }
        },
        { field: 'code', headerName: 'Código', maxwidth: 100, flex: 1,
          renderCell: (cellValues) => {
            return <Link
              component="button"
              onClick={(e) => { handleActionButton(e, cellValues.row.shiftId) }}
            >
              {cellValues.value}
            </Link>
          }
        },
        { field: 'descriptionShift', headerName: 'Descripción', minwidth: 350, flex: 1 },
        {
            field: 'isEnabled', headerName: 'Habilitado', width: 100,
            valueGetter: (params) => (params.value) ? 'SI' : 'NO'
        },
        { field: 'externalShiftCode', headerName: 'External', width: 100 },
      ];

      if (loading) { return ( <BackDrop /> ); }
      if (error) { return (<ErrorSnackbar message={error} />); }

    return (
        <>
            {showModalSegments && <SegmentsReadOnly
                shiftId={id}
                handleCloseModal={setShowModalSegments}
                shiftDescription={shiftDescription}
            />}
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                    Consulta de <span style={{ color: '#00b0ef' }}>Turnos</span>
                    </Typography>
                </Grid>
            </Grid>
            <DataTable columns={columns} rows={data} />
        </>
      );
};

export default ShiftReadOnly;