import { getNewsletterTypes, getNewsletterType, saveNewsletterType, deleteNewsletterType, findNewsletterByTypeId } from '../../shared/data-services/newslettertype-service';

export async function getAllNewsletterTypes (){
  const data = await getNewsletterTypes();
  return data || [ ];
};

export async function getSelectedNewsletterType (id){
  const data = await getNewsletterType(id);
  return data;
};

export async function saveSelectedNewsletterType (id, name){
  const newsletterType = {
    id, 
    name
  }

  const data = await saveNewsletterType(newsletterType);
  return data;
};

export async function deleteSelectedNewsletterType (id){
  const data = await deleteNewsletterType(id);
  return data;
};

export async function NewsletterByTypeId (typeId){
  const data = await findNewsletterByTypeId(typeId);
  return data;
};