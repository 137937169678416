import { getShiftHistory } from '../../shared/data-services/shift-service';
import { callAPI } from '../../shared/network';

export async function getData(params){
    var data = await getShiftHistory(params);

    var dataReal = [];

    data.forEach(element => {
        var item = {
            shiftCode: element.shift.code,
            shiftDate: new Date(element.date + ' 00:00:00'),
            shiftBackGroundColor: element.color,
            shiftAbbreviation: element.shiftAbbreviation.abbreviation,
            shiftAbbreviationTypeId: element.shiftAbbreviation.shiftAbbreviationTypeId,
            shift: element
        };

        dataReal.push(item);
    });

    return dataReal;
}

export async function getDataEventFormat(params){

    var data = await getData(params);

    var eventData = [];

    data.forEach(element => {
        var item = { 
            title: element.shiftCode, 
            start: element.shiftDate,
            extendedProps: {
                abbreviation: element.shiftAbbreviation,
                color: element.shiftBackGroundColor,
                abbreviationTypeId: element.shiftAbbreviationTypeId,
                data: element.shift
            },
            textColor: 'black',
            display: 'auto'
        };

        eventData.push(item);
    });

    return eventData;

}

export async function getPendingNewslettersByEmployee (employeeId,handleError){
  
    const data = await callAPI(
       null, 
             'Newsletter/getPendingNewslettersByEmployeeId?employeeId='+ employeeId, 'get',handleError);
    
    return data || [];
 };