export const SERVICE_URLS = {
    ROOT: process.env.REACT_APP_BACKEND_URL,
}

export const BUTTONS = {
    SAVE: 'Guardar',
    LOGIN: 'Entrar',
    CHANGE_PASSWORD_USER: 'Cambiar clave',
};

export const TITLES = {
  DRAWER: '',
  OVERTIME: 'Remuneración de trabajo nocturno y suplementario',
  WORKSHIFT: 'Mis marcaciones',
  POLYVALENCE: 'Mis cumplimientos',
  POLYVALENCE_POLYVALENCE: 'Detalle Polivalencia',
  MESSAGE_CHANGE_PASSWORD_LINK: 'Hemos enviado un enlace a tu correo electrónico para el cambio de clave obligatorio, ya que es tu primer ingreso.',
  MESSAGE_RECOVER_PASSWORD_LINK: 'Hemos enviado un enlace a tu correo electrónico para la recuperación de la clave.',
  CUSTOMERS: 'Aerolíneas',
  NEWSLETTER_TYPE: 'Tipo circulares',
  NEWSLETTER: 'Circulares',
  SIGNATURE: 'Mis circulares',
  SIGNATURE_COLECT: 'Recolección circulares',
  EXIT: 'Salida del sistema',
  MANAGE_PROFILE_OPTIONS: 'Administración de opciones de perfil',
  USER_QUERY_TALMA_ID: 'Consulta de usuario',
  //MARK_NEWSLETTER_AS_COMPLETE: 'He recibido correctamente la información suministrada en este boletín, por favor hacer clic en el botón para confirmar.',
  MARK_NEWSLETTER_AS_COMPLETE: `Declaro haber entendido la información recibida y me comprometo a utilizarla correctamente en 
  mis funciones, asumiendo las consecuencias de no reportar cualquier duda a mis superiores con el ánimo de aclarar cualquier 
  vacío para la mejora continua del ejercicio de mis funciones`,
  // MARK_NEWSLETTER_AS_COMPLETE: `Como empleado de LASA Sociedad de Apoyo Aeronáutico S.A. (Talma), confirmo que he leído y entendido la información recibida y me 
  // comprometo a usarla correctamente en mi trabajo. Asumo cualquier responsabilidad por las consecuencias de no comunicar dudas y me comprometo a informar a mis superiores para 
  // aclararlas y mejorar los procesos y mi desempeño.`,
  START_ASSESMENT: 'La circular contiene una evaluación, por favor da clic en el botón para continuar',
  SEND_ASSESMENT: 'Presione el botón para enviar la evaluación',
  RETRY_TOMORROW: 'Debes intentar mañana ya excediste los intentos!',
};

export const ACTIONS = {
  DELETE: 'Borrar',
  EDIT: 'Editar',
  CANCEL: 'Cancelar',
  ADD: 'Agregar',
  NEW: 'Nuevo',
  SAVE: 'Guardar',
  EDIT_SOURCES: 'Editar objetivos',
  VIEW_STATE: 'Ver estado',
  VIEW_ASSESSMENT_STATE: 'Ver estado evaluación',
  FILTER: 'Aplicar filtro',
  SIGN: 'Firmar',
  CLEAR: 'Limpiar',
  ACTIVATE: 'Activar',
  DEACTIVATE: 'Desactivar',
  ACCEPT: 'Aceptar',
  IMPORT: 'Importar',
  CONTINUE: 'Continuar',
  VIEW_NEWS_LETTER: 'Ver circular',
  START_ASSESTMENT: 'Iniciar Evaluación',
  CLOSE: 'Cerrar',
  MARK_NEWSLETTER_AS_COMPLETE: 'Confirmar',
  START_ASSESMENT: 'Iniciar Evaluación',
  SEND_ASSESMENT: 'Enviar',
  DOWNLOAD_DETAIL: 'Descargar detalle',
  VIEW_SORT_QUESTIONS: 'Ordenar Preguntas',
  EDIT_ASSESSMENT: 'Editar Evaluacion',
};

export const MESSAGES = {
  SAVED: 'Datos guardados',
  DELETE: 'Datos borrados',
  ALERT: 'Alerta',
  CONFIRM_EXIT: '¿Está seguro que desea salir del sistema?',
  ACTIVATED: 'Activado',
  DEACTIVATED: 'Desactivado',
  CONFIRM_DELETE: '¿Está seguro que desea borrar el registro?'
};