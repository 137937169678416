import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { Spanish } from '../../../../shared/grid-translations';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../../shared/constants';

const ManagementPositionDetail = ({
  data,
  handleClose,
}) => {
  const [selectedCodes, setSelectedCodes] = useState(data || []);

  const codeColumns = [
    { field: 'code', headerName: 'Código', flex: 1},
  ];
  //console.log(data);
  // useEffect(() => {
  //   const combinedCodes = (data || []).map((item, index) => {
  //     const menuItem = menuItems.find(menuItem => menuItem.code === item.code);
  //     //onsole.log(`Buscando: ${code}, Encontrado:`, menuItem);
  //     return {
  //       id: index, // DataGrid espera una propiedad 'id' única para cada fila
  //       code: menuItem ? `${item.code} - ${menuItem.name}` : `${item.code} - Nombre no encontrado`
  //     };
  //   });
  //   //console.log(combinedCodes);
  //   setSelectedCodes(combinedCodes);
  // }, [data]);

  return (
    <div   >
      <Dialog
        width="450px"
        fullWidth="md"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalle Cargo
        </DialogTitle>
        <DialogContent>
          <p />
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataGrid style={{ height: 300, width: '100%' }} columns={codeColumns} rows={selectedCodes} localeText={{ ...Spanish }} componentsProps={{
                  pagination: {
                    labelRowsPerPage: ('Filas por página')
                  }
                }}
                sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
                    },
                  }} />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              endIcon={<CancelIcon />}
              variant="outlined"
              onClick={() => { handleClose(false) }} autoFocus>{ACTIONS.CLOSE}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagementPositionDetail;
