import { getEmployeesByAirport, getUserFaceFeature, saveNewUser, updateUser } from '../../shared/data-services/talmaid-service';
import { getProfile, updateProfilesByPosition, getAllProfilesByEmployeePosition, getAllPermissionsByProfile } from '../../shared/data-services/management-profile-service';


export async function getemployeesByAirport(AirportId){
  const data = await getEmployeesByAirport(AirportId);
  return data;
}

export async function getAllProfiles(){
  const data = await getProfile();
  return data || [];
};

export async function GetUserFaceFeature(IdentificationNumber){
  const data = await getUserFaceFeature(IdentificationNumber);
  return data;
}

export async function SaveNewUser(model){
  const data = await saveNewUser(model);
  return data;
}

export async function UpdateUser(model){
  const data = await updateUser(model);
  return data;
}