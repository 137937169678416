import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListSubheader
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { Spanish } from '../../../../shared/grid-translations';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../../shared/constants';
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils';
import { useSnackbar } from 'notistack';

const ManagementProfileDetail = ({
  data,
  handleClose,
  handleSave,
  profileId,
  profile,
  permissions,
  action
}) => {
  const [selectedprofileId, setSelectedprofile] = useState(profileId);
  const [selectedCodes, setSelectedCodes] = useState(data || []);
  const [selectedCodesSend, setSelectedCodesSend] = useState({
    permissions: [], 
    profileId: selectedprofileId, 
  });
  const [selectedCode, setSelectedCode] = useState(null);
  const [perfilId, setPerfilId] = useState(data?.perfilId || '');
  const isNameReadOnly = action !== ACTIONS.NEW;
  const [profileValue, setProfileValue] = useState(action === ACTIONS.NEW ? '' : profile);
  const [validationErrors, setValidationErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  //console.log('oe ' + isNameReadOnly);


  useEffect(() => {
    if (action === ACTIONS.NEW) {
      setProfileValue('');
      setSelectedCode(null);
      setPerfilId('');
      setSelectedCodes([]);
      setSelectedCodesSend({ permissions: [], profileId: null });
    }
  }, [action]);

  const handleProfileChange = (e) => {
    setProfileValue(e.target.value); // Actualiza el valor con el input del usuario
  };

  // Actualiza selectedCodesSend cada vez que selectedCodes o selectedProfileId cambia
  // useEffect(() => {
  //   setSelectedCodesSend({
  //     permissions: selectedCodes.map(({ description, id }) => ({
  //       Description: description,
  //       Id: id,
  //     })),
  //     profileId: selectedProfileId,
  //   });
  // }, [selectedCodes, selectedProfileId]);

const handleAddprofiles = (e) => {
  e.stopPropagation();

  if (!selectedCode) return;

  let newSelectedCodes = [...selectedCodes];

  // Encuentra el menú padre del submenú seleccionado, si existe.
  if (selectedCode.menuType === 'Submenu' && selectedCode.parentCode) {
    const parentMenu = permissions.find(perm => perm.code === selectedCode.parentCode);
    // Verifica si el menú padre ya está seleccionado, si no, agrégalo.
    if (parentMenu && !newSelectedCodes.some(code => code.code === parentMenu.code)) {
      newSelectedCodes.push({
        id: parentMenu.id,
        description: parentMenu.description,
        code: parentMenu.code,
        profileId: selectedprofileId,
        profileName: profileValue
      });
    }
  }

  // Verifica si el submenú ya está seleccionado.
  if (newSelectedCodes.some(el => el.code === selectedCode.code)) {
    return;
  }

  // Agrega el submenú seleccionado.
  const newProfile = {
    id: selectedCode.id,
    description: selectedCode.description,
    code: selectedCode.code,
    profileId: selectedprofileId,
    profileName: profileValue
  };

  newSelectedCodes.push(newProfile);

  setSelectedCodes(newSelectedCodes);
  setSelectedCodesSend({
    ...selectedCodesSend,
    permissions: newSelectedCodes.map(({ description, code, id }) => ({
      Description: description,
      Code: code,
      Id: id
    })),
    profileId: selectedprofileId, // Asegúrate de que esto corresponde al ID del perfil actualmente seleccionado
    profileName: profileValue,
    action: action === ACTIONS.NEW ? 'CREAR' : 'MODIFICAR'
  });
};

const onSave = () => {
  let errors = {};
  if (profileValue.trim() === '') {
    errors.name = "El nombre del perfil es requerido"; // Esto es solo un ejemplo, idealmente usarías algo como Material-UI Snackbars o un estado para mostrar el error.
    setValidationErrors(errors);
    enqueueSnackbar(errors.name, { variant: "error" });
    return Object.keys(errors).length === 0;
   
  }

  // Estructurar el objeto selectedCodesSend para el guardado
  const newSelectedCodesSend = {
    permissions: selectedCodes.map(({ description, code, id }) => ({
      Description: description,
      Code: code,
      Id: id
    })),
    profileId: selectedprofileId,
    profileName: profileValue,
    action: selectedCodes.length > 0 ? 'MODIFICAR' : 'CREAR' // Si no hay códigos seleccionados, asumir acción 'CREAR'
  };
  //console.log('Lo del save')
  //console.log(newSelectedCodesSend);

  // Llamar a handleSave con el nuevo objeto selectedCodesSend
  handleSave(newSelectedCodesSend);
  
};

  const handleDelete = (e, cellValues) => {
    e.stopPropagation();
    //console.log('Antes del delete');
    //console.log(selectedCodes);
    const updatedSelectedCodes = selectedCodes.filter(el => el.id !== cellValues.row.id);
  
    // Aquí debes actualizar el estado con una función callback para asegurarte de que estás utilizando el estado más reciente.
    setSelectedCodes(updatedSelectedCodes);
    //console.log('Despues del delete');
    //console.log(selectedCodes);
    // setSelectedCodesSend(() => ({
    //   ...selectedCodes,
    //   permissions: updatedSelectedCodes.map(({ description, code, id }) => ({
    //     Description: description,
    //     Code: code,
    //     Id: id
    //   })),
    // }));
  };

  const codeColumns = [
    { field: 'code', headerName: 'Code', flex: 1, hide: true},
    { field: 'description', headerName: 'Descripción', minWidth: 100, flex: 1 },
    {
      field: "EditSources",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="error"
          onClick={(e) => { handleDelete(e, cellValues) }}
        >
          <Delete />
        </IconButton>;
      }
    },
  ];

  return (
    <div   >
      <Dialog
        maxWidth="md"
        fullWidth="md"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalle Perfil
        </DialogTitle>
        <DialogContent>
          <p />
          <>
            <TextField
              label="Perfil"
              fullWidth
              value={profileValue}
              onChange={handleProfileChange}
              required
              InputProps={{
                readOnly: isNameReadOnly,
              }}
              variant="outlined"
            />
            <p />

            <Typography variant="h6" gutterBottom>
              Permisos
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
              <FormControl fullWidth={true} margin="dense">
                <InputLabel id="state-select-label">Permiso</InputLabel>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Select
                      labelId="state-select-label"
                      label="Permiso"
                      fullWidth
                      required
                      value={perfilId}
                      onChange={(e) => {
                        //console.log('si entra al onchage');
                        const selectedProfile = permissions.find(profile => profile.code === e.target.value);
                        setPerfilId(e.target.value); // Actualiza perfilId con el ID del perfil seleccionado
                        setSelectedCode(selectedProfile || null); // Guarda el objeto de perfil completo
                      }}
                    >
                    {/* {permissions.map((permiso) => (
                      <MenuItem key={permiso.code} value={permiso.code}>
                        <strong>{permiso.menuType}:</strong> {permiso.description}
                      </MenuItem>
                    ))} */}
                    {permissions.map((permiso) => {
                      if (permiso.menuType === 'Menu') {
                        return (
                          <ListSubheader key={permiso.code} style={{ background: 'none', pointerEvents: 'none' }}>
                            {permiso.description}
                          </ListSubheader>
                        );
                      } else {
                        return (
                          <MenuItem key={permiso.code} value={permiso.code} style={{ paddingLeft: '20px' }}>
                            {permiso.description}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      color="primary"
                      disabled={!selectedCode}
                      onClick={(e) => { handleAddprofiles(e) }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </FormControl>
              </Grid>
            </Grid>
            <p />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataGrid style={{ height: 300, width: '100%' }} columns={codeColumns} rows={selectedCodes} localeText={{ ...Spanish }} componentsProps={{
                  pagination: {
                    labelRowsPerPage: ('Filas por página')
                  }
                }} 
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
                  },
                }} />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={onSave}
              endIcon={<SaveIcon />}
            >
              {ACTIONS.SAVE}
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              endIcon={<CancelIcon />}
              variant="outlined"
              onClick={handleClose}>{ACTIONS.CANCEL}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagementProfileDetail;
