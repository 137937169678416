import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
  Tooltip,
  Link
} from "@mui/material";

import {
  getShifts,
  getAllAirports,
  getShiftType,
  saveNewShift,
  deleteShift,
  saveUpdateShift
} from './logic';

import {
  Edit,
  AddCircle,
  DeleteOutline,
  Segment
} from '@mui/icons-material';

import BackDrop from '../../shared/backdropDE';
import DataTable from '../../shared/data-table';
import ShiftEdition from './shift-management-edition';
import ErrorSnackbar from '../error-snackbar';
import { useSnackbar } from 'notistack';
import NewsShiftDetails from './shift-management-detail';
import AlertDialogQuestion from '../alert-dialog-question';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import { getUser } from '../../shared/auth-service';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';

const ShiftManagement = () => {
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reloadEffect, setReloadEffect] = useState(false);
  const [data, setData] = useState(null);
  const [typeData, setTypeData] = useState([]);
  const [airports, setAirports] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(null);
  const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const user = getUser();


  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getShifts();
          const types = await getShiftType();
          const airports = await getAllAirports();

          setAirports(airports);
          setTypeData(types);
          setData(data);
          // await RegisterUsabilityLog('Turnos', user.employeeId);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
    return () => {};
  }, [reloadEffect]);

  const navigate = useNavigate();

  const handleSave = async (id, code, externalShiftCode, descriptionShift, shiftType, shiftAssignment) => {
      setLoading(true);
      const codeAssignment = shiftAssignment.map((iata) => {
        const findAirport = airports.find(airport => airport.code === iata);
        return findAirport ? { airportId: findAirport.id } : false;
      });

      setShowModal(false);
      const newId = await saveNewShift(code, externalShiftCode, descriptionShift, shiftType, codeAssignment);
      enqueueSnackbar(MESSAGES.SAVED);
      const redirectToLogin = () => { navigate(`/ShiftSegments/${newId}`); };
      redirectToLogin();
  };

  const handleDelete = async () => {
    setAlertQuestionOpen(false);
    setLoading(true);
    await deleteShift(idDelete);
    setReloadEffect(!reloadEffect);
    enqueueSnackbar(MESSAGES.DELETE);
  };

  const handleDeleteData = async (cellValues) => {
    setAlertQuestionOpen(true);
    setIdDelete(cellValues.row.shiftId);
  };

  const handleCloseAlert = () => {
    setAlertQuestionOpen(false);
    setIdDelete(null);
  };

  const handleNew = async () => {
    setSelectedItem({
        id: id,
        Code: '',
        DescriptionShift: '',
        ShiftType: 0,
        typeData: typeData,
        airports: airports
    });
    setId(0);
    setShowModal(true);
  };

  const handleDataEdit = async (cellValues) => {
    const extra = {
      typeData,
      airports
    };
    setSelectedItem({...cellValues.row, ...extra });
    setId(cellValues.row.id);
    setShowModalEdit(true);
  };

  const handleEdit = async (dataEdit) => {
    const codeAssignment = dataEdit.shiftAssignment.map((iata) => {
      const findAirport = airports.find(airport => airport.code === iata);
      return findAirport ? { airportId: findAirport.id } : null;
    });
    dataEdit.shiftAssignment = codeAssignment;

    setShowModalEdit(false);
    setLoading(true);
    await saveUpdateShift(dataEdit);
    setReloadEffect(!reloadEffect);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const columns = [
    {
      field: "Segments",
      headerName: "Segm",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <Link
          component="span"
          onClick={(e) => { navigate(`/ShiftSegments/${cellValues.row.shiftId}`); }}
        >
          <Tooltip title="Ver Segmentos del Turno">
            <IconButton
              color="success"
            >
              <Segment />
            </IconButton>
          </Tooltip>
        </Link>
      }
    },
    {
      field: "Editar",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleDataEdit(cellValues) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "Borrar",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          aria-label="delete"
          color="error"
          onClick={(e) => { handleDeleteData(cellValues) }}
        >
          <DeleteOutline />
        </IconButton>;
      }
    },
    { field: 'code', headerName: 'Código', maxwidth: 100, flex: 1,
      renderCell: (cellValues) => {
        return <Link
          component="button"
          onClick={(e) => { navigate(`/ShiftSegments/${cellValues.row.shiftId}`); }}
        >
          {cellValues.value}
        </Link>
      }
    },
    { field: 'descriptionShift', headerName: 'Descripción', minwidth: 350, flex: 1 },
    { field: 'shiftType', headerName: 'TipoTurno', flex: 1, minwidth: 250,
      valueGetter: (type) => typeData[type.value].description
    },
    {
        field: 'isEnabled', headerName: 'Habilitado', width: 100,
        valueGetter: (params) => (params.value) ? 'SI' : 'NO'
    },
    { field: 'externalShiftCode', headerName: 'External', width: 100 },
    // {
    //     field: 'isActive', headerName: 'Estado', width: 100,
    //     valueGetter: (params) => (params.value) ? 'ACTIVO' : 'INACTIVO'
    // },
  ];

  if (loading) { return ( <BackDrop /> ); }
  if (error) { return (<ErrorSnackbar message={error} />); }

  return (
    <>
      {showModal && <NewsShiftDetails
          data={selectedItem}
          handleClose={setShowModal}
          handleSave={handleSave}
      />}

      {showModalEdit && <ShiftEdition
          data={selectedItem}
          handleClose={setShowModalEdit}
          handleEdit={handleEdit}
          airports={airports}
      />}

      {alertQuestionOpen && <AlertDialogQuestion
          handlePositive={handleDelete}
          handleNegative={handleCloseAlert}
          textButtonPositive={ACTIONS.ACCEPT}
          textButtonNegative={ACTIONS.CANCEL}
          dialogContentText={MESSAGES.CONFIRM_DELETE}
          dialogTitle={""}
          open={alertQuestionOpen}
          elemento={""}
      />}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Administración <span style={{ color: '#00b0ef' }}>Turnos</span>
          </Typography>
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Tooltip title="Crear Nuevo Turno">
                <Button
                  endIcon={<AddCircle />}
                  variant="contained"
                  onClick={() => { handleNew() }}
                  autoFocus
                >
                  {ACTIONS.NEW}
                </Button>
            </Tooltip>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data} />
    </>
  );
};

export default ShiftManagement;
