import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Autocomplete,
  CircularProgress
} from "@mui/material";
import {
    getemployeesByAirport,
    getallemployeesByAirport,
    getallemployeesByAirportForEdit
} from '../logic';
import { DataGrid } from '@mui/x-data-grid';
import { Spanish } from '../../../../shared/grid-translations';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../../shared/constants';
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { ThirtyFpsSelectSharp } from '@mui/icons-material';

const PositionHomologationInformDetail = ({
  handleClose,
  handleSave,
  handleDelete,
  data,
  listData,
  idData,
  action
}) => {
  const [name, setName] = useState(data.name);
  const id = idData || data.id;
  const [isDisabled, setIsDisabled] = useState(false);
  const [nameError, setNameError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(action == ACTIONS.DELETE) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [action]);

  const onSave = () => {
    const trimmedName = name ? name.trim() : '';
    if (!trimmedName) {
      setNameError('El nombre es obligatorio.');
    } else if (listData.some(item => item.name === trimmedName)) {
      enqueueSnackbar('Ya existe este nombre de grupo.', { variant: 'warning' });
    } else {
      setNameError('');
      handleSave(id, trimmedName);
    }
  };

  return (
    <div   >
      <Dialog
        maxWidth="sm"
        fullWidth="sm"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalle Grupo Cargo Inform
        </DialogTitle>
        <DialogContent>
          <p />
          <TextField
            label="Nombre"
            placeholder="Nombre"
            onKeyUp={(e) => setName(e.target.value.toUpperCase())}
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            type='text'
            disabled={isDisabled}
            fullWidth
            required
            error={!!nameError}
            helperText={nameError}
          />
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box>
            {action !== ACTIONS.DELETE &&
              <Button
                color="primary"
                variant="contained"
                onClick={onSave}
                endIcon={<SaveIcon />}>
                {ACTIONS.SAVE}
              </Button>}
          </Box>
          <Box>
            {action === ACTIONS.DELETE &&
              <Button
                color="error"
                variant="contained"
                onClick={() => { handleDelete(id) }}
                endIcon={<Delete />}>
                {ACTIONS.DELETE}
              </Button>}
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              onClick={() => { handleClose(false) }}
              endIcon={<CancelIcon />}
              autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PositionHomologationInformDetail;
