export const SHIFTS = {
    minHours: 72,
    abbreviateType: [2,4]
};

export const headersExportShiftsHistory = [
    {//Documento
        v: "Documento",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            }
        }
    },
    {//Nombre Completo
        v: "Nombre Completo",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            }
        }
    },
    {//Base
        v: "Base/Aeropuerto",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            }
        }
    },
    {//Area
        v: "Area",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            }
        }
    },
    {//1
        v: "1",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//2
        v: "2",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//3
        v: "3",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//4
        v: "4",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//5
        v: "5",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//6
        v: "6",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//7
        v: "7",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//8
        v: "8",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//9
        v: "9",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//10
        v: "10",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//11
        v: "11",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//12
        v: "12",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//13
        v: "13",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//14
        v: "14",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//15
        v: "15",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//16
        v: "16",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//17
        v: "17",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//18
        v: "18",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//19
        v: "19",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//20
        v: "20",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//21
        v: "21",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//22
        v: "22",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//23
        v: "23",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//24
        v: "24",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            }
        }
    },
    {//25
        v: "25",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//26
        v: "26",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//27
        v: "27",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//28
        v: "28",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//29
        v: "29",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//30
        v: "30",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    },
    {//31
        v: "31",
        t: "s",
        s: {
            font: {
                sz: 12,
                bold: true
            },
            alignment: {
                horizontal: "center"
            }
        }
    }
];