import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
} from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../shared/constants';
const NewsletterTypeDetails = ({
  handleClose,
  handleSave,
  handleDelete,
  data,
  action
}) => {
  const [name, setName] = useState(data.name);
  const { id } = data;
  const [isDisabled, setIsDisabled] = useState(false);


  useEffect(() => {
    if(action == ACTIONS.DELETE) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [action]);

  return (
    <div   >
      <Dialog
        maxWidth="sm"
        fullWidth="sm"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalle Tipo Circular
        </DialogTitle>
        <DialogContent>
          <p />
          <TextField
            label="Nombre"
            placeholder="Nombre"
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            type='text'
            disabled = {isDisabled}
            fullWidth
            require
          />
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box>
            {action !== ACTIONS.DELETE &&
              <Button
                color="primary"
                variant="contained"
                onClick={() => { handleSave(id, name) }}
                endIcon={<SaveIcon />}>
                {ACTIONS.SAVE}
              </Button>}
          </Box>
          <Box>
            {action === ACTIONS.DELETE &&
              <Button
                color="error"
                variant="contained"
                onClick={() => { handleDelete(id) }}
                endIcon={<Delete />}>
                {ACTIONS.DELETE}
              </Button>}
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              onClick={() => { handleClose(false) }}
              endIcon={<CancelIcon />}
              autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NewsletterTypeDetails;