import * as React from 'react';
import MessageSnackbar from '../components/message-snackbar';
import { logout } from './auth-service';

class ErrorBoundary extends React.Component {

    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
       //logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        

        // You can render any custom fallback UI
        return <div>
                  <MessageSnackbar message="Ocurrio un error en el servidor reintente o cominuquese con TI"/>
                  {this.props.children}
              </div>
        //return this.props.children; 
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;