import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Typography,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    FilledInput,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    CircularProgress,
    Snackbar,
    FormHelperText,
    Autocomplete,
    TextField,
    Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  ButtonGroup,
  IconButton,
  Icon
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Cancel,
    DeleteOutline,
    DragIndicator,
    TaskAlt,
    RadioButtonUnchecked,
    Check,
    Close
} from '@mui/icons-material';
import DataTable from '../../../../shared/data-table';
import BackDrop from '../../../../shared/backdropDE';

import {
    getSegments,
    getSegmentType
  } from './logic';

const SegmentsReadOnly = ({
    shiftId,
    handleCloseModal,
    shiftDescription
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [typeSegment, setSegmentType] = useState([]);
    const [order, setOrder] = useState(1);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
          if (loading) {
            try {
                const data = await getSegments(shiftId);
                const typeSegment = await getSegmentType();

                setData(data);
                setSegmentType(typeSegment);
                // setOrder(data.length);
                // await RegisterUsabilityLog('Segmentos - Turnos', user.employeeId);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
                setData([]);
            }
          }
        }
        fetchData();
        return () => {};
      });

    if (loading) {
    return (<BackDrop />);
    }

    return (
        <div>
             <Dialog
                maxWidth="xl"
                fullWidth="xl"
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                        Segmentos del turno <span style={{ color: '#00b0ef' }}>{shiftDescription}</span>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12} style={{ backgroundColor: '#f7f7f7', minHeight: '60vh' , padding: '5px'}} >
                        {
                        data.map((item) => (
                        <Card xs={12} sm={12} md={3} sx={{ m:2 }} key={item.shiftSegmentId}>
                            <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: '#1a3072' }} aria-label={item.shiftSegmentId}>{item.order}</Avatar>
                            }
                            title={item.descriptionSegment}
                            subheader={item.segmentType.description}
                            />
                            <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                <Typography>
                                    Entrada
                                </Typography>
                                    <Table sx={{}} aria-label="table" size="small">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Hora</TableCell>
                                        <TableCell align="center">{item.startTime}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    <TableRow>
                                        <TableCell>Marcación</TableCell>
                                        <TableCell align="center">
                                        {item.startMark ? (
                                            <Icon style={{ color: 'lightgreen' }}>
                                            <TaskAlt />
                                            </Icon>
                                        ) : (
                                            <Icon style={{ color: 'red' }}>
                                            <RadioButtonUnchecked />
                                            </Icon>
                                        )}
                                        </TableCell>
                                    </TableRow>
                                    </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={4}>
                                <Typography>
                                    Salida
                                </Typography>
                                    <Table sx={{}} aria-label="table" size="small">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Hora</TableCell>
                                        <TableCell align="center">{item.endTime}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    <TableRow>
                                        <TableCell>Marcación</TableCell>
                                        <TableCell align="center">
                                        {item.endMark ? (
                                            <Icon style={{ color: 'lightgreen' }}>
                                            <TaskAlt />
                                            </Icon>
                                        ) : (
                                            <Icon style={{ color: 'red' }}>
                                            <RadioButtonUnchecked />
                                            </Icon>
                                        )}
                                        </TableCell>
                                    </TableRow>
                                    </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={4}>
                                <Typography>&nbsp;</Typography>
                                    <Table aria-label="table" size="small">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Duración</TableCell>
                                        <TableCell align="center">{item.duration}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    <TableRow>
                                        <TableCell>Día Adicional</TableCell>
                                        <TableCell align="center">
                                        {item.dayMore ? (
                                            <Icon style={{ color: 'green' }}>
                                            <Check />
                                            </Icon>
                                        ) : (
                                            <Icon style={{ color: 'red' }}>
                                            <Close />
                                            </Icon>
                                        )}
                                        </TableCell>
                                    </TableRow>
                                    </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Typography variant="caption">
                                {item.observation}
                            </Typography>
                            </CardContent>
                        </Card>
                        ))}
                        </Grid>
                </DialogContent>
                <DialogActions sx={{ m: 3 }}>
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={() => { handleCloseModal(false) }}
                            endIcon={<Cancel />}
                            autoFocus>
                            CERRAR
                    </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SegmentsReadOnly;