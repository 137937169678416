import { login, recoverPassword } from '../../shared/auth-service';

export async function validateUser(user, password, isRecoverPassword, setMessage, setOpen) {

  var validationMessage = '';

  if (isRecoverPassword) {
    if (user === '' || user === null)
      validationMessage = 'Ingrese su usuario';
  }
  else {
    if (user === '' || user === null && password === '' || password === null)
      validationMessage = 'Ingrese su usuario y clave';
    else if (user === '' || user === null)
      validationMessage = 'Ingrese su usuario';
    else if (password === '' || password === null)
      validationMessage = 'Ingrese su clave';
  }

  if (validationMessage !== '') {
    setMessage(validationMessage);
    setOpen(true);
    return;
  }

  const result = await login(user, password, isRecoverPassword);

  if (result) {
    if (result.message === 'Ok') {
      window.location = "/";
      return;
    }

    setMessage(result.message);
    setOpen(true);
  }
};