import axios from 'axios';

export async function getUserData(){
    const getResponse = await axios.get(
        'https://alamano.saitlm.com.co/login/userdata',
        { 
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }, {validateStatus: () => true}             
    ).catch(function (error) {
        //console.log('Error', error);
    });
    return getResponse.data;
}
