import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  CircularProgress,
  Snackbar,
  OutlinedInput
} from "@mui/material";

import { Save, Cancel } from '@mui/icons-material';
import { ACTIONS } from '../../../shared/constants';
import { GetCheckAbbreviationExistence, GetCheckShiftCodeExistence } from '../logic';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ShiftAbbreviationDetail = ({
  handleClose,
  handleSave,
  handleEdit,
  data,
  action
}) => {
  const [shiftAbbreviationId] = useState(data.shiftAbbreviationId);
  const [abbreviation, setAbbreviation] = useState(data.abbreviation);
  const [description, setDescription] = useState(data.description);
  const [shiftAbbreviationTypeId, setShiftAbbreviationTypeId] = useState(data.shiftAbbreviationTypeId);
  const [startTime, setStartTime] = useState(data.startTime);
  const [endTime, setEndTime] = useState(data.endTime);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const [severety, setSeverety] = useState('');
  const [checkingAbbreviation, setCheckingAbbreviation] = useState(false);
  const [existAbbreviation, setExistAbbreviation] = useState(false);

  const handleStartTimeChange = (event) => {
    const { value } = event.target;
    setStartTime(value);
  };

  const handleEndTimeChange = (event) => {
    const { value } = event.target;
    setEndTime(value);
  };

  function OpenMessage(message, severety) {
    setMessage(message);
    setSeverety(severety);
    setOpen(true);
  };

  const handleOnBlurAbbreviateChange = async (event) => {
    setCheckingAbbreviation(true);
    const code = event.target.value;
    //console.log(code);
    let exist = false;

    if (code !== ''){
        exist = await GetCheckAbbreviationExistence(code);
        if (exist){
          OpenMessage('Este abreviado ya existe', 'error');
        }else if (code.includes('_')){
          OpenMessage('El código no puede llevar guión abajo (_)', 'error');
          exist = true;
        } else if (code.includes(' ')){
          OpenMessage('El código no puede llevar espacios', 'error');
          exist = true;
        }
    }

    setExistAbbreviation(exist);
    //console.log(exist);
    setCheckingAbbreviation(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
  };

  const validateForm = () => {
    return !(abbreviation && !existAbbreviation);
  }

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
              {(shiftAbbreviationId === 0) ? 'Nuevo': 'Editar' } <span style={{ color: '#00b0ef' }}>Abreviado</span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <p />
          <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth={true} margin="dense">
                <InputLabel htmlFor="outlined-adornment-Código">Código</InputLabel>
                <OutlinedInput
                        label="Código"
                        value={abbreviation || ''}
                        onChange={(e) => setAbbreviation(e.target.value)}
                        onBlur={(e) => handleOnBlurAbbreviateChange(e)}
                        type='text'
                        fullWidth
                        require
                        error={existAbbreviation}
                        disabled={checkingAbbreviation}
                        endAdornment={
                          <InputAdornment position="end">
                              {checkingAbbreviation && <CircularProgress size={20}/>}
                          </InputAdornment>
                        }
                      />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth={true} margin="dense">
                <InputLabel id="select-abbrtype">Tipo de Abreviado</InputLabel>
                <Select
                  label="Tipo de Abreviado"
                  labelId="select-abbrtype"
                  value={shiftAbbreviationTypeId || 1}
                  onChange={(e) => setShiftAbbreviationTypeId(e.target.value)}
                >
                  {data.typeData.map((option) => (
                    <MenuItem key={option.abbreviationTypeId} value={option.abbreviationTypeId}>{option.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth={true} margin="dense">
              <TextField
                      label="Descripción"
                      placeholder="Descripción Abreviado"
                      value={description || ''}
                      onChange={(e) => setDescription(e.target.value)}
                      type='text'
                      require
                    />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
              <FormControl fullWidth={true} margin="dense">
              <TextField
                      label="Hora Inicio"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      value={startTime || false}
                      onChange={handleStartTimeChange}
                      type='time'
                    />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth={true} margin="dense">
              <TextField
                      label="Hora Final"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      value={endTime || false}
                      onChange={handleEndTimeChange}
                      type='time'
                    />
              </FormControl>
            </Grid>
          </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box>
          {action === ACTIONS.NEW && 
            <Button
              color="primary"
              variant="contained"
              disabled = {validateForm()}
              onClick={() => { handleSave({shiftAbbreviationId, abbreviation, description, shiftAbbreviationTypeId, startTime, endTime}) }}
              endIcon={<Save />}>
              {ACTIONS.SAVE}
            </Button>
          }
          {action === ACTIONS.EDIT && 
            <Button
              color="primary"
              variant="contained"
              disabled = {validateForm()}
              onClick={() => { handleEdit({shiftAbbreviationId, abbreviation, description, shiftAbbreviationTypeId, startTime, endTime}) }}
              endIcon={<Save />}>
              {ACTIONS.SAVE}
            </Button>
          }
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              onClick={() => { handleClose(false) }}
              endIcon={<Cancel />}
              autoFocus>
              {ACTIONS.CANCEL}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={severety} sx={{ width: '100%' }}>
              {message}
          </Alert>
      </Snackbar>
    </div>
  );
}

export default ShiftAbbreviationDetail;