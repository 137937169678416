import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';
import {
    Card,
    List,
    CardContent,
    Typography,
    Divider,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getEmployeeOverTime } from './logic';
import { cardStyles } from './styles.js';
import { FormatDateToStringComplete, FormatCurrency, CreateGuid } from '../../shared/functions';
import { getUser } from '../../shared/auth-service';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';
import { RegisterUsabilityLog } from '../../shared/data-services/ModuleUsabilityLog-service';
// import  PendingNewsletters  from '../pending-newsletter';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Overtime = () => {
    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    // const [news, setNews] = useState(null);
    const [error, setError] = useState(null);

    // const refresh = () => {
    //     setLoading(true);
    // }

    useEffect(() => {
        async function fetchData() {
            if (loading) {
                try {
                        // const news = await getPendingNewslettersByEmployee(user.employeeId);
                        const data = await getEmployeeOverTime(user.employeeId);
                        setLoading(false);
                        setData(data);
                        // setNews(news || []);                        
                        // await RegisterUsabilityLog('HorasExtras', user.employeeId);

                    } catch (error) {
                    setError(error.message);
                    setLoading(false);
                }
            }

        }
        fetchData();
    });

    if (loading) {
        return (
            <BackDrop />
        );
    }

    
    // if (news && news.length > 0 ) {
    //     return  (<PendingNewsletters data={news} refresh={refresh} />);
    // }


    if (!data && error) {
        return (<ErrorSnackbar message={error} />);
    }
    const period = data.length > 0 ? data[0].overtimePeriod : null;

    let shiftArray = [];

    data.forEach(element => {
        if (shiftArray.indexOf(element.dateOvertime) === -1) {
            shiftArray.push(element.dateOvertime);
        }
    });

    return (
        <>
            <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                Remuneración de trabajo <span style={{ color: '#00b0ef' }}>nocturno y suplementario</span>
            </Typography>
            <Box sx={{ m: 1 }}>
                <h3>{`Periodo: ${period || ''}`}</h3>
            </Box>
            <List>
                {shiftArray.map((item) => {

                    const getItems = (dateOvertime) => {
                        const detailItems = data.filter(function (dataItem) {
                            return dataItem.dateOvertime === dateOvertime;
                        })
                        return detailItems;
                    }

                    const detailItems = getItems(item);
                    const shiftDescription = detailItems[0].shiftDescription;
                    const dateOvertime = FormatDateToStringComplete(detailItems[0].dateOvertime);
                    const initialValue = 0;
                    const sumWithInitial = detailItems.reduce(
                        (accumulator, item) => accumulator + item.totalAmount,
                        initialValue
                    );

                    if (isMobile) {
                        return (
                            <Box >
                                <Card styles={cardStyles} >
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Fecha {dateOvertime}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component="div">
                                            Turno {shiftDescription}
                                        </Typography>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow key={CreateGuid()}>
                                                        <TableCell align="left">Concepto</TableCell>
                                                        <TableCell align="right">Unidad</TableCell>
                                                        <TableCell align="right">Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {detailItems.map(({ payrollConcept, units, totalAmount }) => (
                                                        <TableRow
                                                            key={CreateGuid()}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {payrollConcept}
                                                            </TableCell>
                                                            <TableCell align="right">{units}</TableCell>
                                                            <TableCell align="right">{FormatCurrency(totalAmount)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <TableRow
                                                        key={CreateGuid()}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >

                                                        <TableCell colSpan="3">
                                                            <Typography align="right">Total {FormatCurrency(sumWithInitial)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </Card>
                                <Divider light />
                            </Box>
                        );
                    }
                    else {

                        return (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    {dateOvertime}
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow key={CreateGuid()}>
                                                <StyledTableCell>Turno</StyledTableCell>
                                                <StyledTableCell align="right">Concepto</StyledTableCell>
                                                <StyledTableCell align="right">Unidades</StyledTableCell>
                                                <StyledTableCell align="right">Total</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {detailItems.map(({ payrollConcept, units, totalAmount, shiftDescription }) => (
                                                <StyledTableRow key={CreateGuid()}>
                                                    <StyledTableCell>{shiftDescription}</StyledTableCell>
                                                    <StyledTableCell>{payrollConcept}</StyledTableCell>
                                                    <StyledTableCell align="right">{units}</StyledTableCell>
                                                    <StyledTableCell align="right">{FormatCurrency(totalAmount)}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                            <StyledTableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell colSpan="3">
                                                    <Typography gutterBottom variant="h6" component="div">Total</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Typography gutterBottom variant="h6" component="div">{FormatCurrency(sumWithInitial)}</Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>);
                    };
                }
                )}
            </List>
        </>
    );
}

export default Overtime;