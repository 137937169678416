import { getAssessments, getAssessment, saveAssessment,getAllQuestionTypes  } from '../../shared/data-services/assessment-service';

export async function getListAssessment (){
  const data = await getAssessments();
  return data || [ ];
};

export async function  getAssessmentsById (id){
  const data = await getAssessment(id);
  return data || [ ];
};

export async function upsertAssessment ( assessment ){
  
  const data = await saveAssessment(assessment);
  return data;
};


export async function getQuestionTypes (){
  const data = await getAllQuestionTypes();
  return data || [ ];
};
