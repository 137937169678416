import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Paper,
    FormControl,
    TextField,
    Link,
} from "@mui/material";
import { paperStyles, loginStyles, linkStyles } from './styles.js';
import { BUTTONS, MESSAGES } from '../../shared/constants';
import { validateUser} from './logic.js';
import AlertDialog from '../alert-dialog';

const Login = () => {
  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClickOpen = () => {
    setLoading(false);
    setOpen(true);
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Previene la acción por defecto del enter
      switch(field) {
        case 'login':
          document.getElementById('password-input').focus();
          break;
        case 'password':
          setLoading(true);
          validateUser(login, password, false, setMessage, setOpen);
          break;
        default:
          break;
      }
    }
  };

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      {open && <AlertDialog
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        dialogContentText={message}
        dialogTitle={MESSAGES.ALERT}
        open={open}
      />}
      <Paper elevation={10} style={paperStyles} >
        <FormControl fullWidth={true} margin="dense">
          <img src="./logo.png" alt="logo" style={loginStyles} />
          <TextField
            label="Usuario"
            placeholder="Usuario"
            value={login || ''}
            onChange={e => setLogin(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, 'login')}
            fullWidth
          />
          <p />
          <TextField
            id='password-input'
            label="Clave"
            placeholder="Clave"
            value={password || ''}
            type='password'
            onChange={e => setPassword(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, 'password')}
            fullWidth
          />
          <p />
          <LoadingButton
            fullWidth
            loading={loading}
            color="primary"
            variant="contained"
            onClick={() => {
              setLoading(true);
              validateUser(login, password, false, setMessage, setOpen);
            }}
          >
            {BUTTONS.LOGIN}
          </LoadingButton>
          <Link
            style={linkStyles}
            component="button"
            variant="body2"
            onClick={() => {
              setLoading(true);
              validateUser(login, '', true, setMessage, setOpen);
            }}
          >
            ¿Olvidó su Clave?
          </Link>
        </FormControl>
      </Paper>
    </>
  );
};

export default Login;
