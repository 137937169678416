import React, { useState, useEffect } from 'react';
import SegmentsReadOnly from '../../shift-management/shift-management-readonly';

import {
    IconButton,
    Typography,
    Grid,
    Tooltip,
    Link
  } from "@mui/material";
  import {
    Segment
  } from '@mui/icons-material';
  
import {
    getPositionHomologation,
    getAllAirports,
  } from './logic';
  import BackDrop from '../../../shared/backdropDE';
import DataTable from '../../../shared/data-table';
import ErrorSnackbar from '../../error-snackbar';

const GroupHomologationReadOnly = () => {
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showModalSegments, setShowModalSegments] = useState(false);
    const [reloadEffect, setReloadEffect] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [shiftDescription, setShiftDescription] = useState('');

    useEffect(() => {
        async function fetchData() {
          if (loading) {
            try {
              const data = await getPositionHomologation();

              setData(data);
              setLoading(false);
            } catch (error) {
              setError(error.message);
              setLoading(false);
              setData([]);
            }
          }
        }
        fetchData();
        return () => {};
      }, [reloadEffect]);

      const handleActionButton = async (e, shiftId, description) => {
        e.stopPropagation();

        setId(shiftId);
        setShiftDescription(description);

        setShowModalSegments(true);
      }

      const columns = [
        {
            field: 'id',
            headerName: 'Id',
            minWidth: 350,
            flex: 1,
            hide: true
        },
        {
            field: 'nombreCargoHeinsohn',
            headerName: 'Cargo Heinsonh',
            minWidth: 350,
            flex: 1,
            valueGetter: (params) => params.row.nombreCargoHeinsohn || '-'
          },
          {
            field: 'nombreCargoInform',
            headerName: 'Cargo Inform',
            minWidth: 350,
            flex: 1,
            valueGetter: (params) => params.row.nombreCargoInform || '-'
          },
          {
            field: 'nombreCargoEngrana',
            headerName: 'Cargo Engrana',
            minWidth: 350,
            flex: 1,
            valueGetter: (params) => params.row.nombreCargoEngrana || '-'
          }
        ];

      if (loading) { return ( <BackDrop /> ); }
      if (error) { return (<ErrorSnackbar message={error} />); }

    return (
        <>
            {showModalSegments && <SegmentsReadOnly
                shiftId={id}
                handleCloseModal={setShowModalSegments}
                shiftDescription={shiftDescription}
            />}
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                    Consulta de Homologación de <span style={{ color: '#00b0ef' }}>Cargos</span>
                    </Typography>
                </Grid>
            </Grid>
            <DataTable columns={columns} rows={data} 
                sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
                }
                }}/>
        </>
      );
};

export default GroupHomologationReadOnly;