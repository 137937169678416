import React, { useState, useEffect } from 'react';

import BackDrop from '../../shared/backdropDE';
import ErrorSnackbar from '../error-snackbar';
import MuiAlert from '@mui/material/Alert';
import * as FileSaver from 'file-saver';
import XLSXStyle from 'sheetjs-style';
import moment from 'moment';
import { getUser } from '../../shared/auth-service';

import {
    getColumns,
    getData,
    getAllAirports,
    getMonthsView,
    getDataExport
  } from './logic';

import {
    Grid,
    Select,
    OutlinedInput,
    CircularProgress,
    Tooltip,
    Typography,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
    Box,
    Snackbar,
    Divider,
    FormControlLabel,
    Switch
  } from "@mui/material";
import Fab from '@mui/material/Fab';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BadgeIcon from '@mui/icons-material/Badge';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DataTable from '../../shared/data-table';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ShiftHistory = () => {
    const user = getUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [employeeId, setEmployeeId] = useState('');
    const [startDate, setStartDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0));
    const [base, setBase] = useState('');
    const [monthsView, setMontsView] = useState('');
    const [loadingRows, setLoadingRows] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [abbreviate, setAbbreviate] = useState(true);
    
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [severetySnackBar, setSeveretySnackBar] = useState(false);
    const [messageSnackBar, setMessageSnackBar] = useState(false);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const [columns, setcolumns] = useState([]);
    const [data, setData] = useState([]);
    const [airports, setAirports] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          if (loading) {
            try {
              // Eliminamos la declaración duplicada de fetchData
              
      
              var MonthsView = await getMonthsView(startDate, endDate); // Obtener la vista de meses
              var data = await getData(QueryFilter()); // Obtener los datos según el filtro de consulta
              var dataColumns = await getColumns(data); // Obtener las columnas para los datos
      
              // Configuración adicional del estado basado en la posición del empleado (comentado)
              // var temp = user.employeePosition.employeePositionOptions.find((x) => x.code === 'DEOP0009');
              // if(temp !== undefined){
              //     setAirports(airportsData);
              // }else{
              //     setAirports([user.airport]);
              // }

              var airportsData = [...user.airports];
              setAirports(airportsData); // Actualiza el estado con los datos de aeropuertos del usuario

              setMontsView(MonthsView); // Actualiza el estado con la vista de meses
              setcolumns(dataColumns); // Actualiza el estado con las columnas de datos
              setData(data); // Actualiza el estado con los datos
              setLoading(false); // Indica que la carga de datos ha terminado
            } catch (error) {
              setError(error.message); // Establece un mensaje de error en caso de que ocurra uno
              setLoading(false); // Indica que la carga de datos ha terminado (también en caso de error)
            }
          }
        };
      
        fetchData(); // Ejecuta la función fetchData
      }, [loading, user.airports, startDate, endDate, getMonthsView, getData, QueryFilter, getColumns]); // Lista de dependencias para `useEffect`
    
    const [successReload, setSuccessReload] = React.useState(false);
    const timer = React.useRef();
    const buttonSx = {
        ...(successReload && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const [successExport, setSuccessExport] = React.useState(false);
    const buttonSxExport = {
        ...(successExport && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleChangeStartDate = async (e) => {
        setStartDate(e);
        var MonthsView = await getMonthsView(e, endDate);
        setMontsView(MonthsView);

    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const handleChangeEndDate = async (e) => {
        setEndDate(e);
        var MonthsView = await getMonthsView(startDate, e);
        setMontsView(MonthsView);
        //console.log(MonthsView);
    };

    const handleReloadQuery_ORI = async () => {
        if (!loadingRows) {

            setSuccessReload(false);
            setLoadingRows(true);

            var MonthsView = await getMonthsView(startDate, endDate);
            setMontsView(MonthsView);

            var dataColumns = await getColumns(startDate, endDate);

            var data = await getData(QueryFilter());

            setcolumns(dataColumns);
            setData(data);

            setSuccessReload(true);
            setLoadingRows(false);

            timer.current = window.setTimeout(() => {
                setSuccessReload(false);
            }, 2000);
        }
    };

    const handleReloadQuery = async () => {
        if (!loadingRows) {
            setSuccessReload(false);
            setLoadingRows(true);

            var MonthsView = await getMonthsView(startDate, endDate);
            setMontsView(MonthsView);

            var data = await getData(QueryFilter());
            var dataColumns = await getColumns(data);

            setcolumns(dataColumns);
            setData(data);

            setSuccessReload(true);
            setLoadingRows(false);

            timer.current = window.setTimeout(() => {
                setSuccessReload(false);
            }, 2000);
        }
    };

    const handleAbbreviatedChange = (event) => {
        setAbbreviate(event.target.checked);
    };


    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    function QueryFilter(){
        var paramsQuery = '';

            if(employeeId !== '' && employeeId != null){
                paramsQuery = 'Employee=' + employeeId;
            }

            if(base !== '' && base != null){
                paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'AirportId=' + base;
            }

            if(startDate !== '' && startDate !== null){
                paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'StartDate=' + formatDate(startDate);
            }

            if(endDate !== '' && endDate !== null){
                paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'EndDate=' + formatDate(endDate);
            }

            if(abbreviate){
                paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'Abbreviate=1';
            }else{
                paramsQuery = (paramsQuery !== '' ? paramsQuery + '&' : '') + 'Abbreviate=0';
            }

            paramsQuery = paramsQuery + '&' + 'employeeId=' + user.employeeId;

            paramsQuery = paramsQuery !== '' ? '?' + paramsQuery : paramsQuery;

        return paramsQuery;
    }

    function OpenMessage(message, severety) {
        setMessageSnackBar(message);
        setSeveretySnackBar(severety);
        setOpenSnackBar(true);
    };

    const handleExport = async () => {
        setLoadingExport(true);

        let rows = await getDataExport(QueryFilter());

        const ws = XLSXStyle.utils.aoa_to_sheet(rows);
        
        var fileName = "ReporteHistorico_" + moment(new Date()).format('YYYYMMDDHHmmss');

        const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
        const excelBuffer = XLSXStyle.write(wb, {bookType: 'xlsx', type: 'array'});
        const dataB = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(dataB, fileName + fileExtension);

        setSuccessExport(true);
        setLoadingExport(false);

        timer.current = window.setTimeout(() => {
            setSuccessExport(false);
        }, 2000);

        OpenMessage("Excel "+ fileName + " exportado correctamente", "success");

    }

    const handleExport_V2 = async () => {
        setLoadingExport(true);

        const rows = await getDataExport(QueryFilter());

        const wb = XLSXStyle.utils.book_new();
        const ws = XLSXStyle.utils.aoa_to_sheet(rows);

        for (const cellAddress in ws) {
            if (ws.hasOwnProperty(cellAddress)) {
                const cell = ws[cellAddress];
                const cellRef = XLSXStyle.utils.decode_cell(cellAddress);
            
                if (cellRef.r === 0) {
                    cell.s = {
                    font: { bold: true },
                    alignment: { horizontal: 'center' },
                    };
                }
            }
        }

        XLSXStyle.utils.book_append_sheet(wb, ws, 'data');

        var fileName = "ReporteHistorico_" + moment(new Date()).format('YYYYMMDDHHmmss');
        const excelBuffer = XLSXStyle.write(wb, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(blob, fileName + fileExtension);

        setSuccessExport(true);
        setLoadingExport(false);

        timer.current = window.setTimeout(() => {
            setSuccessExport(false);
        }, 2000);

        OpenMessage("Excel "+ fileName + " exportado correctamente", "success");
    }

    if (loading) {
        return (<BackDrop />);
    }

    if (error) {
        return (<ErrorSnackbar message={error} />);
    }

    const getRowId = (row) => row.employeeId;

    return (
        <>    
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{ color: '#00B0EF', fontWeight: 'bold', fontSize: '24px' }}>
                        Historial de <span style={{ color: '#1A3072' }}> Turnos</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h7" style={{ color: '#00b0ef' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                        {monthsView.map(item => {
                            return (<span key={`${item.Year}-${item.Month}`} style={{ color: '#1a3072' }}> {monthsView.length > 12 ? item.Year + '-' : ''}{item.Month} </span>);
                        })}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                        label="Fecha Desde"
                        inputFormat="DD/MM/YYYY"
                         value={startDate}
                        onChange={(e) => { setStartDate(e); handleChangeStartDate(e) }}
                        renderInput={(params) => <TextField size='small' sx={{ mb: 1}}{...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                        label="Fecha Hasta"
                        inputFormat="DD/MM/YYYY"
                        value={endDate}
                        onChange={(e) => {setEndDate(e); handleChangeEndDate(e)}}
                        renderInput={(params) => <TextField size='small' sx={{ mb: 1}}{...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <Tooltip title="Filtrar por base/aeropuerto" placement="top">
                        <FormControl fullWidth sx={{ mb: 1 }}>
                            <InputLabel id="demo-simple-select-label" size="small"
                            >Base</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Airport"
                                size="small"
                                onChange={(e) => { setBase(e.target.value) }}
                            >
                                {airports.map(item => {
                                    return (<MenuItem key={item.id} value={item.id}>{item.code}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <Tooltip title="Filtrar por número de documento">
                        <FormControl sx={{ mb: 1, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-searchByDocumento" size="small">Documento</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-searchByDocumento"
                                type={'text'}
                                size="small"
                                onChange={(e) => { setEmployeeId(e.target.value) }}
                                onKeyUp={(e) => { }}
                                endAdornment={

                                    <BadgeIcon />
                                }
                                label="Documento"
                            />
                        </FormControl>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Filtrar">
                            <Box sx={{ mb: 1, mr: 1, position: 'relative' }}>
                                <Fab
                                    size="small"
                                    aria-label="save"
                                    color="primary"
                                    sx={buttonSx}
                                    onClick={handleReloadQuery}
                                >
                                    {successReload ? <CheckIcon /> : <FilterAltIcon />}
                                </Fab>
                                {loadingRows && (
                                    <CircularProgress
                                        size={50}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: -4.9,
                                            left: -5,
                                            zIndex: 1,
                                        }}
                                    />
                                )}

                            </Box>
                        </Tooltip>
                        <Divider orientation="vertical"  flexItem sx={{m:1}}/>
                        <Tooltip title="Exportar a Excel">
                            <Box sx={{ mb: 1, mr: 1, position: 'relative' }}>
                                <Fab
                                    size="small"
                                    aria-label="save"
                                    color="primary"
                                    sx={buttonSxExport}
                                    onClick={handleExport}
                                >
                                    {successExport ? <DownloadDoneIcon /> : <DownloadIcon />}
                                </Fab>
                                {loadingExport && (
                                    <CircularProgress
                                        size={50}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: -4.9,
                                            left: -5,
                                            zIndex: 1,
                                        }}
                                    />
                                )}

                            </Box>
                        </Tooltip>
                        <Tooltip title="Incluír los abreviados en la información">
                            <Box>
                                <FormControl margin="dense">
                                    <FormControlLabel
                                    control={
                                        <Switch 
                                        checked={abbreviate} 
                                        onChange={handleAbbreviatedChange}
                                        />
                                    }
                                    label="Abreviados"
                                    labelPlacement="start"
                                    />
                                </FormControl>
                            </Box>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>

            <DataTable columns={columns} rows={data}  getRowId={getRowId} />

            <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={severetySnackBar} sx={{ width: '100%' }}>
                    {messageSnackBar}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ShiftHistory;
