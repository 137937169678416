import { getAirports } from '../../shared/data-services/airport-service';
import { getUserByAirport, updateState } from '../../shared/data-services/talmaid-service';

export async function getAllAirports (){
    const data = await getAirports();
    return data;
};

export async function getUsersByAirport(AirportId){
    const data = await getUserByAirport(AirportId);
    return data;
}

export async function UpdateState(UserId, State){
    const data = await updateState(UserId, State);
    return data;
}



