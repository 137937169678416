import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Chip,
  Stack,
  Tooltip,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tabs,
  Tab,
  IconButton,
  InputBase,
  Divider,
  TextField,
  ButtonGroup,
  Badge
} from "@mui/material";

import {
  getSegments,
  getAbbreviation,
  getEmployeeRequired,
  saveShiftRequest,
  getChangeRequired,
  approveShiftRequest
} from './logic';

import {
  Cancel,
  Login,
  Logout,
  Check,
  PinDrop,
  WorkspacePremium,
  Person,
  AutoStories,
  Search,
  Info,
  TaskAlt,
  ErrorOutline,
  PublishedWithChanges,
  EventAvailable,
  Send,
  WarningAmber,
  Message,
  Today
} from '@mui/icons-material';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { ACTIONS } from '../../../shared/constants';
import BackDrop from '../../../shared/backdropDE';
import { SHIFTS } from '../../../shared/constants-shifts';

const ShiftDetail = ({
  handleClose,
  data
}) => {
//console.log('data :>> ', data);
  const [segments, setSegments] = useState([]);
  const [abbreviate, setAbbreviate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadChange, setLoadChange] = useState(false);
  const [loadWait, setLoadWait] = useState(false);

  // prepare changes
  const [activeTab, setActiveTab] = useState(0);
  const [isChange, setIsChange] = useState(true);
  const [dataChangeActive, setDataChangeActive] = useState({});
  const [changeActive, setChangeActive] = useState(false);

  // send employeeRequest
  const [employeeRequiredId, setEmployeeRequiredId] = useState('');
  const [date, setDate] = useState(data.date);
  const [airport, setAirport] = useState(data.airport.code);

  // send change
  const [shiftHistoryId, setShiftHistoryId] = useState(data.shiftHistoryId);
  const [shiftHistoryChangeId, setShiftHistoryChangeId] = useState(0);
  const [observations, setObservations] = useState('');

  // visual controller
  const [isMakeRequest, setIsMakeRequest] = useState({"status": false});
  const [isResponseEmployee, setIsResponseEmployee] = useState({
    active: false,
    status: 0,
    title: "Título",
    message: "Mensaje informativo para usuario"
  });

  const [isResponse, setIsResponse] = useState({
    active: false,
    status: 0,
    title: "Atención",
    message: "Mensaje informativo para usuario solicitud",
    data: {}
  });

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const segm = await getSegments(data.shift.shiftId);

          if(data.shiftAbbreviation.abbreviation != null){
            const abbr = await getAbbreviation(data.shiftAbbreviation.abbreviation);
            setAbbreviate(abbr);
          }

          if(data.changeActive){
            const chgActive = await getChangeRequired(0, data.shiftHistoryId);
            if(chgActive.length > 0){
              setChangeActive(true);
              setDataChangeActive(chgActive[0]);
            }
            setIsChange(false);
          }

          setSegments(segm);
          setLoadChange(true);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setSegments([]);
        }
      }
    }
  
    fetchData();
    return () => {};
  });

  // variables
  const dataAbbr = [
    {color: "", icon: ""},
    {color: "#0288D1", icon: <PinDrop />},
    {color: "#D32F2F", icon: <AutoStories />},
    {color: "#ED6C02", icon: <WorkspacePremium />},
    {color: "#2E7D32", icon: <Person />},
  ];

  const color = data.color === "" ? '#1A3072': data.color;
  const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado','Domingo'];
  const monthNames = [
    "","Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const myDate = new Date(data.date);
  const dateParts = data.date.split("-");
  const mes = monthNames[parseInt(dateParts[1])];

  const colorChange = [
    {bgColor: "#E8F5E9", color: "#56CA00", icon: <TaskAlt sx={{ color: '#56CA00' }} fontSize="large" /> }, // success
    {bgColor: "#FCE4EC", color: "#E53935", icon: <ErrorOutline sx={{ color: '#E53935' }} fontSize="large" /> }, // error
    {bgColor: "#FFECD4", color: "#F3A23C", icon: <WarningAmber sx={{ color: '#F3A23C' }} fontSize="large" /> }, // warning
    {bgColor: "#E1F5FE", color: "#1E88E5", icon: <PublishedWithChanges sx={{ color: '#1E88E5' }} fontSize="large" /> }, // pendiente por responder
  ];

  // handles
  const handleTabClick = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSearch = async (search) => {
    setLoadWait(true);
    let employeeRequired = await getEmployeeRequired({...search, ...{employeeId: data.employee.employeeId}});
    if(employeeRequired.status){

      // airport validation
      if(data.airport.id != employeeRequired.airport.id){
        setIsMakeRequest({"active": false});
        setIsResponseEmployee({
          active: true,
          status: 1,
          title: "Atención",
          message: "El colaborador al que deseas solicitar cambio no está en tu misma base o aeropuerto.",
        });
        setLoadWait(false);
        return false;
      }

      // abbreviate validation
      if([2, 4].includes(employeeRequired.shiftAbbreviation.shiftAbbreviationTypeId)){
        setIsMakeRequest({"active": false});
        setIsResponseEmployee({
          active: true,
          status: 1,
          title: "Atención",
          message: "El colaborador con el que necesitas cambiar el turno tiene asignada una capacitación o un día de no asistencia.",
        });
        setLoadWait(false);
        return false;
      }

      const segm = await getSegments(employeeRequired.shift.shiftId);
      employeeRequired = {...employeeRequired, "segments": segm};

      setShiftHistoryChangeId(employeeRequired.shiftHistoryId);
      setIsResponseEmployee({"active": false});
      setIsMakeRequest(employeeRequired);

    } else {
      setIsMakeRequest({"active": false});
      setIsResponseEmployee({
        active: true,
        status: 1,
        title: "Atención",
        message: "Verifica el número de identificación del colaborador o asegúrate de que tenga un turno registrado.",
      });
    }
    setLoadWait(false);
  };

  const handleSend = async (send) => {
    setLoadWait(true);
    const sendChange = await saveShiftRequest(send);
    const valStatus = (sendChange.success) ? 0 : 1;
    const valTitle = (sendChange.success) ? "Exito!" : "Atención";

    setIsResponse({
      active: true,
      status: valStatus,
      title: valTitle,
      message: sendChange.message,
      data: sendChange.data
    });

    setIsMakeRequest({"status": false});
    setIsChange(false);
    setLoadWait(false);
  };

  const handleChangeResponse = async (data) => {
    setLoadWait(true);
    const sendChange = await approveShiftRequest(data);
    const valStatus = (sendChange.data.success) ? 0 : 1;
    const valTitle = (sendChange.data.success) ? "Exito!" : "Atención";

    setChangeActive(false);
    setIsResponse({
      active: true,
      status: valStatus,
      title: valTitle,
      message: sendChange.data.message,
      data: sendChange.data
    });

    setLoadWait(false);
  };

  const handlecheckValidations = async () => {
    const shiftStartTime = (segments.length > 0) ? segments[0].startTime : '00:00:00';
    const shiftStart = `${data.date} ${shiftStartTime}`;

    // dias menores al actual
    const befDays = beforeDays(shiftStart);
    if(!befDays){
      setIsResponseEmployee({
        active: true,
        status: 1,
        title: "Error",
        message: 'No puedes cambiar turno de días anteriores.'
      });
      setIsChange(false);
      return false;
    }

    // ultimo dia del mes
    const lastDays = lastDay(shiftStart);
    if(lastDays){
      setIsResponseEmployee({
        active: true,
        status: 2,
        title: "Atención",
        message: 'No es posible cambiar el turno del último día del mes.'
      });
      setIsChange(false);
      return false;
    }

    // dias tipo TH y CAP
    const AbbType = checkAbbreviationType(data.shiftAbbreviation.shiftAbbreviationTypeId);
    if(!AbbType){
      setIsResponseEmployee({
        active: true,
        status: 1,
        title: "Atención",
        message: 'No puedes cambiar turno de "Descanso/Ausencia" o de Capacitación programada.'
      });
      setIsChange(false);
      return false;
    }

    // 72 horas para cambio
    const diffHours = checkDays(shiftStart);
    if(!diffHours){
      setIsResponseEmployee({
        active: true,
        status: 1,
        title: "Atención",
        message: "No puedes solicitar el cambio de turno, debes realizarlo máximo 72 horas antes."
      });
      setIsChange(false);
      return false;
    }
  };

  if(loadChange){
    handlecheckValidations();
    setLoadChange(false);
  }

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={true}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          <Typography style={{ color: '#00B0EF', fontWeight: 'bold', fontSize: '24px' }}>
            Turno <span style={{ color: '#1A3072' }}> Programado</span>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Card variant="outlined" style={{width:'100%'}}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: color }} aria-label="recipe">
                    {dateParts[2]}
                  </Avatar>
                }
                title = {
                  <Typography variant="h5" display="block">
                    {days[myDate.getDay()]}
                  </Typography>
                }
                subheader = {`${mes} de ${dateParts[0]}`}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  textAlign={'center'}
                  padding={'14px'}
                >
                <Typography variant="h3" component="h3">
                  {data.shift.code}
                </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <TabPanel value={activeTab} index={0}>
          { segments.length > 0 && (
            <Card variant="outlined" sx={{ mt: 2, p:2 }}>
              <Typography variant="subtitle1" style={{ color: '#1a3072' }}>
                Distribución del turno
              </Typography>
              <Grid container>
                {segments.map((item) => (
                  <Paper style={{ width: '100%' }} key={item.shiftSegmentId} sx={{ mt: 2}}>
                    <CardContent style={{ paddingBottom: '16px'}}>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Grid container justifyContent="space-between">
                          <Typography variant="body2">
                            Horario {item.segmentType.description}
                          </Typography>
                          <Typography variant="subtitle2">
                            Duración <span style={{ color: '#1A3072', fontWeight:'bold' }}>{item.duration}</span> hrs.
                          </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ListItem>
                            <ListItemAvatar>
                              <Tooltip title="Ingreso">
                                <Avatar sx={{ bgcolor: '#00B0EF' }}>
                                  <Login />
                                </Avatar>
                              </Tooltip>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                              <Typography variant="h6" component="span">
                                {item.startTime.slice(0, 5)}
                              </Typography>
                              }
                              secondary={
                                item.startMark ? (
                                  <Typography variant="body2" color="text.secondary">
                                    Marcación Biométrica
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">---</Typography>
                                )
                            } />
                          </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ListItem>
                            <ListItemAvatar>
                              <Tooltip title="Salida">
                                <Avatar sx={{ bgcolor: '#00B0EF' }}>
                                  <Logout />
                                </Avatar>
                              </Tooltip>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                              <Typography variant="h6" component="span">
                                {item.endTime.slice(0, 5)}
                              </Typography>
                              }
                              secondary={
                                item.endMark ? (
                                  <Typography variant="body2" color="text.secondary">
                                    Marcación Biométrica
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">---</Typography>
                                )
                            } />
                          </ListItem>
                          {item.dayMore && (
                          <Typography variant="body2" color="text.secondary" align="right">
                            Este turno aplica cambio de día.
                          </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {item.observation != null && item.observation != "" && (
                          <Typography variant="body2">
                            * {item.observation}
                          </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Paper>
                ))}
              </Grid>
            </Card>
          )}

          {abbreviate && (
            <Card variant="outlined" sx={{ mt: 2, p:2 }} style={{borderColor: dataAbbr[abbreviate.shiftAbbreviationTypeId].color}}>
              <Typography variant="subtitle1" style={{ color: dataAbbr[abbreviate.shiftAbbreviationTypeId].color }}>
                {abbreviate.shiftAbbreviationType.description}
              </Typography>

              <Paper style={{ width: '100%' }} key={abbreviate.shiftAbbreviationId} sx={{ mt: 2}}>
                <CardContent style={{ paddingBottom: '16px'}}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: dataAbbr[abbreviate.shiftAbbreviationTypeId].color }}>
                      {dataAbbr[abbreviate.shiftAbbreviationTypeId].icon}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                    <Typography variant="h6" component="span">
                      {abbreviate.abbreviation}
                    </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {abbreviate.description}
                      </Typography>
                  } />
                </ListItem>

                {abbreviate.shiftAbbreviationTypeId == 2 && (
                  <Stack direction="row" spacing={1} sx={{ml: 2}}>
                    <Hidden smDown>
                      <Typography variant="body1" style={{ marginTop: 2 }}>
                        Horario: 
                      </Typography>
                    </Hidden>
                    <Tooltip title="Horario Entrada">
                      <Chip
                        avatar={<Avatar>E</Avatar>}
                        label={
                          <Typography variant="body1">
                            {abbreviate.startTime.slice(0, 5)}
                          </Typography>
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Horario Salida">
                      <Chip
                        avatar={<Avatar>S</Avatar>}
                        label={
                            <Typography variant="body1">
                              {abbreviate.endTime.slice(0, 5)}
                            </Typography>
                        }
                      />
                    </Tooltip>
                  </Stack>
                )}
                </CardContent>
              </Paper>
            </Card>
          )}
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <Card variant="outlined" sx={{ mt: 2, p:2, backgroundColor:'#F9F9F9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
            { isChange && (
              <Paper
                component="form"
                sx={{
                  mb: 2,
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: { xs: '100%', sm: '100%', md: 400 }
                }}
              >
                <IconButton aria-label="person" disabled sx={{ p: '10px' }}>
                  <Person />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Ident. Colaborador Cambio"
                  inputProps={{ 'aria-label': 'identificación colaborador para cambio', name: "nombre" }}
                  value={employeeRequiredId || ''}
                  onChange={(e) => setEmployeeRequiredId(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <InputBase type="hidden" value={date} />
                <InputBase type="hidden" value={airport} />
                <IconButton
                  sx={{ p: '10px' }}
                  type="button"
                  aria-label="search"
                  onClick={(e) => { handleSearch({employeeRequiredId, date, airport}) }}
                >
                  <Search />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                  <Info />
                </IconButton>
              </Paper>
            )}

            { isResponseEmployee.active && (
              <Paper style={{ width: '100%' }} key={1}>
                <Card sx={{ display: 'flex' }}>
                  <Avatar
                    sx={{
                      bgcolor: colorChange[isResponseEmployee.status].bgColor,
                      height: 'auto',
                      width: { xs: '15%', sm: '15%', md: '15%' },
                    }}
                    variant='rounded'
                  >
                    {colorChange[isResponseEmployee.status].icon}
                  </Avatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' , pl: 2 }}>
                      <Typography component="div" variant="h6" sx={{ color: colorChange[isResponseEmployee.status].color }}>
                        {isResponseEmployee.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        {isResponseEmployee.message}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Paper>
            )}

            { isMakeRequest.status && (
              <Paper sx={{ width: '100%' }} key={isMakeRequest.shift.shiftId}>
                  <List sx={{ width: '100%' }}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <Person />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={isMakeRequest.name} secondary={isMakeRequest.employeeRequiredId} />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <EventAvailable />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary = {
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '8px', color: 'darkgray', fontSize: '14px' }}>Turno: </span>
                            <Typography variant="h5" display="block">
                              {isMakeRequest.shift.code}
                            </Typography>
                          </div>
                        }
                        secondary = {
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isMakeRequest.segments[0].startTime.slice(0, 5)} - {isMakeRequest.segments[(isMakeRequest.segments.length - 1)].endTime.slice(0, 5)}
                          </div>
                        }
                      />
                    </ListItem>

                  {(isMakeRequest.shiftAbbreviation.shiftAbbreviationId > 0) && (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: dataAbbr[isMakeRequest.shiftAbbreviation.shiftAbbreviationTypeId].color }}>
                          {dataAbbr[isMakeRequest.shiftAbbreviation.shiftAbbreviationTypeId].icon}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary = {
                            <Typography variant="h5" display="block">
                              {isMakeRequest.shiftAbbreviation.abbreviation}
                            </Typography>                          
                        }
                      />
                    </ListItem>
                  )}
                    <ListItem>
                      <TextField
                        label="Comentarios"
                        variant="standard"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Tu compañero leerá..."
                        aria-label="comentarios"
                        value={observations || ''}
                        onChange={(e) => setObservations(e.target.value)}
                      />
                      <Divider sx={{ height: 28, mx: 1 }} orientation="vertical" />
                      <Button
                        variant="outlined"
                        aria-label="search"
                        startIcon={<Send />}
                        onClick={(e) => { handleSend({shiftHistoryId, shiftHistoryChangeId, observations}) }}
                      >
                        Enviar
                      </Button>
                    </ListItem>
                  </List>
              </Paper>
            )}

            { isResponse.active && (
              <Paper style={{ width: '100%' }} key={1}>
                <Card sx={{ display: 'flex' }}>
                  <Avatar
                    sx={{
                      bgcolor: colorChange[isResponse.status].bgColor,
                      height: 'auto',
                      width: { xs: '15%', sm: '15%', md: '15%' },
                    }}
                    variant='rounded'
                  >
                    {colorChange[isResponse.status].icon}
                  </Avatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' , pl: 2 }}>
                      <Typography component="div" variant="h6" sx={{ color: colorChange[isResponse.status].color }}>
                        {isResponse.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        {isResponse.message}
                      </Typography>

                      { (Object.keys(isResponse.data).length > 0) && (
                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <EventAvailable />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary = {
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span style={{ marginRight: '8px', color: 'darkgray', fontSize: '14px' }}>Turno: </span>
                                  <Typography variant="h5" display="block">
                                    {isResponse.data.shift}
                                  </Typography>
                                </div>
                              }
                              secondary = {
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {isResponse.data.date.slice(0, 10)}
                                </div>
                              }
                            />
                          </ListItem>
                        {isResponse.data.shiftAbbreviation != null && (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: dataAbbr[1].color }}>
                                {dataAbbr[1].icon}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary = {
                                  <Typography variant="h5" display="block">
                                    {isResponse.data.shiftAbbreviation}
                                  </Typography>                          
                              }
                            />
                          </ListItem>
                        )}
                        </List>
                      )}

                    </CardContent>
                  </Box>
                </Card>
              </Paper>
            )}

            { changeActive && (
              <Paper sx={{ width: '100%' }} key={dataChangeActive.shiftChangeRequest.unique}>
                <List sx={{ width: '100%' }}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Person />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={dataChangeActive.employee.name} secondary={dataChangeActive.employee.employeeId} />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <EventAvailable />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary = {
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ marginRight: '8px', color: 'darkgray', fontSize: '14px' }}>Turno: </span>
                          <Typography variant="h5" display="block">
                            {dataChangeActive.shiftHistory.shift.code}
                          </Typography>
                        </div>
                      }
                      secondary = {dataChangeActive.shiftHistory.shift.descriptionShift}
                    />
                  </ListItem>

                {(dataChangeActive.shiftHistory.shiftAbbreviation != null) && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: dataAbbr[2].color }}>
                        {dataAbbr[2].icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary = {
                          <Typography variant="h5" display="block">
                            {dataChangeActive.shiftHistory.shiftAbbreviation.abbreviation}
                          </Typography>
                      }
                    />
                  </ListItem>
                )}

                {(dataChangeActive.shiftChangeRequest.observations.trim() != "") && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Message/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary = {dataChangeActive.shiftChangeRequest.observations.trim()} />
                  </ListItem>
                )}

                  <Divider sx={{ mx: 15 }} />
                  <ListItem sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} >
                    <Button
                      aria-label="check"
                      variant="outlined"
                      color="success"
                      onClick={(e) => { handleChangeResponse({ "approve": true, "shiftChangeRequestId": dataChangeActive.shiftChangeRequest.shiftChangeRequestId }) }}
                    >
                      <Check />
                    </Button>
                    <Divider sx={{ height: 28, mx: 1 }} orientation="vertical" />
                    <Button
                      aria-label="cancel"
                      variant="outlined"
                      color="error"
                      onClick={(e) => { handleChangeResponse({ "approve": false, "shiftChangeRequestId": dataChangeActive.shiftChangeRequest.shiftChangeRequestId }) }}
                    >
                      <Cancel />
                    </Button>
                  </ListItem>
                </List>
              </Paper>
            )}
            </Card>
          </TabPanel>
        </DialogContent>

        <Tabs
          value={activeTab}
          onChange={handleTabClick}
          indicatorColor="primary"
          textColor="primary"
          centered
          >
          <Tab label="Turno" icon={<Today />}/>
          <Tab 
            label="Cambio" 
            icon={
              <Badge color="error" variant={changeActive ? "dot" : ""}>
                <EventRepeatIcon/>
              </Badge>
            }
            style={{ display: 'none' }}
          />
        </Tabs>

        <DialogActions sx={{ m: 1 }}>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="outlined"
              onClick={() => { handleClose(false) }}
              endIcon={<Cancel />}
              autoFocus>
              {ACTIONS.CLOSE}
            </Button>
          </Box>
        </DialogActions>

        {loading && (<BackDrop />)}

        {loadWait && (<BackDrop />)}
      </Dialog>
    </div>
  );
}

var TabPanel = (props) => {
  const { value, index, children } = props;

  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box component="div">
          {children}
        </Box>
      )}
    </div>
  );
}

const checkDays = (chgDate) => {
  const actualDate = new Date();
  const changeDate = new Date(chgDate);

  const diffMs = changeDate - actualDate;
  const diffHours = diffMs / (1000 * 60 * 60);
  const roundHours = diffHours.toFixed(2);

  return (SHIFTS.minHours < roundHours) ? true : false;
}

const lastDay = (chgDate) => {
  const now = new Date(chgDate);
  const lastDayMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return now.getDate() === lastDayMonth.getDate();
};

const checkAbbreviationType = (abbreviationTypeId) => (SHIFTS.abbreviateType.includes(abbreviationTypeId)) ? false : true;

const beforeDays = (chgDate) => new Date() < new Date(chgDate);

export default ShiftDetail;