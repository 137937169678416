import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Paper,
  Grid,
  TextField,
  FormControl,
} from "@mui/material";
import { paperStyles, loginStyles } from './styles.js';
import { BUTTONS } from '../../shared/constants';
import { changePasswordUser } from './logic.js';
import AlertDialog from '../alert-dialog';

const UpdatePassword = () => {
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const { key } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showButton = () => {
    if (!password || !passwordConfirmation) {
      return false;
    }

    if (password.length < 1 || passwordConfirmation.length < 1) {
      return false;
    }
    if (password !== passwordConfirmation) {
      return false;
    }

    return true;
  };

  return (
    <Grid>
      {open && <AlertDialog
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        dialogContentText={message}
        dialogTitle="Alerta"
        open={open}
      />}
      <Paper elevation={10} style={paperStyles} >
        <FormControl fullWidth={true} margin="dense">
          <img src="../logo.png" alt="logo" style={loginStyles} />
          <TextField
            label="Nueva clave"
            placeholder="Nueva clave"
            value={password || ''}
            type='password'
            onChange={e => setPassword(e.target.value)}
            fullWidth
            require />
          <p />
          <TextField
            label="Confirmar clave"
            placeholder="Confirmar clave"
            value={passwordConfirmation || ''}
            type='password'
            onChange={e => setPasswordConfirmation(e.target.value)}
            fullWidth
            require />
          <p />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!showButton()}
            onClick={() => {
              changePasswordUser(
                password,
                setMessage,
                setOpen,
                key);
            }}
          >
            {BUTTONS.CHANGE_PASSWORD_USER}
          </Button>
        </FormControl>
      </Paper>
    </Grid>
  );
};

export default UpdatePassword;
