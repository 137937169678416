import React from 'react';

import {
    Card,
    List,
    CardContent,
    Grid,
    Divider,
    Typography, 
    Button,
    Alert,
} from "@mui/material";
import { ACTIONS } from '../../shared/constants';
import CheckIcon from '@mui/icons-material/Check';


const UserAssessmentFeedBack = (props) => {
    
    const { name,
            assessment,
            message,
            handleClose,
            questions,
            refresh,
        } = props;

    
    const { feedback } =  assessment;
   
    
    return (
        <>
            <Typography variant="h5" style={{color: '#1a3072'}} fontWeight="bold" sx={{ flexGrow: 1, m: 1,  }}>
                Circular <span style={{color: '#00b0ef'}}>{name} </span>
            </Typography>
            <Typography variant="h6" component="div">
                                            {feedback}
            </Typography>
            <Alert severity={message =='¡Has contestado con éxito la evaluación!' ? 'success' : 'error' }>{message}</Alert>
            <List>
                    
                {questions.map((question) => {
                        const {questionText, feedback} = question;
                        return (
                            <>
                               {feedback &&  
                                    <>
                                    <Card  >
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                <b>{questionText}</b>
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                            
                                            </Typography>
                                            <Grid container spacing={3}
                                                rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                > 
                                                <Grid item xs={12}>
                                                    {feedback}
                                                </Grid>
                                            </Grid>        
                                        </CardContent>
                                    </Card>
                                    <Divider light />
                                    </>
                                }
                            </>
                        );
                    }
                )}
            </List>
            <Grid container spacing={12}
                    rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    > 
                    <Grid item xs={12}>
                        <Button 
                        onClick={()=> {refresh()}} 
                        variant="contained"
                        color="info"
                        endIcon={<CheckIcon  />}>
                            {ACTIONS.ACCEPT}
                        </Button>
                    </Grid> 
                </Grid>    
        </>
);
} 


export default UserAssessmentFeedBack;