import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import DataTable from '../../shared/data-table';
import {
  getAllNewsletterTypes,
  getSelectedNewsletterType,
  saveSelectedNewsletterType,
  deleteSelectedNewsletterType,
  NewsletterByTypeId
} from './logic';
import NewsletterTypeDetails from './newsletter-type-detail';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';

const NewsletterType = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [action, setAction] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getAllNewsletterTypes();
          setLoading(false);
          setData(data);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
  });

  useEffect(() => {
    async function fetchRow() {
      if (id) {
        const item = await getSelectedNewsletterType(id);
        setSelectedItem(item);
        setId(null);
        setShowModal(true);
      }
    }
    fetchRow();
  });

  const handleSave = async (id, identification, name) => {
    await saveSelectedNewsletterType(id, identification, name);
    setShowModal(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleDelete = async (id) => {
    var data = await NewsletterByTypeId(id);
    if (data)
      enqueueSnackbar('No se puede eliminar dado a que tiene circulares asociadas', { variant: 'warning' });
    else  {
      await deleteSelectedNewsletterType(id);
      setShowModal(false);
      setLoading(true);
      enqueueSnackbar(MESSAGES.DELETE);
    }
  };

  const handleNew = async (action) => {
    setSelectedItem({});
    setId(null);
    setShowModal(true);
    setAction(action)
  };

  const handleActionButton = (e, cellValues, action) => {
    e.stopPropagation();
    setId(cellValues.row.id);
    setAction(action);
  };

  const columns = [
    { field: 'id', headerName: 'Código', miwidth: 100, flex: 1 },
    { field: 'name', headerName: 'Nombre', minwidth: 350, flex: 1 },
    {
      field: "Editar",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.EDIT) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "Borrar",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          aria-label="delete"
          color="error"
          onClick={(e) => { handleActionButton(e, cellValues, ACTIONS.DELETE) }}
        >
          <DeleteIcon />
        </IconButton>;
      }
    },
  ];

  if (loading) {
    return (
      // <Box sx={{ display: 'flex' }}>
      //     <CircularProgress />
      // </Box>
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>
      {showModal && <NewsletterTypeDetails
        data={selectedItem}
        handleClose={setShowModal}
        action={action}
        handleSave={handleSave}
        handleDelete={handleDelete}
      />}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Tipo <span style={{ color: '#00b0ef' }}>Circulares</span>
          </Typography>
          {/* <Typography variant="h5" gutterBottom>
                {`${TITLES.NEWSLETTER_TYPE}`}
              </Typography> */}
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Button
              endIcon={<AddCircle />}
              variant="contained"
              onClick={() => { handleNew(ACTIONS.NEW) }} autoFocus>{ACTIONS.NEW}</Button>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data} />
    </>
  );
};

export default NewsletterType;
