import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListSubheader,
  Autocomplete
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid'; 
import { Spanish } from '../../../../shared/grid-translations';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ACTIONS } from '../../../../shared/constants';
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils';
import { useSnackbar } from 'notistack';

const PositionHomologationInformAdd = ({
  handleClose,
  handleSave,
  handleSavePositions,
  groupHomologationId,
  name,
  positions,
  prevPositions,
  action
}) => {
  const [selectedgroupHomologationId, setSelectedname] = useState(groupHomologationId);
  const [selectedPositions, setSelectedPositions] = useState(prevPositions || []);
  const [selectedPositionsSend, setSelectedPositionsSend] = useState({
    positions: [], 
    groupHomologationId: selectedgroupHomologationId, 
  });
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [positionId, setPositionId] = useState('');
  const isNameReadOnly = action !== ACTIONS.NEW;
  const [nameValue, setnameValue] = useState(action === ACTIONS.NEW ? '' : name);
  const [validationErrors, setValidationErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (action === ACTIONS.NEW) {
      setnameValue('');
      setSelectedPosition(null);
      setPositionId('');
      setSelectedPositions([]);
      setSelectedPositionsSend({ positions: [], groupHomologationId: null });
    }
  }, [action]);

  const handlenameChange = (e) => {
    setnameValue(e.target.value); // Actualiza el valor con el input del usuario
  };

  const handleAddpositions = (e) => {
    e.stopPropagation();
    if (!selectedPosition || selectedPosition.length === 0) return;

    let newselectedPositions = [...selectedPositions];
  
    selectedPosition.forEach(position => {
      if (!newselectedPositions.some(el => el.id === position.id)) {
        newselectedPositions.push({
          id: position.id,
          name: position.name
        });
      } else {
        enqueueSnackbar(`La posición ${position.name} ya está agregada.`, { variant: 'warning' });
      }
    });

    setSelectedPositions(newselectedPositions);
    setSelectedPositionsSend({
      ...selectedPositionsSend,
      positions: newselectedPositions.map(({ id, name }) => ({
        id: id,
        name: name
      })),
      action: action === ACTIONS.NEW ? 'CREAR' : 'MODIFICAR'
    });
  };

  const onSave = () => {
    const newselectedPositionsSend = {
      positions: selectedPositions.map(({name, id }) => ({
        name: name,
        id: id
      })),
      groupHomologationId: selectedgroupHomologationId,
      action: selectedPositions.length > 0 ? 'MODIFICAR' : 'CREAR' // Si no hay códigos seleccionados, asumir acción 'CREAR'
    };
    handleSavePositions(newselectedPositionsSend);
  };

  const handleDelete = (e, cellValues) => {
    e.stopPropagation();
    const updatedselectedPositions = selectedPositions.filter(el => el.id !== cellValues.row.id);
  
    // Aquí debes actualizar el estado con una función callback para asegurarte de que estás utilizando el estado más reciente.
    setSelectedPositions(updatedselectedPositions);
  };

  const codeColumns = [
    { field: 'id', headerName: 'Id', flex: 1, hide: true},
    { field: 'name', headerName: 'Cargo', minWidth: 100, flex: 1 },
    {
      field: "EditSources",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="error"
          onClick={(e) => { handleDelete(e, cellValues) }}
        >
          <Delete />
        </IconButton>;
      }
    },
  ];

  return (
    <div   >
      <Dialog
        maxWidth="md"
        fullWidth="md"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Detalle Perfil
        </DialogTitle>
        <DialogContent>
          <p />
          <>
            <TextField
              label="Grupo"
              fullWidth
              value={nameValue}
              onChange={handlenameChange}
              required
              InputProps={{
                readOnly: isNameReadOnly,
              }}
              variant="outlined"
            />
            <p />

            <Typography variant="h6" gutterBottom>
              Cargos
            </Typography>
            <Grid container spacing={4}>
            <Grid item xs={12}>
            <FormControl fullWidth margin="dense">
            <Box display="flex" alignItems="center">
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={positions}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setSelectedPosition(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Cargo" fullWidth />}
                style={{ flex: 1 }}
              />
              <IconButton
                color="primary"
                disabled={!selectedPosition}
                onClick={(e) => { handleAddpositions(e) }}
                style={{ marginLeft: 8 }}
              >
                <AddCircleIcon />
              </IconButton>
            </Box>
          </FormControl>
            </Grid>
            </Grid>
            <p />
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ height: 300, width: '100%' }}>
                <DataGrid 
                  columns={codeColumns} 
                  rows={selectedPositions} 
                  localeText={Spanish}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: 'Filas por página'
                    }
                  }}
                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={onSave}
              endIcon={<SaveIcon />}
            >
              {ACTIONS.SAVE}
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              endIcon={<CancelIcon />}
              variant="outlined"
              onClick={handleClose}>{ACTIONS.CANCEL}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PositionHomologationInformAdd;
