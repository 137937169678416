import { 
  getAllPositionHomologationEngrana,
  saveGroupHomologationEngrana, 
  editGroupHomologationEngrana, 
  deleteGroupHomologationEngrana, 
  validateGroupHomologationEngrana, 
  getGroupHomologationEngrana, 
  savePositionsEngrana
 } from '../../../shared/data-services/positionHomologation-service';
import { 
  getEmployeePositions 
} from '../../../shared/data-services/employee-position-service';

export async function getPositionHomologation(){
  const data = await getAllPositionHomologationEngrana();
  return data || [ ];
};

export async function getAllEmployeePosition(){
  const data = await getEmployeePositions();
  return data || [ ];
};

export async function saveSelectedGroupHomologation (id, name){
  const groupHomologation = {
    id, 
    name
  }

  const data = await saveGroupHomologationEngrana(groupHomologation);
  return data;
};

export async function editSelectedGroupHomologation (id, name){
  const groupHomologation = {
    id, 
    name
  }
  await editGroupHomologationEngrana(groupHomologation);
  return;
};

export async function deleteSelectedGroupHomologation (id){
  const data = await deleteGroupHomologationEngrana(id);
  return data;
};

export async function validateSelectedGroupHomologation (id){
  const data = await validateGroupHomologationEngrana(id);
  return data;
};

export async function getSelectedGroupHomologation (id){
  const data = await getGroupHomologationEngrana(id);
  return data;
};

export async function saveAllPositions (positions, groupId){
  const data = await savePositionsEngrana(positions, groupId);
  return data;
};

