import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../../shared/constants';
import DataTable from '../../../shared/data-table';
import {
  getAllProfile,
  getSelectedProfile,
  saveSelectedProfile,
  deleteSelectedProfile,
  getAllPermissions,
  saveSelectedPermissions,
  updateAllProfilesByEmployee,
  getEmployees,
  getAllAirports,
  getProfilesByEmployee
} from './logic';
//import ProfileTypeDetails from '../../Profile-type/Profile-type-detail';
import ManagementEmployeeDetail from '../../gestion/management-employee/management-employeeByProfile-Source';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import ErrorSnackbar from '../../error-snackbar';
import BackDrop from '../../../shared/backdropDE';
import { getAllPermissionsByProfile } from '../../../shared/data-services/management-profile-service';
import { getUser } from '../../../shared/auth-service';
import AlertDialogQuestion from '../../alert-dialog-question';

const ManagementEmployee = () => {
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [action, setAction] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [optionDetails, setOptionDetails] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [uniquePositions, setUniquePositions] = useState([]);
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [airports, setAirports] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState(0);
  const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const positions = [47, 56, 95, 60];

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getEmployees();
          var airportsData = user.airports;

          var position = user.employeePosition.id;
          if (positions.includes(position)) {
             airportsData = await getAllAirports();
          }
          //console.log(airportsData);
          setSelectedAirport(user.airport.id);
          //console.log('info employee');
          //console.log(data);
          setAirports(airportsData);
          const profiles = await getAllProfile();
          setLoading(false);
          setData(data);
          setProfiles(profiles);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
  });

  // useEffect(() => {
  //   async function fetchRow() {
  //     if (id) {
  //       const item = await getSelectedProfile(id);
  //       setSelectedItem(item);
  //       setId(null);
  //       setShowModal(true);
  //     }
  //   }
  //   fetchRow();
  // });
  useEffect(() => {
    if (data) {
      setUniquePositions(getUniquePositions(data));
    }
  }, [data]);

  const getUniquePositions = (data) => {
    const unique = {};
    data.forEach((item) => {
      if (!unique[item.profile]) {
        unique[item.profile] = item;
      }
    });
    //console.log(unique);
    return Object.values(unique);
  };

  const handleDeleteData = async (cellValues) => {
    setAlertQuestionOpen(true);
    setIdDelete(cellValues.row.employee.employeeId);
  };

  const handleCloseAlert = () => {
    setAlertQuestionOpen(false);
    setIdDelete(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = async (profiles) => {
    await updateAllProfilesByEmployee(profiles);
    setShowModal(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleDelete = async (cellValues) => {
    //console.log(cellValues);
    await deleteSelectedProfile(idDelete);
    setShowModal(false);
    setAlertQuestionOpen(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.DELETE);
  };

  const handleNew = async () => {
    setSelectedItem({});
    setId(null);
    setShowModal(true);
    setAction(ACTIONS.NEW);
  };

  const handleActionButton = (e, cellValues, action) => {
    e.stopPropagation();
    setId(cellValues.row.id);
    setAction(action);
  };

  const handleEdit = (e, cellValues) => {
    e.stopPropagation(); 
    //setEmployee(cellValues.row.description)
    //console.log('data de la fila');
    //console.log(cellValues.row);
    setSelectedAirport(cellValues.row.employee.airport.id)
    setEmployeeId(cellValues.row.employee.employeeId);
    fetchOptionDetails(cellValues.row.employee.employeeId); 
  }

  const fetchOptionDetails = async (employeeId) => {
    //console.log("fetchOptionDetails called with id:", profileId);
    //setLoadingOptionDetails(true);
    try {
      const details = await getProfilesByEmployee(employeeId);
      //console.log("Option detailsssss:");
      //console.log(details);
      setOptionDetails(details);
      setShowModal(true);
      setAction(ACTIONS.Edit);
    } catch (error) {
      setError(error.message);
    } finally {
    }
  };

  const columns = [
    { field: 'id', headerName: 'Código', miwidth: 100, flex: 0.2, hide: true },
    {
      field: 'airportId',
      headerName: 'Aeropuerto',
      minwidth: 350,
      flex: 1,
      hide: true,
      valueGetter: (params) => `${params.row.employee.ariport.id || ''}`,
    },
    //{ field: 'employeeId', headerName: 'Cedula', minwidth: 350, flex: 0.3 },
    {
      field: 'employeeId',
      headerName: 'Cedula',
      minwidth: 350,
      flex: 0.3,
      valueGetter: (params) => `${params.row.employee.employeeId || ''}`,
   },
    {
        field: 'name',
        headerName: 'Nombre Completo',
        minwidth: 350,
        flex: 1,
        valueGetter: (params) => `${params.row.employee.name || ''} ${params.row.employee.lastName || ''}`,
    },
    {
      field: 'profileCount',
      headerName: 'Perfiles Asignados',
      type: 'number',
      minwidth: 150,
      flex: 0.15,
      align: 'center', headerAlign: 'center',
      valueGetter: (params) => params.row.profiles.length || 0, // Asegúrate de tener disponible el conteo de perfiles en cada fila
    },
    {
      field: "Editar",
      headerName: "Editar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          color="info"
          onClick={(e) => { handleEdit(e, cellValues) }}
        >
          <Edit />
        </IconButton>;
      }
    },
    {
      field: "Borrar",
      headerName: "Borrar",
      width: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return <IconButton
          aria-label="delete"
          color="error"
          onClick={() => { handleDeleteData(cellValues) }}
        >
          <DeleteIcon />
        </IconButton>;
      }
    },
  ];

  if (loading) {
    return (
      // <Box sx={{ display: 'flex' }}>
      //     <CircularProgress />
      // </Box>
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>
      {showModal && <ManagementEmployeeDetail
        data={optionDetails}
        employeeId={employeeId}
        employee={employee}
        profiles={profiles}
        handleClose={handleCloseModal}
        handleSave={handleSave}
        airportId={selectedAirport}
        airports={airports}
        action={action}
      />}

      {alertQuestionOpen && <AlertDialogQuestion
        handlePositive={handleDelete}
        handleNegative={handleCloseAlert}
        textButtonPositive={ACTIONS.ACCEPT}
        textButtonNegative={ACTIONS.CANCEL}
        dialogContentText={MESSAGES.CONFIRM_DELETE}
        dialogTitle={""}
        open={alertQuestionOpen}
        elemento={""}
      />}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Perfiles Adicionales Por <span style={{ color: '#00b0ef' }}>Empleado</span>
          </Typography>
          {/* <Typography variant="h5" gutterBottom>
                {`${TITLES.Profile_TYPE}`}
              </Typography> */}
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Button
              endIcon={<AddCircle />}
              variant="contained"
              onClick={() => { handleNew() }} autoFocus>{ACTIONS.NEW}</Button>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data} 
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
          }
        }}/>
    </>
  );
};

export default ManagementEmployee;
